import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import { Drawer, Toolbar, Typography, styled, CircularProgress } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Badge from '@mui/material/Badge';

import { OFFSET, WIDTH } from '@/constants/sizes';
import { ButtonStyled } from '@/components/atoms/Button';
import type { ITemplateInputWithLabel } from '@/components/atoms/TemplateInputWithLabel';
import { TemplateInputWithLabel } from '@/components/atoms/TemplateInputWithLabel';

const DrawerCustomStyled = styled(Drawer)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderTopLeftRadius: '4px',
    borderBottomLeftRadius: '4px',
  },
}));

type IIDrawerLikeModal = {
  open: boolean;
  toggleOpen: () => void;
  toggleClose?: () => void;
  items?: ITemplateInputWithLabel[];
  saveForm: (event: React.FormEvent<HTMLFormElement>) => void;
  [k: string]: any;
};

export const DrawerLikeModal = ({
  open,
  toggleOpen,
  saveForm,
  items,
  title,
  children,
  buttonConfirmText,
  isDisabled,
  isLoading,
  toggleClose,
  ...other
}: IIDrawerLikeModal) => {
  const [formHasErrorRaw, setFormHasErrorRaw] = useState<Record<string, boolean>>({});
  const [isError, setIsError] = useState<boolean>(false);

  const setFormHasError = (val: any) => {
    setFormHasErrorRaw((formHasErrorRaw) => ({ ...(formHasErrorRaw ?? {}), ...val }));
  };

  useEffect(() => {
    setIsError(Object.values(formHasErrorRaw).some((item) => item));
  }, [formHasErrorRaw]);

  const handleSveForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (saveForm) {
      saveForm(event);
    }
  };
  const { sx } = other;

  return (
    <DrawerCustomStyled anchor="right" open={open} onClose={toggleOpen}>
      <Toolbar
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexDirection: 'column',
          p: OFFSET.m,
          minWidth: '520px',
          borderTopLeftRadius: '4px',
          borderBottomLeftRadius: '4px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Typography
            sx={{
              fontStyle: 'normal',
              fontWeight: '500',
              fontSize: '24px',
              lineHeight: '30px',
              mb: OFFSET.m,
            }}
          >
            {title}
          </Typography>
          <Badge color="secondary">
            <CloseOutlinedIcon onClick={toggleOpen} />
          </Badge>
        </Box>
        <Box
          component="form"
          autoComplete="off"
          onSubmit={handleSveForm}
          sx={{
            width: '100%',
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            position: other.isFormUpload ? 'relative' : null,
            ...sx,
          }}
        >
          {items?.map(({ id, placeholder, label, ...other }) => (
            <TemplateInputWithLabel
              handleGlobalError={setFormHasError}
              isError={formHasErrorRaw?.[id as string] ?? false}
              key={id}
              id={id}
              name={id}
              placeholder={placeholder}
              label={label}
              style={{ width: WIDTH.s }}
              autoComplete="off"
              {...other}
            />
          ))}
          {children && children}
          {other?.hideSpacer ? (
            <></>
          ) : (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                px: [1],
                flex: 1,
              }}
            />
          )}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              position: other.isFormUpload ? 'absolute' : null,
              bottom: 0,
            }}
          >
            <ButtonStyled text="Отменить" customType="error" variant="outlined" onClick={toggleOpen} />
            <ButtonStyled
              startIcon={isLoading ? <CircularProgress size={20} /> : <></>}
              text={buttonConfirmText ?? 'Сохранить'}
              isSubmit
              disabled={isDisabled || isError || isLoading}
            />
          </Box>
        </Box>
      </Toolbar>
    </DrawerCustomStyled>
  );
};
