import {observable, makeObservable, action, reaction, computed, runInAction} from 'mobx';
import { apiPath } from '@/constants/api';
import type { IButtonGroupItem } from '@/constants/types';
import { createAxiosRequest } from '@/utils/request';
import type { TableStoreType } from '@/stores/TableStore';
import { TableStore } from '@/stores/TableStore';
import type { IContragentReportTableLTM, IRootStore } from '@/stores/type';

export type IDataLTM = {
  id: number;
  code: number;
  value: number;
  report_date: string;
  parameter_date: string;
  inn: string;
  from_api: boolean;
};

export type IGetContragentReports<T> = {
  count: number;
  next?: string;
  previous?: string;
  results: T[];
};

export type IContragentTableStoreWithILTMStore<T> = IContragentReportTableLTM & TableStoreType<T>;

export class ContragentReportTableLTM
  extends TableStore<IDataLTM>
  implements IContragentTableStoreWithILTMStore<IDataLTM> {
  @observable
  public rootStore: IRootStore;

  @observable
  public results: IDataLTM[];

  @observable
  public selectedFilter?: IButtonGroupItem<string>;

  @observable
  public selectedAccountId?: string | number;

  constructor(rootStore: IRootStore) {
    super();
    makeObservable(this);
    this.rootStore = rootStore;
  }

  @action
  getReportsTable = async () => {
    this.rootStore.requestTemplate({
      errorMessage: 'Ошибка получения отчета о финансовых результатах',
      callback: async () => {
        const limit = 500;
        const offset = 0;
        const { data } = await createAxiosRequest<
          { offset: number; limit: number },
          IGetContragentReports<IDataLTM>
        >({
          path: apiPath.ltm.replace('{account_pk}', `${this.selectedAccountId}`),
          method: 'GET',
          params: { limit, offset },
          useToken: true,
        });
        runInAction(() => {
          this.limit = limit;
          this.summary = data.count;
          this.offset = offset;
          const tableData = data.results;
          this.tableBody = [...tableData];
          this.results = [...tableData];
        })
      },
    });
  };

  @action
  clear = () => {
    this.selectedFilter = undefined;
  };
}
