import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';

import { Checkbox, TableRow } from '@mui/material';

import { TableTemplateWithoutStore } from '@/components/Templates/TableTemplateWithoutStore';
import { TableCellStyled } from '@/components/Templates/TableComponentStyled';
import { TextFieldOutlinedStyled } from '@/components/atoms/TextFieldStyled';
import { useRootStore } from '@/stores';
import { IClientSettings } from '@/stores/ScoringPageStore';
import { TAB_BAR_TYPE } from '@/pages/ScoringSettingsPageDetails';
import { apiPath } from '@/constants/api';
import { SETTINGS_STATUS_TO_STATUS } from '@/constants/backendEnum';
import { color } from '@/constants/colors';

export type ITableBodyScoringPageSettings = {
  id: number;
  name: string;
  period: string | number | null;
  weight?: string | number | null;
  score?: string | number | null;
  statusFactor: boolean;
};

const tableHeader = ['Название', 'Диапазон', 'Вес', 'Балл', 'Стоп-фактор'];

export const SimpleInput = ({
  initialVal,
  fieldName,
  onChange,
  ...props
}: {
  initialVal?: string | number | null;
  fieldName: number | string;
  onChange: ({ val }: Record<string, string>) => void;
  [k: string]: any;
}) => {
  const [val, setVal] = React.useState(initialVal);

  const handleChange = (e: any) => {
    const val = e.target.value;
    onChange({ [fieldName]: val });
    setVal(val);
  };

  return <TextFieldOutlinedStyled value={val} size="small" onChange={handleChange} {...props} />;
};

const RenderBodyAnalysisTable = ({
  row,
  filled,
  _isLast,
  dictionary,
  usedUrlTemplate,
  disabled,
  type,
}: {
  row: ITableBodyScoringPageSettings;
  filled: boolean;
  _isLast?: boolean;
  dictionary: { [k: string]: string };
  disabled: boolean;
  usedUrlTemplate: string;
  type: string;
}) => {
  const parentRef = useRef<Record<string | number, any>>({});
  const { scoringPageStore } = useRootStore();
  const usedUrl = usedUrlTemplate.replace('{id}', `${row.id}`);

  useEffect(() => {
    parentRef.current = { ...(parentRef?.current ?? {}), id: row.id }
  }, []);

  const [checked, setChecked] = useState<boolean>(row.statusFactor);
  const onChangeChecked = (_e: any, value: boolean) => {
    setChecked(value);
    parentRef.current = { ...(parentRef.current), 'stop_factor': value }
    onBlur();
  }

  const handleChangeInput = (val: Record<string, string>) => {
    parentRef.current = { ...(parentRef.current), ...val };
  }

  const onBlur = () => {
    //фильтруем все изменения в рефе чтобы они содержали что-то помимо id
    const isNeedUpdate = Object.keys(parentRef.current).some((key) => key !== 'id');
    if (isNeedUpdate) {
      scoringPageStore.silentPatchAlgorithmData({
        url: usedUrl,
        paramsToPatch: parentRef.current,
        type
      });
    }
  }

  return (
    <TableRow key={row.id} sx={{ backgroundColor: filled ? color.light : color.transparent }}>
      <TableCellStyled>{dictionary?.[row.name] ?? '-'}</TableCellStyled>
      <TableCellStyled>
        <SimpleInput initialVal={row.period} fieldName={'diapason'} onChange={handleChangeInput} disabled={disabled} onBlur={onBlur} />
      </TableCellStyled>
      {
        type !== TAB_BAR_TYPE.RELATED &&
        <TableCellStyled>
          <SimpleInput initialVal={row.weight} fieldName={'weight'} onChange={handleChangeInput} disabled={disabled} onBlur={onBlur} />
        </TableCellStyled>
      }
      {
        type !== TAB_BAR_TYPE.RELATED &&
        <TableCellStyled>
          <SimpleInput initialVal={row.score} fieldName={'score'} onChange={handleChangeInput} disabled={disabled} onBlur={onBlur} />
        </TableCellStyled>
      }

      <TableCellStyled align="right">
        <Checkbox checked={checked} disabled={disabled} onChange={onChangeChecked} />
      </TableCellStyled>
    </TableRow>
  );
};

const mapToLikeStore = (data: IClientSettings[]) => (
  data.map((item) => ({
    id: item.id,
    name: item.rule_name ?? '',
    period: item.diapason ?? '',
    weight: item.weight ?? '',
    score: item.score ?? '',
    statusFactor: Boolean(item.stop_factor),
  }))
)

export const ScoringSettingsPageDetailsTable = observer(({ type }: { type: string }) => {
  const prevId = useRef('')
  const { usedId } = useParams<{ usedId: string }>();
  const { pathname } = useLocation();
  const id = pathname?.split('/')?.[2] ?? usedId;

  const usedUrlTemplate = React.useRef('');

  const { scoringPageStore, userStore, isLoading } = useRootStore();
  const currSettings = useMemo(() => scoringPageStore?.settings?.find((item) => item.id?.toString() === id), [id, scoringPageStore?.settings]);

  const {
    settingsClient,
    settingsDebtor,
    settingsRelation,
    settings,
  } = scoringPageStore;

  useEffect(() => {
    if (id && settings.length === 0) {
      scoringPageStore.getSettings();
    }

    if (type === TAB_BAR_TYPE.CLIENT) {
      usedUrlTemplate.current = apiPath.settingsClientByIdPatch.replace('{algorithm_pk}', `${id}`);
      if (id && settingsClient.length === 0 && prevId.current !== id) {
        prevId.current = id
        scoringPageStore.getSettingsClientById(id);
      }
    }

    else if (type === TAB_BAR_TYPE.DEBTOR) {
      usedUrlTemplate.current = apiPath.settingsDebtorByIdPatch.replace('{algorithm_pk}', `${id}`);
      if (id && settingsDebtor.length === 0) {
        scoringPageStore.getDebtorClientById(id);
      }
    }

    else if (type === TAB_BAR_TYPE.RELATED) {
      usedUrlTemplate.current = apiPath.settingsRelationByIdPatch.replace('{algorithm_pk}', `${id}`);
      if (id && settingsRelation.length === 0) {
        scoringPageStore.getRelationClientById(id);
      }
    }

  }, [
    id,
    scoringPageStore,
    settings.length,
    settingsClient.length,
    settingsDebtor.length,
    settingsRelation.length,
    type
  ])

  const likeStore = useMemo(() => {
    if (type === TAB_BAR_TYPE.DEBTOR) {
      return mapToLikeStore(settingsDebtor);
    }

    else if (type === TAB_BAR_TYPE.RELATED) {
      return mapToLikeStore(settingsRelation);
    }

    return mapToLikeStore(settingsClient ?? []);

  }, [settingsClient, settingsDebtor, settingsRelation, type])

  const usedTableHeader = useMemo(() => type === TAB_BAR_TYPE.RELATED ? ['Название', 'Диапазон', 'Стоп-фактор'] : tableHeader, [type])

  return (

    <TableTemplateWithoutStore<ITableBodyScoringPageSettings>
      tableHeader={usedTableHeader}
      RenderComponent={React.memo(
        (props: any) => <RenderBodyAnalysisTable
          {...props}
          dictionary={userStore.dictForScoreSetting}
          usedUrlTemplate={usedUrlTemplate.current}
          disabled={currSettings?.status !== SETTINGS_STATUS_TO_STATUS.Draft}
          type={type}
        /> as any,
        (prev: any, next: any) => prev.isLoading !== next.isLoading
      ) as any}
      store={{
        renderedRows: likeStore,
      }}
      isLoading={isLoading}
    />
  );
});
