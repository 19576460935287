import React, { useEffect, useState } from 'react';

import Typography from '@mui/material/Typography';
import { Box, Button, Grid, Modal } from '@mui/material';

import { ButtonStyled } from '@/components/atoms/Button';
import { TemplateInputWithLabelMonitoring } from '@/components/atoms/TemplateInputWithLabel';
import { color } from '@/constants/colors';
import { InputLabelStyled } from '@/components/atoms/InputLabelStyled';
import TextareaAutosizeStyled from '@/components/atoms/TextareaAutosizeStyled';
import { Download } from '@/assets';
import { TableMonitoring } from '@/stores/MonitoringStore';
import { useRootStore } from '@/stores';
import { formatDateToday } from '@/utils';

export const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 632,
  height: 625,
  bgcolor: 'background.paper',
  borderRadius: 4,
  boxShadow: 24,
  padding: '30px 44px',
  textAlign: 'center',
  outline: 'none',
};

interface ModalMonitoring {
  open: boolean;
  handleClose: () => void;
  handleConfirm?: () => void;
  title?: string;
  saveButtonText?: string;
  cancelButtonText?: string;
  uniqId?: string;
  row: TableMonitoring;
  downloadMonitoringById: () => Promise<void>;
}

export const ModalMonitoringResults = ({
  open,
  handleClose,
  handleConfirm,
  title,
  saveButtonText,
  cancelButtonText,
  uniqId,
  row,
  downloadMonitoringById,
}: ModalMonitoring) => {
  const { monitoringStore, userStore } = useRootStore();

  const [nameMonitoring, setNameMonitoring] = useState<string | undefined>(row.account?.name);
  const [innMonitoring, setInnMonitoring] = useState<string | undefined>(row.account?.inn);
  const [nameSignalMonitoring, setNameSignalMonitoring] = useState<string>(row.name_signal);
  const [industryMonitoring, setIndustryMonitoring] = useState<string>(row.industry);
  const [segmentMonitoring, setSegmentMonitoring] = useState<string>(row.segment);
  const [companyGroupMonitoring, setCompanyGroupMonitoring] = useState<string>(row.company_group);
  const [scoreMonitoring, setScoreMonitoring] = useState<string>(row.score);
  const [commentMonitoring, setCommentMonitoring] = useState<string>(row.comment);

  useEffect(() => {
    setScoreMonitoring(row.score);
    setCommentMonitoring(row.comment);
  }, [row]);

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setScoreMonitoring(e.target.value);
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCommentMonitoring(e.target.value);
  };

  const updateMonitoringById = async () => {
    const dataToSave: {
      monitoringId: number;
      score: string;
      updated_by: number;
      comment: string;
      date_status: string;
    } = {
      monitoringId: 0,
      score: '',
      updated_by: 0,
      comment: '',
      date_status: '',
    };

    dataToSave.monitoringId = row.id;
    dataToSave.updated_by = userStore.results.pk;
    dataToSave.score = scoreMonitoring;
    dataToSave.comment = commentMonitoring;
    dataToSave.date_status = formatDateToday;

    await monitoringStore.updateReportMonitoringById(dataToSave);
    handleClose();
  };

  return (
    <Modal
      key={uniqId}
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        {title && (
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{
              fontWeight: '600',
              fontSize: '25px',
              lineHeight: '120%',
              whiteSpace: 'pre-line',
              color: color.graphite_black,
              marginBottom: '30px',
            }}
          >
            {title}
          </Typography>
        )}

        <Box>
          <Grid container columnSpacing={2} columns={12}>
            <Grid item xs={6}>
              <TemplateInputWithLabelMonitoring
                label="Наименование контрагента"
                value={nameMonitoring}
                placeholder={'Данные отсутствуют'}
                disabled={true}
              />
            </Grid>
            <Grid item xs={6}>
              <TemplateInputWithLabelMonitoring
                label="ИНН"
                value={innMonitoring}
                placeholder={'Данные отсутствуют'}
                disabled={true}
              />
            </Grid>
            <Grid item xs={6}>
              <TemplateInputWithLabelMonitoring
                label="Выявленный риск-сигнал"
                value={nameSignalMonitoring}
                placeholder={'Данные отсутствуют'}
                disabled={true}
              />
            </Grid>
            <Grid item xs={6}>
              <TemplateInputWithLabelMonitoring
                label="Отрасль"
                value={industryMonitoring}
                placeholder={'Данные отсутствуют'}
                disabled={true}
              />
            </Grid>
            <Grid item xs={6}>
              <TemplateInputWithLabelMonitoring
                label="Сегмент"
                value={segmentMonitoring}
                placeholder={'Данные отсутствуют'}
                disabled={true}
              />
            </Grid>
            <Grid item xs={6}>
              <TemplateInputWithLabelMonitoring
                label="Принадлежность к группе"
                value={companyGroupMonitoring}
                placeholder={'Данные отсутствуют'}
                disabled={true}
              />
            </Grid>
            <Grid item xs={6}>
              <TemplateInputWithLabelMonitoring
                label="Скоринг"
                placeholder={'Введите значение'}
                onChange={handleChangeInput}
                value={scoreMonitoring}
              />
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            margin: '0',
            alignItems: 'start',
            flexDirection: 'column',
            display: 'flex',
          }}
        >
          <InputLabelStyled
            sx={{
              margin: '0 0 5px 0',
              fontSize: '13px',
              lineHeight: '18px',
              fontWeight: 500,
            }}
          >
            Комментарий
          </InputLabelStyled>
          <TextareaAutosizeStyled
            ariaLabel="Комментарий"
            placeholder="Добавьте комментарий"
            value={commentMonitoring}
            onChange={handleChange}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
          onClick={downloadMonitoringById}
        >
          <Button
            sx={{
              display: 'flex',
              alignItems: 'center',
              mt: '15px',
              gap: '8px',
            }}
          >
            <Typography
              sx={{
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: 'auto',
                color: color.light_black,
                cursor: 'pointer',
              }}
            >
              {'Скачать файл'}
            </Typography>
            <Download />
          </Button>
        </Box>
        <Box
          sx={{
            mt: '30px',
            display: 'flex',
            justifyContent: 'center',
            gap: '50px',
          }}
        >
          <ButtonStyled onClick={handleClose} text={cancelButtonText ?? 'Отменить'} variant="text" />
          <ButtonStyled
            disabled={!commentMonitoring && !scoreMonitoring}
            text={saveButtonText ?? 'Сохранить'}
            onClick={updateMonitoringById}
            isFilter={true}
            sx={{ textTransform: 'none' }}
            height="40px"
          />
        </Box>
      </Box>
    </Modal>
  );
};
