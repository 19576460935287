import React from 'react';

import { Button, ButtonGroup, styled } from '@mui/material';

import { OFFSET } from '@/constants/sizes';
import { color } from '@/constants/colors';

interface IButtonGroupItemStyled {
  selected?: boolean;
}

//@ts-ignore
const ButtonGroupItemStyled = styled(Button, {
  shouldForwardProp: (selected) => selected,
})<IButtonGroupItemStyled>(({ theme, selected }) => ({
  height: '36px',
  padding: `${OFFSET.xxs} ${OFFSET.xxxs}`,
  borderColor: color.light_green,
  background: selected ? color.light_green : color.transparent,
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '14px',
  color: selected ? color.white : color.light_green,
  textTransform: 'none',
  '&:hover': {
    borderColor: color.light_green,
    background: color.light_green,
    color: color.white,
  },
  '&:active': {
    borderColor: color.green_jungle,
    background: color.green_jungle,
    color: color.white,
  },
}));

export interface IButtonGroupItem {
  name: string;
  onClick?: () => void;
}

export const ButtonGroupStyled = ({
  items,
  outOnClick,
  selectedName,
}: {
  items: IButtonGroupItem[];
  outOnClick?: (name: string) => void;
  selectedName: string;
}) => {
  const handleClick = (event: any) => {
    if (outOnClick) {
      outOnClick(event?.target?.innerText);
    }
  };
  return (
    <ButtonGroup variant="outlined" aria-label="outlined button group" onClick={handleClick}>
      {items.map(({ name, onClick }) => (
        <ButtonGroupItemStyled key={name} selected={selectedName === name} onClick={onClick}>
          {name}
        </ButtonGroupItemStyled>
      ))}
    </ButtonGroup>
  );
};
