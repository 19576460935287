import React from "react";
import { TextFieldOutlinedStyled } from "@/components/atoms/TextFieldStyled";

export const SimpleInputCustom = ({
  initialVal,
  fieldName,
  onChange,
  typeVal,
  ...props
}: {
  initialVal?: string | number | null;
  typeVal: "number" | "null" | "string",
  fieldName: number | string;
  onChange: ({ val }: Record<string, any>) => void;
  [k: string]: any;
}) => {

  const handleChange = (e: any) => {
    const val = e.target.value;
    
    if (typeVal === "number" || typeVal === "null") {
      if ((/^[+-]?((-|[0-9])+([.][0-9]*)?|[.][0-9]+)$/).test(val) || val === "") {
        onChange({ [fieldName]: val === "" ? null : val });
      }
      return;
    }
    onChange({ [fieldName]: val });
  };

  return <TextFieldOutlinedStyled value={initialVal ?? ""} size="small" onChange={handleChange} {...props} />;
};
