import React, { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';

import { Box, CircularProgress, InputAdornment } from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

import { ROUTES } from '@/constants';
import { OFFSET, WIDTH } from '@/constants/sizes';
import type { IButtonGroupItem } from '@/constants/types';
import { useRootStore } from '@/stores';
import type { ITableBodyAdministrationAssessmentTemplate } from '@/stores/freskoApi/AdministrationAssessmentTemplateStore';
import { dateFormatterWithHoursMinutes } from '@/utils';
import { ButtonGroupStyled } from '@/components/common/ButtonGroupStyled';
import { LabelLikeHintWithText } from '@/components/common/LabelLikeHintWithText';
import { TableRowStyled, TableCellSmallPadding } from '@/components/Templates/TableComponentStyled';
import { TableTemplate } from '@/components/Templates/TableTemplate';
import { ModelEvaluationModal } from './ModelEvaluationModal';
import { MoreMenuTabData } from '../AdministrationTabData/MoreMenuTabData';
import { ModalDelete } from '@/components/common/ModalDelete';
import { useModalDelete } from '@/components/common/hooks/useModalDelete';
import { SETTINGS_STATUS, SETTINGS_STATUS_TO_STATUS, STATUS_TO_STATUS } from '@/constants/backendEnum';
import { StatusBadge } from '@/components/atoms/Bage/StatusBadge';
import type { IBusinessUnit } from '@/stores/BusinessUnitStore';
import { color } from '@/constants/colors';
import { TextFieldOutlinedStyled } from '@/components/atoms/TextFieldStyled';
import {runInAction} from "mobx";

const FILTER_TYPE = {
  ACTIVE: 'Active',
  DRAFT: 'Draft',
  INACTIVE: 'Archive',
  ALL: 'ALL',
} as const;

const buttonItems: IButtonGroupItem<string>[] = [
  {
    name: 'Все',
    type: FILTER_TYPE.ALL,
  },
  {
    name: 'Активные',
    type: FILTER_TYPE.ACTIVE,
  },
  {
    name: 'Черновики',
    type: FILTER_TYPE.DRAFT,
  },
  {
    name: 'Архив',
    type: FILTER_TYPE.INACTIVE,
  },
];

const tableHeader = ['Название', 'Контекст оценки', 'Подразделение', 'Статус', 'Изменен', ''];

const RenderRowModelEvaluation = ({
  row,
  filled,
  setEditData,
  handleOpenDeleteModal,
  setDuplicateData,
  allBusinessUnits,
}: {
  row: ITableBodyAdministrationAssessmentTemplate;
  filled: boolean;
  setEditData: (val: ITableBodyAdministrationAssessmentTemplate) => void;
  handleOpenDeleteModal: (val: ITableBodyAdministrationAssessmentTemplate) => void;
  setDuplicateData: (val: ITableBodyAdministrationAssessmentTemplate) => void;
  allBusinessUnits?: IBusinessUnit[];
}) => {
  const { name, version, status, updateDate, updateName, contextName, contextObjectName, bu } = row;
  const nameBu = allBusinessUnits?.find((item) => item.id === bu)?.name;

  const navigate = useNavigate();

  const onRowClick = (e: any) => {
    e.preventDefault();
    if (navigate) {
      navigate(`${ROUTES.settings}${ROUTES.administration}${ROUTES.model}/${row.id}`);
    }
  };

  const onDelete = () => {
    handleOpenDeleteModal(row);
  };

  // const onEdit = () => {
  //   setEditData(row)
  // }

  const onDuplicate = () => {
    setDuplicateData(row);
  };

  const type = () => {
    if (row.status === SETTINGS_STATUS_TO_STATUS.Draft) {
      return STATUS_TO_STATUS.New;
    }

    if (row.status === SETTINGS_STATUS_TO_STATUS.Active) {
      return STATUS_TO_STATUS.Active;
    }

    return STATUS_TO_STATUS.Inactive;
  };

  return (
    <>
      <TableRowStyled
        key={row.id}
        sx={{ backgroundColor: filled ? color.light : color.transparent }}
        onClick={onRowClick}
      >
        <TableCellSmallPadding>
          <LabelLikeHintWithText reverse title={`Версия: ${version}`} value={name} />
        </TableCellSmallPadding>
        <TableCellSmallPadding>
          <LabelLikeHintWithText reverse title={contextName} value={contextObjectName} />
        </TableCellSmallPadding>
        <TableCellSmallPadding>
          <LabelLikeHintWithText reverse value={nameBu ?? '-'} />
        </TableCellSmallPadding>
        <TableCellSmallPadding>
          <StatusBadge
            type={type() as any}
            text={SETTINGS_STATUS[row.status as keyof typeof SETTINGS_STATUS]}
            children={undefined}
          />
        </TableCellSmallPadding>
        <TableCellSmallPadding>
          <LabelLikeHintWithText reverse title={updateName} value={dateFormatterWithHoursMinutes(updateDate)} />
        </TableCellSmallPadding>
        <TableCellSmallPadding align="right">
          <MoreMenuTabData
            onDelete={onDelete}
            // onEdit={onEdit}
            onDuplicate={onDuplicate}
          />
        </TableCellSmallPadding>
      </TableRowStyled>
    </>
  );
};

const ModelEvaluationRaw = () => {
  const { administrationAssessmentTemplateStore, businessUnitStore, isLoading } = useRootStore();
  const [selectedFilter, setSelectedFilter] = useState(
    administrationAssessmentTemplateStore.selectedFilter || buttonItems[0],
  );
  const [query, setQuery] = useState<string | undefined>(undefined);
  const [editData, setEditData] = useState<ITableBodyAdministrationAssessmentTemplate | undefined>();
  const [duplicateData, setDuplicateData] = useState<ITableBodyAdministrationAssessmentTemplate | undefined>();
  const {
    open,
    handleClose,
    handleOpen,
    deletedValue: data,
  } = useModalDelete<ITableBodyAdministrationAssessmentTemplate>();

  useEffect(() => {
    administrationAssessmentTemplateStore.getAdministrationAssessmentTemplate();
  }, []);

  useEffect(() => {
    if (!administrationAssessmentTemplateStore.isLoading) {
      administrationAssessmentTemplateStore.getDropdownContextValues();
    }
  }, [administrationAssessmentTemplateStore.isLoading]);

  const handleChangeButtonFilter = (selectedName: string) => {
    const selectedItem = buttonItems.find(({ name }) => name === selectedName) ?? buttonItems[0];
    setSelectedFilter(selectedItem);
    administrationAssessmentTemplateStore.selectedFilter = selectedItem;
  };

  const handleDelete = () => {
    if (data) {
      administrationAssessmentTemplateStore.deleteTemplate(data.id);
      handleClose();
    }
  };

  const handleDuplicate = () => {
    if (duplicateData) {
      administrationAssessmentTemplateStore.duplicateAdministrationAssessmentTemplate({ template: duplicateData });
      setDuplicateData(undefined);
    }
  };

  useEffect(() => {
    if (duplicateData) {
      handleDuplicate();
    }
  }, [duplicateData]);

  const onChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };

  useEffect(() => {
    let handler: NodeJS.Timeout;
    if (query?.length === 0) {
      handler = setTimeout(() => {
        runInAction(() => {
          administrationAssessmentTemplateStore.searchString = query;
        })
      }, 1000);
    }

    if (isLoading || (query?.length as number) < 3) {
      return undefined;
    }

    handler = setTimeout(() => {
      runInAction(() => {
        administrationAssessmentTemplateStore.searchString = query;
      })
    }, 1000);

    return () => {
      if (handler) {
        clearTimeout(handler);
      }
    };
  }, [isLoading, administrationAssessmentTemplateStore, query]);

  return (
    <>
      <Box
        sx={{
          mt: OFFSET.sm,
          mb: OFFSET.sm,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <ButtonGroupStyled
          items={buttonItems}
          outOnClick={handleChangeButtonFilter}
          selectedName={selectedFilter.name}
        />
        <TextFieldOutlinedStyled
          onChange={onChangeSearch}
          id="search"
          name="search"
          size="small"
          type="text"
          autoComplete="no"
          placeholder="Найти..."
          sx={{ width: WIDTH.s }}
          value={query}
          endAdornment={
            isLoading && (
              <InputAdornment position="end">
                <CircularProgress size={20} />
              </InputAdornment>
            )
          }
          startAdornment={
            <InputAdornment position="start">
              <SearchOutlinedIcon />
            </InputAdornment>
          }
        />
      </Box>
      <TableTemplate<ITableBodyAdministrationAssessmentTemplate>
        tableHeader={tableHeader}
        RenderComponent={(props) => (
          <RenderRowModelEvaluation
            {...props}
            setEditData={setEditData}
            handleOpenDeleteModal={handleOpen}
            setDuplicateData={setDuplicateData}
            allBusinessUnits={businessUnitStore.allBusinessUnits}
          />
        )}
        store={administrationAssessmentTemplateStore}
        isLoading={administrationAssessmentTemplateStore.isLoading}
      />
      <ModelEvaluationModal editData={editData} setEditData={setEditData} />
      <ModalDelete
        uniqId="Data_table_modal"
        open={open}
        handleClose={handleClose}
        handleDelete={handleDelete}
        title="Вы действительно хотите удалить модель?"
        descriptions={[data?.name ?? '', data?.contextObjectName ?? '', data?.contextName ?? '']}
      />
    </>
  );
};

ModelEvaluationRaw.displayName = 'ModelEvaluation';

export const ModelEvaluation = observer(ModelEvaluationRaw);
