import {observable, makeObservable, action, reaction, computed, runInAction} from 'mobx';
import {FILTER_TYPE_REPORT} from '@/constants';
import {apiPath} from '@/constants/api';
import type {IButtonGroupItem} from '@/constants/types';
import {createAxiosRequest} from '@/utils/request';
import type {TableStoreType} from '@/stores/TableStore';
import {TableStore} from '@/stores/TableStore';
import type {IContragentReportTableBalanceStore, IRootStore} from '@/stores/type';

export type DataRowBalance = {
    id: number;
    form_startvalue: number;
    form_endvalue: number;
    inn: string;
    ogrn: number;
    form_year: number;
    organizationtype: string;
    form_code: number;
    form_string_name: string;
    capital_portion: number | null;
};
export type IGetContragentReports<T> = {
    count: number;
    next?: string;
    previous?: string;
    results: T[];
};

export type IContragentTableStoreWithITableBalanceStore<T> = IContragentReportTableBalanceStore & TableStoreType<T>;

export class ContragentReportTableBalanceStore
    extends TableStore<DataRowBalance>
    implements IContragentTableStoreWithITableBalanceStore<DataRowBalance> {
    @observable
    public rootStore: IRootStore;

    @observable
    public results: DataRowBalance[];

    @observable
    public selectedFilter?: IButtonGroupItem<string>;

    @observable
    public selectedAccountId?: string | number;

    constructor(rootStore: IRootStore) {
        super();
        makeObservable(this);
        this.rootStore = rootStore;

        reaction(
            () => this.rowsPerPageChange,
            (value, _previousValue) => {
                if (this.maxSelectedPage <= value) {
                    this.selectedFilter?.type === FILTER_TYPE_REPORT.BALANCE
                        ? this.getReportsTableByPeriod()
                        : this.getReportsTable();
                }
            },
        );

        reaction(
            () => this.selectedPage,
            (value, _previousValue) => {
                if (this.maxSelectedPage <= value) {
                    this.selectedFilter?.type === FILTER_TYPE_REPORT.BALANCE
                        ? this.getReportsTableByPeriod()
                        : this.getReportsTable();
                }
            },
        );

        reaction(
            () => this.selectedFilter?.type,
            (value, previousValue) => {
                if (previousValue !== value) {
                    this.tableBody = [];
                    this.selectedPage = 0;
                    value === FILTER_TYPE_REPORT.BALANCE ? this.getReportsTableByPeriod() : this.getReportsTable();
                }
            },
        );

        reaction(
            () => this.selectedAccountId,
            (value, _previousValue) => {
                if (value !== _previousValue) {
                    this.selectedFilter?.type === FILTER_TYPE_REPORT.BALANCE
                        ? this.getReportsTableByPeriod()
                        : this.getReportsTable();
                }
            },
        );

        reaction(
            () => this.searchString,
            (value, previousValue) => {
                if (previousValue !== value) {
                    this.selectedFilter?.type === FILTER_TYPE_REPORT.BALANCE
                        ? this.getReportsTableByPeriod()
                        : this.getReportsTable();
                }
            },
        );
    }

    @computed
    public get isSearch() {
        return this.searchString || this.searchString?.trim();
    }

    @action
    getReportsTable = async () => {
        this.rootStore.requestTemplate({
            errorMessage: 'Ошибка получения отчета баланса',
            callback: async () => {
                const limit = 500;
                const offset = 0;
                const {data} = await createAxiosRequest<
                    { offset: number; limit: number },
                    IGetContragentReports<DataRowBalance>
                >({
                    path: apiPath.konturBalance.replace('{account_pk}', `${this.selectedAccountId}`),
                    method: 'GET',
                    params: {limit, offset},
                    useToken: true,
                });
                runInAction(() => {
                    this.limit = limit;
                    this.summary = data.count;
                    this.offset = offset;
                    const tableData = data.results;
                    this.tableBody = [...tableData];
                    this.results = [...tableData];
                })
            },
        });
    };

    @action
    getReportsTableByPeriod = async () => {
        this.rootStore.requestTemplate({
            errorMessage: 'Ошибка получения отчета по балансу',
            callback: async () => {
                const searchString = this.isSearch ? this.searchString?.trim() : undefined;

                const limit = this.isSearch ? 10 : this.rowsPerPageChange;
                const offset = this.isSearch ? 0 : this.rowsPerPageChange * this.selectedPage;
                const {data} = await createAxiosRequest<
                    { offset: number; limit: number; search?: string },
                    IGetContragentReports<DataRowBalance>
                >({
                    path: apiPath.konturBalance.replace('{account_pk}', `${this.selectedAccountId}`),
                    method: 'GET',
                    params: {offset, limit, search: searchString},
                    useToken: true,
                });
                runInAction(() => {
                    this.limit = limit;
                    this.summary = data.count;
                    this.offset = offset;
                    const tableData = data.results;
                    this.tableBody = [...tableData];
                })
            },
        });
    };
    @action
    clear = () => {
        // Реализуйте здесь логику для очистки данных в хранилище
        this.selectedFilter = undefined;
    };
}
