import React, { FC, useEffect, useRef, useState } from 'react';
import style from '@/components/Contagents/ContactTabs/ContractReportTable.module.scss';

import { useRootStore } from '@/stores';
import { DataRowBalance } from '@/stores/ContragentReportTableBalanceStore';
import { numberWithSpaces, yearsKontur } from '@/utils/reportTable';
import { observer } from 'mobx-react';
import ContractReportTableInputKontur
  from '@/components/Contagents/ContactTabs/ContractReportTableInput/ContractReportTableInputKontur';

type StaticRowsKontur = {
  name: string;
  code: number;
};

export type TableCell = {
  year: number;
  form_endvalue: number | string;
};

export const StaticRowsKontur: StaticRowsKontur[] = [
  { name: 'Актив', code: NaN },
  { name: 'I. Внеоборотные активы', code: NaN },
  { name: 'Нематериальные активы', code: 1110 },
  { name: 'Результаты исследований и разработок', code: 1120 },
  { name: 'Нематериальные поисковые активы', code: 1130 },
  { name: 'Материальные поисковые активы', code: 1140 },
  { name: 'Основные средства', code: 1150 },
  { name: 'Доходные вложения в материальные ценности', code: 1160 },
  { name: 'Финансовые вложения', code: 1170 },
  { name: 'Отложенные налоговые активы', code: 1180 },
  { name: 'Прочие внеоборотные активы', code: 1190 },
  { name: 'Итого по разделу I', code: 1100 },
  { name: 'II. Оборотные активы', code: NaN },
  { name: 'Запасы', code: 1210 },
  { name: 'Налог на добавленную стоимость по приобретенным ценностям', code: 1220 },
  { name: 'Дебиторская задолженность', code: 1230 },
  { name: 'Финансовые вложения (за исключением денежных эквивалентов)', code: 1240 },
  { name: 'Денежные средства и денежные эквиваленты', code: 1250 },
  { name: 'Прочие оборотные активы', code: 1260 },
  { name: 'Итого по разделу II', code: 1200 },
  { name: 'Баланс', code: 1600 },
  { name: '', code: NaN },
  { name: 'Пассив', code: NaN },
  { name: 'III. Капитал и резервы', code: NaN },
  { name: 'Уставный капитал (складочный капитал, уставный фонд, вклады товарищей)', code: 1310 },
  { name: 'Собственные акции, выкупленные у акционеров', code: 1320 },
  { name: 'Переоценка внеоборотных активов', code: 1340 },
  { name: 'Добавочный капитал (без переоценки)', code: 1350 },
  { name: 'Резервный капитал', code: 1360 },
  { name: 'Нераспределенная прибыль (непокрытый убыток)', code: 1370 },
  { name: 'Итого по разделу III', code: 1300 },
  { name: 'IV. Долгосрочные обязательства', code: NaN },
  { name: 'Заёмные средства', code: 1410 },
  { name: 'Отложенные налоговые обязательства', code: 1420 },
  { name: 'Оценочные обязательства', code: 1430 },
  { name: 'Прочие обязательства', code: 1450 },
  { name: 'Итого по разделу IV', code: 1400 },
  { name: 'V. Краткосрочные обязательства', code: NaN },
  { name: 'Заёмные средства', code: 1510 },
  { name: 'Кредиторская задолженность', code: 1520 },
  { name: 'Доходы будущих периодов', code: 1530 },
  { name: 'Оценочные обязательства', code: 1540 },
  { name: 'Прочие обязательства', code: 1550 },
  { name: 'Итого по разделу V', code: 1500 },
  { name: 'Баланс', code: 1700 },
];

const specialCodes = [NaN, 1200, 1600, 1300, 1400, 1500, 1700];


const ContractReportTableBalanceKontur: FC = observer(() => {
  const { contragentReportTableBalanceStore } = useRootStore();
  const results = contragentReportTableBalanceStore.results;
  const [resultsBalance, setResultsBalance] = useState<DataRowBalance[]>(results);

  useEffect(() => {
    setResultsBalance(results);
  }, [results]);

  const getTableData = (): Record<string, TableCell> => {
    const tableData: Record<string, TableCell> = {};

    StaticRowsKontur.forEach((staticRow) => {
      const { name, code } = staticRow;
      yearsKontur.forEach(async (year) => {
        const matchingResult =
          resultsBalance && resultsBalance.find((item) => item.form_year === year && item.form_code === code);
        const key = `${code}-${year}`;

        if (matchingResult) {
          const formattedValue = numberWithSpaces(matchingResult.form_endvalue);
          tableData[key] = {
            year,
            form_endvalue: formattedValue,
          };
        } else {
          tableData[key] = {
            year,
            form_endvalue: ' ',
          };
        }
      });
    });
    return tableData;
  };

  const [tableData, setTableData] = useState(getTableData());

  useEffect(() => {
    const newTableData = getTableData();
    setTableData(newTableData);
  }, [resultsBalance]);

  return (
    <>
      <div className={`${style.wrapper} ${style.wrapper__Kontur}`}>
        <h4 className={style.wrapper__header}>Бухгалтерский Баланс (Форма 1)</h4>
        <table className={style.balanceTable}>
          <thead className={style.balanceTable__header}>
            <tr>
              <th className={style.balanceTable__header__name}>Наименование</th>
              <th className={style.balanceTable__header__code}>Код строки</th>
              {yearsKontur.map((year, index) => (
                <React.Fragment key={index}>
                  <th className={style.balanceTable__header__year}>
                    <div className={style.balanceTable__header__year__container}>31.12.{year}</div>
                  </th>
                </React.Fragment>
              ))}
            </tr>
          </thead>
          <tbody>
            {StaticRowsKontur.map((staticRow, index) => {
              const { name, code } = staticRow;

              return (
                <tr
                  className={`${style.balanceTable__container} ${
                    specialCodes.includes(code) ? style.balanceTable__specialRow : ''
                  }`}
                  key={index}
                >
                  <td className={style.balanceTable__name}>{name}</td>
                  <td className={`${style.balanceTable__code} ${!isNaN(code) ? '' : style.balanceTable__nanRow}`}>
                    {code}
                  </td>
                  <ContractReportTableInputKontur
                    code={code}
                    tableData={tableData}
                  />
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
});

export default ContractReportTableBalanceKontur;
