import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';

import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  styled,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
} from '@mui/material';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

import { useRootStore } from '@/stores';
import type { IButtonGroupItem } from '@/constants/types';
import { TableRowStyled } from '@/components/Templates/TableComponentStyled';
import { EditButtons } from '@/components/Templates/EditButtons';
import { ButtonGroupStyled } from '@/components/common/ButtonGroupStyled';
import type { ITableBodyContragentReport, ITableBodyContragentReportByPeriod } from '@/stores/ContragentReportStore';
import { OFFSET } from '@/constants/sizes';
import { LabelLikeHintWithText } from '@/components/common/LabelLikeHintWithText';
import { LIST_NUMBER_REPORT } from '@/constants/backendEnum';
import { dateFormatterDefault, formatToCurrency } from '@/utils';
import { Chevron } from '@/assets';
import { FILTER_TYPE_REPORT } from '@/constants';
import ContractReportTableBalance from '@/components/Contagents/ContactTabs/ContractReportTableBalance';
import ContractReportTableFinancialResults from '@/components/Contagents/ContactTabs/ContractReportTableFinancialResults';
import ContractReportTablePerQuarter from '@/components/Contagents/ContactTabs/ContractReportTablePerQuarter';
import ContractReportTableKonturReporting from '@/components/Contagents/ContactTabs/ContractReportTableKonturReporting';
import { color } from '@/constants/colors';
import { useYears } from '@/utils/useYears';
import ContractReportTableLTM from './ContractReportTableLTM';
import { runInAction } from 'mobx';
import { ButtonStyled } from '@/components/atoms/Button';
import { useParams } from 'react-router-dom';

export const TableCellStyled = styled(TableCell)<{ verticalAlign?: string; isSmall?: boolean }>(
  ({ theme, verticalAlign, isSmall }) => ({
    overflowWrap: 'anywhere',
    border: 0,
    color: 'inherit',
    verticalAlign: verticalAlign ?? 'baseline',
  }),
);

export const TableBodyStyled = styled(TableBody)(({ theme }) => ({
  '::-webkit-scrollbar': {
    width: '0.4em',
  },
  '::-webkit-scrollbar-track': {
    '-webkit-box-shadow': `inset 0 0 6px ${color.emerald}`,
  },
  '::-webkit-scrollbar-thumb': {
    backgroundColor: color.light_green,
    outline: `1px solid ${color.emerald}`,
  },
}));

const tableHeader = ['Отчетная дата', 'Код', 'Значение', 'Источник', 'Создано', 'Автор'];
const tableHeaderPeriod = ['', 'Отчетная дата', 'Источник', 'Создано', 'Автор', ''];
const subTableHeaderPeriod = ['Расшифровка', 'Код', 'Значение', 'Предыдущее значение'];
const subTableHeaderPeriodFinancial = ['Расшифровка', 'Код', 'Значение'];

const buttonItems: IButtonGroupItem<string>[] = [
  {
    name: 'Отчетность КФ',
    type: FILTER_TYPE_REPORT.CF_REPORTING,
  },
  {
    name: 'Баланс',
    type: FILTER_TYPE_REPORT.BALANCE,
  },

  {
    name: 'ОФР',
    type: FILTER_TYPE_REPORT.STATEMENT_OF_FINANCIAL_RESULTS,
  },
  {
    name: 'ОФР за квартал',
    type: FILTER_TYPE_REPORT.PER_QUARTER,
  },
  {
    name: 'ОФР LTM',
    type: FILTER_TYPE_REPORT.LTM,
  },
];

const RenderBodyapplicationsTable = ({ row, filled }: { row: ITableBodyContragentReport; filled: boolean }) => {
  const handleLoadFile = (e: any) => {
    e.stopPropagation();
    if (row.filePath) {
      const link = document.createElement('a');

      link.download = row.filePath;
      link.href = row.filePath;
      link.click();
    }
  };

  return (
    <TableRowStyled key={row.id} sx={{ backgroundColor: filled ? color.light : color.transparent }}>
      <TableCellStyled verticalAlign="center">{moment(row.reportDate).format('DD.MM.YYYY')}</TableCellStyled>
      <TableCellStyled verticalAlign="center">{row.code}</TableCellStyled>
      <TableCellStyled verticalAlign="center">{formatToCurrency(row.value)}</TableCellStyled>
      <TableCellStyled verticalAlign="center">
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <LabelLikeHintWithText
            title={LIST_NUMBER_REPORT[row.listNumber as keyof typeof LIST_NUMBER_REPORT]}
            value={row.fileName}
            reverse
          />
          <IconButton onClick={handleLoadFile}>
            <FileDownloadOutlinedIcon />
          </IconButton>
        </Box>
      </TableCellStyled>
      <TableCellStyled verticalAlign="center">{dateFormatterDefault(row.createdDate)}</TableCellStyled>
      <TableCellStyled align="right" verticalAlign="center">
        {row.author}
      </TableCellStyled>
    </TableRowStyled>
  );
};

export const AccordionSummaryStyled = styled(AccordionSummary)(({ theme }) => ({
  padding: 0,
  '.MuiAccordionSummary-content': {
    margin: 0,
  },
}));

export const TableCellStyledReportTable = styled(TableCell)<{ verticalAlign?: string; width?: string }>(
  ({ theme, verticalAlign, width }) => ({
    width: width ?? '250px',
    overflowWrap: 'anywhere',
    border: 0,
    color: 'inherit',
  }),
);

const RenderBodyReportByPeriodTable = ({
  row,
  filled,
  handleDelete,
  isFirst,
}: {
  row: ITableBodyContragentReportByPeriod;
  filled: boolean;
  handleDelete: (val: any) => void;
  isFirst?: boolean;
}) => {
  const [expanded, setExpanded] = React.useState<boolean>(false);

  const deleteReportPerson = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    handleDelete(row);
  };

  const handleLoadFile = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    if (row.filePath) {
      const link = document.createElement('a');

      link.download = row.filePath;
      link.href = row.filePath;
      link.click();
    }
  };

  const handleExpanded = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    setExpanded(!expanded);
  };

  const isFinancial = useMemo(() => row.fileType === 'financial', [row.fileType]);
  const subTitle = useMemo(() => (isFinancial ? subTableHeaderPeriod : subTableHeaderPeriodFinancial), [isFinancial]);

  return (
    <TableRow>
      <TableCell colSpan={8} sx={{ padding: 0 }}>
        <Accordion onClick={handleExpanded}>
          <AccordionSummaryStyled>
            <Table size="medium" sx={{ border: `1px solid ${color.pale_green}` }}>
              {isFirst && (
                <TableHead>
                  <TableRowStyled>
                    {tableHeaderPeriod.map((name, index) => (
                      <TableCellStyled
                        key={index}
                        isSmall={index === 0 || index === tableHeaderPeriod?.length - 1}
                        sx={{
                          fontStyle: 'normal',
                          fontWeight: '700',
                          fontSize: '14px',
                          lineHeight: '16px',
                          borderBottom: `1px solid ${color.pale_green}`,
                        }}
                      >
                        {name}
                      </TableCellStyled>
                    ))}
                  </TableRowStyled>
                </TableHead>
              )}
              <TableBody>
                <TableRowStyled
                  key={row.id}
                  sx={{ backgroundColor: filled || expanded ? color.light : color.transparent }}
                >
                  <TableCellStyledReportTable width="50px">
                    <Chevron
                      style={{
                        transform: expanded ? `rotate(0deg)` : `rotate(-90deg)`,
                        transition: 'transform .5s',
                      }}
                    />
                  </TableCellStyledReportTable>
                  <TableCellStyledReportTable width="150px">
                    {moment(row.reportDate).format('DD.MM.YYYY')}
                  </TableCellStyledReportTable>
                  <TableCellStyledReportTable>
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                      <LabelLikeHintWithText
                        title={LIST_NUMBER_REPORT[row.listNumber as keyof typeof LIST_NUMBER_REPORT]}
                        value={row.fileName}
                        reverse
                      />
                      <IconButton onClick={handleLoadFile}>
                        <FileDownloadOutlinedIcon />
                      </IconButton>
                    </Box>
                  </TableCellStyledReportTable>
                  <TableCellStyledReportTable width="150px">
                    {dateFormatterDefault(row.createdDate)}
                  </TableCellStyledReportTable>
                  <TableCellStyledReportTable width="150px">{row.author}</TableCellStyledReportTable>

                  <TableCellStyledReportTable sx={{ textAlign: '-webkit-right' }} align="right" width="50px">
                    <EditButtons handleDelete={deleteReportPerson} />
                  </TableCellStyledReportTable>
                </TableRowStyled>
              </TableBody>
            </Table>
          </AccordionSummaryStyled>
          <AccordionDetails>
            <Table sx={{ width: '100%' }}>
              <TableBodyStyled
                sx={{
                  display: 'block',
                  height: '250px',
                  overflow: 'auto',
                }}
              >
                <TableHead>
                  <TableRowStyled>
                    {subTitle.map((name, index) => (
                      <TableCellStyledReportTable
                        key={index}
                        width={index === 0 ? '550px' : '150px'}
                        sx={{
                          fontStyle: 'normal',
                          fontWeight: '700',
                          fontSize: '14px',
                          lineHeight: '16px',
                          borderBottom: `1px solid ${color.pale_green}`,
                        }}
                      >
                        {name}
                      </TableCellStyledReportTable>
                    ))}
                  </TableRowStyled>
                </TableHead>
                {row.innerReportParams?.map(({ id, code, value, param_name, parameter_date, prev_value }) => (
                  <TableRowStyled key={id}>
                    <TableCellStyledReportTable width="550px">{param_name ?? ''}</TableCellStyledReportTable>
                    <TableCellStyledReportTable width="150px">{code}</TableCellStyledReportTable>
                    <TableCellStyledReportTable width="150px">{formatToCurrency(value)}</TableCellStyledReportTable>
                    {isFinancial && (
                      <TableCellStyledReportTable width="150px"> {prev_value ?? ''} </TableCellStyledReportTable>
                    )}
                  </TableRowStyled>
                ))}
              </TableBodyStyled>
            </Table>
          </AccordionDetails>
        </Accordion>
      </TableCell>
    </TableRow>
  );
};

export const ContractReportTable = observer(() => {
  const { contragentReportStore, monitoringStore } = useRootStore();
  const { id } = useParams<{ id: string }>();

  const { years } = useYears();

  const [selectedFilter, setSelectedFilter] = useState<IButtonGroupItem<string>>(
    contragentReportStore.selectedFilter ?? buttonItems[0],
  );
  const handleChangeButtonFilter = (selectedName: string) => {
    const selectedItem = buttonItems.find(({ name }) => name === selectedName);
    if (selectedItem) {
      setSelectedFilter(selectedItem);
      runInAction(() => {
        contragentReportStore.selectedFilter = selectedItem;
      });
    }
  };

  const handleMonitoring = () => {
    if (id) {
      monitoringStore.getMonitoringSignalById(id);
    }
  };

  const Kontur_Reporting = useMemo(() => selectedFilter?.name === 'Отчетность КФ', [selectedFilter?.name]);
  const Balance = useMemo(() => selectedFilter?.name === 'Баланс', [selectedFilter?.name]);
  const FinancialResults = useMemo(() => selectedFilter?.name === 'ОФР', [selectedFilter?.name]);
  const PerQuarter = useMemo(() => selectedFilter?.name === 'ОФР за квартал', [selectedFilter?.name]);
  const LTM = useMemo(() => selectedFilter?.name === 'ОФР LTM', [selectedFilter?.name]);

  return (
    <>
      <Box
        sx={{
          mt: OFFSET.none,
          mb: OFFSET.sm,
          display: 'flex',
          alignItems: 'center',
          gap: OFFSET.xxxl,
        }}
      >
        <ButtonGroupStyled
          items={buttonItems}
          outOnClick={handleChangeButtonFilter}
          selectedName={selectedFilter?.name}
        />
        <ButtonStyled
          startIcon={monitoringStore.isLoading ? <CircularProgress size={20} /> : <></>}
          text={'Выполнить мониторинг'}
          onClick={handleMonitoring}
        />
      </Box>
      {Kontur_Reporting && <ContractReportTableKonturReporting />}
      {Balance && <ContractReportTableBalance years={years} />}
      {FinancialResults && <ContractReportTableFinancialResults years={years} />}
      {PerQuarter && <ContractReportTablePerQuarter years={years} />}
      {LTM && <ContractReportTableLTM years={years} />}
    </>
  );
});
