import { action, computed, makeObservable, observable } from 'mobx';

export type AssessmentTemplateByAsmntTmplPkAttrPk = {
  id?: number;
  sequence: number;
  boolean_value: boolean;
  category_value: string | null;
  interval_start_value: number | null;
  interval_end_value: number | null;
  score: number | null;
  boolean_score: boolean;
  asmnt_tmpl_attr: number;
};

export type AssessmentTemplateAttrModelProps = {
  id: number | undefined;
  sequence: number;
  boolean_value: boolean;
  category_value: string | null;
  interval_start_value: string | number | null;
  interval_end_value: string | number | null;
  score: string | number | null;
  boolean_score: boolean;
  asmnt_tmpl_attr: number;
  fieldChanged: Record<string, any>;
  restore: () => void;
};

export class AssessmentTemplateAttrModel implements AssessmentTemplateAttrModelProps {
  @observable
  public id: number | undefined = undefined;

  @observable
  public sequence: number = 0;

  @observable
  public boolean_value: boolean = false;

  @observable
  public category_value: string | null;

  @observable
  public interval_start_value: string | number | null = null;

  @observable
  public interval_end_value: string | number | null = null;

  @observable
  public score: string | number | null = null;

  @observable
  public boolean_score: boolean = false;

  @observable
  public asmnt_tmpl_attr: number;

  private _id: number | undefined = undefined;

  private _sequence: number = 0;

  private _boolean_value: boolean = false;

  private _category_value: string | null;

  private _interval_start_value: number | null = null;

  private _interval_end_value: number | null = null;

  private _score: number | null = null;

  private _boolean_score: boolean = false;

  private _asmnt_tmpl_attr: number;

  constructor({
    id,
    sequence,
    boolean_value,
    category_value,
    interval_start_value,
    interval_end_value,
    score,
    boolean_score,
    asmnt_tmpl_attr,
  }: AssessmentTemplateByAsmntTmplPkAttrPk) {
    makeObservable(this);
    this.id = id;
    this.sequence = sequence;
    this.boolean_value = boolean_value;
    this.category_value = category_value;
    this.interval_start_value = interval_start_value;
    this.interval_end_value = interval_end_value;
    this.score = score;
    this.boolean_score = boolean_score;
    this.asmnt_tmpl_attr = asmnt_tmpl_attr;

    this._id = id;
    this._sequence = sequence;
    this._boolean_value = boolean_value;
    this._category_value = category_value;
    this._interval_start_value = interval_start_value;
    this._interval_end_value = interval_end_value;
    this._score = score;
    this._boolean_score = boolean_score;
    this._asmnt_tmpl_attr = asmnt_tmpl_attr;
  }

  @computed
  get fieldChanged() {
    return {
      id: this.id !== this._id,
      sequence: this.sequence != this._sequence,
      boolean_value: this.boolean_value !== this._boolean_value,
      category_value: this.category_value !== this._category_value,
      interval_start_value: this.interval_start_value != this._interval_start_value,
      interval_end_value: this.interval_end_value != this._interval_end_value,
      score: this.score != this._score,
      boolean_score: this.boolean_score !== this._boolean_score,
      asmnt_tmpl_attr: this.asmnt_tmpl_attr !== this._asmnt_tmpl_attr,
    };
  }

  @action
  public restore = () => {
    this.id = this._id;
    this.sequence = this._sequence;
    this.boolean_value = this._boolean_value;
    this.category_value = this._category_value;
    this.interval_start_value = this._interval_start_value;
    this.interval_end_value = this._interval_end_value;
    this.score = this._score;
    this.boolean_score = this._boolean_score;
    this.asmnt_tmpl_attr = this._asmnt_tmpl_attr;
  };
}
