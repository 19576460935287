import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';

import { useRootStore } from '@/stores';
import { DrawerLikeModal } from '@/components/common/DrawerLikeModal';
import { TemplateInputWithLabel } from '@/components/atoms/TemplateInputWithLabel';
import { WIDTH } from '@/constants/sizes';
import { Dropdown } from '@/components/atoms/Dropdown';
import { APP_STATUS, APP_STATUS_TO_STATUS, APP_ACC_ROLE, APP_ACC_ROLE_TO_ROLE } from '@/constants/backendEnum';
import { useValidation } from '@/hooks/useValidation';

type Props = {
  openAdd: boolean;
  toggleOpen: () => void;
};

export const templateDropdownListRole = [
  {
    id: 'role',
    label: 'Роль ⃰',
    placeholder: 'Выбрать',
    dropdownItems: [
      {
        label: APP_ACC_ROLE.Client,
        value: APP_ACC_ROLE_TO_ROLE.Client,
      },
      {
        label: APP_ACC_ROLE.Debtor,
        value: APP_ACC_ROLE_TO_ROLE.Debtor,
      },
      {
        label: APP_ACC_ROLE.Guarantee,
        value: APP_ACC_ROLE_TO_ROLE.Guarantee,
      },
    ],
  },
];

export const templateDropdownListStatus = [
  {
    id: 'status',
    label: 'Статус заявки',
    placeholder: 'Выбрать...',
    dropdownItems: [
      {
        label: APP_STATUS.New,
        value: APP_STATUS_TO_STATUS.New,
      },
      {
        label: APP_STATUS['In Progress'],
        value: APP_STATUS_TO_STATUS['In Progress'],
      },
      {
        label: APP_STATUS['On Refining'],
        value: APP_STATUS_TO_STATUS['On Refining'],
      },
      {
        label: APP_STATUS.Completed,
        value: APP_STATUS_TO_STATUS.Completed,
      },
      {
        label: APP_STATUS.Rejected,
        value: APP_STATUS_TO_STATUS.Rejected,
      },
    ],
    value: APP_STATUS_TO_STATUS.New,
  },
];

export const ContragentApplicationModal = observer(({ openAdd, toggleOpen }: Props) => {
  const { contactPersonsStore, isLoading, applicationsStore } = useRootStore();
  const { id } = useParams<{ id: string }>();
  const { setFormHasError, value, onChangeDropdown, formHasErrorRaw, isError } = useValidation();

  useEffect(() => {
    if (id) {
      contactPersonsStore.getContacts(id, 500);
    }
  }, [id]);

  useEffect(() => {
    return () => {
      applicationsStore.getDropdownProducts();
    };
  }, []);

  const handleSaveForm = async (event: React.FormEvent<HTMLFormElement>) => {
    const data = new FormData(event.currentTarget);
    const role = value.role as keyof typeof APP_ACC_ROLE;
    const application_type = value.application_type as string;
    const product_name = value.product_name as string;
    const manager = data.get('manager') as string;
    const status = value.status as keyof typeof APP_STATUS_TO_STATUS;

    if (id) {
      contactPersonsStore.createAccountApplication(id, {
        role,
        application_type,
        product_name,
        manager,
        status,
      });
      toggleOpen();
    }
  };

  return (
    <>
      <DrawerLikeModal
        title="Заявка клиента"
        open={openAdd}
        toggleOpen={toggleOpen}
        saveForm={handleSaveForm}
        buttonConfirmText="Создать заявку"
        isDisabled={isLoading || isError}
      >
        {templateDropdownListRole.map(({ label, id, placeholder, dropdownItems, ...props }) => (
          <Dropdown
            handleGlobalError={setFormHasError}
            isError={formHasErrorRaw?.role ?? false}
            onChange={onChangeDropdown}
            required
            key={id}
            label={label}
            title={placeholder}
            id={id}
            dropdownItems={dropdownItems}
            {...props}
          />
        ))}
        <Dropdown
          handleGlobalError={setFormHasError}
          onChange={onChangeDropdown}
          isError={formHasErrorRaw?.application_type ?? false}
          // required
          key="application_type"
          label="Тип заявки ⃰"
          title="Выбрать"
          id="application_type"
          dropdownItems={[]}
        />
        <Dropdown
          handleGlobalError={setFormHasError}
          onChange={onChangeDropdown}
          isError={formHasErrorRaw?.product_name ?? false}
          required
          key="product_name"
          label="Продукт ⃰"
          title="Выберите продукт"
          id="product_name"
          dropdownItems={applicationsStore?.createAccountDropdownValue ?? []}
        />
        <TemplateInputWithLabel
          key="manager"
          id="Клиентский менеджер"
          name="manager"
          label="Клиентский менеджер"
          style={{ width: WIDTH.s }}
          placeholder={'Введите ФИО'}
        />
        {templateDropdownListStatus.map(({ label, id, placeholder, dropdownItems, ...props }) => (
          <Dropdown
            onChange={onChangeDropdown}
            key={id}
            label={label}
            title={placeholder}
            id={id}
            dropdownItems={dropdownItems}
            {...props}
          />
        ))}
      </DrawerLikeModal>
    </>
  );
});
