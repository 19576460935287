import { useRef, useEffect } from 'react';

function usePrevious<T extends any>(value: T) {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value; // assign the value of ref to the argument
  }, [value]); // this code will run when the value of 'value' changes

  const clearPrev = () => {
    ref.current = undefined;
  };

  return {
    prevVal: ref.current,
    clearPrev,
  }; // in the end, return the current ref value.
}
export default usePrevious;
