import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { TextareaAutosize } from '@mui/material';

import { useRootStore } from '@/stores';
import { ButtonStyled } from '@/components/atoms/Button';
import { DrawerCustom } from '@/components/atoms/DrawerCustom';
import { DrawerLikeModal } from '@/components/common/DrawerLikeModal';
import type { ITableBodyAdministrationDataAttribute } from '@/stores/freskoApi/AdministrationDataAttributeStore';
import { Dropdown } from '@/components/atoms/Dropdown';
import { useValidation } from '@/hooks/useValidation';
import { TemplateInputWithLabel } from '@/components/atoms/TemplateInputWithLabel';
import { MAP_DATA_TYPE } from '@/constants/backendEnum';
import { WIDTH } from '@/constants/sizes';
import { SwitchStyled } from '@/components/Administering/ModelEvaluation/ModelEvaluationDetail/ModelEvaluationDetailTab/ModelEvaluationDetailTabAttributes/components/Switch';

const templateInputWithLabelList = (editAttribute: ITableBodyAdministrationDataAttribute | undefined) => [
  {
    id: 'name',
    label: 'Название',
    required: true,
    value: editAttribute?.bdName,
  },
  {
    id: 'description',
    label: 'Описание',
    value: editAttribute?.description,
  },
];

export const AdministrationDataDetailTabAttributesModal = observer(
  ({
    editAttribute,
    setEditAttribute,
  }: {
    editAttribute: ITableBodyAdministrationDataAttribute | undefined;
    setEditAttribute: (data: undefined | ITableBodyAdministrationDataAttribute) => void;
  }) => {
    const { value, onChangeDropdown, handleChangeInput, setFormHasError, isError } = useValidation();
    const { id } = useParams<{ id: string }>();
    const [hasError, setHasError] = useState(true);
    const [isExpression, setIsExpression] = useState(false);

    useEffect(() => {
      setIsExpression(!!editAttribute?.expressionName);
    }, [editAttribute?.expressionName]);

    const {
      isLoading,
      uiStateStore: { open },
      administrationDataAttributeStore,
      administrationDataObjectJoinStore,
    } = useRootStore();

    const [openAdd, setOpenAdd] = useState<boolean>(false);

    useEffect(() => {
      administrationDataObjectJoinStore.getAdministrationDataObjectJoin({ data_object_id: id });
    }, [id]);

    const toggleOpen = () => {
      setOpenAdd(!openAdd);
      if (openAdd) {
        setEditAttribute(undefined);
        handleChangeInput({ expression: undefined });
        handleChangeInput({ db_object_column: undefined });
      }
    };

    useEffect(() => {
      if (editAttribute) {
        toggleOpen();
      }
    }, [editAttribute]);

    const handleSaveForm = async (event: React.FormEvent<HTMLFormElement>) => {
      const data = new FormData(event.currentTarget);
      const name = data.get('name') as string;
      const calc_expression = value?.expression as string;
      const db_object_column = value?.db_object_column as string;
      const join = value?.join;
      const data_type = value?.data_type;
      const description = data.get('description') as string;

      if (editAttribute) {
        if (id) {
          administrationDataAttributeStore.editAdministrationDataAttributeById(id, {
            id: `${editAttribute.id}`,
            dataAttr: {
              id: editAttribute.id,
              name: name ?? editAttribute.bdName,
              db_object_column: db_object_column ?? editAttribute.columnName,
              calc_expression: calc_expression ?? editAttribute.expressionName,
              join: join ?? editAttribute.connectionName,
              data_object: id,
              data_type: data_type ?? editAttribute.dataType,
            },
          });

          setEditAttribute(undefined);
          toggleOpen();
        }

        return;
      }

      if (id && name) {
        administrationDataAttributeStore.createAdministrationDataAttribute(id, {
          dataAttr: {
            name,
            db_object_column,
            calc_expression,
            join,
            description,
            data_object: id,
            data_type,
          },
        });

        toggleOpen();
      }
    };

    const dropdownItems = useMemo(() => {
      if (administrationDataObjectJoinStore.dropdownJoinValuesByObjectId) {
        return administrationDataObjectJoinStore.dropdownJoinValuesByObjectId.map(({ alias }) => ({
          label: alias,
          value: alias,
        }));
      }

      return [];
    }, [administrationDataObjectJoinStore.dropdownJoinValuesByObjectId]);

    const editDropdownValue = useMemo(() => {
      if (administrationDataObjectJoinStore.dropdownJoinValuesByObjectId) {
        return administrationDataObjectJoinStore.dropdownJoinValuesByObjectId.find(
          ({ alias }) => alias === editAttribute?.connectionName,
        )?.alias;
      }

      return undefined;
    }, [administrationDataObjectJoinStore.dropdownJoinValuesByObjectId, editAttribute?.connectionName]);

    const type = Object.keys(MAP_DATA_TYPE).map((key) => ({
      label: MAP_DATA_TYPE[key as keyof typeof MAP_DATA_TYPE],
      value: key,
    }));
    const requiredOneOf = ['expression', 'db_object_column'];

    useEffect(() => {
      if (editAttribute) {
        handleChangeInput({ expression: editAttribute?.expressionName });
        handleChangeInput({ db_object_column: editAttribute?.columnName });
      }
    }, [editAttribute]);

    useEffect(() => {
      if (requiredOneOf.some((key) => !!value[key])) {
        setHasError(false);
      } else {
        setHasError(true);
      }
    }, [value]);

    return (
      <>
        <DrawerCustom open={open}>
          <ButtonStyled
            text="добавить атрибут"
            startIcon={<AddCircleOutlineIcon />}
            onClick={toggleOpen}
            disabled={false}
          />
          <DrawerLikeModal
            title={editAttribute ? 'Изменить атрибут' : 'Добавить атрибут'}
            open={openAdd}
            toggleOpen={toggleOpen}
            items={templateInputWithLabelList(editAttribute)}
            saveForm={handleSaveForm}
            buttonConfirmText={editAttribute ? 'Сохранить' : 'Создать атрибут'}
            isDisabled={isLoading || isError || hasError}
            handleGlobalError={setFormHasError}
          >
            <TemplateInputWithLabel label="Выражение">
              <SwitchStyled checked={isExpression} onChange={(_val: any, checked: any) => setIsExpression(checked)} />
            </TemplateInputWithLabel>
            {!isExpression && (
              <>
                <TemplateInputWithLabel
                  handleGlobalError={setFormHasError}
                  isError={hasError}
                  key="db_object_column"
                  id="db_object_column"
                  name="db_object_column"
                  placeholder="Имя объекта в бд"
                  label="Колонка"
                  style={{ width: WIDTH.s }}
                  autoComplete="off"
                  value={editAttribute?.columnName}
                  handleChange={handleChangeInput}
                  errorMessage={'Заполните "Колонка" или "Выражение"'}
                />
                <Dropdown
                  mb="16px"
                  onChange={onChangeDropdown}
                  key="join"
                  label="Соединение"
                  disabledPlaceholder={false}
                  title="Выберете соединение"
                  id="join"
                  value={editDropdownValue}
                  dropdownItems={dropdownItems}
                />
              </>
            )}
            {isExpression && (
              <TemplateInputWithLabel
                key="calc_expression"
                id="calc_expression"
                name="calc_expression"
                label="Выражение"
                handleGlobalError={setFormHasError}
              >
                <TextareaAutosize
                  aria-label="Выражение"
                  placeholder="Выражение"
                  value={value.expression ?? editAttribute?.expressionName}
                  onChange={(e: any) => handleChangeInput({ expression: e.target.value })}
                  maxRows={2}
                  style={{
                    maxWidth: '262px',
                    width: '262px',
                    maxHeight: '30px',
                    fontSize: '16px',
                    padding: '8px 10px',
                    fontFamily: 'Raleway',
                  }}
                />
              </TemplateInputWithLabel>
            )}
            <Dropdown
              mb={0}
              forceSet
              onChange={onChangeDropdown}
              key="data_type"
              label="Тип"
              title="Выберете тип"
              id="data_type"
              handleGlobalError={setFormHasError}
              value={editAttribute?.dataType ?? type[2]?.value}
              dropdownItems={type}
            />
          </DrawerLikeModal>
        </DrawerCustom>
      </>
    );
  },
);
