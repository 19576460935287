import {action, makeObservable, observable, reaction, runInAction} from 'mobx';
import {apiPath} from '@/constants/api';
import {createAxiosRequest} from '@/utils/request';
import type {TableStoreType} from '@/stores/TableStore';
import {TableStore} from '@/stores/TableStore';
import type {IRootStore} from '@/stores/type';

export type IPositionStore = {
    getPositions: () => void;
    createPosition: ({name, description, bu}: { name: string; description: string; bu: string | number }) => void;
    deletePosition: ({id}: { id: string | number }) => void;
    editPosition: ({
                       id,
                       name,
                       description,
                       bu,
                   }: {
        id: string | number;
        name: string;
        description: string;
        bu: string | number;
    }) => void;
    allPositions?: IPosition[];
    getAllPositions: () => void;
};

export type IPosition = {
    id: number;
    name: string;
    description?: string;
    status?: string;
    bu: number;
    updated?: string;
    created?: string;
    created_by?: {
        pk: number;
        username?: string;
        last_name?: string;
        first_name?: string;
        middle_name?: string;
        email?: string;
        url?: string;
        role?: string;
        phone?: string;
    };
    updated_by?: {
        pk: number;
        username?: string;
        last_name?: string;
        first_name?: string;
        middle_name?: string;
        email?: string;
        url?: string;
        role?: string;
        phone?: string;
    };
};

export type IGetPosition = {
    count: number;
    next?: string;
    previous?: string;
    results: IPosition[];
};

export type ITableBodyPosition = {
    id: number;
    name: string;
    description?: string;
    bu: number;
    updatedBy?: string;
    updated?: string;
};

const mapPositionToTableBody = (rawList: IPosition[]): ITableBodyPosition[] => {
    return rawList?.map((item) => ({
        id: item.id,
        name: `${item.name}`,
        description: item?.description,
        bu: item.bu,
        updatedBy: item.updated_by?.first_name
            ? `${item.updated_by?.first_name} ${item.updated_by?.middle_name} ${item.updated_by?.last_name}`
            : item.updated_by?.username ?? `-`,
        updated: item?.updated ?? item.created,
    })) as ITableBodyPosition[];
};

export type PositionStoreWithTableStore<T> = IPositionStore & TableStoreType<T>;

export class PositionStore
    extends TableStore<ITableBodyPosition>
    implements PositionStoreWithTableStore<ITableBodyPosition> {
    @observable
    public rootStore: IRootStore;

    // используется как значения для дропдауна
    @observable
    public allPositions?: IPosition[];

    @observable
    public isLoading?: boolean = false;

    constructor(rootStore: IRootStore) {
        super();
        makeObservable(this);
        this.rootStore = rootStore;

        reaction(
            () => this.rowsPerPageChange,
            (value) => {
                if (this.maxSelectedPage <= value) {
                    this.getPositions();
                }
            },
        );

        reaction(
            () => this.selectedPage,
            (value) => {
                if (this.maxSelectedPage <= value) {
                    this.getPositions();
                }
            },
        );
    }

    @action
    getPositions = () => {
        this.rootStore.requestTemplate({
            errorMessage: `Ошибка получения данных должности`,
            callback: async () => {
                const limit = this.rowsPerPageChange;
                const offset = this.rowsPerPageChange * this.selectedPage;

                const {data} = await createAxiosRequest<{ limit?: number; offset?: number }, IGetPosition>({
                    path: `${apiPath.crPosition}`,
                    method: 'GET',
                    useToken: true,
                    params: {limit, offset},
                });
                runInAction(() => {
                    this.limit = limit;
                    this.summary = data.count;
                    this.offset = offset;
                    this.tableBody = mapPositionToTableBody(data.results);
                })
            },
        });
    };

    @action
    getAllPositions = () => {
        this.rootStore.requestTemplate({
            errorMessage: `Ошибка получения данных всех должностей`,
            immediately: true,
            callback: async () => {
                if (!this.isLoading) {
                    try {
                        this.isLoading = true;
                        const {data} = await createAxiosRequest<{ limit?: number; offset?: number }, IGetPosition>({
                            path: `${apiPath.crPosition}`,
                            method: 'GET',
                            useToken: true,
                            params: {limit: 100, offset: 0},
                        });
                        runInAction(() => {
                            this.allPositions = data.results;
                        })
                    } catch {
                        // noop
                    } finally {
                        this.isLoading = false;
                    }
                }
            },
        });
    };

    @action
    getPositionsById = ({id}: { id: string }) => {
        return this.rootStore.requestTemplate({
            isReturned: true,
            errorMessage: `Ошибка получения данных должности ${id}`,
            callback: async () => {
                const {data} = await createAxiosRequest<
                    { name: string; search: string; limit?: number; offet?: number },
                    ITableBodyPosition
                >({
                    path: `${apiPath.crudPositionById?.replace('{id}', id)}`,
                    method: 'GET',
                    useToken: true,
                });

                return data;
            },
        });
    };

    @action
    createPosition = ({name, description, bu}: { name: string; description: string; bu: string | number }) => {
        return this.rootStore.requestTemplate({
            errorMessage: `Ошибка создания должности ${name}`,
            finallyAction: () => {
                this.getPositions();
                this.getAllPositions();
            },
            callback: async () => {
                await createAxiosRequest({
                    path: `${apiPath.crPosition}`,
                    method: 'POST',
                    data: {name, description, bu},
                    useToken: true,
                });
            },
        });
    };

    @action
    editPosition = ({
                        id,
                        name,
                        description,
                        bu,
                    }: {
        id: string | number;
        name: string;
        description: string;
        bu: string | number;
    }) => {
        this.rootStore.requestTemplate({
            errorMessage: `Ошибка редактирования должности ${name}`,
            finallyAction: () => {
                this.getPositions();
                this.getAllPositions();
            },
            callback: async () => {
                await createAxiosRequest<any, any>({
                    path: apiPath.crudPositionById?.replace('{id}', `${id}`),
                    method: 'PATCH',
                    data: {name, description, bu},
                    useToken: true,
                });
            },
        });
    };

    @action
    deletePosition = ({id}: { id: string | number }) => {
        this.rootStore.requestTemplate({
            errorMessage: `Ошибка удаления должности ${id}`,
            finallyAction: () => {
                this.getPositions();
                this.getAllPositions();
            },
            callback: async () => {
                await createAxiosRequest({
                    path: apiPath.crudPositionById?.replace('{id}', `${id}`),
                    method: 'DELETE',
                    useToken: true,
                });
            },
        });
    };
}
