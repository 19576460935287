import React from 'react';
import { Drawer } from '@/components/common/Drawer';

export const withDrawer =
  (Component: React.ComponentType<any>) =>
  ({ ...props }) => {
    return (
      <Drawer>
        <Component {...props} />
      </Drawer>
    );
  };
