import React from 'react';

import Typography from '@mui/material/Typography';
import { Box, Modal } from '@mui/material';

import { ButtonStyled } from '@/components/atoms/Button';

export const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: 4,
  boxShadow: 24,
  p: 4,
  textAlign: 'center',
};

const isFirstByIndex = (index: number) => {
  return index === 0;
};

const isLastByIndex = <T extends any>(index: number, array: T[]): boolean => {
  return index === array.length - 1;
};

interface IModalDelete {
  open: boolean;
  handleClose: () => void;
  handleDelete: () => void;
  title?: string;
  descriptions?: string | Array<string | undefined | number>;
  deleteButtonText?: string;
  cancelButtonText?: string;
  uniqId?: string;
}

export const ModalDelete = ({
  open,
  handleClose,
  handleDelete,
  title,
  descriptions,
  deleteButtonText,
  cancelButtonText,
  uniqId,
}: IModalDelete) => {
  return (
    <Modal
      key={uniqId}
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        {title && (
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {title}
          </Typography>
        )}
        {descriptions && !Array.isArray(descriptions) && (
          <Typography id="modal-modal-description" sx={{ mt: 3, mb: 4 }} variant="body1">
            {descriptions ?? ''}
          </Typography>
        )}
        {descriptions &&
          Array.isArray(descriptions) &&
          descriptions.map(
            (description, index) =>
              description && (
                <Typography
                  key={index}
                  id="modal-modal-description"
                  sx={{
                    mt: isFirstByIndex(index) ? 3 : 1,
                    mb: isLastByIndex(index, descriptions) ? 4 : undefined,
                  }}
                  variant="body1"
                >
                  {description}
                </Typography>
              ),
          )}
        <Box
          sx={{
            mt: '12px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <ButtonStyled
            text={cancelButtonText ?? 'Отменить'}
            customType="error"
            variant="outlined"
            onClick={handleClose}
          />
          <ButtonStyled text={deleteButtonText ?? 'Удалить'} onClick={handleDelete} />
        </Box>
      </Box>
    </Modal>
  );
};
