import styled from '@emotion/styled';
import type { BoxProps } from '@mui/material';
import { Box } from '@mui/material';

export const StyledBox = styled(Box)<BoxProps>(() => {
  return {
    '&:hover': {
      cursor: 'pointer',
    },
  };
});
