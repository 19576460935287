import React from 'react';

import MuiDrawer from '@mui/material/Drawer';
import { styled, Toolbar } from '@mui/material';

import { drawerWidth } from '@/constants/magicalNumber';
import { OFFSET } from '@/constants/sizes';
import { color } from '@/constants/colors';

const ToolbarStyled = styled(Toolbar)(({ theme }) => ({
  widthMax: `calc(100% - ${drawerWidth})`,
}));

const DrawerCustomStyled = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  '& .MuiToolbar-root': {
    backgroundColor: color.periwinkle_crayola,
    height: '64px',
  },
  '& .MuiDrawer-paper': {
    whiteSpace: 'nowrap',
    marginLeft: drawerWidth,
    overflowX: 'hidden',
    boxSizing: 'border-box',
    width: `calc(100% - ${drawerWidth})`,
    ...(open && {
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    }),
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: theme.spacing(7),
      width: `calc(100% - ${theme.spacing(7)})`,
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(9),
        width: `calc(100% - ${theme.spacing(9)})`,
      },
    }),
  },
}));

export const DrawerCustom = ({
  children,
  open,
  isContractReportModal,
}: React.PropsWithChildren<{ open: boolean; isContractReportModal?: boolean }>) => {
  return (
    <DrawerCustomStyled variant="permanent" anchor="bottom" open={open}>
      <ToolbarStyled
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: isContractReportModal ? 'flex-start' : 'space-between',
          gap: isContractReportModal ? '20px' : null,
          px: OFFSET.m,
        }}
        style={{ paddingLeft: OFFSET.m }}
      >
        {children}
      </ToolbarStyled>
    </DrawerCustomStyled>
  );
};
