import React, { useMemo } from 'react';

import { Box, Pagination, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import { PaginationItemStyled } from '@/components/Templates/TableComponentStyled';
import { color } from '@/constants/colors';

export type PaginationCustomProps = {
  rowsPerPageChange: number;
  summary: number;
  searchString?: string;
  selectedPage: number;
  handleChangePage: (event: React.ChangeEvent<unknown>, page: number) => void;
  disabled?: boolean;
};

export const PaginationCustom = ({
  summary,
  rowsPerPageChange,
  searchString,
  selectedPage,
  handleChangePage,
  disabled,
}: PaginationCustomProps) => {
  const pages = useMemo(() => Math.ceil(summary / rowsPerPageChange), [rowsPerPageChange, summary]);
  const defaultPage = useMemo(() => (selectedPage ?? 0) + 1, [searchString, selectedPage]);
  const startIndex = (defaultPage - 1) * rowsPerPageChange + 1;
  const endIndex = Math.min(startIndex + rowsPerPageChange - 1, summary);

  if (Number.isNaN(pages) || pages < 2) {
    return <></>;
  }

  return (
    <>
      <Typography
        ml={'24px'}
        sx={{
          weight: 500,
          size: '14px',
          lineHeight: '20px',
          color: color.mountain_meadow,
        }}
      >
        {summary === endIndex && endIndex === startIndex ? (
          <>
            Показаны {endIndex} из {summary}
          </>
        ) : (
          <>
            Показаны {startIndex}-{endIndex} из {summary}
          </>
        )}
      </Typography>
      <Pagination
        count={pages}
        page={defaultPage}
        shape="rounded"
        disabled={disabled}
        onChange={handleChangePage}
        renderItem={(item) => (
          <PaginationItemStyled
            sx={{
              color: color.mountain_meadow,
              borderRadius: '4px',
            }}
            components={{
              previous: () => (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <ArrowBackIcon sx={{ color: color.mountain_meadow, width: '15px' }} />
                  <Typography
                    ml="9px"
                    sx={{
                      weight: 500,
                      size: '14px',
                      lineHeight: '20px',
                      color: color.mountain_meadow,
                    }}
                  >
                    НАЗАД
                  </Typography>
                </Box>
              ),
              next: () => (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography
                    mr="9px"
                    sx={{
                      weight: 500,
                      size: '14px',
                      lineHeight: '20px',
                      color: color.mountain_meadow,
                    }}
                  >
                    ВПЕРЕД
                  </Typography>
                  <ArrowForwardIcon sx={{ color: color.mountain_meadow, width: '15px' }} />
                </Box>
              ),
            }}
            {...item}
          />
        )}
      />
    </>
  );
};
