import React, {useState, useEffect} from 'react';
import style from '@/pages/Contragents.module.scss';
import {observer} from 'mobx-react';
import {runInAction} from 'mobx';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import InputAdornment from '@mui/material/InputAdornment';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CircularProgress from '@mui/material/CircularProgress';

import {ContragentsTable} from '@/components/Contagents/ContragentsTable';
import {ButtonStyled} from '@/components/atoms/Button';
import {OFFSET, WIDTH} from '@/constants/sizes';
import {Title} from '@/components/atoms/Title';
import {Hint} from '@/components/atoms/Hint';
import {useRootStore} from '@/stores';
import {DrawerLikeModal} from '@/components/common/DrawerLikeModal';
import type {FilterItem, FilterType} from '@/constants/types';
import {TemplateWithLabel, TemplateInputWithLabelInn} from '@/components/atoms/TemplateInputWithLabel';
import {AutocompleteInn} from '@/components/atoms/AutocompleteInn';
import type {DaDataParty, DaDataSuggestion} from '@/utils/dadataTypes';
import {pluralize} from '@/utils';
import {TextFieldOutlinedStyled} from '@/components/atoms/TextFieldStyled';
import {useValidation} from '@/hooks/useValidation';
import {FilterStatus, useFilters} from '@/components/atoms/FilterStatus';
import {SelectChangeEvent} from '@mui/material';
import {LIMIT_CHARGE, PROBLEM_LEVEL, USER_COMPANY_GROUPS} from '@/constants/backendEnum';
import {MoreMenuTabData} from '@/components/Administering/AdministrationTabData/MoreMenuTabData';
import {mapAccountsToTableBody} from "@/stores/ContragentsStore";

const filterLabels = {
    LIMIT_AND_LOADING: 'Лимит и загрузка',
    RESPONSIBILITY_ZONE: 'Зона проблемности',
    OUR_COMPANY_STATUS: 'Статус в нашей ГК',
};

const filterItems: FilterItem[] = [
    {
        label: filterLabels.LIMIT_AND_LOADING,
        items: LIMIT_CHARGE,
        width: '144px',
    },
    {
        label: filterLabels.RESPONSIBILITY_ZONE,
        items: PROBLEM_LEVEL,
        width: '160px',
    },
    {
        label: filterLabels.OUR_COMPANY_STATUS,
        items: USER_COMPANY_GROUPS,
        width: '146px',
    },
];

const initialFilterStatus: FilterType = {
    [filterLabels.LIMIT_AND_LOADING]: [],
    [filterLabels.RESPONSIBILITY_ZONE]: [],
    [filterLabels.OUR_COMPANY_STATUS]: [],
};

export const Contragents = observer(() => {
    const {isLoading, contragentsStore} = useRootStore();
    const [openAdd, setOpenAdd] = useState<boolean>(false);
    const [query, setQuery] = useState<string | undefined>(undefined);
    const [selectedContragent, setSelectedContragent] = useState<DaDataSuggestion<DaDataParty> | undefined>(undefined);
    const {formHasErrorRaw, setFormHasError, isError} = useValidation();
    const [invalidInnList, setInvalidInnList] = useState<string[]>([]);
    const [innList, setInnList] = useState<string[]>([]);
    const [innListValue, setInnListValue] = useState<string>('');

    const [filterStatus, setFilterStatus] = useState<FilterType>(initialFilterStatus);
    const [selectedFilters, setSelectedFilters] = useState<FilterType>(initialFilterStatus);

    const {open: openFilter, handleOpen, handleClose} = useFilters();

    const toggleOpen = () => {
        setInnList([]);
        setSelectedContragent(undefined);
        setOpenAdd(!openAdd);
    };

    const handleChangeDadata = (value: DaDataSuggestion<DaDataParty>) => {
        setSelectedContragent(value);
    };

    const handleSaveForm = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const innList = data.get('innList') as string;
        const innListInput = innList.split(/\s+|\n|,\s*/).filter((inn) => inn.trim() !== '');
        const inn = selectedContragent?.data.inn;
        const validInnList: string[] = [];
        const invalidInnList: string[] = [];
        const validInnObjects: { inn: string }[] = [];

        if (inn) {
            validInnObjects.push({inn});
        }

        if (innListValue.length > 0) {
            for (const inn of innListInput) {
                if (/^\d{10}$/.test(inn)) {
                    validInnList.push(inn);
                } else {
                    const invalidInn = inn.split(' ').filter((inn) => inn.trim() !== '');
                    invalidInnList.push(...invalidInn);
                }
            }

            if (validInnList.length > 0) {
                validInnList.forEach((inn) => validInnObjects.push({inn}));
            }
        }

        if (validInnObjects.length > 0) {
            await contragentsStore.createAccount(validInnObjects);
            if (innListInput.length === 0) {
                toggleOpen();
            }

            data.delete('innList');

            if (invalidInnList.length === 0) {
                setOpenAdd(!openAdd);
            }
        }

        if (invalidInnList.length === 0) {
            setInnList([]);
            setInvalidInnList([]);
        } else {
            const invalidInnListString = invalidInnList.join(' \n');
            setInnList([invalidInnListString]);
            setInvalidInnList([invalidInnListString]);
        }
    };

    const onChangeSearch = (e: any) => {
        setQuery(e.target.value);
    };

    useEffect(() => {
        let handler: NodeJS.Timeout;
        if (query?.length === 0) {
            handler = setTimeout(() => {
                contragentsStore.searchString = query;
            }, 1000);
        }

        if (isLoading || (query?.length as number) < 3) {
            return undefined;
        }

        handler = setTimeout(() => {
            runInAction(() => {
                contragentsStore.searchString = query;
            })
        }, 1000);

        return () => {
            if (handler) {
                clearTimeout(handler);
            }
        };
    }, [isLoading, contragentsStore, query]);

    const handleInnListChange = (newValue: string) => {
        setInnListValue(newValue);
    };

    const handleChangeFilter = (filterKey: string) => (e: SelectChangeEvent) => {
        const {value} = e.target;
        setFilterStatus((prevStatus) => ({
            ...prevStatus,
            [filterKey]: typeof value === 'string' ? value.split(',') : value,
        }));
    };

    const handleResetFilter = (filterKey: string) => (e: React.MouseEvent) => {
        if (e.target instanceof SVGSVGElement) {
            setFilterStatus((prevStatus) => ({
                ...prevStatus,
                [filterKey]: [],
            }));
            setSelectedFilters((prevStatus) => ({
                ...prevStatus,
                [filterKey]: [],
            }));
        }
        setFilterStatus((prevStatus) => ({
            ...prevStatus,
            [filterKey]: [],
        }));
        setSelectedFilters((prevStatus) => ({
            ...prevStatus,
            [filterKey]: [],
        }));
    };

    const handleConfirmFilter = (filterKey: string, label: string) => () => {
        setSelectedFilters((prevSelectedFilters) => ({
            ...prevSelectedFilters,
            [filterKey]: filterStatus[filterKey],
        }));

        handleClose(label);
    };


    useEffect(() => {
        runInAction(() => {
            contragentsStore.selectedProblemLevel = selectedFilters[filterLabels.RESPONSIBILITY_ZONE].join(',');
        });
    }, [selectedFilters[filterLabels.RESPONSIBILITY_ZONE]]);

    useEffect(() => {
        runInAction(() => {
            contragentsStore.selectedLimitCharge = selectedFilters[filterLabels.LIMIT_AND_LOADING].join(',');
        });
    }, [selectedFilters[filterLabels.LIMIT_AND_LOADING]]);

    useEffect(() => {
        runInAction(() => {
            contragentsStore.selectedCompanyGroups = selectedFilters[filterLabels.OUR_COMPANY_STATUS].join(',');
        });
    }, [selectedFilters[filterLabels.OUR_COMPANY_STATUS]]);

    const downloadContragentsAll = () => {
        contragentsStore.downloadContragentsAll();
    };

    return (
        <Box mb={OFFSET.xxxl}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-end',
                }}
            >
                <Title props={{mt: OFFSET.sm}}>Контрагенты</Title>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '23px',
                    }}
                >
                    <TextFieldOutlinedStyled
                        onChange={onChangeSearch}
                        id="search"
                        name="search"
                        size="small"
                        type="text"
                        autoComplete="no"
                        placeholder="Найти..."
                        sx={{width: WIDTH.s}}
                        value={query}
                        endAdornment={
                            isLoading && (
                                <InputAdornment position="end">
                                    <CircularProgress size={20}/>
                                </InputAdornment>
                            )
                        }
                        startAdornment={
                            <InputAdornment position="start">
                                <SearchOutlinedIcon/>
                            </InputAdornment>
                        }
                    />
                    <ButtonStyled text="Добавить контрагента" startIcon={<AddCircleOutlineIcon/>} onClick={toggleOpen}/>
                </Box>
            </Box>
            {contragentsStore.summary > 0 && (
                <Hint props={{mt: OFFSET.xxxxs}}>
                    {pluralize({count: contragentsStore.summary, words: ['компания', 'компании', 'компаний']})}
                </Hint>
            )}
            <Box
                sx={{
                    mt: OFFSET.sm,
                    mb: OFFSET.xs,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                    }}
                >
                    {filterItems.map((filterItem) => (
                        <FilterStatus
                            key={filterItem.label}
                            filterLabel={filterItem.label}
                            selectTitle={filterItem.label}
                            selectItems={filterItem.items}
                            handleOpen={() => handleOpen(filterItem.label)}
                            handleClose={() => handleClose(filterItem.label)}
                            handleConfirm={handleConfirmFilter(filterItem.label, filterItem.label)}
                            handleChangeStatus={handleChangeFilter(filterItem.label)}
                            handleResetStatus={handleResetFilter(filterItem.label)}
                            statusName={filterStatus[filterItem.label]}
                            open={openFilter(filterItem.label)}
                            width={filterItem.width}
                        />
                    ))}
                </Box>
                <Box>
                    <MoreMenuTabData handleDownload={downloadContragentsAll} toDownloadAll={true}/>
                </Box>
            </Box>
            <Box sx={{m: 0, mt: OFFSET.xs}}>
                <Grid item xs={12}>
                    <Paper
                        sx={{
                            p: 3,
                            display: 'flex',
                            flexDirection: 'column',
                            padding: '0px',
                            boxShadow: '0',
                            borderRadius: 0,
                            backgroundColor: 'transparent',
                        }}
                    >
                        <ContragentsTable/>
                    </Paper>
                </Grid>
            </Box>
            <DrawerLikeModal
                title="Добавить контрагента"
                open={openAdd}
                isLoading={isLoading}
                toggleOpen={() => {
                    toggleOpen();
                    setInvalidInnList([]);
                }}
                saveForm={handleSaveForm}
                isDisabled={!selectedContragent && !innListValue}
                sx={{
                    alignItems: 'flex-end',
                }}
            >
                <TemplateWithLabel label="ИНН Клиента">
                    <AutocompleteInn sx={{minWidth: WIDTH.m, margin: 0}} onChange={handleChangeDadata}/>
                </TemplateWithLabel>

                <TemplateInputWithLabelInn
                    sx={{minWidth: WIDTH.m}}
                    label="Добавить списком"
                    id="innList"
                    value={innList}
                    innList={innList}
                    setInnList={setInnList}
                    required={!selectedContragent}
                    isError={formHasErrorRaw?.inn ?? false}
                    handleGlobalError={setFormHasError}
                    handleChange={handleInnListChange}
                />
                {invalidInnList.length > 0 && (
                    <ul className={style.invalidList}>
                        <p className={style.invalidList__title}>Введите корректный ИНН</p>
                        <div className={style.invalidList__item}>
                            {invalidInnList.map((inn) => (
                                <li key={inn}>
                                    <textarea value={inn} disabled/>
                                </li>
                            ))}
                        </div>
                    </ul>
                )}
            </DrawerLikeModal>
        </Box>
    );
});
