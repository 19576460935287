// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TemplateInputWithLabel_inputInn__np0Qe {
  width: 350px;
  height: 150px;
  background-color: transparent;
  border-color: #bdbdbd;
  font-family: Raleway;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  text-transform: none;
  font-feature-settings: "lnum";
  color: #000000;
  box-sizing: border-box;
  position: relative;
  cursor: text;
  display: inline-flex;
  align-items: center;
  position: relative;
  margin-top: 5px;
  resize: none;
  margin-top: 25px;
  border-radius: 2px;
  padding: 7px;
}`, "",{"version":3,"sources":["webpack://./src/components/atoms/TemplateInputWithLabel.module.scss","webpack://./src/constants/_colors.scss"],"names":[],"mappings":"AAEA;EACE,YAAA;EACA,aAAA;EACA,6BAAA;EACA,qBCDW;EDEX,oBAAA;EACA,gBAAA;EACA,eAAA;EACA,qBAAA;EACA,oBAAA;EACA,6BAAA;EACA,cCbM;EDcN,sBAAA;EACA,kBAAA;EACA,YAAA;EACA,oBAAA;EACA,mBAAA;EACA,kBAAA;EACA,eAAA;EACA,YAAA;EACA,gBAAA;EACA,kBAAA;EACA,YAAA;AADF","sourcesContent":["@import '@/constants/colors';\n\n.inputInn {\n  width: 350px;\n  height: 150px;\n  background-color: transparent;\n  border-color: $gray_smoke;\n  font-family: Raleway;\n  font-weight: 400;\n  font-size: 1rem;\n  line-height: 1.4375em;\n  text-transform: none;\n  font-feature-settings: 'lnum';\n  color: $black;\n  box-sizing: border-box;\n  position: relative;\n  cursor: text;\n  display: inline-flex;\n  align-items: center;\n  position: relative;\n  margin-top: 5px;\n  resize: none;\n  margin-top: 25px;\n  border-radius: 2px;\n  padding: 7px;\n}\n","$black: #000000;\n$white: #ffffff;\n$grey: #6e6e6e;\n$slightly_grey: #e6e6e6;\n$light-gray: #def3db;\n$gray_smoke: #bdbdbd;\n$red: #ff0000;\n$heavenly: #61dafb;\n$granite_grey: #282c34;\n$periwinkle_crayola: #f1f9f7;\n$pale_blue: #d7d7d8;\n$dull_grey: #616161;\n$emerald: #5dc689;\n$light_green: #1dc255;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputInn": `TemplateInputWithLabel_inputInn__np0Qe`
};
export default ___CSS_LOADER_EXPORT___;
