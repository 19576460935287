import type { AxiosRequestHeaders, AxiosResponse } from 'axios';
import axios from 'axios';
import { baseUrl, apiPath } from '@/constants/api';
import type { HttpCache } from '@/utils/http-cache-dadata';
import type { DaDataSuggestion, DaDataParty } from '@/utils/dadataTypes';

export const POSSIBLE_METHOD = {
  POST: 'POST',
  GET: 'GET',
  PUT: 'PUT',
  PATCH: 'PATCH',
  DELETE: 'DELETE',
};

export type PossibleMethodType = keyof typeof POSSIBLE_METHOD;

const logout = () => {
  localStorage.removeItem('refresh');
  localStorage.removeItem('token');
  window.location.reload();
};

const axiosInstance = axios.create({
  baseURL: baseUrl,
  headers: {
    'Access-Control-Allow-Origin': `*`,
    'Referrer-Policy': 'no-referrer',
  },
});

type CreateAxiosRequestType<T> = {
  path: string;
  method: PossibleMethodType;
  data?: T;
  useToken?: boolean;
  params?: T;
  isFile?: boolean;
  responseType?: boolean;
};

axiosInstance.interceptors.response.use(
  async (response) => {
    return response;
  },
  async (error) => {
    const config = error?.config;

    if (
      config.url === `${apiPath.profile}` ||
      (error?.response?.status === 401 && config.url === `${apiPath.refresh}`) ||
      config._retry
    ) {
      logout();
      return Promise.reject(error);
    }

    if (error?.response?.status === 401 && error?.response?.data?.code === 'token_not_valid' && !config._retry) {
      const refresh = localStorage.getItem('refresh');

      if (refresh) {
        return axiosInstance({
          url: `${apiPath.refresh}`,
          method: 'POST',
          data: {
            refresh: localStorage.getItem('refresh'),
          },
        })
          .then((response) => {
            config._retry = true;
            if (response?.data?.access) {
              localStorage.setItem('token', response?.data?.access);
              config.headers.Authorization = `Bearer ${response?.data?.access}`;
              return axiosInstance(config);
            }
            logout();
          })
          .catch(() => {
            logout();
          });
      }

      logout();
    }
    return Promise.reject(error);
  },
);

export const createAxiosRequest = async <T, U extends {}>({
  path,
  method,
  data,
  useToken,
  params,
  isFile,
  responseType,
}: CreateAxiosRequestType<T>): Promise<AxiosResponse<U>> => {
  const headers: AxiosRequestHeaders = {
    'Content-Type': isFile ? 'multipart/form-data' : 'application/json',
  };

  if (useToken) {
    const token = localStorage.getItem('token');
    headers.Authorization = `Bearer ${token}`;
  }

  return axiosInstance({
    url: `${path}`,
    method,
    data,
    params,
    headers,
    responseType: responseType ? 'blob' : undefined,
  });
};

const dadataPartyEndpoint = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/party';

export const createAxiosRequestDadata = async ({
  query,
  count,
  cache,
}: {
  query: string;
  count?: number;
  cache: HttpCache | null;
}): Promise<DaDataSuggestion<DaDataParty>[]> => {
  const headers: AxiosRequestHeaders = {
    Accept: 'application/json',
    Authorization: `Token ${process.env.REACT_APP_DADATA_API_KEY}`,
    'Content-Type': 'application/json',
  };

  let cacheKey: string;
  if (cache) {
    cacheKey = cache.serializeCacheKey({
      headers,
      body: {
        query,
        count: count || 10,
      },
      url: dadataPartyEndpoint,
      method: 'POST',
    });
    const cachedData = cache.get(cacheKey);
    if (cachedData) {
      return new Promise((resolve) => resolve(cachedData));
    }
  }

  return axios({
    url: `${dadataPartyEndpoint}`,
    method: 'POST',
    data: {
      query,
      count: count || 10,
    },
    headers,
  }).then(({ data, status }) => {
    const payload = data.suggestions;

    if (payload && status === 200) {
      cache?.set(cacheKey, payload);
    }

    return data.suggestions;
  });
};
