
import { action, computed, makeObservable, observable } from 'mobx';
import type { AdministrationAssessmentTemplateAttribute } from '@/stores/freskoApi/AdministrationAssessmentTemplateStore';
import { AssessmentTemplateAttrModel } from '@/stores/Models/AssessmentTemplateAttrModel';

type AssessmentTemplateAttributeModelProps = {
  id: number;
  sequence: number;
  name: string;
  description: string;
  value_type: string;
  data_obj_attr_name: string;
  weight: number;
  is_boolean_score: boolean;
  out_of_range_score: number;
  out_of_range_bool_score: boolean;
  tags: string | null;
  asmnt_tmpl: number;
  fieldChanged: Record<string, any>;
  restore: () => void;
};

export class AssessmentTemplateAttributeModel implements AssessmentTemplateAttributeModelProps {
  @observable
  id: number;

  @observable
  sequence: number;

  @observable
  name: string;

  @observable
  description: string;

  @observable
  value_type: string;

  @observable
  data_obj_attr_name: string;

  @observable
  weight: number;

  @observable
  is_boolean_score: boolean;

  @observable
  out_of_range_score: number;

  @observable
  out_of_range_bool_score: boolean;

  @observable
  tags: string | null;

  @observable
  asmnt_tmpl: number;

  @observable
  templateAttrs: AssessmentTemplateAttrModel[] = [];

  _id: number;

  _sequence: number;

  _name: string;

  _description: string;

  _value_type: string;

  _data_obj_attr_name: string;

  _weight: number;

  _is_boolean_score: boolean;

  _out_of_range_score: number;

  _out_of_range_bool_score: boolean;

  _tags: string | null;

  _asmnt_tmpl: number;

  constructor({
    id,
    sequence,
    name,
    description,
    value_type,
    data_obj_attr_name,
    weight,
    is_boolean_score,
    out_of_range_score,
    out_of_range_bool_score,
    tags,
    asmnt_tmpl,
  }: AdministrationAssessmentTemplateAttribute) {
    makeObservable(this);
    this.id = id;
    this.sequence = sequence;
    this.name = name;
    this.description = description;
    this.value_type = value_type;
    this.data_obj_attr_name = data_obj_attr_name;
    this.weight = weight;
    this.is_boolean_score = is_boolean_score;
    this.out_of_range_score = out_of_range_score;
    this.out_of_range_bool_score = out_of_range_bool_score;
    this.tags = tags;
    this.asmnt_tmpl = asmnt_tmpl;

    this._id = id;
    this._sequence = sequence;
    this._name = name;
    this._description = description;
    this._value_type = value_type;
    this._data_obj_attr_name = data_obj_attr_name;
    this._weight = weight;
    this._is_boolean_score = is_boolean_score;
    this._out_of_range_score = out_of_range_score;
    this._out_of_range_bool_score = out_of_range_bool_score;
    this._tags = tags;
    this._asmnt_tmpl = asmnt_tmpl;
  }

  @computed
  get fieldChanged() {
    return {
      id: this.id !== this._id,
      sequence: this.sequence != this._sequence,
      name: this.name !== this._name,
      description: this.description !== this._description,
      value_type: this.value_type !== this._value_type,
      data_obj_attr_name: this.data_obj_attr_name !== this._data_obj_attr_name,
      weight: this.weight != this._weight,
      is_boolean_score: this.is_boolean_score != this._is_boolean_score,
      out_of_range_score: this.out_of_range_score != this._out_of_range_score,
      out_of_range_bool_score: this.out_of_range_bool_score !== this._out_of_range_bool_score,
      tags: this.tags !== this._tags,
      asmnt_tmpl: this.asmnt_tmpl !== this._asmnt_tmpl,
    };
  }

  @action
  public restore = () => {
    this.id = this._id;
    this.sequence = this._sequence;
    this.name = this._name;
    this.description = this._description;
    this.value_type = this._value_type;
    this.data_obj_attr_name = this._data_obj_attr_name;
    this.weight = this._weight;
    this.is_boolean_score = this._is_boolean_score;
    this.out_of_range_score = this._out_of_range_score;
    this.out_of_range_bool_score = this._out_of_range_bool_score;
    this.tags = this._tags;
    this.asmnt_tmpl = this._asmnt_tmpl;
  };

  @action
  public addTemplateAttrs = () => {
    this.templateAttrs.push(
      new AssessmentTemplateAttrModel({
        sequence: Math.max(0, ...this.templateAttrs.map(({ sequence }) => sequence)) + 1,
        boolean_value: false,
        category_value: null,
        interval_start_value: null,
        interval_end_value: null,
        score: null,
        boolean_score: false,
        asmnt_tmpl_attr: this._asmnt_tmpl,
      }),
    );
  };

  @action
  public addTemplateAttrsCategory = () => {
    this.templateAttrs.push(
      new AssessmentTemplateAttrModel({
        sequence: Math.max(0, ...this.templateAttrs.map(({ sequence }) => sequence)) + 1,
        boolean_value: false,
        category_value: '',
        interval_start_value: null,
        interval_end_value: null,
        score: null,
        boolean_score: false,
        asmnt_tmpl_attr: this._asmnt_tmpl,
      }),
    );
  };

  @action
  public deleteTemplateAttrBySequence = (deleteSequence: number) => {
    this.templateAttrs = this.templateAttrs.filter(({ sequence }) => sequence !== deleteSequence);
  };

  @action
  public deleteAllAttrWithoutId = () => {
    this.templateAttrs = this.templateAttrs.filter((item) => item.id);
  };

  @computed
  get allAttrWithoutId() {
    return this.templateAttrs.filter((item) => !item.id);
  }
}
