import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import { TableTemplate } from '@/components/Templates/TableTemplate';
import { ModalDelete } from '@/components/common/ModalDelete';
import { TableRowStyled, TableCellStyled } from '@/components/Templates/TableComponentStyled';
import { LabelLikeHintWithText } from '@/components/common/LabelLikeHintWithText';
import type { ITableBodyPosition } from '@/stores/PositionStore';
import { useRootStore } from '@/stores';
import { useModalDelete } from '@/components/common/hooks/useModalDelete';
import { MoreMenuTabData } from '@/components/Administering/AdministrationTabData/MoreMenuTabData';
import type { IBusinessUnit } from '@//stores/BusinessUnitStore';
import { dateFormatterDefault } from '@/utils';
import { color } from '@/constants/colors';

const tableHeader = ['Название', 'Подразделение', 'Изменен', ' '];

const RenderBodyPositionTable = ({
  row,
  filled,
  openModal,
  setEdit,
  allBusinessUnits,
}: {
  row: ITableBodyPosition;
  filled: boolean;
  openModal: (id: ITableBodyPosition) => void;
  setEdit: (value?: ITableBodyPosition) => void;
  allBusinessUnits?: IBusinessUnit[];
}) => {
  const { id, name, description, updatedBy, updated, bu } = row;
  const nameBu = allBusinessUnits?.find((item) => item.id === bu)?.name;

  const handleDelete = () => {
    openModal(row);
  };

  const handleEdit = () => {
    setEdit(row);
  };

  return (
    <TableRowStyled key={id} sx={{ backgroundColor: filled ? color.light : color.transparent }}>
      <TableCellStyled verticalAlign="middle">
        <LabelLikeHintWithText reverse title={description} value={name} />
      </TableCellStyled>
      <TableCellStyled verticalAlign="middle">
        <LabelLikeHintWithText value={nameBu} />
      </TableCellStyled>
      <TableCellStyled verticalAlign="middle">
        <LabelLikeHintWithText reverse title={updated ? dateFormatterDefault(updated) : '-'} value={updatedBy} />
      </TableCellStyled>
      <TableCellStyled align="right">
        <MoreMenuTabData onDelete={handleDelete} onEdit={handleEdit} />
      </TableCellStyled>
    </TableRowStyled>
  );
};

export const PositionTable = observer(({ setEdit }: { setEdit: (value?: ITableBodyPosition) => void }) => {
  const { open, handleClose, handleOpen, deletedValue } = useModalDelete<ITableBodyPosition>();
  const {
    isLoading,
    positionStore,
    businessUnitStore: { allBusinessUnits },
  } = useRootStore();

  useEffect(() => {
    positionStore.getPositions();
  }, []);

  const handleDelete = () => {
    if (deletedValue?.id) {
      positionStore.deletePosition({ id: deletedValue.id });
      handleClose();
    }
  };

  return (
    <>
      <TableTemplate<ITableBodyPosition>
        tableHeader={tableHeader}
        RenderComponent={({ row, filled }) => (
          <RenderBodyPositionTable
            row={row}
            filled={filled}
            openModal={handleOpen}
            setEdit={setEdit}
            allBusinessUnits={allBusinessUnits}
          />
        )}
        store={positionStore}
        isLoading={isLoading}
      />
      <ModalDelete
        uniqId="Position_delete_modal"
        open={open}
        handleClose={handleClose}
        handleDelete={handleDelete}
        title="Вы действительно хотите удалить должность?"
        descriptions={[deletedValue?.name, deletedValue?.description, deletedValue?.updatedBy ?? '']}
      />
    </>
  );
});
