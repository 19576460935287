import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';

import Box from '@mui/material/Box';
import { Button, Tooltip } from '@mui/material';

import { OFFSET } from '@/constants/sizes';
import { LabelLikeHintWithText } from '@/components/common/LabelLikeHintWithText';
import { MetabaseIframe } from '@/utils/metabase';
import { useRootStore } from '@/stores';
import type { BadgeType } from '@/components/atoms/Bage/Badge';
import { Badge } from '@/components/atoms/Bage/Badge';
import { ANALYTICS_STATUS } from '@/constants/backendEnum';
import { ROUTES } from '@/constants';
import { BreadrcrumbsWithWrapper } from '@/components/common/Breadrcrumbs';
import { color } from '@/constants/colors';
import { RETURN_TABS } from './ApplicationPageDetails';

export const ApplicationMetabaseAnalysisPage = observer(() => {
  const { applicationsPageDetailsStore } = useRootStore();
  const { id, fileId, fileType } = useParams<{ id: string; fileId: string; fileType: string }>();

  useEffect(() => {
    if (id && applicationsPageDetailsStore?.detailApplicationById?.id?.toString() !== id) {
      applicationsPageDetailsStore.getDetailByApplicationId(id);
    }
  }, [id, applicationsPageDetailsStore, fileType]);

  useEffect(() => {
    if (id && fileId && applicationsPageDetailsStore?.k62Details?.id?.toString() !== fileId && fileType) {
      applicationsPageDetailsStore.getK62Details({ applicationId: id, k62Id: fileId, fileType });
    }
  }, [fileId, id, applicationsPageDetailsStore, fileType]);

  const navigate = useNavigate();

  const goToApplicationId = () => {
    navigate(`${ROUTES.applications}/${id}`, { state: RETURN_TABS[2] });
  };

  const { num } = applicationsPageDetailsStore?.detailApplicationById ?? {};

  const { status, error_msg, file_period, file_name, debtor_name } = applicationsPageDetailsStore?.k62Details ?? {};

  return (
    <Box mb={OFFSET.xxxl}>
      <BreadrcrumbsWithWrapper
        breadCrumbItems={[
          {
            name: num ?? '-',
            action: goToApplicationId,
          },
          {
            name: `К${fileType} результаты анализа`,
          },
        ]}
      />
      <Box
        sx={{
          height: '88px',
          mrgingTop: '24px',
          background: color.light,
          border: `1px solid ${color.pale_green}`,
          borderRadius: '4px',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexDirection: 'row',
          display: 'flex',
          padding: '0 24px',
        }}
      >
        <LabelLikeHintWithText title="Название файла" value={file_name} />
        <LabelLikeHintWithText title="Дебитор" value={debtor_name} />

        {error_msg ? (
          <Tooltip title={error_msg} followCursor>
            <Button disableElevation disableRipple disableFocusRipple disableTouchRipple>
              <LabelLikeHintWithText title="Аналитика">
                <Badge type={status?.toLowerCase() as keyof typeof BadgeType}>
                  {ANALYTICS_STATUS[status as keyof typeof ANALYTICS_STATUS] ?? '-'}
                </Badge>
              </LabelLikeHintWithText>
            </Button>
          </Tooltip>
        ) : (
          <LabelLikeHintWithText title="Аналитика">
            <Badge type={status?.toLowerCase() as keyof typeof BadgeType}>
              {ANALYTICS_STATUS[status as keyof typeof ANALYTICS_STATUS] ?? '-'}
            </Badge>
          </LabelLikeHintWithText>
        )}
        <LabelLikeHintWithText title="Период оценки" value={file_period} />
      </Box>
      <Box
        sx={{
          mt: OFFSET.sm,
          mb: OFFSET.sm,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      />
      <MetabaseIframe
        dashboard={fileType === '62' ? 8 : 22}
        params={fileType === '62' ? { id: `${fileId}` } : { file_id: fileId }}
      />
    </Box>
  );
});
