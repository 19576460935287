import React from 'react';

import styled from '@emotion/styled';
import { Paper, Tab, Tabs } from '@mui/material';

import { color } from '@/constants/colors';

const StyledPaper = styled(Paper)`
  background: ${color.white};
  box-shadow: none;
  margin: 0px 0px 20px 0px;
`;

const StyledTabs = styled(Tabs)`
  .MuiTabs-indicator {
    background-color: ${color.light_green};
  }
  .MuiTab-textColorPrimary {
    color: ${color.black};
  }
  .MuiTab-root {
    position: relative;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 2px;
      background-color: ${color.gray};
      opacity: 0.8;
    }
    &:hover:not(.Mui-selected) {
      color: ${color.light_green};
    }
  }
  .Mui-selected {
    color: ${color.light_green};
  }
`;

interface TabStyledProps {
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
}

const TabStyled: React.FC<TabStyledProps> = ({ value, onChange }) => {
  return (
    <StyledPaper>
      <StyledTabs value={value} onChange={onChange} indicatorColor="primary" textColor="primary" centered>
        <Tab label="Физ. лицо" />
        <Tab label="Юр. лицо" />
      </StyledTabs>
    </StyledPaper>
  );
};

export default TabStyled;
