import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import style from '@/components/Contagents/ContactTabs/ContractReportTable.module.scss';

import { useRootStore } from '@/stores';
import { IDataRowFinancialResults } from '@/stores/ContragentReportTableFinancialResults';
import { numberWithSpaces, yearsKontur } from '@/utils/reportTable';
import ContractReportTableInputKontur
  from '@/components/Contagents/ContactTabs/ContractReportTableInput/ContractReportTableInputKontur';

type StaticRowsFinancial = {
  name: string;
  code: number;
};

export type TableCell = {
  year: number;
  form_endvalue: number | string;
};

export const StaticRowsFinancial: StaticRowsFinancial[] = [
  { name: 'Доходы и расходы по обычным видам деятельности', code: NaN },
  { name: 'Выручка', code: 2110 },
  { name: 'Себестоимость продаж', code: 2120 },
  { name: 'Валовая прибыль (убыток)', code: 2100 },
  { name: 'Коммерческие расходы', code: 2210 },
  { name: 'Управленческие расходы', code: 2220 },
  { name: 'Прибыль (убыток) от продажи', code: 2200 },
  { name: 'Доходы от участия в других организациях', code: 2310 },
  { name: 'Проценты к получению', code: 2320 },
  { name: 'Проценты к уплате', code: 2330 },
  { name: 'Прочие доходы', code: 2340 },
  { name: 'Прочие расходы', code: 2350 },
  { name: 'Прибыль (убыток) до налогообложения', code: 2300 },
  { name: 'Налог на прибыль', code: 2410 },
  { name: 'в т.ч. текущий налог на прибыль', code: 2411 },
  { name: 'отложенный налог на прибыль', code: 2412 },
  { name: 'Прочее', code: 2460 },
  { name: 'Чистая прибыль (убыток)', code: 2400 },
];

const specialCodes = [NaN, 2200, 2300, 2400];

const ContractReportTableFinancialResultsKontur: React.FC = observer(() => {
  const { contragentReportTableFinancialResults } = useRootStore();

  const results = contragentReportTableFinancialResults.results;
  const [resultsBalance, setResultsBalance] = useState<IDataRowFinancialResults[]>(results);

  useEffect(() => {
    setResultsBalance(results);
  }, [results]);

  const getTableData = (): Record<string, TableCell> => {
    const tableData: Record<string, TableCell> = {};

    StaticRowsFinancial.forEach((staticRow) => {
      const { name, code } = staticRow;
      yearsKontur.forEach(async (year) => {
        const matchingResult =
          resultsBalance && resultsBalance.find((item) => item.form_year === year && item.form_code === code);
        const key = `${code}-${year}`;

        if (matchingResult) {
          const formattedValue = numberWithSpaces(matchingResult.form_endvalue);
          tableData[key] = {
            year,
            form_endvalue: formattedValue,
          };
        } else {
          tableData[key] = {
            year,
            form_endvalue: ' ',
          };
        }
      });
    });
    return tableData;
  };

  const [tableData, setTableData] = useState(getTableData());

  useEffect(() => {
    const newTableData = getTableData();
    setTableData(newTableData);
  }, [resultsBalance]);

  return (
    <>
      <div className={style.wrapper}>
        <h4 className={style.wrapper__header}>Отчет о финансовых результах (Форма 2)</h4>
        <table className={style.balanceTable}>
          <thead className={style.balanceTable__header}>
            <tr>
              <th className={style.balanceTable__header__name}>Наименование</th>
              <th className={style.balanceTable__header__code}>Код строки</th>
              {yearsKontur.map((year, index) => (
                <React.Fragment key={index}>
                  <th className={style.balanceTable__header__year}>
                    <div className={style.balanceTable__header__year__container}>31.12.{year}</div>
                  </th>
                </React.Fragment>
              ))}
            </tr>
          </thead>
          <tbody>
            {StaticRowsFinancial.map((staticRow, index) => {
              const { name, code } = staticRow;

              return (
                <tr
                  className={`${style.balanceTable__container} ${
                    specialCodes.includes(code) ? style.balanceTable__specialRow : ''
                  }`}
                  key={index}
                >
                  <td className={style.balanceTable__name}>{name}</td>
                  <td className={`${style.balanceTable__code} ${!isNaN(code) ? '' : style.balanceTable__nanRow}`}>
                    {code}
                  </td>
                  <ContractReportTableInputKontur
                    code={code}
                    tableData={tableData}
                  />
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
});

export default ContractReportTableFinancialResultsKontur;
