import { useState } from "react";

export const useModalDelete = <T>(disabled?: boolean) => {
  const [open, setOpen] = useState(false);
  const [deletedValue, setDeletedValue] = useState<T | undefined>(undefined);

  const handleOpen = (deletedValue: T) => {
    if (disabled) {
      return;
    }

    setDeletedValue(deletedValue);
    setOpen(true);
  };

  const handleClose = () => {
    setDeletedValue(undefined);
    setOpen(false);
  };

  return {
    open,
    handleOpen,
    handleClose,
    deletedValue,
  }
}