import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import { Box } from '@mui/material';

import { OFFSET } from '@/constants/sizes';
import { InputLabelStyled } from '@/components/atoms/InputLabelStyled';
import { Dropdown } from '@/components/atoms/Dropdown';
import { useRootStore } from '@/stores';
import { FilterComponent } from '@/components/atoms/FilterComponent';
import { color } from '@/constants/colors';

export const ModelEvaluationDetailTabData = observer(() => {
  const { administrationAssessmentTemplateStore } = useRootStore();

  const { context_name, context_object_name, is_suitable } =
    administrationAssessmentTemplateStore.selectedAdministrationAssessmentTemplateById;

  useEffect(() => {
    if (context_name && context_object_name) {
      administrationAssessmentTemplateStore.getDataContextBy({ context_name, context_object_name });
    }
  }, [administrationAssessmentTemplateStore, context_name, context_object_name]);

  useEffect(() => {
    administrationAssessmentTemplateStore.getDropdownContextValues();
  }, []);

  const contextField = administrationAssessmentTemplateStore.dataContextDropdown?.map(({ name }) => ({
    label: name,
    value: name,
  }));
  const contextObjNameField = administrationAssessmentTemplateStore.dataContextObjectDropdown?.map(({ name }) => ({
    label: name,
    value: name,
  }));

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'end' }}>
        <Box sx={{ flex: 1, marginRight: OFFSET.xxs }}>
          <InputLabelStyled sx={{ mb: OFFSET.xxxs, mt: 0 }}>Контекст</InputLabelStyled>
          <Dropdown
            forceSet
            disabled
            mb={0}
            key="context_name"
            label=""
            title=""
            id="context_name"
            dropdownWidth="100%"
            value={context_name}
            dropdownItems={contextField}
            onChange={() => {}}
          />
        </Box>
        <Box sx={{ flex: 1, marginRight: OFFSET.xxs }}>
          <InputLabelStyled sx={{ mb: OFFSET.xxxs, mt: 0 }}>Объект оценки</InputLabelStyled>
          <Dropdown
            mb={0}
            disabled
            forceSet
            key="context_object_name"
            label=""
            title=""
            id="context_object_name"
            dropdownWidth="100%"
            value={context_object_name}
            dropdownItems={contextObjNameField}
            onChange={() => {}}
          />
        </Box>
      </Box>
      <Box
        mt="24px"
        sx={{
          width: '100%',
        }}
      >
        <InputLabelStyled sx={{ mb: OFFSET.xxxs, mt: 0 }}>
          Условие (модель доступна, если объект оценки соответствует этим критериям)
        </InputLabelStyled>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            height: '22px',
            border: `1px solid rgba(0,0,0, 0.38)}`,
            overflow: 'hidden',
            borderRadius: '2px',
            width: 'calc(100% - 30px)',
            padding: '8px 10px',
            color: color.slate_gray,
          }}
        >
          <FilterComponent filterText={is_suitable ?? ''} />
        </Box>
      </Box>
    </Box>
  );
});
