import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useLocation, useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { CircularProgress, FormHelperText, Paper } from '@mui/material';
import { Check } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { ROUTES } from '@/constants';
import { useRootStore } from '@/stores';
import { ButtonStyled } from '@/components/atoms/Button';
import { TextFieldOutlinedStyled } from '@/components/atoms/TextFieldStyled';
import { FullLogoGreen } from '@/assets';
import { OFFSET } from '@/constants/sizes';
import { InputLabelStyled } from '@/components/atoms/InputLabelStyled';
import { CustomizedSnackbars } from '@/components/common/SnakBar';
import { color } from '@/constants/colors';

const LoadingButton = (props: { [x: string]: any }) => {
  const { classes, loading, done, children, ...other } = props;
  if (done) {
    return (
      <ButtonStyled type="success" isSubmit className={classes ?? 'button'} {...other} disabled>
        <Check />
      </ButtonStyled>
    );
  }
  if (loading) {
    return (
      <ButtonStyled type="success" isSubmit sx={{ maxHeight: '36px' }} className={classes ?? 'button'} {...other}>
        <CircularProgress sx={{ maxHeight: '36px' }} />
      </ButtonStyled>
    );
  }
  return <ButtonStyled type="success" isSubmit className={classes ?? 'button'} text={children} {...other} />;
};

export const SignIn = observer(() => {
  const [isShowPassword, setIsShowPassword] = useState(true);
  const [error, setError] = useState<any>({});
  const { authStore, isLoading, userStore } = useRootStore();

  const navigate = useNavigate();
  const location = useLocation();

  const togglePassword = () => {
    setIsShowPassword(!isShowPassword);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    try {
      const response = await authStore.getApiTokenAuth({
        username: data.get('email') as string,
        password: data.get('password') as string,
      });

      if (response?.status === 200) {
        userStore.clear();
        navigate(ROUTES.contragents);
      } else {
        setError(response?.response?.data);
        authStore.rootStore?.setRequestError(
          response?.response?.data?.detail ?? 'Не верное имя пользователя или пароль',
        );
      }
    } catch (e) {
      setError(e?.response?.data);
      authStore.rootStore?.setRequestError(e?.response?.data?.detail ?? 'Не верное имя пользователя или пароль');
    }
  };

  React.useEffect(() => {
    if (location.pathname === ROUTES.logout) {
      authStore.logout();
      navigate(ROUTES.login);
    }
  }, []);

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Paper
        elevation={3}
        sx={{
          padding: OFFSET.m,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <FullLogoGreen />
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: OFFSET.xl }}>
            <InputLabelStyled sx={{ margin: '0 4px' }}>Логин</InputLabelStyled>
            <Box sx={{ mb: OFFSET.sm }}>
              <TextFieldOutlinedStyled
                required
                fullWidth
                id="email"
                name="email"
                autoComplete="email"
                size="small"
                autoFocus
                error={Boolean(error?.email)}
              />
              {error?.username && <FormHelperText error>{error?.username}</FormHelperText>}
            </Box>

            <InputLabelStyled sx={{ margin: '0 4px' }}>Пароль</InputLabelStyled>
            <TextFieldOutlinedStyled
              required
              fullWidth
              name="password"
              type={!isShowPassword ? 'text' : 'password'}
              id="password"
              size="small"
              autoComplete="current-password"
              error={Boolean(error?.password)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={togglePassword}
                    onMouseDown={togglePassword}
                    edge="end"
                  >
                    {isShowPassword ? <VisibilityOff htmlColor={color.light_green} /> : <Visibility htmlColor={color.light_green} />}
                  </IconButton>
                </InputAdornment>
              }
            />
            {error?.password && <FormHelperText error>{error?.password}</FormHelperText>}
            {error?.non_field_errors && (
              <FormHelperText error filled>
                {error?.non_field_errors}
              </FormHelperText>
            )}
            <LoadingButton type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} loading={isLoading}>
              Войти
            </LoadingButton>
          </Box>
        </Box>
      </Paper>
      <CustomizedSnackbars />
    </Container>
  );
});
