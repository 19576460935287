import * as React from 'react';
import style from '@/components/atoms/TextareaStyled.module.scss';

type TextareaProps = {
  placeholder: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  width?: string;
  disabled?: boolean;
};

export const TextareaStyled = ({ placeholder, value, onChange, width, disabled }: TextareaProps) => {
  return (
    <textarea
      className={style.textArea}
      style={{ width: width ? width : '70%' }}
      rows={10}
      onChange={onChange}
      value={value}
      placeholder={placeholder}
      disabled={disabled}
    />
  );
};
