import React, { useMemo } from 'react';
import { observer } from 'mobx-react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box, Skeleton } from '@mui/material';

import { ReactComponent as Empty } from '@/assets/empty.svg';
import { TableCellStyled, TableRowStyled } from '@/components/Templates/TableTemplate';
import { color } from '@/constants/colors';
import { formatToCurrency } from '@/utils';

type LikeMinimalStore<T> = {
  rowsPerPageChange?: number;
  renderedRows: T[];
};

export type ITable<T> = {
  totalVisible?: boolean;
  tableHeader: (string | JSX.Element)[];
  tableHeaderWidth?: string[];
  tableTitle?: string | JSX.Element;
  store: LikeMinimalStore<T>;
  isLoading: boolean;
  RenderComponent: ({
    row,
    filled,
    isLast,
    isFirst,
    index,
  }: {
    row: T;
    filled: boolean;
    isLast?: boolean;
    isFirst?: boolean;
    index: number;
  }) => JSX.Element;
  lastRowItem?: JSX.Element;
  isHideBorders?: boolean;
  isHideBorderslastRowItem?: boolean;
  sxTableWrapper?: Record<string, string>;
  lastElement?: boolean;
  isSkipEmptyImage?: boolean;
  rowsPerPage?: number;
};

export const TableTemplateWithoutStore = observer(
  <T extends { [k: string]: any }>({
    tableHeader,
    tableHeaderWidth,
    totalVisible,
    RenderComponent,
    isLoading,
    store,
    lastRowItem,
    isHideBorders,
    isHideBorderslastRowItem,
    sxTableWrapper,
    lastElement,
    isSkipEmptyImage,
    rowsPerPage,
  }: ITable<T>) => {
    const { rowsPerPageChange = rowsPerPage ? rowsPerPage : 10, renderedRows = [] } = store;
    const rows = useMemo(() => Array.apply(null, Array(rowsPerPageChange)), [rowsPerPageChange]);

    const sum = store.renderedRows
      .map((el, index) => el.loadedLimitAmount)
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

    if (isSkipEmptyImage && !renderedRows?.length && !isLoading && !lastRowItem) {
      return null;
    }

    if (!renderedRows?.length && !isLoading && !lastRowItem) {
      return (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Empty width="50em" height="25em" />
        </Box>
      );
    }

    const borderCustom = lastRowItem ? { borderBottom: 'none' } : {};
    const borderCustomLastItem = isHideBorderslastRowItem
      ? {}
      : { border: `1px solid ${color.pale_green}`, borderTop: 'none' };
    const border = !isHideBorders ? { border: `1px solid ${color.pale_green}` } : {};

    return (
      <>
        <Box sx={{ backgroundColor: color.white, width: '100%', ...sxTableWrapper }}>
          <Table size="medium" sx={{ ...border, ...borderCustom }}>
            <TableHead>
              <TableRowStyled>
                {tableHeader.map((name, index) =>
                  typeof name === 'string' ? (
                    <TableCellStyled
                      align={tableHeader?.length - 1 === index ? 'right' : undefined}
                      key={index}
                      sx={tableHeaderWidth ? { width: tableHeaderWidth[index] } : undefined}
                    >
                      {name}
                    </TableCellStyled>
                  ) : (
                    name
                  ),
                )}
              </TableRowStyled>
            </TableHead>
            <TableBody>
              {isLoading
                ? rows.map((_name, index) => (
                    <TableRow key={index}>
                      {tableHeader.map((_, index) => (
                        <TableCellStyled align={tableHeader?.length - 1 === index ? 'right' : undefined} key={index}>
                          <Skeleton
                            key={index}
                            variant="rectangular"
                            height={19}
                            sx={{
                              display: 'flex',
                              flex: 1,
                            }}
                          />
                        </TableCellStyled>
                      ))}
                    </TableRow>
                  ))
                : renderedRows.map((el, index) => (
                    <RenderComponent
                      key={el?.id ?? index}
                      row={el}
                      filled={index % 2 !== 0}
                      isLast={renderedRows?.length - 1 === index}
                      isFirst={index === 0}
                      index={index}
                    />
                  ))}
              {lastElement ? lastRowItem || null : null}
            </TableBody>
            {totalVisible && (
              <TableHead>
                <TableRowStyled>
                  <TableCellStyled>Итого</TableCellStyled>
                  <TableCellStyled></TableCellStyled>
                  <TableCellStyled></TableCellStyled>
                  <TableCellStyled>{formatToCurrency(sum)}</TableCellStyled>
                </TableRowStyled>
              </TableHead>
            )}
          </Table>
          {lastElement ? <></> : <Box sx={borderCustomLastItem}>{lastRowItem && lastRowItem}</Box>}
        </Box>
      </>
    );
  },
);
