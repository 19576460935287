import React, { useState } from 'react';
import { PositionTable } from '@/components/Group/Position/PositionTable';
import { PositionModal } from '@/components/Group/Position/PositionModal';
import type { ITableBodyPosition } from '@/stores/PositionStore';

export const Position = () => {
  const [editData, setEditData] = useState<ITableBodyPosition | undefined>(undefined);
  return (
    <>
      <PositionTable setEdit={setEditData} />
      <PositionModal setEditData={setEditData} editData={editData} />
    </>
  );
};
