import {makeObservable, observable, runInAction} from 'mobx';
import { apiPath } from '@/constants/api';
import { createAxiosRequest } from '@/utils/request';
import { TableStoreType, TableStore } from '@/stores/TableStore';
import type { IRootStore } from '@/stores/type';

export type IAdministrationDataObjectJoinSpec = {
  id: number;
  destination_obj_column: string;
  source_obj_attr?: string;
  dest_obj_column_constr?: string;
  description?: string;
  join?: number;
}

export type IAdministrationDataObjectJoin = {
  id: number;
  alias: string;
  db_layout: string;
  db_object: string;
  outer_join: string;
  description?: string;
  created: string;
  modified: string;
  created_by: string;
  modified_by: string;
  data_object?: string;
}

export type ITableBodyAdministrationDataObjectJoin = {
  id: number;
  name: string;
  object?: string;
  priority: number;
  updateDate: string;
  updateName: string;
  version: string;
};

export interface IAdministrationDataObjectJoinStore {
  rootStore: IRootStore;
  getAdministrationDataObjectJoin: ({ data_object_id }: { data_object_id?: string | number }) => void;
  // getAdministrationDataObjectJoinById: (id: string | number) => void;
  deleteAdministrationDataObjectJoinById: ({ id, data_object_id }: { id: string | number, data_object_id: string }) => void;
  deleteAdministrationDataObjectJoinSpecById: ({ id, joinId }: { id: string | number, joinId: string | number }) => void;
  tableBody?: ITableBodyAdministrationDataObjectJoin[];
  isLoading: boolean;
  // selectedAdministrationDataObjectJoinById: ITableBodyAdministrationDataObjectJoin | Record<string, string>;
  dropdownJoinValuesByObjectId: IAdministrationDataObjectJoin[];
  getAdministrationDataObjectJoinSpecByJoinId: ({ joinId }: { joinId: string | number }) => void;
  addAdministrationDataObjectJoinSpecByJoinId:
  ({ joinId, joinSpecData }: { joinId: string | number, joinSpecData: Partial<IAdministrationDataObjectJoinSpec> }) => void;
  addAdministrationDataObjectJoinByJoinId:
  ({ data_object_id, joinData }: { data_object_id: string | number, joinData: Partial<IAdministrationDataObjectJoin> }) => void;
  joinSpecValuesByJoinId: any;
}

export type IAdministrationDataObjectJoinStoreWithITableStore<T> = IAdministrationDataObjectJoinStore & TableStoreType<T>;

export type IGetAdministrationDataObjectJoin = IGetWithPagination<IAdministrationDataObjectJoin>;
export type IGetAdministrationDataObjectJoinSpec = IGetWithPagination<IAdministrationDataObjectJoinSpec>;

export class AdministrationDataObjectJoinStore
  extends TableStore<ITableBodyAdministrationDataObjectJoin>
  implements IAdministrationDataObjectJoinStoreWithITableStore<ITableBodyAdministrationDataObjectJoin> {

  @observable
  public rootStore: IRootStore;

  @observable
  public isLoading: boolean = false;

  @observable
  public selectedAdministrationDataObjectJoinById: ITableBodyAdministrationDataObjectJoin | Record<string, string> = {};

  @observable
  public dropdownJoinValuesByObjectId: IAdministrationDataObjectJoin[] = [];

  @observable
  public joinSpecValuesByJoinId: IAdministrationDataObjectJoinSpec[] = [];


  constructor(rootStore: IRootStore) {
    super();
    makeObservable(this);
    this.rootStore = rootStore;
  }

  public getAdministrationDataObjectJoinById(id: string | number) {
    if (!this.isLoading) {
      this.rootStore.requestTemplate({
        errorMessage: `Ошибка получения данных соединения`,
        callback: async () => {
          try {
            this.isLoading = true;

            const { data } = await createAxiosRequest<{}, IAdministrationDataObjectJoin>({
              path: apiPath.dataObjJoinById.replace('{id}', `${id}`),
              method: 'GET',
              useToken: true,
            });

            // this.selectedAdministrationDataObjectJoinById = [data]?.[0];

          } finally {
            this.isLoading = false;
          }
        },
      });
    }
  }

  public getAdministrationDataObjectJoin({ data_object_id }: { data_object_id?: string | number }) {
    if (!this.isLoading) {
      this.rootStore.requestTemplate({
        immediately: true,
        errorMessage: `Ошибка получения данных соединения`,
        callback: async () => {
          try {
            this.isLoading = true;

            const limit = data_object_id ? 100 : this.rowsPerPageChange;
            const offset = data_object_id ? 0 : this.rowsPerPageChange * this.selectedPage;
            const { data } = await createAxiosRequest<{ offset: number; limit: number, data_object_id?: string | number }, IGetAdministrationDataObjectJoin>({
              path: apiPath.dataObjJoin,
              method: 'GET',
              params: { offset: this.rowsPerPageChange * this.selectedPage, limit: this.rowsPerPageChange, data_object_id },
              useToken: true,
            });
            runInAction(() => {
              if (data_object_id) {
                this.dropdownJoinValuesByObjectId = data.results;
              } else {
                this.limit = limit;
                this.summary = data.count;
                this.offset = offset;
                const tableData = data.results;
                // this.tableBody = [...tableData];
              }
            })
          } finally {
            this.isLoading = false;
          }
        },
      });
    }
  }

  public deleteAdministrationDataObjectJoinById({ id, data_object_id }: { id: string | number, data_object_id: string }) {
    if (!this.isLoading) {
      this.rootStore.requestTemplate({
        finallyAction: () => this.getAdministrationDataObjectJoin({ data_object_id }),
        errorMessage: `Ошибка удаления данных соединения`,
        callback: async () => {
          try {
            this.isLoading = true;

            await createAxiosRequest<{}, IAdministrationDataObjectJoin>({
              path: apiPath.dataObjJoinById.replace('{id}', `${id}`),
              method: 'DELETE',
              useToken: true,
            });

          } finally {
            this.isLoading = false;
          }
        },
      });
    }
  }

  public deleteAdministrationDataObjectJoinSpecById({ id, joinId }: { id: string | number, joinId: string | number }) {
    if (!this.isLoading) {
      this.rootStore.requestTemplate({
        finallyAction: () => this.getAdministrationDataObjectJoinSpecByJoinId({ joinId }),
        errorMessage: `Ошибка удаления атрибута соединения`,
        callback: async () => {
          try {
            this.isLoading = true;

            await createAxiosRequest<any, any>({
              path: apiPath.dataObjJoinSpecById.replace('{id}', `${id}`),
              method: 'DELETE',
              useToken: true,
            });

          } finally {
            this.isLoading = false;
          }
        },
      });
    }
  }

  public getAdministrationDataObjectJoinSpecByJoinId({ joinId }: { joinId: string | number }) {
    this.rootStore.requestTemplate({
      errorMessage: `Ошибка получения данных соединения`,
      immediately: true,
      callback: async () => {
        try {
          this.isLoading = true;

          const { data } = await createAxiosRequest<{ offset: number, limit: number }, IGetAdministrationDataObjectJoinSpec>({
            path: apiPath.dataObjJoinSpecByJoinPkId.replace('{join_pk}', `${joinId}`),
            params: { offset: 0, limit: 100 },
            method: 'GET',
            useToken: true,
          });
          runInAction(() => {
            this.joinSpecValuesByJoinId = data.results;
          })
        } finally {
          this.isLoading = false;
        }
      },
    });
  }

  public addAdministrationDataObjectJoinSpecByJoinId({ joinId, joinSpecData }: { joinId: string | number, joinSpecData: Partial<IAdministrationDataObjectJoinSpec> }) {
    if (!this.isLoading) {
      this.rootStore.requestTemplate({
        finallyAction: () => this.getAdministrationDataObjectJoinSpecByJoinId({ joinId }),
        errorMessage: `Ошибка ${joinSpecData.id ? 'добавления' : 'обновления'} данных атрибута`,
        callback: async () => {
          try {
            this.isLoading = true;

            await createAxiosRequest<{}, {}>({
              path: joinSpecData.id ? apiPath.dataObjJoinSpecById.replace('{id}', `${joinSpecData.id}`) : apiPath.dataObjJoinSpec,
              method: joinSpecData.id ? 'PATCH' : 'POST',
              data: { ...joinSpecData },
              useToken: true,
            });

          } finally {
            this.isLoading = false;
          }
        },
      });
    }
  }

  public addAdministrationDataObjectJoinByJoinId({ data_object_id, joinData }: { data_object_id: string | number, joinData: Partial<IAdministrationDataObjectJoin> }) {
    if (!this.isLoading) {
      this.rootStore.requestTemplate({
        // finallyAction: () => this.getAdministrationDataObjectJoin({ data_object_id }),
        errorMessage: `Ошибка ${joinData.id ? 'добавления' : 'обновления'} соединения`,
        callback: async () => {
          try {
            this.isLoading = true;

            await createAxiosRequest<{}, {}>({
              path: apiPath.dataObjJoin,
              method: joinData.id ? 'PATCH' : 'POST',
              data: { ...joinData },
              useToken: true,
            });

          } finally {
            this.isLoading = false;
          }
        },
      });
    }
  }
}

export type MapAdministrationDataObjectJoinSpec = ReturnType<typeof mapAdministrationDataObjectJoinSpec>;

export const mapAdministrationDataObjectJoinSpec = (data: IAdministrationDataObjectJoinSpec[]) =>
  data.map((item) => ({
    id: item.id,
    destination: item.destination_obj_column,
    attributeName: item.source_obj_attr,
    attributeBdName: item.description,
    common: item.dest_obj_column_constr,
  }));

export type MapAdministrationDataJoinToTableBody = ReturnType<typeof mapAdministrationDataJoinToTableBody>;

export const mapAdministrationDataJoinToTableBody = (data: IAdministrationDataObjectJoin[]) =>
  data.map((item) => ({
    id: item.id,
    displayName: item.alias,
    objectName: item.db_object,
    objectBdName: item?.db_layout,
    connectionType: item.outer_join,
  }));
