import React from 'react';
import Typography from '@mui/material/Typography';
import { color } from '@/constants/colors';

interface TitleProps {
  children?: React.ReactNode;
  props?: any; 
  onClick?: () => void;
  sx?: {};
}

export const Title = ({ children, props, onClick, sx = {} }: TitleProps) => {
  return (
    <Typography
      {...props}
      component="h2"
      variant="h6"
      gutterBottom
      onClick={onClick}
      sx={{
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '32px',
        lineHeight: '38px',
        whiteSpace: 'nowrap',
        marginBottom: 0,
        color: onClick ? color.light_green : color.light_black,
        cursor: onClick ? 'pointer' : 'auto',
        ...sx
      }}
    >
      {children}
    </Typography>
  );
};
