import React from 'react';
import '@/App.scss';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { Buffer } from 'buffer';
import { ROUTES, ROLE } from '@/constants';
import { withDrawer } from '@/hoc/withDrawer';
import { useCustomTheme } from '@/hooks/useCustomTheme';
import { useDrawerToggle } from '@/hooks/useDrawerToggle';
import { CustomContext } from '@/context';

import { SignIn } from '@/pages/Signin';
import { Contragents } from '@/pages/Contragents';
import { ContragentsDetailsPage } from '@/pages/ContragentsDetailsPage';
import { ApplicationsPage } from '@/pages/ApplicationsPage';
import { ApplicationPageDetails } from '@/pages/ApplicationPageDetails';
import { ApplicationScoringPage } from '@/pages/ApplicationScoringPage';
import { ScoringSettingsPage } from '@/pages/ScoringSettingsPage';
import { ScoringSettingsPageDetails } from '@/pages/ScoringSettingsPageDetails';
import { Group } from '@/pages/Group';
import { ContragentsMonitoringPage } from '@/pages/ContragentsMonitoringPage';
import { ApplicationMetabaseAnalysisPage } from '@/pages/ApplicationMetabaseAnalysisPage';
import { Profile } from '@/pages/Profile';

import { PrivateRoute } from '@/components/common/PrivateRoute';
import { AdministrationDataDetail } from '@/components/Administering/AdministrationTabData/AdministrationDataDetail/AdministrationDataDetail';
import { ModelEvaluationDetail } from '@/components/Administering/ModelEvaluation/ModelEvaluationDetail/ModelEvaluationDetail';
import { ProductsPage } from '@/components/ProductsPage/ProductsPage';

import { ThemeProvider } from '@mui/material/styles';
import { HomePage } from './pages/HomePage';

const NotFoundComponent = () => <>not found</>;

global.Buffer = Buffer;

function App() {
  const { isDarkTheme, changeTheme, theme } = useCustomTheme();
  const { open, toggleOpen } = useDrawerToggle();

  return (
    <CustomContext.Provider
      value={{
        theme: { theme, changeTheme, isDarkTheme },
        drawer: { open, toggleOpen },
      }}
    >
      <ThemeProvider theme={theme}>
        <Router>
          <Routes>
            <Route path={ROUTES.login} element={<SignIn />} />
            <Route path={ROUTES.logout} element={<SignIn />} />
            <Route
              path={ROUTES.others}
              element={<PrivateRoute roles={[]} component={withDrawer(NotFoundComponent)} />}
            />
            <Route
              path={ROUTES.home}
              element={
                <PrivateRoute
                  roles={[ROLE.GENERAL, ROLE.SMARTFACT, ROLE.BALTICLEASING, ROLE.PSBL]}
                  component={withDrawer(() => (
                    <HomePage />
                  ))}
                />
              }
            />
            <Route
              path={`${ROUTES.contragents}/:id`}
              element={
                <PrivateRoute
                  roles={[ROLE.GENERAL, ROLE.SMARTFACT, ROLE.BALTICLEASING, ROLE.PSBL]}
                  component={withDrawer(ContragentsDetailsPage)}
                />
              }
            />
            <Route
              path={`${ROUTES.monitoring}`}
              element={
                <PrivateRoute
                  roles={[ROLE.SMARTFACT, ROLE.BALTICLEASING, ROLE.PSBL]}
                  component={withDrawer(ContragentsMonitoringPage)}
                />
              }
            />
            {/* TODO Разрулить разные скрины для разных ролей, внутри компонента */}
            <Route
              path={ROUTES.contragents}
              element={
                <PrivateRoute
                  roles={[ROLE.GENERAL, ROLE.SMARTFACT, ROLE.BALTICLEASING, ROLE.PSBL]}
                  component={withDrawer(Contragents)}
                />
              }
            />
            <Route
              path={`${ROUTES.applications}/:id/:scoringId`}
              element={
                <PrivateRoute
                  roles={[ROLE.GENERAL, ROLE.SMARTFACT, ROLE.BALTICLEASING, ROLE.PSBL]}
                  component={withDrawer(ApplicationScoringPage)}
                />
              }
            />
            <Route
              path={`${ROUTES.applications}/:id`}
              element={
                <PrivateRoute
                  roles={[ROLE.GENERAL, ROLE.SMARTFACT, ROLE.BALTICLEASING, ROLE.PSBL]}
                  component={withDrawer(ApplicationPageDetails)}
                />
              }
            />
            <Route
              path={`${ROUTES.applications}/:id/:fileId/:fileType${ROUTES.monitoring}`}
              element={
                <PrivateRoute
                  roles={[ROLE.GENERAL, ROLE.SMARTFACT, ROLE.BALTICLEASING, ROLE.PSBL]}
                  component={withDrawer(ApplicationMetabaseAnalysisPage)}
                />
              }
            />
            <Route
              path={ROUTES.applications}
              element={
                <PrivateRoute
                  roles={[ROLE.GENERAL, ROLE.SMARTFACT, ROLE.BALTICLEASING, ROLE.PSBL]}
                  component={withDrawer(ApplicationsPage)}
                />
              }
            />
            <Route
              path={`${ROUTES.settings}/:id`}
              element={
                <PrivateRoute
                  roles={[ROLE.GENERAL, ROLE.SMARTFACT, ROLE.BALTICLEASING, ROLE.PSBL]}
                  component={withDrawer(ScoringSettingsPageDetails)}
                />
              }
            />
            <Route
              path={ROUTES.settings}
              element={
                <PrivateRoute
                  roles={[ROLE.GENERAL, ROLE.SMARTFACT, ROLE.BALTICLEASING, ROLE.PSBL]}
                  component={withDrawer(ScoringSettingsPage)}
                />
              }
            />
            <Route
              path={`${ROUTES.settings}${ROUTES.administration}${ROUTES.data}/:id`}
              element={
                <PrivateRoute
                  roles={[ROLE.GENERAL, ROLE.SMARTFACT, ROLE.BALTICLEASING, ROLE.PSBL]}
                  component={withDrawer(AdministrationDataDetail)}
                />
              }
            />
            <Route
              path={`${ROUTES.settings}${ROUTES.administration}${ROUTES.model}/:id`}
              element={
                <PrivateRoute
                  roles={[ROLE.GENERAL, ROLE.SMARTFACT, ROLE.BALTICLEASING, ROLE.PSBL]}
                  component={withDrawer(ModelEvaluationDetail)}
                />
              }
            />
            <Route
              path={ROUTES.profile}
              element={
                <PrivateRoute
                  roles={[ROLE.GENERAL, ROLE.SMARTFACT, ROLE.BALTICLEASING, ROLE.PSBL]}
                  component={withDrawer(Profile)}
                />
              }
            />
            <Route
              path={ROUTES.employees}
              element={
                <PrivateRoute
                  roles={[ROLE.GENERAL, ROLE.SMARTFACT, ROLE.BALTICLEASING, ROLE.PSBL]}
                  component={withDrawer(Group)}
                />
              }
            />
            <Route
              path={`${ROUTES.products}`}
              element={
                <PrivateRoute
                  roles={[ROLE.GENERAL, ROLE.SMARTFACT, ROLE.BALTICLEASING, ROLE.PSBL]}
                  component={withDrawer(ProductsPage)}
                />
              }
            />
          </Routes>
        </Router>
      </ThemeProvider>
    </CustomContext.Provider>
  );
}

export default App;
