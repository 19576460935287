import React from 'react';

import { Tooltip, TooltipProps, Typography, tooltipClasses } from '@mui/material';
import styled from '@emotion/styled';

import { color } from '@/constants/colors';

interface StyledTooltipProps {
  children?: React.ReactNode;
  props?: any;
  title?: string;
  description?: React.ReactNode;
  placement?: TooltipProps['placement'];
}

const StyledTooltip = styled(({ className, placement, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} placement={placement || 'right'} arrow />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: color.white,
    color: color.black,
    fontSize: '10px',
    letterSpacing: '0.4px',
    fontWeight: 600,
    lineHeight: '18px',
    borderRadius: '6px',
    padding: '10px',
    boxShadow: '0 4px 16px rgba(0,0,0,0.15)',
    '& .MuiTooltip-arrow': {
      color: color.white,
    },
  },
}));

export const CustomizedTooltips = ({ title, description, placement, children, props }: StyledTooltipProps) => {
  return (
    <div>
      <StyledTooltip
        {...props}
        placement={placement}
        title={
          <>
            <Typography
              sx={{ fontSize: '12px', fontWeight: 600, letterSpacing: '0.2px', marginBottom: '2px' }}
              color={color.grayMain}
            >
              {title}
            </Typography>
            {description}
          </>
        }
      >
        {children}
      </StyledTooltip>
    </div>
  );
};
