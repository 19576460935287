import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';

import { Box } from '@mui/material';

import { AdministrationDataDetailTabConnectionsConnectionsList } from '@/components/Administering/AdministrationTabData/AdministrationDataDetail/AdministrationDataDetailTab/AdministrationDataDetailTabConnections/AdministrationDataDetailTabConnectionsConnectionsList';
import { AdministrationDataDetailTabConnectionsTable } from '@/components/Administering/AdministrationTabData/AdministrationDataDetail/AdministrationDataDetailTab/AdministrationDataDetailTabConnections/AdministrationDataDetailTabConnectionsTable';
import { AdministrationDataDetailTabConnectionsAddConnectModal } from '@/components/Administering/AdministrationTabData/AdministrationDataDetail/AdministrationDataDetailTab/AdministrationDataDetailTabConnections/AdministrationDataDetailTabConnectionsAddConnectModal';
import { useRootStore } from '@/stores';
import type {
  MapAdministrationDataJoinToTableBody,
  MapAdministrationDataObjectJoinSpec,
} from '@/stores/freskoApi/AdministrationDataObjectJoinStore';

export const AdministrationDataDetailTabConnections = observer(() => {
  const { id: idData } = useParams<{ id: string }>();
  const { administrationDataObjectJoinStore } = useRootStore();
  const [editAttribute, setEditAttribute] = useState<MapAdministrationDataObjectJoinSpec[0] | undefined>(undefined);
  const [selectedConnection, setSelectedConnection] = useState<MapAdministrationDataJoinToTableBody[0] | undefined>();

  useEffect(() => {
    if (idData) {
      // @ts-ignore
      administrationDataObjectJoinStore.getAdministrationDataObjectJoin({ data_object_id: idData });
    }
  }, [administrationDataObjectJoinStore, idData]);

  useEffect(() => {
    if (selectedConnection) {
      administrationDataObjectJoinStore.getAdministrationDataObjectJoinSpecByJoinId({ joinId: selectedConnection.id });
    }

    return () => {
      administrationDataObjectJoinStore.joinSpecValuesByJoinId = [];
    };
  }, [administrationDataObjectJoinStore, selectedConnection]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            flexDirection: 'column',
            marginRight: '70px',
          }}
        >
          <AdministrationDataDetailTabConnectionsConnectionsList setSelectedConnection={setSelectedConnection} />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flex: 2,
          }}
        >
          <AdministrationDataDetailTabConnectionsTable
            joinId={selectedConnection?.id}
            setEditAttribute={setEditAttribute}
          />
        </Box>
      </Box>
      <AdministrationDataDetailTabConnectionsAddConnectModal
        joinId={selectedConnection?.id}
        editAttribute={editAttribute}
        setEditAttribute={setEditAttribute}
      />
    </>
  );
});
