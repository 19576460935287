import React, { useEffect, useMemo } from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';

import { WIDTH } from '@/constants/sizes';
import { TextFieldStyled } from '@/components/atoms/TextFieldStyled';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { GetCompanyGroups, TableBodyCompanyGroup } from '@/stores/CompanyGroupStore';
import { createAxiosRequest } from '@/utils/request';
import { apiPath } from '@/constants/api';

export const guardianTypeIsCompanyGroup = (value: string | TableBodyCompanyGroup): value is TableBodyCompanyGroup =>
  (value as TableBodyCompanyGroup)?.name !== undefined;

export const AutocompleteCompanyGroups = (props: any) => {
  const [open, setOpen] = React.useState(!!props?.value);
  const [query, setQuery] = React.useState<string>(props?.value ?? '');
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [options, setOptions] = React.useState<readonly TableBodyCompanyGroup[]>([]);
  const selectedValue = React.useRef<TableBodyCompanyGroup | null | string>();

  const handleSelectedValue = (
    _event: any,
    value: TableBodyCompanyGroup | null | string,
    _reason: any,
    _details?: any,
  ) => {
    if (props.onChange && value) {
      props.onChange(value);
      if (props?.handleChangeInput) {
        props.handleChangeInput({ autocompleteCompanyGroups: '' });
      }
    }
    selectedValue.current = value;
  };

  const disableSearchDadata = useMemo(
    () => props?.disableClearable && props?.freeSolo && props?.disableSearchDadata,
    [props?.disableClearable, props?.disableSearchDadata, props?.freeSolo],
  );

  const handleChange = (event: any) => {
    setQuery(event.target.value);
    if (props?.handleChangeInput) {
      props.handleChangeInput({ autocompleteCompanyGroups: event.target.value });
    }
  };

  const requestSuggestion = React.useCallback(async () => {
    if (!disableSearchDadata) {
      setIsLoading(true);
      try {
        //запрос данных
        const { data } = await createAxiosRequest<
          { name?: string; search?: string; limit?: number; offset?: number },
          GetCompanyGroups
        >({
          path: `${apiPath.companyGroups}`,
          method: 'GET',
          useToken: true,
          params: { search: query, limit: 10, offset: 0 },
        });
        setOptions([...data?.results]);
      } catch (e) {
      } finally {
        setIsLoading(false);
      }
    }
  }, [disableSearchDadata, query]);

  useEffect(() => {
    if (!Boolean(query?.trim()) || isLoading) {
      return undefined;
    }

    const handler = setTimeout(() => {
      requestSuggestion();
    }, 500);

    return () => {
      if (handler) {
        clearTimeout(handler);
      }
    };
  }, [query, requestSuggestion]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const onKeyDown = (event: any) => {
    if ((!open || options.length === 0) && props?.onKeyDown) {
      try {
        props.onKeyDown(event);
      } catch (e) {
        console.log('onKeyDown', 'AutocompleteInn', { e });
      }
    }
  };

  return (
    <Autocomplete
      onKeyDown={onKeyDown}
      disableClearable={props?.disableClearable}
      freeSolo={props?.freeSolo}
      id="asynchronous-demo"
      sx={{ width: WIDTH.s, ...props?.sx }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) => {
        return value?.name?.includes(query);
      }}
      getOptionLabel={(option) => {
        if (guardianTypeIsCompanyGroup(option)) {
          return `${option?.name}`;
        }

        return option;
      }}
      value={query}
      options={options}
      loading={isLoading}
      popupIcon={<></>}
      renderOption={(props, option, { inputValue }) => {
        const name = option.name;

        const matchesName = match(name, inputValue);
        const partsName = parse(name, matchesName);

        const summaryParts = [...partsName];

        return (
          <li {...props}>
            <div>
              {summaryParts.map((part, index) => (
                <span
                  key={index}
                  style={{
                    fontWeight: part.highlight ? 700 : 400,
                  }}
                >
                  {part.text}
                </span>
              ))}
            </div>
          </li>
        );
      }}
      onChange={handleSelectedValue}
      renderInput={(params) => (
        <TextFieldStyled
          {...params}
          label=""
          placeholder={props.placeholder}
          onChange={handleChange}
          sx={{ padding: 0 }}
          size="small"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

AutocompleteCompanyGroups.displayName = 'AutocompleteCompanyGroups';
