import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { Box } from '@mui/system';
import { CircularProgress } from '@mui/material';
import { EditOutlined } from '@mui/icons-material';

import { TemplateInputWithLabel } from '@/components/atoms/TemplateInputWithLabel';
import { OFFSET, WIDTH } from '@/constants/sizes';
import { useRootStore } from '@/stores';
import type { IProfile } from '@/stores/UserStore';
import { ButtonStyled } from '@/components/atoms/Button';
import { APP_SR_ROLE, APP_SR_ROLE_TO_ROLE } from '@/constants/backendEnum';
import { Dropdown } from '@/components/atoms/Dropdown';
import { ROLE } from '@/constants';

const templateInputWithLabelListBeforeDropDown = (selectedEmployee?: IProfile) => [
  {
    id: 'userName',
    label: 'Имя пользователя ⃰',
    required: true,
    autoComplete: 'nope',
    value: selectedEmployee?.username,
    disabled: true,
  },
  {
    id: 'firstName',
    placeholder: 'Не обязательно',
    label: 'Имя',
    autoComplete: 'nope',
    value: selectedEmployee?.first_name,
  },
  {
    id: 'lastName',
    placeholder: 'Не обязательно',
    label: 'Фамилия',
    autoComplete: 'nope',
    value: selectedEmployee?.last_name,
  },
  {
    id: 'secondName',
    placeholder: 'Не обязательно',
    label: 'Отчество',
    value: selectedEmployee?.middle_name,
  },
];

const templateDropdown = {
  id: 'position',
  label: 'Роль ⃰',
  placeholder: 'Выбрать...',
  dropdownItems: [
    {
      label: APP_SR_ROLE['Client Manager'],
      value: APP_SR_ROLE_TO_ROLE['Client Manager'],
    },
    {
      label: APP_SR_ROLE['Credit Analyst'],
      value: APP_SR_ROLE_TO_ROLE['Credit Analyst'],
    },
    {
      label: APP_SR_ROLE['Legal Advisor'],
      value: APP_SR_ROLE_TO_ROLE['Legal Advisor'],
    },
    {
      label: APP_SR_ROLE['Security Advisor'],
      value: APP_SR_ROLE_TO_ROLE['Security Advisor'],
    },
  ],
};

const templateInputWithLabelListAfterDropDown = (selectedEmployee?: IProfile) => [
  {
    id: 'phone',
    label: 'Телефон',
    required: true,
    value: selectedEmployee?.phone,
  },
  {
    id: 'email',
    placeholder: 'Не обязательно',
    label: 'E-mail',
    value: selectedEmployee?.email,
  },
  {
    id: 'password',
    placeholder: 'Не обязательно',
    label: 'Изменить пароль',
  },
];

export const Profile = observer(() => {
  const { userStore, isLoading, employeesStore, positionStore, businessUnitStore } = useRootStore();
  const [disabled, setDisable] = useState(true);

  const toggleDisable = () => {
    setDisable(!disabled);
  };

  const dropdownValueState = useRef<string | undefined>(undefined);

  const [formHasErrorRaw, setFormHasErrorRaw] = useState<Record<string, boolean>>({});
  const [isError, setIsError] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(Boolean(userStore.profileInfo?.is_staff));

  const setFormHasError = (val: any) => {
    setFormHasErrorRaw((hasErrorRaw) => ({ ...(hasErrorRaw ?? {}), ...val }));
  };

  useEffect(() => {
    setIsError(Object.values(formHasErrorRaw).some((item) => item));
  }, [formHasErrorRaw]);

  const onChangeCheckbox = (_e?: any, value?: boolean) => {
    if (value !== undefined) {
      setChecked(value);
    } else {
      setChecked(!checked);
    }
  };

  useEffect(() => {
    onChangeCheckbox(undefined, Boolean(userStore.profileInfo?.is_staff));
  }, [userStore]);

  const onChangeDropdown = (name: string, value: string) => {
    dropdownValueState.current = value;
  };

  const position = positionStore?.allPositions?.find((some) => some.id === userStore.profileInfo?.postn_id);
  const busines = businessUnitStore?.allBusinessUnits?.find((some) => some.id === position?.bu);

  const isCanBeRender = userStore.profileInfo && !isLoading;

  const handleSaveForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);
    const userName = userStore.profileInfo?.username;
    const lastName = data.get('lastName') as string;
    const firstName = data.get('firstName') as string;
    const secondName = data.get('secondName') as string;
    const phone = data.get('phone') as string;
    const email = data.get('email') as string;
    const password = data.get('password') as string | undefined;
    const role = dropdownValueState.current as string;
    const superVisor = checked as boolean;

    if (userName && phone && userStore.profileInfo?.pk && userStore.profileInfo?.view_set) {
      employeesStore.updateEmployee(userStore.profileInfo?.pk, {
        userName,
        lastName,
        firstName,
        middleName: secondName,
        email,
        role: null,
        phone,
        isStaff: superVisor,
        password,
        isMe: true,
        view_set: userStore.profileInfo.view_set,
      });
      toggleDisable();
    }
  };

  return (
    <Box
      mt={OFFSET.m}
      width={WIDTH.xxl}
      component="form"
      autoComplete="off"
      onSubmit={handleSaveForm}
      sx={{ display: 'grid' }}
    >
      {isLoading && (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />{' '}
        </Box>
      )}
      {isCanBeRender &&
        templateInputWithLabelListBeforeDropDown(userStore.profileInfo)?.map(({ id, placeholder, label, ...other }) => (
          <TemplateInputWithLabel
            handleGlobalError={setFormHasError}
            key={id}
            id={id}
            isError={formHasErrorRaw?.[id] ?? false}
            name={id}
            placeholder={placeholder}
            label={label}
            style={{ width: WIDTH.s }}
            disabled={disabled}
            {...other}
          />
        ))}
      {isCanBeRender && position && (
        <Dropdown
          handleGlobalError={setFormHasError}
          onChange={onChangeDropdown}
          key={templateDropdown.id}
          isError={formHasErrorRaw?.[templateDropdown.id] ?? false}
          label={templateDropdown.label}
          title={templateDropdown.placeholder}
          id={templateDropdown.id}
          dropdownItems={[{ label: `${position?.name} / ${busines?.name}`, value: position.id }]}
          value={position?.id}
          disabled
        />
      )}

      {isCanBeRender && userStore.profileInfo?.view_set && (
        <Dropdown
          handleGlobalError={setFormHasError}
          onChange={onChangeDropdown}
          key="view_set"
          isError={formHasErrorRaw?.view_set ?? false}
          label="Интерфейс"
          title="Интерфейс"
          value={userStore.profileInfo?.view_set}
          id="view_set"
          dropdownItems={[
            {
              label: 'Админ',
              value: ROLE.ADMIN,
            },
            {
              label: 'Стандартный',
              value: ROLE.GENERAL,
            },
            {
              label: 'Смартфакт',
              value: ROLE.SMARTFACT,
            },
            {
              label: 'Балтийский лизинг',
              value: ROLE.BALTICLEASING,
            },
            {
              label: 'ПСБ Лизинг',
              value: ROLE.PSBL,
            },
          ]}
          disabled
        />
      )}
      {isCanBeRender &&
        templateInputWithLabelListAfterDropDown(userStore.profileInfo)?.map(({ id, placeholder, label, ...other }) => (
          <TemplateInputWithLabel
            handleGlobalError={setFormHasError}
            key={id}
            id={id}
            name={id}
            isError={formHasErrorRaw?.[id] ?? false}
            placeholder={placeholder}
            label={label}
            style={{ width: WIDTH.s }}
            disabled={disabled}
            {...other}
          />
        ))}
      <Box mt={OFFSET.s} sx={{ display: 'flex', justifyContent: 'space-between' }}>
        {disabled ? (
          <ButtonStyled text="Редактировать" startIcon={<EditOutlined />} onClick={toggleDisable} />
        ) : (
          <ButtonStyled text="Отменить" customType="error" variant="outlined" onClick={toggleDisable} />
        )}

        {disabled ? <></> : <ButtonStyled text="Сохранить" isDisabled={isLoading || isError} isSubmit />}
      </Box>
    </Box>
  );
});
