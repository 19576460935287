import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Box, Container } from '@mui/material';
import { TextFieldStyled } from '@/components/atoms/TextFieldStyled';
import { color } from '@/constants/colors';

interface TitleProps {
  children?: React.ReactNode;
  props?: any;
  [k: string]: any;
}

export const DragAndDrop = ({ parentHandleDrop, fileName, ...props }: TitleProps) => {
  const [dragging, setDragging] = useState(false);
  let dragCounter = 0;

  const dropRef = React.createRef<HTMLDivElement>();
  const input = React.createRef<HTMLInputElement>();

  const stopPropagation = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrag = (e: DragEvent) => {
    stopPropagation(e);
  };

  const handleDragIn = (e: DragEvent) => {
    stopPropagation(e);
    dragCounter++;

    if (e?.dataTransfer?.items && e?.dataTransfer?.items?.length > 0) {
      setDragging(true);
    }
  };

  const handleDragOut = (e: DragEvent) => {
    stopPropagation(e);
    dragCounter--;

    if (dragCounter > 0) return;
    setDragging(false);
  };

  const handleDrop = (e: DragEvent) => {
    stopPropagation(e);
    setDragging(false);

    if (e?.dataTransfer?.files && e.dataTransfer.files.length > 0) {
      parentHandleDrop(e.dataTransfer.files);
      dragCounter = 0;
    }
  };

  const componentDidMount = () => {
    const component = dropRef.current;
    component?.addEventListener('dragenter', handleDragIn);
    component?.addEventListener('dragleave', handleDragOut);
    component?.addEventListener('dragover', handleDrag);
    component?.addEventListener('drop', handleDrop);
  };

  const componentWillUnmount = () => {
    const component = dropRef.current;
    component?.removeEventListener('dragenter', handleDragIn);
    component?.removeEventListener('dragleave', handleDragOut);
    component?.removeEventListener('dragover', handleDrag);
    component?.removeEventListener('drop', handleDrop);
    dragCounter = 0;
  };

  function handleChangeFromHideInput(e: any) {
    parentHandleDrop(e.target.files);
  }

  const handleClickShowedInput = () => {
    input?.current?.click();
  };

  useLayoutEffect(() => {
    componentDidMount();

    return () => componentWillUnmount();
  }, []);

  if (props?.hideInput) {
    return (
      <div style={{ display: 'flex', flex: 1 }} ref={dropRef}>
        {dragging ? (
          <>
            <input
              type="file"
              id="drop_zone"
              style={{ display: 'none' }}
              accept=".xlsx,.xls"
              ref={input}
              onChange={handleChangeFromHideInput}
              multiple={true}
            />
            <Container
              onClick={handleClickShowedInput}
              sx={{
                ...props?.sx,
                zIndex: 9999,
                border: `4px dashed ${color.aquamarine}`,
                borderRadius: '6px',
                top: '50%',
                right: 0,
                left: 0,
                textAlign: 'center',
                color: color.slate_gray,
                fontSize: 14,
                cursor: 'pointer',
                backgroundColor: color.forest_wolf,
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
              }}
            >
              <p>Выберите файл или перетащите в это поле</p>
            </Container>
          </>
        ) : (
          <>
            <input
              type="file"
              id="drop_zone"
              style={{ display: 'none' }}
              accept=".xlsx,.xls"
              ref={input}
              onChange={handleChangeFromHideInput}
              multiple={true}
            />
            <Container
              onClick={handleClickShowedInput}
              sx={{
                ...props?.sx,
                border: `4px dashed ${color.aquamarine}`,
                borderRadius: '6px',
                top: '50%',
                right: 0,
                left: 0,
                textAlign: 'center',
                color: color.slate_gray,
                fontSize: 14,
                cursor: 'pointer',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                display: 'flex',
              }}
            >
              {fileName && fileName.length > 0 ? (
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                  {fileName.map((file: File, index: number) => (
                    <p key={index} style={{ margin: '8px 0' }}>
                      {file.name}
                    </p>
                  ))}
                </Box>
              ) : (
                <p>Выберите файл или перетащите в это поле</p>
              )}
            </Container>
          </>
        )}
      </div>
    );
  }

  return (
    <div style={{ display: 'inline-block', position: 'relative' }} ref={dropRef}>
      {dragging && (
        <div
          style={{
            border: 'dashed grey 4px',
            backgroundColor: `rgba(255, 255, 255,.8)`,
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 9999,
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: '50%',
              right: 0,
              left: 0,
              textAlign: 'center',
              color: color.grayMain,
              fontSize: 36,
            }}
          />
        </div>
      )}
      <input
        type="file"
        id="drop_zone"
        style={{ display: 'none' }}
        accept=".xlsx,.xls"
        ref={input}
        onChange={handleChangeFromHideInput}
      />
      <TextFieldStyled
        value={props.fileNameAnalysis}
        onClick={handleClickShowedInput}
        {...props}
        placeholder="Выберете файл"
      />
    </div>
  );
};

type FileUploaderType = {
  handleFile: (file: any) => void;
  [k: string]: any;
};

export const FileUploader = ({ handleFile, ...props }: FileUploaderType) => {
  const [stateFiles, setStateFiles] = useState<File[]>([]);

  useEffect(() => {
    if (handleFile && props?.hideInput) {
      handleFile(stateFiles);
    } else {
      handleFile(stateFiles[0]);
    }
  }, [stateFiles]);

  const handleDrop = (files: FileList) => {
    setStateFiles((prevState) => {
      const uniqueFiles = Array.from(files).filter(
        (file) => !prevState.some((existingFile) => existingFile.name === file.name),
      );
      return [...prevState, ...uniqueFiles];
    });
  };

  const fileNameAnalysis = stateFiles.length > 0 ? stateFiles[0].name : '';

  return (
    <DragAndDrop parentHandleDrop={handleDrop} fileName={stateFiles} fileNameAnalysis={fileNameAnalysis} {...props} />
  );
};
