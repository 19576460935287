import React from 'react';
import { observer } from 'mobx-react';

import { Box } from '@mui/material';

import { TableCellStyled } from '@/components/Templates/TableComponentStyled';
import { LabelLikeHintWithText } from '@/components/common/LabelLikeHintWithText';
import { AssessmentTemplateAttributeModel } from '@/stores/Models/AdministrationAssessmentTemplateAttributeModel';
import { SimpleInputCustom } from '@/components/Administering/ModelEvaluation/ModelEvaluationDetail/ModelEvaluationDetailTab/ModelEvaluationDetailTabAttributes/components/SimpleInputCustom';
import { color } from '@/constants/colors';

export const Weight = observer(
  ({ modelData, disabled }: { modelData: AssessmentTemplateAttributeModel; disabled: boolean }) => {
    const onChangeInputSave = (val: Record<string, string>) => {
      //@ts-ignore
      modelData.weight = Object.values(val)[0];
    };

    return (
      <TableCellStyled>
        <Box sx={{ visibility: modelData?.is_boolean_score ? 'collapse' : 'visible' }}>
          <LabelLikeHintWithText title="Вес">
            <SimpleInputCustom
              typeVal="number"
              sx={{ backgroundColor: modelData.fieldChanged.weight ? color.light : color.transparent }}
              disabled={disabled}
              required
              fullWidth
              fieldName="weight"
              autoComplete="off"
              size="small"
              value={modelData.weight}
              onChange={onChangeInputSave}
              error={false}
            />
          </LabelLikeHintWithText>
        </Box>
      </TableCellStyled>
    );
  },
);
