import React, {useEffect, useMemo} from 'react';
import {useParams} from 'react-router-dom';
import {observer} from 'mobx-react';

import {Box} from '@mui/material';

import {useRootStore} from '@/stores';
import {
    ModelEvaluationAttributeTabDataList
} from '@/components/Administering/ModelEvaluation/ModelEvaluationDetail/ModelEvaluationDetailTab/ModelEvaluationDetailTabAttributes/ModelEvaluationAttributeTabDataList';
import {
    ModelEvaluationAttributeTabParams
} from '@/components/Administering/ModelEvaluation/ModelEvaluationDetail/ModelEvaluationDetailTab/ModelEvaluationDetailTabAttributes/ModelEvaluationAttributeTabParams';
import {AssessmentTemplateAttributeModelStore} from '@/stores/Models/AssessmentTemplateAttributeModelStore';
import type {AssessmentTemplateAttributeModel} from '@/stores/Models/AdministrationAssessmentTemplateAttributeModel';
import {SETTINGS_STATUS_TO_STATUS} from '@/constants/backendEnum';
import {runInAction} from "mobx";

const store = new AssessmentTemplateAttributeModelStore();

export const ModelEvaluationAttributeTabData = observer(() => {
    const {id: idData} = useParams<{ id: string }>();
    const {administrationAssessmentTemplateStore} = useRootStore();
    const isStatusDisabled = useMemo(
        () =>
            administrationAssessmentTemplateStore.selectedAdministrationAssessmentTemplateById.status !==
            SETTINGS_STATUS_TO_STATUS.Draft,
        [administrationAssessmentTemplateStore.selectedAdministrationAssessmentTemplateById.status],
    );

    useEffect(() => {
        if (idData) {
            store.getAssessmentTemplateAttrByTemplateId(idData);
        }
    }, [administrationAssessmentTemplateStore, idData]);

    const setInfo = (value: AssessmentTemplateAttributeModel | undefined) => {
        runInAction(() => {
            store.selectedTemplate = value;
        })
    };

    useEffect(() => {
        if (idData && store.selectedTemplate) {
            store.getAssessmentTemplateByAsmntTmplPkAttrValPk({
                asmnt_tmpl_pk: idData,
                asmnt_tmpl_attr_pk: store.selectedTemplate.id,
            });
        }
    }, [store.selectedTemplate, idData]);

    useEffect(() => {
        return () => {
            runInAction(() => {
                store.selectedTemplate = undefined;
                store.templateAttrByTemplateId = [];
            })
        };
    }, []);

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flex: 1,
                        justifyContent: 'center',
                        flexDirection: 'column',
                        marginRight: '70px',
                    }}
                >
                    <ModelEvaluationAttributeTabDataList
                        setInfo={setInfo}
                        attributeSet={store.templateAttrByTemplateId}
                        store={store}
                        isStatusDisabled={isStatusDisabled}
                    />
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flex: 2,
                    }}
                >
                    <ModelEvaluationAttributeTabParams
                        selectedAttributeSet={store.selectedTemplate}
                        store={store}
                        isStatusDisabled={isStatusDisabled}
                    />
                </Box>
            </Box>
        </>
    );
});
