import React, { useEffect, useState } from 'react';
import style from '@/components/atoms/TemplateInputWithLabel.module.scss';

import Box from '@mui/material/Box';
import { FormHelperText, Skeleton, Typography } from '@mui/material';

import { OFFSET } from '@/constants/sizes';
import { InputStyledMonitoring, TextFieldStyled } from '@/components/atoms/TextFieldStyled';
import { InputLabelStyled } from '@/components/atoms/InputLabelStyled';
import { color } from '@/constants/colors';

const emailRegexp =
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

export type ITemplateInputWithLabel = {
  id?: string;
  placeholder?: string;
  label?: string;
  [k: string]: any;
};

export const TemplateWithLabel = ({ id, placeholder, label, children }: ITemplateInputWithLabel) => {
  return (
    <Box
      mb={OFFSET.xs}
      sx={{
        alignItems: 'center',
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        maxHeight: '36px',
      }}
    >
      {label && <InputLabelStyled>{label}</InputLabelStyled>}
      {children}
    </Box>
  );
};

export const TemplateInputWithLabel = ({
  id,
  placeholder,
  label,
  handleGlobalError,
  errorMessage,
  isError,
  value,
  handleChange,
  regExp,
  children,
  ...other
}: React.PropsWithChildren & ITemplateInputWithLabel) => {
  const [text, setText] = useState<string | undefined>(value || undefined);

  useEffect(() => {
    if (value && other.forceSet) {
      setText(value);
    }
  }, [value]);

  useEffect(() => {
    if (other.required && id) {
      handleGlobalError && handleGlobalError({ [id]: Boolean(!value) });
    }

    return () => {
      if (other.required && id) {
        handleGlobalError && handleGlobalError({ [id]: Boolean(false) });
      }
    };
  }, []);

  useEffect(() => {
    if (id === 'fio' && text) {
      if (
        text.split(' ').length >= 2 &&
        text
          .split(' ')
          .slice(0, 2)
          .every((t) => t)
      ) {
        handleGlobalError && handleGlobalError({ [id]: false });
        return;
      }

      handleGlobalError && handleGlobalError({ [id]: true });
      return;
    }
    if (id === 'phone' && text) {
      if (text.length >= 16) {
        handleGlobalError && handleGlobalError({ [id]: false });
        return;
      }
      handleGlobalError && handleGlobalError({ [id]: true });
    } else if (text && other.required && id) {
      handleGlobalError && handleGlobalError({ [id]: false });
    } else if (!text && other.required && id) {
      handleGlobalError && handleGlobalError({ [id]: true });
    } else if (!text && !other.required && id) {
      handleGlobalError && handleGlobalError({ [id]: false });
    }

    if (id === 'email' && text && other.required) {
      if (!emailRegexp.test(text) && handleGlobalError) {
        handleGlobalError({ [id]: true });
      }
    }
  }, [text, isError]);

  const formatPhoneNumber = (event: any) => {
    event.target.value = event?.target?.value?.replace(/\D/g, '');
    const input = event?.target?.value?.replace(/\D/g, '')?.substring(0, 11); // First ten digits of input only
    const areaCode = input?.substring(1, 4);
    const middle = input?.substring(4, 7);
    const last = input?.substring(7, 9);
    const post = input?.substring(9, 11);

    if (input?.length > 8 && post) {
      event.target.value = `+7(${areaCode})${middle}-${last}-${post}`;
    } else if (input?.length > 6 && last) {
      event.target.value = `+7(${areaCode})${middle}-${last}`;
    } else if (input?.length > 4 && middle) {
      event.target.value = `+7(${areaCode})${middle}`;
    } else if (input?.length > 0) {
      event.target.value = `+7(${areaCode}`;
    }

    return event?.target?.value;
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (id === 'phone') {
      const formattedText = formatPhoneNumber(event);
      setText(formattedText);
      if (handleChange && id) {
        handleChange({ [id]: formattedText });
      }
    } else if (id === 'fio') {
      const formattedText = event?.target?.value.replace('  ', ' ');
      setText(formattedText);
      if (handleChange && id) {
        handleChange({ [id]: formattedText });
      }
    } else if (regExp) {
      setText(event?.target?.value?.match(regExp)?.join(''));
      if (handleChange && id) {
        handleChange({ [id]: event?.target?.value });
      }
    } else {
      setText(event?.target?.value);
      if (handleChange && id) {
        handleChange({ [id]: event?.target?.value });
      }
    }

    return event;
  };

  const isErrorCompilation =
    id !== 'phone' && id !== 'email'
      ? !!isError && text !== undefined && text?.trim() === ''
      : !!isError && text !== undefined;

  return (
    <Box
      mb={other.mb ?? OFFSET.xs}
      mr={other.mr}
      mt={isErrorCompilation ? OFFSET.xs : undefined}
      sx={{
        alignItems: 'center',
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        maxHeight: '36px',
      }}
    >
      {label && <InputLabelStyled>{label}</InputLabelStyled>}
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'self-end',
        }}
      >
        {children || (
          <TextFieldStyled
            key={id}
            id={id}
            name={id}
            variant="outlined"
            placeholder={placeholder}
            error={isError && text !== undefined && text?.trim() === ''}
            onChange={onChange}
            autoComplete="off"
            {...other}
            value={text}
          />
        )}
        {!!isError && text !== undefined && id === 'fio' && (
          <FormHelperText error>Фамилия Имя через пробел</FormHelperText>
        )}
        {!!isError && text !== undefined && text?.trim() === '' && id !== 'phone' && id !== 'email' && (
          <FormHelperText error>{errorMessage ?? 'Поле обязательно для заполнения'}</FormHelperText>
        )}
        {!!isError && text !== undefined && text?.trim() === '' && id === 'phone' && (
          <FormHelperText error>Поле обязательно для заполнения</FormHelperText>
        )}
        {!!isError && text !== undefined && text?.trim() !== '' && id === 'phone' && (
          <FormHelperText error>Телефон введен неверно</FormHelperText>
        )}
        {!!isError && text !== undefined && id === 'email' && (
          <FormHelperText error>Email введен неверно</FormHelperText>
        )}
      </Box>
    </Box>
  );
};

export const TemplateInputWithLabelInnDisabled = ({
  id,
  placeholder,
  label,
  handleGlobalError,
  errorMessage,
  isError,
  value,
  handleChange,
  regExp,
  children,
  invalidInnList,
  ...other
}: React.PropsWithChildren & ITemplateInputWithLabel) => {
  const [text, setText] = useState<string | undefined>(value || undefined);

  useEffect(() => {
    if (value && other.forceSet) {
      setText(value);
    }
  }, [value]);

  useEffect(() => {
    if (other.required && id) {
      handleGlobalError && handleGlobalError({ [id]: Boolean(!value) });
    }

    return () => {
      if (other.required && id) {
        handleGlobalError && handleGlobalError({ [id]: Boolean(false) });
      }
    };
  }, []);

  useEffect(() => {
    if (id === 'fio' && text) {
      if (
        text.split(' ').length >= 2 &&
        text
          .split(' ')
          .slice(0, 2)
          .every((t) => t)
      ) {
        handleGlobalError && handleGlobalError({ [id]: false });
        return;
      }

      handleGlobalError && handleGlobalError({ [id]: true });
      return;
    }
    if (id === 'phone' && text) {
      if (text.length >= 16) {
        handleGlobalError && handleGlobalError({ [id]: false });
        return;
      }
      handleGlobalError && handleGlobalError({ [id]: true });
    } else if (text && other.required && id) {
      handleGlobalError && handleGlobalError({ [id]: false });
    } else if (!text && other.required && id) {
      handleGlobalError && handleGlobalError({ [id]: true });
    } else if (!text && !other.required && id) {
      handleGlobalError && handleGlobalError({ [id]: false });
    }

    if (id === 'email' && text && other.required) {
      if (!emailRegexp.test(text) && handleGlobalError) {
        handleGlobalError({ [id]: true });
      }
    }
  }, [text, isError]);

  const formatPhoneNumber = (event: any) => {
    event.target.value = event?.target?.value?.replace(/\D/g, '');
    const input = event?.target?.value?.replace(/\D/g, '')?.substring(0, 11); // First ten digits of input only
    const areaCode = input?.substring(1, 4);
    const middle = input?.substring(4, 7);
    const last = input?.substring(7, 9);
    const post = input?.substring(9, 11);

    if (input?.length > 8 && post) {
      event.target.value = `+7(${areaCode})${middle}-${last}-${post}`;
    } else if (input?.length > 6 && last) {
      event.target.value = `+7(${areaCode})${middle}-${last}`;
    } else if (input?.length > 4 && middle) {
      event.target.value = `+7(${areaCode})${middle}`;
    } else if (input?.length > 0) {
      event.target.value = `+7(${areaCode}`;
    }

    return event?.target?.value;
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (id === 'phone') {
      const formattedText = formatPhoneNumber(event);
      setText(formattedText);
      if (handleChange && id) {
        handleChange({ [id]: formattedText });
      }
    } else if (id === 'fio') {
      const formattedText = event?.target?.value.replace('  ', ' ');
      setText(formattedText);
      if (handleChange && id) {
        handleChange({ [id]: formattedText });
      }
    } else if (regExp) {
      setText(event?.target?.value?.match(regExp)?.join(''));
      if (handleChange && id) {
        handleChange({ [id]: event?.target?.value });
      }
    } else {
      setText(event?.target?.value);
      if (handleChange && id) {
        handleChange({ [id]: event?.target?.value });
      }
    }

    return event;
  };

  const isErrorCompilation =
    id !== 'phone' && id !== 'email'
      ? !!isError && text !== undefined && text?.trim() === ''
      : !!isError && text !== undefined;

  return (
    <Box
      mb={other.mb ?? OFFSET.xs}
      mr={other.mr}
      mt={isErrorCompilation ? OFFSET.xs : undefined}
      sx={{
        alignItems: 'center',
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        maxHeight: '36px',
      }}
    >
      {label && <InputLabelStyled>{label}</InputLabelStyled>}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'self-end',
        }}
      >
        {children || (
          <TextFieldStyled
            key={id}
            id={id}
            name={id}
            variant="outlined"
            placeholder={placeholder}
            error={isError && text !== undefined && text?.trim() === ''}
            onChange={onChange}
            autoComplete="off"
            {...other}
            value={text}
          />
        )}
        {!!isError && text !== undefined && id === 'fio' && (
          <FormHelperText error>Фамилия Имя через пробел</FormHelperText>
        )}
        {!!isError && text !== undefined && text?.trim() === '' && id !== 'phone' && id !== 'email' && (
          <FormHelperText error>{errorMessage ?? 'Поле обязательно для заполнения'}</FormHelperText>
        )}
        {!!isError && text !== undefined && id === 'phone' && (
          <FormHelperText error>Телефон введен неверно</FormHelperText>
        )}
        {!!isError && text !== undefined && id === 'email' && (
          <FormHelperText error>Email введен неверно</FormHelperText>
        )}
      </Box>
    </Box>
  );
};

export const TemplateInputWithLabelInn = ({
  id,
  innList,
  placeholder,
  label,
  handleGlobalError,
  errorMessage,
  isError,
  value,
  handleChange,
  regExp,
  children,
  ...other
}: React.PropsWithChildren & ITemplateInputWithLabel) => {
  const [text, setText] = useState<string | undefined>(value || undefined);
  useEffect(() => {
    setText(innList);
  }, [innList]);
  useEffect(() => {
    if (value && other.forceSet) {
      setText(value);
    }
  }, [value]);

  useEffect(() => {
    if (other.required && id) {
      handleGlobalError && handleGlobalError({ [id]: Boolean(!value) });
    }

    return () => {
      if (other.required && id) {
        handleGlobalError && handleGlobalError({ [id]: Boolean(false) });
      }
    };
  }, []);

  useEffect(() => {
    if (text && other.required && id) {
      handleGlobalError && handleGlobalError({ [id]: false });
    } else if (!text && other.required && id) {
      handleGlobalError && handleGlobalError({ [id]: true });
    } else if (!text && !other.required && id) {
      handleGlobalError && handleGlobalError({ [id]: false });
    }
  }, [text, isError]);

  const onChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(event?.target?.value);
    if (handleChange && id) {
      handleChange(event?.target?.value);
    }

    return event;
  };

  return (
    <Box
      mb={other.mb ?? OFFSET.xs}
      mr={other.mr}
      sx={{
        alignItems: 'start',
        display: 'flex',
        width: '100%',
        height: '150px',
        justifyContent: 'space-between',
        maxHeight: '150px',
        marginTop: '20px',
      }}
    >
      {label && <InputLabelStyled>{label}</InputLabelStyled>}
      <Box
        sx={{
          height: '150px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'self-end',
        }}
      >
        {children || (
          <textarea
            className={style.inputInn}
            key={id}
            id={id}
            name={id}
            placeholder={placeholder}
            onChange={onChange}
            autoComplete="off"
            {...other}
            value={text}
          />
        )}
        {!!isError && text !== undefined && text?.trim() === '' && id !== 'phone' && id !== 'email' && (
          <FormHelperText error>{errorMessage ?? 'Поле обязательно для заполнения'}</FormHelperText>
        )}
      </Box>
    </Box>
  );
};

export const TemplateInputWithLabelDisabled = ({ label, ...other }: ITemplateInputWithLabel) => {
  const { marginRight, width, value, isLoading } = other;

  return (
    <Box
      mb={OFFSET.xs}
      sx={{
        alignItems: 'start',
        flexDirection: 'column',
        display: 'flex',
        width: width ?? '100%',
        justifyContent: 'space-between',
        marginRight,
      }}
    >
      <InputLabelStyled
        sx={{
          margin: 0,
          marginLeft: '4px',
          marginBottom: '4px',
          fontSize: '12px',
        }}
      >
        {label ?? ''}
      </InputLabelStyled>
      <Box
        mb={OFFSET.xs}
        sx={{
          alignItems: 'start',
          flexDirection: 'column',
          display: 'flex',
          width: width ?? '100%',
          justifyContent: 'space-between',
          background: color.periwinkle,
          border: `1px solid ${color.gray}`,
          borderRadius: '2px',
        }}
      >
        <Typography
          sx={{
            padding: '10px 8px',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '16px',
            color: value ? color.light_black : color.gray,
          }}
        >
          {isLoading ? <Skeleton width="92px" /> : value || label}
        </Typography>
      </Box>
    </Box>
  );
};

export const TemplateInputWithLabelMonitoring = ({ label, disabled, ...other }: ITemplateInputWithLabel) => {
  const { marginRight, width, value, placeholder, onChange } = other;

  return (
    <Box
      sx={{
        alignItems: 'start',
        flexDirection: 'column',
        display: 'flex',
        width: width ?? '100%',
        justifyContent: 'space-between',
        marginRight,
        marginBottom: '15px',
      }}
    >
      <InputLabelStyled
        sx={{
          margin: 0,
          fontSize: '13px',
          lineHeight: '18px',
          fontWeight: '500',
        }}
      >
        {label ?? ''}
      </InputLabelStyled>
      <Box
        sx={{
          width: width ?? '100%',
          height: '38px',
          marginTop: '5px',
          flexDirection: 'column',
          display: 'flex',
        }}
      >
        <InputStyledMonitoring
          disabled={disabled}
          sx={{
            backgroundColor: disabled ? color.light : color.inherit,
            color: disabled ? color.grayMain : color.inherit,
            borderRadius: '12px',
          }}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
        />
      </Box>
    </Box>
  );
};
