import {action, makeObservable, observable, reaction, runInAction} from 'mobx';
import {TableStoreType, TableStore} from '@/stores/TableStore';
import type {IRootStore} from '@/stores/type';
import {apiPath} from '@/constants/api';
import {createAxiosRequest} from '@/utils/request';

export type IAssessment = {
    id: number;
    created_by: string;
    updated_by: string;
    obj_primary_id: number;
    context_name: string;
    context_object_name: string;
    ctx_primary_obj_id: number;
    total_score: number;
    status: string;
    error_code: string;
    error_msg: string;
    asmnt_session_uuid: string;
    created: string;
    updated: string;
    asmnt_tmpl: number;
    helpers: Record<string, string>[];
    asmnt_tmpl_name: string;
};

export type IAssessmentAttr = {
    id?: number;
    created_by?: string;
    updated_by?: string;
    sequence: number;
    name: string;
    status?: string;
    error_code?: string;
    error_msg?: string;
    description?: string;
    value_type?: string;
    data_obj_attr_name?: string;
    value?: string;
    boolean_value?: boolean;
    category_value?: string;
    number_value?: number;
    score?: number;
    boolean_score?: boolean;
    is_out_of_range: boolean;
    tags?: string;
    created?: string;
    updated?: string;
    assessment?: number;
    is_boolean_score: boolean;
};

export type IReAssessment = {
    obj_primary_id?: number;
    context_name?: string;
    context_object_name?: string;
    ctx_primary_obj_id?: number;
    total_score?: number;
    status?: string;
    error_code?: string;
    error_msg?: string;
    asmnt_session_uuid: string;
    asmnt_tmpl?: number;
};

export type ITableBodyAssessment = {
    id: number;
    model: string;
    object: string;
    dateAssessment: string;
    status: string;
    score: number;
    role: string;
    contragent: string;
    errorMessage: string;
    assessmentTemplId: number;
    asmnt_session_uuid: string;
    asmnt_tmpl_name?: string;
    helpers?: Record<string, string>[];
};

export interface IAdministrationAssessmentStore {
    rootStore: IRootStore;
    getAssessment: () => void;
    tableBody?: ITableBodyAssessment[];
    assessmentAttributes?: IAssessmentAttr[];
    getAssessmentAttrById: (id: string | number) => void;
    getAssessmentGetAvailableAsmntTmpl: (id: string | number) => void;
    makeNewAssessmentById: ({id, bundleData}: { id: string | number; bundleData: any[] }) => void;
    reAssessmentById: ({id, data}: { id: string | number; data: IReAssessment }) => Promise<void> | undefined;
    patchAssessmentAttrById: ({
                                  asmnt_pk,
                                  id,
                                  data,
                              }: {
        asmnt_pk: string | number;
        id: string | number;
        data: Partial<IAssessmentAttr>;
    }) => void;
    getAssessmentById: (id: string | number) => Promise<ITableBodyAssessment>;
    isLoading: boolean;
    assessmentAvailableAsmntTmpl?: any;
    selectedOrderId?: string;
}

export type AdministrationAssessmentStoreWithTableStore<T> = IAdministrationAssessmentStore & TableStoreType<T>;
export type IGetAssessment = IGetWithPagination<IAssessment>;
export type IGetAssessmentAttr = IGetWithPagination<IAssessmentAttr>;

export class AdministrationAssessmentStore
    extends TableStore<ITableBodyAssessment>
    implements AdministrationAssessmentStoreWithTableStore<ITableBodyAssessment> {
    @observable
    public rootStore: IRootStore;

    @observable
    public assessmentAttributes?: IAssessmentAttr[] = [];

    @observable
    public assessmentAvailableAsmntTmpl?: any;

    @observable
    public isLoading: boolean = false;

    @observable
    public selectedOrderId: string;

    constructor(rootStore: IRootStore) {
        super();
        makeObservable(this);
        this.rootStore = rootStore;

        reaction(
            () => this.selectedPage,
            (value, _previousValue) => {
                if (this.maxSelectedPage <= value) {
                    this.getAssessment();
                }
            },
        );
    }

    @action
    getAssessment = async (immediately?: boolean) => {
        if (this.selectedOrderId) {
            this.rootStore.requestTemplate({
                immediately,
                errorMessage: 'Ошибка получения данных оценки',
                callback: async () => {
                    const limit = this.rowsPerPageChange;
                    const offset = this.rowsPerPageChange * this.selectedPage;

                    const {data} = await createAxiosRequest<
                        { offset: number; limit: number; ordering?: string; search?: string },
                        IGetAssessment
                    >({
                        path: 'applications/{id}/assessments'.replace('{id}', `${this.selectedOrderId}`),
                        method: 'GET',
                        params: {offset, limit, ordering: '-updated'},
                        useToken: true,
                    });
                    runInAction(() => {
                        this.limit = limit;
                        this.summary = data.count;
                        this.offset = offset;
                        const tableData = mapAccountsToTableBody(data.results);
                        this.tableBody = [...tableData];
                    })
                },
            });
        }
    };

    @action
    getAssessmentAttrById = async (id: string | number) => {
        this.rootStore.requestTemplate({
            errorMessage: 'Ошибка получения данных',
            callback: async () => {
                const {data} = await createAxiosRequest<
                    { offset: number; limit: number; status?: string; search?: string },
                    IGetAssessmentAttr
                >({
                    path: apiPath.assessmentAttrById?.replace('{asmnt_pk}', `${id}`),
                    method: 'GET',
                    params: {offset: 0, limit: 100},
                    useToken: true,
                });
                runInAction(() => {
                    this.assessmentAttributes = data.results?.sort((a: IAssessmentAttr, b: IAssessmentAttr) =>
                        a?.sequence > b?.sequence ? 1 : -1,
                    );
                })
            },
        });
    };

    @action
    getAssessmentGetAvailableAsmntTmpl = async (id: string | number) => {
        if (!this.isLoading) {
            this.rootStore.requestTemplate({
                errorMessage: 'Ошибка получения данных',
                immediately: true,
                callback: async () => {
                    try {
                        this.isLoading = true;

                        const {data} = await createAxiosRequest<any, any>({
                            path: apiPath.assessmentGetAvailableAsmntTmpl?.replace('{id}', `${id}`),
                            method: 'GET',
                            useToken: true,
                        });
                        runInAction(() => {
                            this.assessmentAvailableAsmntTmpl = data;
                        })
                    } catch {
                        // noop
                    } finally {
                        runInAction(() => {
                            this.isLoading = false;
                        })
                    }
                },
            });
        }
    };

    @action
    makeNewAssessmentById = async ({id, bundleData}: { id: string | number; bundleData: any[] }) => {
        if (!this.isLoading) {
            this.rootStore.requestTemplate({
                finallyAction: () => this.getAssessment(),
                errorMessage: 'Ошибка новой оценки',
                immediately: true,
                callback: async () => {
                    try {
                        this.isLoading = true;

                        await createAxiosRequest<any, any>({
                            path: apiPath.assessmentMakeNewAssessmentById?.replace('{id}', `${id}`),
                            method: 'POST',
                            data: {bundle: bundleData},
                            useToken: true,
                        });
                    } catch {
                        // noop
                    } finally {
                        runInAction(() => {
                            this.isLoading = false;
                        })
                    }
                },
            });
        }
    };

    @action
    getAssessmentById = async (id: string | number) => {
        return this.rootStore.requestTemplate({
            isReturned: true,
            errorMessage: 'Ошибка новой оценки',
            immediately: true,
            callback: async () => {
                try {
                    this.isLoading = true;
                    const {data} = await createAxiosRequest<any, IAssessment>({
                        path: apiPath.assessmentById?.replace('{id}', `${id}`),
                        method: 'GET',
                        useToken: true,
                    });

                    return mapAccountsToTableBody([data])?.[0];
                } catch {
                } finally {
                    runInAction(() => {
                        this.isLoading = false;
                    })
                }
            },
        });
    };

    @action
    reAssessmentById = ({id, data}: { id: string | number; data: IReAssessment }) => {
        if (!this.isLoading) {
            return this.rootStore.requestTemplate({
                isReturned: true,
                finallyAction: () => {
                    this.getAssessmentAttrById(id);
                },
                errorMessage: 'Ошибка новой оценки',
                immediately: true,
                callback: async () => {
                    try {
                        this.isLoading = true;

                        await createAxiosRequest<any, any>({
                            path: apiPath.assessmentMakeReAssessmentById?.replace('{id}', `${id}`),
                            method: 'POST',
                            data,
                            useToken: true,
                        });
                    } catch {
                    } finally {
                        runInAction(() => {
                            this.isLoading = false;
                        })
                    }
                },
            });
        }
    };

    @action
    patchAssessmentAttrById = async ({
                                         asmnt_pk,
                                         id,
                                         data,
                                     }: {
        asmnt_pk: string | number;
        id: string | number;
        data: any;
    }) => {
        this.rootStore.requestTemplate({
            errorMessage: 'Ошибка обновления данных',
            callback: async () => {
                const {data: response} = await createAxiosRequest<any, IAssessmentAttr>({
                    path: apiPath.patchAssessmentAttrById.replace('{asmnt_pk}', `${asmnt_pk}`).replace('{id}', `${id}`),
                    method: 'PATCH',
                    data,
                    useToken: true,
                });
                runInAction(() => {
                    if (this.assessmentAttributes && response?.id) {
                        const prev = this.assessmentAttributes.find(({id}) => id === response?.id);
                        if (prev) {
                            const prevIndex = this.assessmentAttributes.indexOf(prev);
                            this.assessmentAttributes[prevIndex] = response;
                        }
                    }
                })
            },
        });
    };
}

const mapAccountsToTableBody = (data: IAssessment[]): ITableBodyAssessment[] => {
    return data.map((item) => ({
        id: item.id,
        model: item.asmnt_tmpl_name,
        object: item.context_object_name,
        dateAssessment: item.updated ?? item.created,
        status: item?.status,
        score: item?.total_score,
        role: '-', // TODO
        contragent: '-', // TODO
        errorMessage: item.error_msg,
        assessmentTemplId: item.asmnt_tmpl,
        asmnt_session_uuid: item.asmnt_session_uuid,
        helpers: item?.helpers,
    }));
};

export type IAvailableAsmntTmpl = {
    id: number;
    client_name: string;
    staff_role: string;
    staff_fio: string;
    created: string;
    updated: string;
    created_by: number;
    updated_by: number;
    num: string;
    source: string;
    stage: string;
    employees: string;
};
