import {action, makeObservable, observable, runInAction} from 'mobx';
import {createAxiosRequest} from '@/utils/request';
import type {
    AdministrationAssessmentTemplateAttribute,
    AssessmentTemplateByAsmntTmplPkAttrPk,
} from '@/stores/freskoApi/AdministrationAssessmentTemplateStore';
import {apiPath} from '@/constants/api';
import {AssessmentTemplateAttributeModel} from '@/stores/Models/AdministrationAssessmentTemplateAttributeModel';
import {AssessmentTemplateAttrModel} from '@/stores/Models/AssessmentTemplateAttrModel';

export class AssessmentTemplateAttributeModelStore {
    @observable
    isLoading: boolean = false;

    @observable
    isLoadingAttribute: boolean = false;

    @observable
    isLoadingPatch: boolean = false;

    @observable
    templateAttrByTemplateId: AssessmentTemplateAttributeModel[] = [];

    @observable
    selectedTemplate?: AssessmentTemplateAttributeModel = undefined;

    @observable
    templateId?: string | number;

    @observable
    attrId?: string | number;

    constructor() {
        makeObservable(this);
    }

    @action
    public async getAssessmentTemplateAttrByTemplateId(id: string | number) {
        if (!this.isLoading) {
            try {
                this.isLoading = true;

                const {data} = await createAxiosRequest<{}, IGetWithPagination<AdministrationAssessmentTemplateAttribute>>({
                    path: apiPath.assessmentTemplateByAsmntTmplPk.replace('{asmnt_tmpl_pk}', `${id}`),
                    method: 'GET',
                    useToken: true,
                    params: {limit: 200, offset: 0, ordering: 'sequence'},
                });
                runInAction(() => {
                    this.templateAttrByTemplateId = data.results?.map((item) => new AssessmentTemplateAttributeModel(item));
                })
            } finally {
                runInAction(() => {
                    this.isLoading = false;
                })
            }
        }
    }

    @action
    public async getAssessmentTemplateByAsmntTmplPkAttrValPk({
                                                                 asmnt_tmpl_pk,
                                                                 asmnt_tmpl_attr_pk,
                                                             }: {
        asmnt_tmpl_pk: string | number;
        asmnt_tmpl_attr_pk: string | number;
    }) {
        if (!this.isLoadingAttribute) {
            try {
                this.isLoadingAttribute = true;

                const {data} = await createAxiosRequest<{}, IGetWithPagination<AssessmentTemplateByAsmntTmplPkAttrPk>>({
                    path: apiPath.assessmentTemplateByAsmntTmplPkAttrPk
                        .replace('{asmnt_tmpl_pk}', `${asmnt_tmpl_pk}`)
                        .replace('{asmnt_tmpl_attr_pk}', `${asmnt_tmpl_attr_pk}`),
                    method: 'GET',
                    params: {offset: 0, limit: 20, ordering: 'sequence'},
                    useToken: true,
                });
                runInAction(() => {
                    if (this.selectedTemplate) {
                        this.selectedTemplate.templateAttrs = data.results?.map((item) => new AssessmentTemplateAttrModel(item));
                    }
                })
            } finally {
                this.isLoadingAttribute = false;
            }
        }
    }

    @action
    public async updateAssessmentTemplateAttributes({
                                                        pageId,
                                                        data,
                                                    }: {
        pageId: string | number;
        data: Partial<AssessmentTemplateAttributeModel[]>;
    }) {
        if (!this.isLoadingPatch) {
            try {
                this.isLoadingPatch = true;

                await createAxiosRequest<{}, any>({
                    path: apiPath.templAttrInModelUpdate
                        .replace('{asmnt_tmpl_pk}', `${pageId}`),
                    method: 'PUT',
                    data,
                    useToken: true,
                });

                await this.getAssessmentTemplateAttrByTemplateId(pageId);
            } finally {
                this.isLoadingPatch = false;
            }
        }
    }

    public updateAssessmentTemplateAllValues = async ({
                                                          pageId,
                                                          selectedAttributeId,
                                                          asmnt_tmpl_attr,
                                                          asmnt_tmpl_attr_val,
                                                          mark_as_delete_ids,
                                                      }: {
        pageId: string | number;
        asmnt_tmpl_attr: any;
        asmnt_tmpl_attr_val: any[];
        selectedAttributeId: string | number;
        mark_as_delete_ids: number[];
    }) => {
        if (!this.isLoadingPatch) {
            try {
                this.isLoadingPatch = true;
                const requests = [];
                const afterReq = [];

                if (mark_as_delete_ids?.length > 0) {
                    mark_as_delete_ids?.forEach((id) => {
                        requests.push(
                            createAxiosRequest<any, any>({
                                path: apiPath.assessmentTemplateByAsmntTmplPkAttrPkById
                                    .replace('{asmnt_tmpl_pk}', `${pageId}`)
                                    .replace('{asmnt_tmpl_attr_pk}', `${this.selectedTemplate?.id}`)
                                    .replace('{id}', `${id}`),
                                method: 'DELETE',
                                useToken: true,
                            }),
                        );
                    });
                }

                if (asmnt_tmpl_attr_val?.length > 0) {
                    asmnt_tmpl_attr_val.forEach((element) => {
                        const createOrEditAttrPath = element?.id
                            ? apiPath.assessmentTemplateByAsmntTmplPkAttrPkById
                                .replace('{asmnt_tmpl_pk}', `${pageId}`)
                                .replace('{asmnt_tmpl_attr_pk}', `${this.selectedTemplate?.id}`)
                                .replace('{id}', `${element?.id}`)
                            : apiPath.assessmentTemplateByAsmntTmplPkAttrPk
                                .replace('{asmnt_tmpl_pk}', `${pageId}`)
                                .replace('{asmnt_tmpl_attr_pk}', `${this.selectedTemplate?.id}`);
                        const method = element?.id ? 'PATCH' : 'POST';

                        requests.push(
                            createAxiosRequest<{}, any>({
                                path: createOrEditAttrPath,
                                method,
                                data: {...element},
                                useToken: true,
                            }),
                        );
                    });
                }

                if (Object.keys(asmnt_tmpl_attr)?.length > 0) {
                    requests.push(
                        createAxiosRequest<{}, any>({
                            path: apiPath.templAttrByIdInModel
                                .replace('{asmnt_tmpl_pk}', `${pageId}`)
                                .replace('{id}', `${selectedAttributeId}`),
                            method: 'PATCH',
                            data: {...asmnt_tmpl_attr},
                            useToken: true,
                        }),
                    );
                }

                await Promise.all(requests);

                if (asmnt_tmpl_attr_val?.length > 0 || asmnt_tmpl_attr_val?.length > 0) {
                    if (this.selectedTemplate && this.selectedTemplate?.id) {
                        afterReq.push(
                            this.getAssessmentTemplateByAsmntTmplPkAttrValPk({
                                asmnt_tmpl_pk: `${pageId}`,
                                asmnt_tmpl_attr_pk: `${this.selectedTemplate.id}`,
                            }),
                        );
                    }
                }

                if (Object.keys(asmnt_tmpl_attr)?.length > 0) {
                    afterReq.push(this.getAssessmentTemplateAttrByTemplateId(`${pageId}`));
                }

                await Promise.all(afterReq);
            } finally {
                this.isLoadingPatch = false;
            }
        }
    };

    @action
    public async deleteTemplateAttrVal({pageId, deleteId}: { pageId: string | number; deleteId: string | number }) {
        if (!this.isLoadingPatch) {
            try {
                this.isLoadingPatch = true;

                await createAxiosRequest<{}, any>({
                    path: apiPath.assessmentTemplateByAsmntTmplPkAttrPkById
                        .replace('{asmnt_tmpl_pk}', `${pageId}`)
                        .replace('{asmnt_tmpl_attr_pk}', `${this.selectedTemplate?.id}`)
                        .replace('{id}', `${deleteId}`),
                    method: 'DELETE',
                    useToken: true,
                });

                if (this.selectedTemplate) {
                    await this.getAssessmentTemplateByAsmntTmplPkAttrValPk({
                        asmnt_tmpl_pk: `${pageId}`,
                        asmnt_tmpl_attr_pk: `${this.selectedTemplate.id}`,
                    });
                }
            } finally {
                this.isLoadingPatch = false;
            }
        }
    }

    public async deleteTemplateAttribute({
                                             pageId,
                                             attributeId,
                                         }: {
        pageId: string | number;
        attributeId: string | number;
    }) {
        if (!this.isLoadingPatch) {
            try {
                this.isLoadingPatch = true;

                await createAxiosRequest<{}, {}>({
                    path: apiPath.templAttrByIdInModel.replace('{asmnt_tmpl_pk}', `${pageId}`).replace('{id}', `${attributeId}`),
                    method: 'DELETE',
                    useToken: true,
                });

                await this.getAssessmentTemplateAttrByTemplateId(pageId);
            } finally {
                this.isLoadingPatch = false;
            }
        }
    }

    public async addAssessmentTemplateAttribute({
                                                    pageId,
                                                    data,
                                                }: {
        pageId: string | number;
        data: Partial<AdministrationAssessmentTemplateAttribute>;
    }) {
        let newElement: AssessmentTemplateAttributeModel | undefined;
        if (!this.isLoadingPatch) {
            try {
                this.isLoadingPatch = true;
                const {data: newElementData} = await createAxiosRequest<{}, AdministrationAssessmentTemplateAttribute>({
                    path: apiPath.templAttrInModel.replace('{asmnt_tmpl_pk}', `${pageId}`),
                    method: 'POST',
                    data,
                    useToken: true,
                });
                newElement = new AssessmentTemplateAttributeModel(newElementData);

                await this.getAssessmentTemplateAttrByTemplateId(pageId);
            } finally {
                this.isLoadingPatch = false;

                return newElement;
            }
        }
    }
}
