import React from 'react';
import { observer } from 'mobx-react';

import { Box, useTheme } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';

import { ReactComponent as Empty } from '@/assets/empty.svg';
import { useRootStore } from '@/stores';
import { OFFSET } from '@/constants/sizes';
import { LabelLikeHintWithText } from '@/components/common/LabelLikeHintWithText';
import { dateFormatter } from '@/utils';
import { color } from '@/constants/colors';
import {
  ComponentForGridItemByType,
  ComponentsType,
} from '@/components/Contagents/ContactTabs/MainTabComponents/ComponentForGridItemByType';
import { CustomizedTooltips } from '@/components/atoms/CustomizeTooltip';
import { tooltipSegmentDescription, tooltipSegmentTitle } from '@/components/Contagents/ContactTabs/Main';
import { IApplicationsPageDetailsStoreStoreWithITableStore } from '@/stores/ApplicationsPageDetailsStore';
import { TableApplications } from '@/stores/ApplicationsStore';

const gridItems = (
  applicationsPageDetailsStore: IApplicationsPageDetailsStoreStoreWithITableStore<TableApplications>,
) => [
  {
    name: 'Название',
    value: applicationsPageDetailsStore.contragentApplicationById?.name || '',
  },
  {
    name: 'ИНН/КПП',
    value: `${applicationsPageDetailsStore.contragentApplicationById?.inn || '-'} / ${
      applicationsPageDetailsStore.contragentApplicationById?.kpp || '-'
    }`,
  },
  {
    name: 'Сегмент бизнеса',
    value: applicationsPageDetailsStore?.contragentApplicationById?.segment || '',
    icon: (
      <CustomizedTooltips title={tooltipSegmentTitle} description={tooltipSegmentDescription}>
        <HelpIcon sx={{ marginBottom: '5px', width: '16px' }} />
      </CustomizedTooltips>
    ),
  },
  {
    name: 'Отраслевая принадлежность',
    value: applicationsPageDetailsStore?.contragentApplicationById?.industry || '',
  },
  {
    name: 'Основной вид деятельности',
    value: applicationsPageDetailsStore?.contragentApplicationById?.complementary_activities,
    title: applicationsPageDetailsStore?.contragentApplicationById?.activities,
    component: {
      type: ComponentsType.MODAL,
    },
  },
  {
    name: 'Юридический адрес',
    value: applicationsPageDetailsStore?.contragentApplicationById?.address || '',
  },
  {
    name: 'Единоличный исполнительный орган',
    value: `${applicationsPageDetailsStore?.contragentApplicationById?.head?.fio || '-'} / ${
      applicationsPageDetailsStore?.contragentApplicationById?.head?.innfl || '-'
    }`,
  },
  {
    name: 'Группа компаний',
    value: applicationsPageDetailsStore?.contragentApplicationById?.company_group,
    component: {
      type: ComponentsType.COMPANY_GROUP_WITH_SEARCH,
    },
    contragentId: applicationsPageDetailsStore?.contragentApplicationById?.id,
  },
  {
    name: ['Сайт', 'Телефоны'],
    value: [
      applicationsPageDetailsStore?.contragentApplicationById?.site,
      applicationsPageDetailsStore?.contragentApplicationById?.phones,
    ],
    component: {
      type: [ComponentsType.LINK_WITH_EDIT, ComponentsType.TEXT_WITH_EDIT],
    },
    contragentId: applicationsPageDetailsStore?.contragentApplicationById?.id,
  },
  {
    name: 'Акционеры / Участники',
    value: applicationsPageDetailsStore?.contragentApplicationById?.owners,
    component: {
      type: ComponentsType.MODAL_OWNERS,
    },
  },
  {
    name: 'Дата регистрации',
    value: `${applicationsPageDetailsStore.contragentApplicationById?.registrationdate || ''}`,
  },
  {
    name: 'Описание бизнеса',
    value: applicationsPageDetailsStore?.contragentApplicationById,
    component: {
      type: ComponentsType.BUSINESS_DESCRIPTION,
    },
    contragentId: applicationsPageDetailsStore?.contragentApplicationById?.id,
    disabled: true,
  },
];

export const ApplicationClients = observer(() => {
  const { applicationsPageDetailsStore, isLoading } = useRootStore();
  const theme = useTheme();

  const { risk_level_display, updated } = applicationsPageDetailsStore.contragentApplicationById || {};

  return (
    <>
      {false && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Empty width="50em" height="25em" />
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'row',
          clear: 'none',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            flexDirection: 'column',
            minWidth: '160px',
            backgroundColor: color.light,
            margin: 0,
            marginRight: OFFSET.m,
            padding: OFFSET.sm,
            height: 'min-content',
          }}
        >
          <LabelLikeHintWithText title="Обновлен из контура" value={dateFormatter(updated)} mb={OFFSET.xs} />
          <LabelLikeHintWithText title="Мониторинг" value={risk_level_display || 'Нет сигналов'} mb={OFFSET.xs} />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            clear: 'none',
            flexGrow: '1',
          }}
        >
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: 'repeat(3, 1fr)',
              margin: '-24px 0 0 -24px;',
              [theme.breakpoints.down(900)]: {
                gridTemplateColumns: 'repeat(2, 1fr)',
              },
            }}
          >
            {gridItems(applicationsPageDetailsStore).map((item, index) => (
              <ComponentForGridItemByType item={item} index={index} key={index} />
            ))}
          </Box>
        </Box>
      </Box>
    </>
  );
});
