import React from 'react';

import { Box, IconButton } from '@mui/material';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

import { TableCellStyled } from '@/components/Templates/TableComponentStyled';

const Wrapper = ({ children, isHideTrWrapper }: React.PropsWithChildren & { isHideTrWrapper: boolean }) =>
  isHideTrWrapper ? <>{children}</> : <TableCellStyled align="right">{children}</TableCellStyled>;

interface EditableButtons {
  isEdit?: boolean;
  isError?: boolean;
  disabled?: boolean;
  handleSave?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  handleDelete?: (e: React.MouseEvent<HTMLLabelElement>) => void;
  handleClose?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  handleOpen?: () => void;
  isHideTrWrapper?: boolean;
}

export const EditButtons = ({
  isEdit,
  isError,
  disabled,
  handleSave,
  handleDelete,
  handleClose,
  handleOpen,
  isHideTrWrapper,
}: EditableButtons) => {
  return (
    <>
      {isEdit ? (
        <Wrapper isHideTrWrapper={Boolean(isHideTrWrapper)}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <IconButton color="success" aria-label="confirm" onClick={handleSave} disabled={isError}>
              <CheckOutlinedIcon />
            </IconButton>
            <IconButton color="error" aria-label="confirm" onClick={handleClose}>
              <HighlightOffOutlinedIcon />
            </IconButton>
          </Box>
        </Wrapper>
      ) : (
        <Wrapper isHideTrWrapper={Boolean(isHideTrWrapper)}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            {handleSave && (
              <IconButton aria-label="edit" onClick={handleOpen} disabled={disabled}>
                <ModeEditOutlinedIcon />
              </IconButton>
            )}
            {handleDelete && (
              <IconButton
                color="primary"
                aria-label="удалить"
                component="label"
                onClick={handleDelete}
                disabled={disabled}
              >
                <DeleteOutlinedIcon color={disabled ? undefined : 'error'} />
              </IconButton>
            )}
          </Box>
        </Wrapper>
      )}
    </>
  );
};
