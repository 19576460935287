import React, {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {observer} from 'mobx-react';
import {runInAction} from 'mobx';

import {Box, useTheme} from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';

import {useRootStore} from '@/stores';
import {ReactComponent as Empty} from '@/assets/empty.svg';
import type {IContragentsStoreWithITableStore, TableBodyContragents} from '@/stores/ContragentsStore';
import {
    ComponentForGridItemByType,
    ComponentsType,
} from '@/components/Contagents/ContactTabs/MainTabComponents/ComponentForGridItemByType';
import {CustomizedTooltips} from '@/components/atoms/CustomizeTooltip';

export const tooltipSegmentTitle = 'Выручка';
export const tooltipSegmentDescription = (
    <>
        {`от 0 до 1 млрд - малый,`}
        <br/>
        {`от 1 млрд до - 3 млрд-  средний,`}
        <br/>
        {`выше 3 млрд - крупный`}
    </>
);

const gridItems = (contragentsStore: IContragentsStoreWithITableStore<TableBodyContragents>) => [
    {
        name: 'Название',
        value: contragentsStore.selectedContragent?.name || '-',
    },
    {
        name: 'ИНН/КПП',
        value: `${contragentsStore.selectedContragent?.inn || '-'} / ${contragentsStore.selectedContragent?.kpp || '-'}`,
    },
    {
        name: 'Сегмент бизнеса',
        value: contragentsStore?.selectedContragent?.segment || '-',
        icon: (
            <CustomizedTooltips title={tooltipSegmentTitle} description={tooltipSegmentDescription}>
                <HelpIcon sx={{marginBottom: '5px', width: '16px'}}/>
            </CustomizedTooltips>
        ),
    },
    {
        name: 'Отраслевая принадлежность',
        value: contragentsStore?.selectedContragent?.industry || '-',
    },
    {
        name: 'Основной вид деятельности',
        value: contragentsStore?.selectedContragent?.complementary_activities,
        title: contragentsStore?.selectedContragent?.activities,
        component: {
            type: ComponentsType.MODAL,
        },
    },
    {
        name: 'Юридический адрес',
        value: contragentsStore?.selectedContragent?.address || '-',
    },
    {
        name: 'Единоличный исполнительный орган',
        value: `${contragentsStore?.selectedContragent?.head?.fio || '-'} / ${
            contragentsStore?.selectedContragent?.head?.innfl || '-'
        }`,
    },
    {
        name: 'Группа компаний',
        value: contragentsStore?.selectedContragent?.company_group,
        component: {
            type: ComponentsType.COMPANY_GROUP_WITH_SEARCH,
        },
    },
    {
        name: ['Сайт', 'Телефоны'],
        value: [contragentsStore?.selectedContragent?.site, contragentsStore?.selectedContragent?.phones],
        component: {
            type: [ComponentsType.LINK_WITH_EDIT, ComponentsType.TEXT_WITH_EDIT],
        },
    },
    {
        name: 'Акционеры / Участники',
        value: contragentsStore?.selectedContragent?.owners || '-',
        component: {
            type: ComponentsType.MODAL_OWNERS,
        },
    },
    {
        name: 'Дата регистрации',
        value: `${contragentsStore.selectedContragent?.registrationdate || '-'}`,
    },
    {
        name: 'Описание бизнеса',
        value: contragentsStore?.selectedContragent,
        component: {
            type: ComponentsType.BUSINESS_DESCRIPTION,
        },
    },
];

export const Main = observer(() => {
    const {
        contragentsStore,
        isLoading,
        contragentReportTableBalanceStore,
        contragentReportTablePerQuarter,
        contragentReportTableLTM,
        contragentReportBalancePeriodStore,
        contragentReportTableFinancialResults,
    } = useRootStore();
    const {id} = useParams<{ id: string }>();
    const theme = useTheme();

    useEffect(() => {
        const fetchData = async () => {
            if (id) {
                if (!contragentsStore.selectedContragent) {
                    await contragentsStore.getAccountById(id);
                }

                runInAction(() => {
                    contragentReportTableBalanceStore.selectedAccountId = id;
                });
                await contragentReportTableBalanceStore.getReportsTable();

                runInAction(() => {
                    contragentReportTableFinancialResults.selectedAccountId = id;
                });
                await contragentReportTableFinancialResults.getReportsTable();

                runInAction(() => {
                    contragentReportBalancePeriodStore.selectedAccountId = id;
                });
                await contragentReportBalancePeriodStore.getReportsBalancePeriod();

                runInAction(() => {
                    contragentReportTablePerQuarter.selectedAccountId = id;
                });
                await contragentReportTablePerQuarter.getReportsTable();

                runInAction(() => {
                    contragentReportTableLTM.selectedAccountId = id;
                });
                await contragentReportTableLTM.getReportsTable();
            }
        };

        fetchData();
    }, [id, contragentsStore, contragentReportTableBalanceStore, contragentReportTableFinancialResults, contragentReportTablePerQuarter, contragentReportBalancePeriodStore, contragentReportTableLTM]);

    return (
        <>
            {!contragentsStore.selectedContragent && !isLoading && (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <Empty width="50em" height="25em"/>
                </Box>
            )}
            {contragentsStore.selectedContragent && !isLoading && (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        clear: 'none',
                    }}
                >
                    <Box
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(3, 1fr)',
                            margin: '-24px 0 0 -24px;',
                            [theme.breakpoints.down(900)]: {
                                gridTemplateColumns: 'repeat(2, 1fr)',
                            },
                        }}
                    >
                        {gridItems(contragentsStore).map((item, index) => (
                            <ComponentForGridItemByType item={item} index={index} key={index}/>
                        ))}
                    </Box>
                </Box>
            )}
        </>
    );
});
