import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import Box from '@mui/material/Box';

import { OFFSET } from '@/constants/sizes';
import { useRootStore } from '@/stores';
import { EmployeesTable } from '@/components/Employees/EmployeesTable';
import type { ITableBodyEmployee } from '@/stores/EmployeesStore';
import { EmployeesModal } from '@/components/Employees/EmployeesModal';
import { EmployeesEditModal } from '@/components/Employees/EmployeesEditModal';
import type { TabBarItem } from '@/components/common/TabBarStyled';
import { TabBarStyled } from '@/components/common/TabBarStyled';
import { Breadrcrumbs } from '@/components/common/Breadrcrumbs';
import { BusinessUnit } from '@/components/Group/BusinessUnit/BusinessUnit';
import { Position } from '@/components/Group/Position/Position';
import { ROLE } from '@/constants';

const tabBarItems: TabBarItem[] = [
  {
    name: 'Бизнес-подразделения',
    component: () => <BusinessUnit />,
    permissions: [ROLE.GENERAL, ROLE.SMARTFACT, ROLE.BALTICLEASING, ROLE.PSBL],
  },
  {
    name: 'Должности',
    component: () => <Position />,
    permissions: [ROLE.GENERAL, ROLE.SMARTFACT, ROLE.BALTICLEASING, ROLE.PSBL],
  },
  {
    name: 'Сотрудники',
    component: () => <Employees />,
    permissions: [ROLE.GENERAL, ROLE.SMARTFACT, ROLE.BALTICLEASING, ROLE.PSBL],
  },
];

export const Group = observer(() => {
  const { userStore } = useRootStore();
  const [selectedTab, setSelectedTab] = useState(tabBarItems[0]);
  const onChangeTab = (currentTab: string) => {
    setSelectedTab(tabBarItems.find(({ name }) => name === currentTab) ?? tabBarItems[0]);
  };

  return (
    <Box mb={OFFSET.xxxl}>
      <Box
        sx={{
          display: 'flex',
          alignContent: 'center',
          alignItems: 'center',
          mt: '10px',
          mb: 0,
          justifyContent: 'space-between',
        }}
      >
        <Breadrcrumbs
          breadCrumbItems={[
            {
              name: selectedTab?.name ?? '-',
            },
          ]}
        />
      </Box>
      <Box
        sx={{
          mt: OFFSET.sm,
          mb: OFFSET.sm,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <TabBarStyled
          items={tabBarItems}
          selected={selectedTab}
          onChangeTab={onChangeTab}
          userRole={userStore.profileInfo?.view_set}
        />
      </Box>
      {selectedTab.component()}
    </Box>
  );
});

export const Employees = observer(() => {
  const [selectedEmployee, setSelectedEmployee] = useState<ITableBodyEmployee | undefined>(undefined);
  const [openEdit, setOpenEdit] = useState<boolean>(false);

  const toggleOpen = () => {
    if (openEdit) {
      setSelectedEmployee(undefined);
    }
    setOpenEdit(!openEdit);
  };

  useEffect(() => {
    if (selectedEmployee) {
      setOpenEdit(true);
    } else {
      setOpenEdit(false);
      setSelectedEmployee(undefined);
    }
  }, [selectedEmployee]);

  const { employeesStore, isLoading } = useRootStore();

  useEffect(() => {
    if (employeesStore.tableBody?.length <= 0) {
      employeesStore.getEmployees();
    }
  }, []);

  return (
    <Box mb={OFFSET.xxxl}>
      <EmployeesTable store={employeesStore} isLoading={isLoading} setSelectedEmployee={setSelectedEmployee} />
      <EmployeesModal />
      <EmployeesEditModal selectedEmployee={selectedEmployee} handleOpen={toggleOpen} open={openEdit} />
    </Box>
  );
});
