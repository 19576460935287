import React, { useRef, useState } from "react";
import { observer } from "mobx-react";

import { Box } from "@mui/material";
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

import { OFFSET } from "@/constants/sizes";
import { TextFieldOutlinedStyled } from "@/components/atoms/TextFieldStyled";
import { Badge } from "@/components/atoms/Bage/Badge";
import { AssessmentTemplateAttributeModel } from "@/stores/Models/AdministrationAssessmentTemplateAttributeModel";
import { LabelLikeHintWithText } from "@/components/common/LabelLikeHintWithText";
import { TableCellStyled } from "@/components/Templates/TableComponentStyled";

const Marks = observer(({ modelData, disabled }: { modelData: AssessmentTemplateAttributeModel, disabled: boolean }) => {
  const newMark = useRef('');
  const [isNewMarkName, setIsNewMarkName] = useState(false);
  const [localNewMarks, setLocalNewMarks] = useState<string[]>([]);
  const [localDeleteMarks, setLocalDeleteMarks] = useState<string[]>([]);

  const toggleMarkName = () => {
    if (disabled) {
      return;
    }
    setIsNewMarkName((isEdit) => !isEdit)
  };

  const toggleMarkNameWithSave = () => {
    if (disabled) {
      return;
    }

    if (isNewMarkName) {
      return addNewMark();
    }

    return toggleMarkName();
  };

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e?.target?.value) {
      newMark.current = e?.target?.value
    }
  }

  const addNewMark = (e?: any) => {
    e?.preventDefault();
    e?.stopPropagation();
    toggleMarkName();
    if (newMark.current) {
      const allTags = modelData?.tags?.split(';') ?? [];
      allTags.push(newMark.current)
      modelData.tags = allTags.join(';')
      setLocalNewMarks([...localNewMarks, newMark.current])
      newMark.current = ""
    }
  }

  const deleteMark = (name: string) => {
    if (disabled) {
      return;
    }
    const allTags = modelData?.tags?.split(';') ?? [];
    const removedMarkArray = allTags?.filter((item) => item !== name);
    setLocalDeleteMarks([...localDeleteMarks, name])
    modelData.tags = removedMarkArray?.length > 0 ? removedMarkArray.join(';') : null;
  }

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
      {modelData?.tags?.split(';').map((name, index) => (
        <Box sx={{ display: 'flex' }} key={`${name}_${index}`} mr={OFFSET.xxs} mb={OFFSET.xxs}>
          <Badge type={localNewMarks.includes(name) ? 'success' : 'New'}>{name}</Badge>
          <DeleteOutlinedIcon color="error" onClick={() => deleteMark(name)} />
        </ Box>
      ))}
      {localDeleteMarks?.map((name, index) => (
        <Box sx={{ display: 'flex' }} key={`${name}_${index}`} mr={OFFSET.xxs} mb={OFFSET.xxs}>
          <Badge type="error">{name}</Badge>
        </ Box>
      ))}
      <Box mr={OFFSET.xxs} onClick={toggleMarkNameWithSave} sx={{ cursor: 'pointer' }}><Badge type="New" text={isNewMarkName ? "✓" : "+"} /></Box>

      {isNewMarkName
        && (
          <form onSubmit={addNewMark}>
            <TextFieldOutlinedStyled
              disabled={disabled}
              autoFocus
              required
              name="addMark"
              autoComplete="off"
              size="small"
              onChange={handleChangeInput}
              error={false}
            />
            <button type="submit" style={{ display: 'none' }}>Submit</button>
          </form>
        )
      }
    </ Box>
  );
});

export const MarksToField = ({ modelData, disabled }: { modelData: AssessmentTemplateAttributeModel, disabled: boolean }) => (
  <TableCellStyled colSpan={2}>
    <LabelLikeHintWithText title='Метки'>
      <Marks modelData={modelData} disabled={disabled} />
    </ LabelLikeHintWithText>
  </ TableCellStyled>
);
