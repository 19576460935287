import TableCell, {TableCellProps} from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import styled from '@emotion/styled';
import {PaginationItem} from '@mui/material';

import {OFFSET} from '@/constants/sizes';
import {color} from '@/constants/colors';

export const TableCellStyled = styled(TableCell)<{ verticalAlign?: string; textAlign?: string }>(
    ({theme, verticalAlign}) => ({
        border: 0,
        color: color.inherit,
        verticalAlign: verticalAlign ?? 'baseline',
    }),
);

export const TableCellStyledMonitoring = styled(TableCell)<{
    identified?: string;
    isarchive?: string;
    verticalAlign?: string;
    textAlign?: string;
}>(({verticalAlign, identified, isarchive}) => ({
    border: 0,
    fontWeight: '500',
    padding: '8px',
    height: `47px`,
    color: isarchive === 'true' ? color.medium_gray : identified === 'false' ? color.black : color.red,
    verticalAlign: verticalAlign ?? 'baseline',
}));

export const TableCellStyledWithoutPadding = styled(TableCell)<{ verticalAlign?: string }>(
    ({theme, verticalAlign}) => ({
        border: 0,
        color: color.inherit,
        verticalAlign: verticalAlign ?? 'baseline',
        padding: 0,
        paddingTop: '7px',
    }),
);

export const TableRowStyled = styled(TableRow)(({theme}) => ({
    '&:hover>td': {
        backgroundColor: color.pale_green,
        color: color.royal_blue,
        textDecorationLine: 'underline',
        cursor: 'pointer',
    },
}));

export const TableRowStyledMonitoring = styled(TableRow)<{
    identified?: string;
}>(({identified}) => ({
    backgroundColor: identified === 'true' ? 'rgba(255, 24, 38, 0.07)' : '',

    '&:hover': {
        backgroundColor: color.pale_green,
    },
}));

export const TableRowStyledWithoutPadding = styled(TableRow)(({theme}) => ({
    padding: 0,
}));

export const PaginationItemStyled = styled(PaginationItem)(({theme}) => ({
    '&.Mui-selected': {
        backgroundColor: color.honeydew,
        border: `1px solid ${color.moderately_green}`,
    },
}));

export const TableCellSmallPadding = styled(TableCellStyled)<TableCellProps & { rightPadding?: string }>(
    ({theme, rightPadding}) => ({
        padding: rightPadding
            ? `${OFFSET.xxxs} ${OFFSET.xs} ${rightPadding} ${OFFSET.none}`
            : `${OFFSET.xxxs} ${OFFSET.xs}`,
        verticalAlign: 'middle',
    }),
);

export const TableCellSmallHeight = styled(TableCellStyled)(({theme}) => ({
    height: `37px`,
    verticalAlign: 'middle',
}));
