import {action, makeObservable, observable, reaction, runInAction} from 'mobx';
import {apiPath} from '@/constants/api';
import {createAxiosRequest} from '@/utils/request';
import {TableStoreType, TableStore} from '@/stores/TableStore';
import type {IRootStore} from '@/stores/type';
import type {IButtonGroupItem} from '@/constants/types';

export type AssessmentTemplateByAsmntTmplPkAttrPk = {
    id: number;
    sequence: number;
    boolean_value: boolean;
    category_value: string;
    interval_start_value: number;
    interval_end_value: number;
    score: number;
    boolean_score: boolean;
    asmnt_tmpl_attr: number;
};

export type AdministrationAssessmentTemplateAttribute = {
    id: number;
    sequence: number;
    name: string;
    description: string;
    value_type: string;
    data_obj_attr_name: string;
    weight: number;
    is_boolean_score: boolean;
    out_of_range_score: number;
    out_of_range_bool_score: boolean;
    tags: string | null;
    asmnt_tmpl: number;
};

export type IAdministrationAssessmentTemplate = {
    id: number;
    name: string;
    description?: string;
    status?: string;
    version: number;
    data_object_name?: string;
    created: string;
    updated: string;
    created_by: string;
    updated_by: string;
    context_name: string;
    context_object_name: string;
    is_suitable: string;
    bu?: number | null;
};

export type ITableBodyAdministrationAssessmentTemplate = {
    id: number;
    name: string;
    object?: string;
    priority: number;
    updateDate: string;
    updateName: string;
    version: number;
    contextName: string;
    contextObjectName: string;
    description?: string;
    status?: string;
    bu?: number | null;
};

export type IDataContext = {
    id: number;
    name: string;
    display_name: null;
    db_object_column: string;
    calc_expression: string;
    join: string;
    description: string;
    data_type: string;
    data_object: number;
};

export type IDataContextDropdown = {
    id: number;
    name: string;
    description: string;
    primary_data_context_object: string;
};

export type IDataContextObjectDropdown = {
    id: number;
    name: string;
    data_object_name: string;
    parent_data_ctx_obj_name: string;
    data_object_parent_id_attr: number;
    data_context: number;
};

export interface IAdministrationAssessmentTemplateStore {
    rootStore: IRootStore;
    getAdministrationAssessmentTemplate: () => void;
    getAdministrationAssessmentTemplateById: (id: string | number) => void;
    deleteAdministrationAssessmentTemplateById: (id: string | number) => void;
    getAdministrationAssessmentTemplateArgumentsById: (id: string | number) => void;
    getAssessmentTemplateByAsmntTmplPkAttrPk: ({
                                                   asmnt_tmpl_pk,
                                                   asmnt_tmpl_attr_pk,
                                                   isSkipLoading,
                                               }: {
        asmnt_tmpl_pk: string | number;
        asmnt_tmpl_attr_pk: string | number;
        isSkipLoading?: boolean;
    }) => void;
    createAdministrationAssessmentTemplate: (template: Partial<IAdministrationAssessmentTemplate>) => void;
    updateAssessmentTemplateAllValues: ({
                                            mainId,
                                            selectedAttributeId,
                                            asmnt_tmpl_attr,
                                            asmnt_tmpl_attr_val,
                                            mark_as_delete_ids,
                                        }: {
        asmnt_tmpl_attr: any;
        asmnt_tmpl_attr_val: any[];
        selectedAttributeId: string | number;
        mainId: string | number;
        mark_as_delete_ids: number[];
    }) => void;
    deleteTemplateAttrVal: ({pageId, deleteId}: { pageId: string | number; deleteId: string | number }) => void;
    addAssessmentTemplateAttribute: ({
                                         pageId,
                                         data,
                                     }: {
        pageId: string | number;
        data: Partial<AdministrationAssessmentTemplateAttribute>;
    }) => void;
    deleteAssessmentTemplateAttribute: ({
                                            pageId,
                                            attributeId,
                                        }: {
        pageId: string | number;
        attributeId: string | number;
    }) => void;
    getDataContextBy: ({
                           context_name,
                           context_object_name,
                       }: {
        context_name: string;
        context_object_name: string;
    }) => void;
    updateModel: ({
                      modelId,
                      dataModel,
                      isGetAllModel,
                  }: {
        modelId: string | number;
        dataModel: Partial<IAdministrationAssessmentTemplate>;
        isGetAllModel?: boolean;
    }) => void;

    getDataContextDropdown: () => void;
    getDataContextObjectDropdown: () => void;
    getDropdownContextValues: () => void;
    duplicateAdministrationAssessmentTemplate: ({
                                                    template,
                                                }: {
        template: ITableBodyAdministrationAssessmentTemplate;
    }) => void;
    deleteTemplate: (modelId: string | number) => void;

    tableBody?: ITableBodyAdministrationAssessmentTemplate[];
    isLoading: boolean;
    selectedAdministrationAssessmentTemplateById: Partial<IAdministrationAssessmentTemplate> | Record<string, string>;
    selectedAdministrationAssessmentTemplateArguments: AdministrationAssessmentTemplateAttribute[];
    assessmentTemplateByAsmntTmplPkAttrPk: AssessmentTemplateByAsmntTmplPkAttrPk[];
    dataContext: IDataContext[];

    dataContextDropdown: IDataContextDropdown[];
    dataContextObjectDropdown: IDataContextObjectDropdown[];

    updateModelVersion: ({modelId, status}: { modelId: string | number; status: string }) => any;
    reviseModelVersion: ({modelId}: { modelId: string | number }) => any;

    selectedFilter?: IButtonGroupItem<string>;
}

export type IAdministrationAssessmentTemplateStoreWithITableStore<T> = IAdministrationAssessmentTemplateStore &
    TableStoreType<T>;

export type IGetAdministrationAssessmentTemplate = IGetWithPagination<IAdministrationAssessmentTemplate>;

export class AdministrationAssessmentTemplateStore
    extends TableStore<ITableBodyAdministrationAssessmentTemplate>
    implements IAdministrationAssessmentTemplateStoreWithITableStore<ITableBodyAdministrationAssessmentTemplate> {
    @observable
    public rootStore: IRootStore;

    @observable
    public isLoading: boolean = false;

    @observable
    public selectedAdministrationAssessmentTemplateById:
        | Partial<IAdministrationAssessmentTemplate>
        | Record<string, any> = {};

    @observable
    public selectedAdministrationAssessmentTemplateArguments: AdministrationAssessmentTemplateAttribute[] = [];

    @observable
    public assessmentTemplateByAsmntTmplPkAttrPk: AssessmentTemplateByAsmntTmplPkAttrPk[] = [];

    @observable
    public dataContext: IDataContext[] = [];

    @observable
    dataContextDropdown: IDataContextDropdown[] = [];

    @observable
    dataContextObjectDropdown: IDataContextObjectDropdown[] = [];

    @observable
    public selectedFilter?: IButtonGroupItem<string>;

    constructor(rootStore: IRootStore) {
        super();
        makeObservable(this);
        this.rootStore = rootStore;

        reaction(
            () => this.selectedFilter?.type,
            (value, previousValue) => {
                this.handleChangePage(null, 1);
                if (previousValue !== value) {
                    this.getAdministrationAssessmentTemplate();
                }
            },
        );

        reaction(
            () => this.selectedPage,
            (value) => {
                if (this.maxSelectedPage <= value) {
                    this.getAdministrationAssessmentTemplate();
                }
            },
        );

        reaction(
            () => this.searchString,
            (value, previousValue) => {
                if (previousValue !== value) {
                    this.selectedPage = 0;
                    this.getAdministrationAssessmentTemplate();
                }
            },
        );
    }

    public getAdministrationAssessmentTemplateArgumentsById(id: string | number) {
        this.rootStore.requestTemplate({
            errorMessage: `Ошибка получения данных аргументов оценки`,
            callback: async () => {
                try {
                    this.isLoading = true;

                    const {data} = await createAxiosRequest<{}, IGetWithPagination<AdministrationAssessmentTemplateAttribute>>({
                        path: apiPath.assessmentTemplateByAsmntTmplPk.replace('{asmnt_tmpl_pk}', `${id}`),
                        method: 'GET',
                        useToken: true,
                        params: {limit: 200, offset: 0},
                    });
                    runInAction(() => {
                        this.selectedAdministrationAssessmentTemplateArguments = data.results?.sort((a, b) =>
                            a.sequence > b.sequence ? 1 : -1,
                        );
                    })
                } finally {
                    this.isLoading = false;
                }
            },
        });
    }

    public getAdministrationAssessmentTemplate() {
        if (!this.isLoading) {
            const isSearch = this.searchString || this.searchString?.trim();
            this.rootStore.requestTemplate({
                errorMessage: `Ошибка получения данных модели оценки`,
                callback: async () => {
                    try {
                        this.isLoading = true;
                        const status =
                            this.selectedFilter?.type === 'ALL' || !this.selectedFilter?.type ? undefined : this.selectedFilter?.type;
                        const searchString = isSearch ? this.searchString?.trim() : undefined;
                        const limit = isSearch ? 10 : this.rowsPerPageChange;
                        const offset = this.rowsPerPageChange * this.selectedPage;
                        const {data} = await createAxiosRequest<
                            { offset: number; limit: number; status?: string; ordering: string; search?: string },
                            IGetAdministrationAssessmentTemplate
                        >({
                            path: apiPath.assessmentTemplate,
                            method: 'GET',
                            params: {offset, limit, status, ordering: '-updated', search: searchString},
                            useToken: true,
                        });
                        runInAction(() => {
                            this.limit = limit;
                            this.summary = data.count;
                            this.offset = offset;
                            const tableData = mapAdministrationDataToTableBody(data.results);
                            this.tableBody = [...tableData];
                        })
                    } finally {
                        runInAction(() => {
                            this.isLoading = false;
                        })
                    }
                },
            });
        }
    }

    public duplicateAdministrationAssessmentTemplate({
                                                         template,
                                                     }: {
        template: ITableBodyAdministrationAssessmentTemplate;
    }) {
        if (!this.isLoading) {
            this.rootStore.requestTemplate({
                errorMessage: `Ошибка дублирования данных модели оценки`,
                finallyAction: () => this.getAdministrationAssessmentTemplate(),
                callback: async () => {
                    try {
                        this.isLoading = true;

                        const {id, name, description} = template;

                        const templateData = {
                            name,
                            description,
                            data_object_name: template.object,
                            version: template.version,
                            context_name: template.contextName,
                            context_object_name: template.contextObjectName,
                            status: 'Active',
                        };

                        await createAxiosRequest({
                            path: apiPath.duplicateAssessmentTemplateById.replace('{id}', `${id}`),
                            method: 'POST',
                            data: {...templateData},
                            useToken: true,
                        });
                    } finally {
                        this.isLoading = false;
                    }
                },
            });
        }
    }

    public getAdministrationAssessmentTemplateById(id: string | number) {
        this.rootStore.requestTemplate({
            errorMessage: `Ошибка получения данных модели оценки`,
            immediately: true,
            callback: async () => {
                try {
                    this.isLoading = true;

                    const {data} = await createAxiosRequest<{}, IAdministrationAssessmentTemplate>({
                        path: apiPath.assessmentTemplateById.replace('{id}', `${id}`),
                        method: 'GET',
                        useToken: true,
                    });
                    runInAction(() => {
                        this.selectedAdministrationAssessmentTemplateById = data;
                    })
                } finally {
                    this.isLoading = false;
                }
            },
        });
    }

    public addAssessmentTemplateAttribute({
                                              pageId,
                                              data,
                                          }: {
        pageId: string | number;
        data: Partial<AdministrationAssessmentTemplateAttribute>;
    }) {
        if (!this.isLoading) {
            this.rootStore.requestTemplate({
                finallyAction: () => this.getAdministrationAssessmentTemplateArgumentsById(pageId),
                errorMessage: `Ошибка создания данных атрибута`,
                callback: async () => {
                    try {
                        this.isLoading = true;

                        await createAxiosRequest<{}, {}>({
                            path: apiPath.templAttr,
                            method: 'POST',
                            data,
                            useToken: true,
                        });
                    } finally {
                        this.isLoading = false;
                    }
                },
            });
        }
    }

    public deleteAssessmentTemplateAttribute({
                                                 pageId,
                                                 attributeId,
                                             }: {
        pageId: string | number;
        attributeId: string | number;
    }) {
        if (!this.isLoading) {
            this.rootStore.requestTemplate({
                finallyAction: () => this.getAdministrationAssessmentTemplateArgumentsById(pageId),
                errorMessage: `Ошибка создания данных атрибута`,
                callback: async () => {
                    try {
                        this.isLoading = true;

                        await createAxiosRequest<{}, {}>({
                            path: apiPath.templAttrById.replace('{id}', `${attributeId}`),
                            method: 'DELETE',
                            useToken: true,
                        });
                    } finally {
                        this.isLoading = false;
                    }
                },
            });
        }
    }

    public getAssessmentTemplateByAsmntTmplPkAttrPk = ({
                                                           asmnt_tmpl_pk,
                                                           asmnt_tmpl_attr_pk,
                                                           isSkipLoading,
                                                       }: {
        asmnt_tmpl_pk: string | number;
        asmnt_tmpl_attr_pk: string | number;
        isSkipLoading?: boolean;
    }) => {
        if (!this.isLoading || isSkipLoading) {
            this.rootStore.requestTemplate({
                immediately: true,
                errorMessage: `Ошибка получения данных модели оценки`,
                callback: async () => {
                    try {
                        this.isLoading = true;

                        const {data} = await createAxiosRequest<{}, IGetWithPagination<AssessmentTemplateByAsmntTmplPkAttrPk>>({
                            path: apiPath.assessmentTemplateByAsmntTmplPkAttrPk
                                .replace('{asmnt_tmpl_pk}', `${asmnt_tmpl_pk}`)
                                .replace('{asmnt_tmpl_attr_pk}', `${asmnt_tmpl_attr_pk}`),
                            method: 'GET',
                            params: {offset: 0, limit: 20},
                            useToken: true,
                        });
                        runInAction(() => {
                            this.assessmentTemplateByAsmntTmplPkAttrPk = data.results.sort((a, b) =>
                                a?.sequence > b.sequence ? 1 : -1,
                            );
                        })
                    } finally {
                        this.isLoading = false;
                    }
                },
            });
        }
    };

    public deleteAdministrationAssessmentTemplateById(id: string | number) {
        if (!this.isLoading) {
            this.rootStore.requestTemplate({
                errorMessage: `Ошибка удаления данных модели оценки`,
                callback: async () => {
                    try {
                        this.isLoading = true;

                        await createAxiosRequest<{}, IAdministrationAssessmentTemplate>({
                            path: apiPath.assessmentTemplateById.replace('{id}', `${id}`),
                            method: 'DELETE',
                            useToken: true,
                        });
                    } finally {
                        this.isLoading = false;
                    }
                },
            });
        }
    }

    public createAdministrationAssessmentTemplate(template: Partial<IAdministrationAssessmentTemplate>) {
        if (!this.isLoading) {
            this.rootStore.requestTemplate({
                finallyAction: () => this.getAdministrationAssessmentTemplate(),
                errorMessage: `Ошибка создания данных модели оценки`,
                callback: async () => {
                    try {
                        this.isLoading = true;

                        await createAxiosRequest<{}, IAdministrationAssessmentTemplate>({
                            path: apiPath.assessmentTemplate,
                            method: 'POST',
                            data: {...template},
                            useToken: true,
                        });
                    } finally {
                        this.isLoading = false;
                    }
                },
            });
        }
    }

    public deleteTemplateAttrVal({pageId, deleteId}: { pageId: string | number; deleteId: string | number }) {
        if (!this.isLoading) {
            this.rootStore.requestTemplate({
                finallyAction: () => this.getAdministrationAssessmentTemplateArgumentsById(pageId),
                errorMessage: `Ошибка удаления данных`,
                callback: async () => {
                    try {
                        this.isLoading = true;

                        await createAxiosRequest<{}, any>({
                            path: apiPath.asmntTmplAttrValById.replace('{id}', `${deleteId}`),
                            method: 'DELETE',
                            useToken: true,
                        });
                    } finally {
                        this.isLoading = false;
                    }
                },
            });
        }
    }

    public updateAssessmentTemplateAllValues = ({
                                                    mainId,
                                                    selectedAttributeId,
                                                    asmnt_tmpl_attr,
                                                    asmnt_tmpl_attr_val,
                                                    mark_as_delete_ids,
                                                }: {
        asmnt_tmpl_attr: any;
        asmnt_tmpl_attr_val: any[];
        selectedAttributeId: string | number;
        mainId: string | number;
        mark_as_delete_ids: number[];
    }) => {
        if (!this.isLoading) {
            this.rootStore.requestTemplate({
                finallyAction: () => {
                    this.getAdministrationAssessmentTemplateArgumentsById(mainId);
                },
                errorMessage: `Ошибка обновления атрибута или параметра`,
                callback: async () => {
                    try {
                        this.isLoading = true;
                        const requests = [];

                        mark_as_delete_ids?.forEach((id) => {
                            requests.push(
                                createAxiosRequest<any, any>({
                                    path: apiPath.asmntTmplAttrValById.replace('{id}', `${id}`),
                                    method: 'DELETE',
                                    useToken: true,
                                }),
                            );
                        });

                        asmnt_tmpl_attr_val.forEach((element) => {
                            const createOrEditAttrPath = element?.id
                                ? '/asmnt_tmpl_attr_val/{id}/'.replace('{id}', element?.id)
                                : '/asmnt_tmpl_attr_val/';
                            const method = element?.id ? 'PATCH' : 'POST';

                            requests.push(
                                createAxiosRequest<{}, any>({
                                    path: createOrEditAttrPath,
                                    method,
                                    data: {...element},
                                    useToken: true,
                                }),
                            );
                        });

                        requests.push(
                            requests.push(
                                createAxiosRequest<{}, any>({
                                    path: apiPath.templAttrById?.replace('{id}', `${selectedAttributeId}`),
                                    method: 'PATCH',
                                    data: {...asmnt_tmpl_attr},
                                    useToken: true,
                                }),
                            ),
                        );

                        await Promise.all(requests);
                    } finally {
                        this.isLoading = false;
                    }
                },
            });
        }
    };

    getDataContextBy = ({context_name, context_object_name}: { context_name: string; context_object_name: string }) => {
        if (!this.isLoading) {
            this.rootStore.requestTemplate({
                errorMessage: `Ошибка удаления данных`,
                callback: async () => {
                    try {
                        this.isLoading = true;

                        const {data} = await createAxiosRequest<{}, IDataContext[]>({
                            path: apiPath.dataContext,
                            method: 'POST',
                            data: {context_name, context_object_name},
                            useToken: true,
                        });
                        runInAction(() => {
                            this.dataContext = data.sort((a, b) => (a.name > b.name ? 1 : -1));
                        })
                    } finally {
                        this.isLoading = false;
                    }
                },
            });
        }
    };

    getDataContextDropdown = () => {
        this.rootStore.requestTemplate({
            errorMessage: `Ошибка данных`,
            immediately: true,
            callback: async () => {
                const {data} = await createAxiosRequest<any, IGetWithPagination<IDataContextDropdown>>({
                    path: apiPath.dataContextDropdown,
                    method: 'GET',
                    data: {limit: 100, offset: 0},
                    useToken: true,
                });
                runInAction(() => {
                    this.dataContextDropdown = data.results;
                })
            },
        });
    };

    getDataContextObjectDropdown = () => {
        this.rootStore.requestTemplate({
            errorMessage: `Ошибка данных`,
            immediately: true,
            callback: async () => {
                const {data} = await createAxiosRequest<any, IGetWithPagination<IDataContextObjectDropdown>>({
                    path: apiPath.dataContextObjectDropdown,
                    method: 'GET',
                    data: {limit: 100, offset: 0},
                    useToken: true,
                });
                runInAction(() => {
                    this.dataContextObjectDropdown = data.results;
                })
            },
        });
    };

    getDropdownContextValues = () => {
        if (!this.isLoading) {
            this.rootStore.requestTemplate({
                errorMessage: `Ошибка данных`,
                callback: async () => {
                    try {
                        this.isLoading = true;

                        this.getDataContextDropdown();
                        this.getDataContextObjectDropdown();
                    } finally {
                        this.isLoading = false;
                    }
                },
            });
        }
    };

    updateModel = ({
                       modelId,
                       dataModel,
                       isGetAllModel,
                   }: {
        modelId: string | number;
        dataModel: Partial<IAdministrationAssessmentTemplate>;
        isGetAllModel?: boolean;
    }) => {
        if (!this.isLoading) {
            this.rootStore.requestTemplate({
                finallyAction: () => {
                    if (isGetAllModel) {
                        this.getAdministrationAssessmentTemplate();
                    } else {
                        this.getAdministrationAssessmentTemplateById(modelId);
                    }
                },
                errorMessage: `Ошибка данных`,
                callback: async () => {
                    try {
                        this.isLoading = true;

                        await createAxiosRequest<any, any>({
                            path: apiPath.assessmentTemplateById.replace('{id}', `${modelId}`),
                            method: 'PUT',
                            data: {...dataModel},
                            useToken: true,
                        });
                    } finally {
                        this.isLoading = false;
                    }
                },
            });
        }
    };

    reviseModelVersion = ({modelId}: { modelId: string | number }) => {
        if (!this.isLoading) {
            return this.rootStore.requestTemplate({
                isReturned: true,
                finallyAction: () => {
                    this.getAdministrationAssessmentTemplate();
                },
                errorMessage: `Ошибка создания черновика`,
                callback: async () => {
                    try {
                        this.isLoading = true;

                        const {data} = await createAxiosRequest<any, any>({
                            path: apiPath.reviseAssessmentTemplateById.replace('{id}', `${modelId}`),
                            method: 'POST',
                            useToken: true,
                        });

                        return data;
                    } finally {
                        this.isLoading = false;
                    }
                },
            });
        }
    };

    updateModelVersion = ({modelId, status}: { modelId: string | number; status: string }) => {
        if (!this.isLoading) {
            return this.rootStore.requestTemplate({
                isReturned: true,
                finallyAction: () => {
                    this.getAdministrationAssessmentTemplateById(modelId);
                },
                errorMessage: `Ошибка обновления версии`,
                callback: async () => {
                    try {
                        this.isLoading = true;

                        const {data} = await createAxiosRequest<any, any>({
                            path: apiPath.assessmentTemplateById.replace('{id}', `${modelId}`),
                            method: 'PATCH',
                            data: {status},
                            useToken: true,
                        });

                        return data;
                    } finally {
                        this.isLoading = false;
                    }
                },
            });
        }
    };

    @action
    deleteTemplate = async (modelId: string | number) => {
        this.rootStore.requestTemplate({
            errorMessage: 'Ошибка удаления модели',
            finallyAction: () => this.getAdministrationAssessmentTemplate(),
            callback: async () => {
                await createAxiosRequest({
                    path: apiPath.assessmentTemplateById.replace('{id}', `${modelId}`),
                    method: 'DELETE',
                    useToken: true,
                });
            },
        });
    };
}

const mapAdministrationDataToTableBody = (
    data: IAdministrationAssessmentTemplate[],
): ITableBodyAdministrationAssessmentTemplate[] =>
    data.map((item, index) => ({
        id: item.id,
        name: item.name,
        object: item.data_object_name,
        priority: index,
        updateDate: item.updated ?? item.created,
        updateName: item.updated_by ?? item.created_by,
        version: item.version,
        contextName: item.context_name,
        contextObjectName: item.context_object_name,
        description: item?.description,
        status: item?.status,
        bu: item?.bu,
    }));
