import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';

import { Box } from '@mui/material';

import { TableTemplateWithoutStore } from '@/components/Templates/TableTemplateWithoutStore';
import { BoxOutlined, Play } from '@/assets/index';
import { TableRowStyled, TableCellStyled } from '@/components/Templates/TableComponentStyled';
import { StatusBadge } from '@/components/atoms/Bage/StatusBadge';
import { useRootStore } from '@/stores';
import { ISetting } from '@/stores/ScoringPageStore';
import { dateFormatterDefault } from '@/utils';
import { SETTINGS_STATUS, SETTINGS_STATUS_TO_STATUS, STATUS_TO_STATUS } from '@/constants/backendEnum';
import { color } from '@/constants/colors';

export type ITableBodyScoringPage = {
  id: string | number;
  name: string;
  version: string | number;
  status: string;
  period: string | null;
  operation?: string | number | null;
};

const tableHeader = ['Название', 'Версия', 'Статус', 'Период действия', 'Операция'];

const RenderBodyAnalysisTable = ({
  row,
  filled,
  isLast,
}: {
  row: ITableBodyScoringPage;
  filled: boolean;
  isLast?: boolean;
}) => {
  const navigate = useNavigate();
  const { scoringPageStore } = useRootStore();

  const onCLick = () => {
    scoringPageStore.settingsRelation = [];
    scoringPageStore.settingsClient = [];
    scoringPageStore.settingsDebtor = [];
    navigate && navigate(`${row.id}`);
  };

  const Icon = () => {
    if (row.status === SETTINGS_STATUS_TO_STATUS.Draft) {
      return <Play />;
    }

    if (row.status === SETTINGS_STATUS_TO_STATUS.Active) {
      return <BoxOutlined stroke={color.light_black} />;
    }

    return <></>;
  };

  const type = () => {
    if (row.status === SETTINGS_STATUS_TO_STATUS.Draft) {
      return STATUS_TO_STATUS.New;
    }

    if (row.status === SETTINGS_STATUS_TO_STATUS.Active) {
      return STATUS_TO_STATUS.Active;
    }

    return STATUS_TO_STATUS.Inactive;
  };

  return (
    <TableRowStyled key={row.id} sx={{ backgroundColor: filled ? color.light : color.transparent }} onClick={onCLick}>
      <TableCellStyled>{row.name}</TableCellStyled>
      <TableCellStyled>{row.version}</TableCellStyled>
      <TableCellStyled>
        <StatusBadge type={type() as any} text={SETTINGS_STATUS[row.status as keyof typeof SETTINGS_STATUS]} children={undefined} />
      </TableCellStyled>
      <TableCellStyled>{row.period}</TableCellStyled>
      <TableCellStyled align="right">
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}
        >
          <Icon />
        </Box>
      </TableCellStyled>
    </TableRowStyled>
  );
};

export const mapToLikeStore = (data: ISetting[]) => (
  data.map((item) => ({
    id: item.id,
    name: item.name,
    version: item.version,
    status: item.status,
    period: item.effective_start_date && item.effective_end_date
      ? `${dateFormatterDefault(item.effective_start_date)} - ${dateFormatterDefault(item.effective_end_date)}`
      : dateFormatterDefault(item.effective_start_date) ?? '',
    operation: null,
  }))

);

export const ScoringPageTable = observer(() => {
  const { scoringPageStore, isLoading } = useRootStore();

  useEffect(() => {
    if (
      (!scoringPageStore.settings || scoringPageStore.settings?.length === 0) &&
      !scoringPageStore.settingsResponseIsEmpty
    ) {
      scoringPageStore.getSettings();
    }
  })

  const likeStore = scoringPageStore.settings && mapToLikeStore(scoringPageStore.settings)

  return (
    <TableTemplateWithoutStore<ITableBodyScoringPage>
      tableHeader={tableHeader}
      RenderComponent={(props) => <RenderBodyAnalysisTable {...props} />}
      store={{
        renderedRows: likeStore ?? [],
      }}
      isLoading={isLoading}
    />
  );
});
