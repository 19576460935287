import React from 'react';

import { Box, Typography } from '@mui/material';

import { OFFSET } from '@/constants/sizes';
import { LabelLikeHintWithText } from '@/components/common/LabelLikeHintWithText';
import { StyledBox } from '@/components/Administering/Assessment/components/common';
import type { CardsItem } from '@/components/Administering/Assessment/components/types';
import { color } from '@/constants/colors';

export type CardsAssessmentProps = {
  cards?: CardsItem[];
  cardsSelected: CardsItem[];
  setCardsSelected: (selected: CardsItem[]) => void;
};

type CardItemProps = {
  card: CardsItem;
  isSelected: boolean;
  addCards: (title: CardsItem) => void;
};

const CardItem = ({ card, isSelected, addCards }: CardItemProps) => {
  const onClick = () => addCards(card);

  return (
    <StyledBox
      onClick={onClick}
      mb={OFFSET.xxs}
      sx={{
        minHeight: '33px',
        borderLeft: isSelected ? '5px' : '1px',
        borderTop: '1px',
        borderBottom: '1px',
        borderRight: '1px',
        borderColor: isSelected ? color.aquamarine : color.gainsborough,
        borderStyle: 'solid',
        padding: '15px 12px',
        borderRadius: '5px',
      }}
    >
      <Box sx={{ flex: 3 }}>
        <LabelLikeHintWithText reverse title={card.context_object_name} value={card.assessment_template_name} />
      </Box>
      <Box sx={{ flex: 1 }} mt={OFFSET.xxxs}>
        <Typography
          component="h2"
          variant="h6"
          color={color.primary}
          gutterBottom
          sx={{
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '12px',
            lineHeight: '14px',
            color: color.slate_gray,
          }}
        >
          {Object.values(card.context_object_data).join(' ')}
        </Typography>
      </Box>
    </StyledBox>
  );
};

export const CardsAssessment = ({ cards, cardsSelected, setCardsSelected }: CardsAssessmentProps) => {
  if (!cards) {
    return null;
  }

  const addCards = (newFilter: CardsItem) => {
    if (cardsSelected.includes(newFilter)) {
      return setCardsSelected(cardsSelected.filter((item) => item !== newFilter));
    }

    return setCardsSelected([...cardsSelected, newFilter]);
  };

  return (
    <>
      {cards.map((card) => (
        <CardItem
          key={`${card.context_object_id}_${card.assessment_template_name}_${card.context_object_name}`}
          card={card}
          isSelected={cardsSelected.includes(card)}
          addCards={addCards}
        />
      ))}
    </>
  );
};
