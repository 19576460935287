import Button from '@mui/material/Button';
import styled from '@emotion/styled';
import { ButtonType } from '@/components/atoms/Button/constants';
import { color } from '@/constants/colors';

type ButtonProps = {
  props: {
    customType: keyof typeof ButtonType;
    height?: string;
  };
};

const ButtonColorsMapOutlined = {
  [ButtonType.error]: {
    default: color.transparent,
    hover: color.pale_pink,
    pressed: color.pale_pink,
    disabled: color.transparent,
    color: color.light_pink,
    borderColor: color.light_pink,
    disabledTextColor: color.periwinkle,
  },
  [ButtonType.success]: {
    default: color.honeydew,
    hover: color.honeydew,
    pressed: color.light,
    disabled: color.transparent,
    color: color.light_green,
    borderColor: color.moderately_green,
    borderColorHover: color.transparent,
    borderActive: color.light,
    disabledTextColor: color.periwinkle,
  },
};

//@ts-ignore
export const ButtonColorStyledOutlined = styled(Button, {
  shouldForwardProp: (props) => props,
})<ButtonProps>(({ theme, props }) => ({
  textTransform: 'uppercase',
  height: '36px',
  minWidth: 'auto',
  backgroundColor: ButtonColorsMapOutlined[props.customType].hover,
  background: ButtonColorsMapOutlined[props.customType].default,
  color: ButtonColorsMapOutlined[props.customType].color,
  borderRadius: '4px',
  borderColor: ButtonColorsMapOutlined[props.customType].borderColor,
  padding: '8px 12px',
  '&:hover': {
    borderColor:
      ButtonColorsMapOutlined[props.customType]?.borderColorHover ??
      ButtonColorsMapOutlined[props.customType].borderColor,
    background: `${ButtonColorsMapOutlined[props.customType].hover}`,
    color: ButtonColorsMapOutlined[props.customType].color,
  },
  '&:active': {
    borderColor:
      ButtonColorsMapOutlined[props.customType].borderActive ?? ButtonColorsMapOutlined[props.customType].borderColor,
    background: `${ButtonColorsMapOutlined[props.customType].pressed}`,
    color: ButtonColorsMapOutlined[props.customType].color,
  },
  '&:disabled': {
    background: `${ButtonColorsMapOutlined[props.customType].disabled}`,
    color: ButtonColorsMapOutlined[props.customType].disabledTextColor,
  },
}));
