import React from 'react';

import ContractReportTableBalanceKontur from '@/components/Contagents/ContactTabs/ContractReportTableBalanceKontur';
import ContractReportTableFinancialResultsKontur from '@/components/Contagents/ContactTabs/ContractReportTableFinancialResultsKontur';

const ContractReportTableKonturReporting: React.FC = () => {
  return (
    <>
      <ContractReportTableBalanceKontur />
      <ContractReportTableFinancialResultsKontur />
    </>
  );
};

export default ContractReportTableKonturReporting;
