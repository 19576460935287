import {observable, makeObservable, action, reaction, runInAction} from 'mobx';
import {apiPath} from '@/constants/api';
import {createAxiosRequest} from '@/utils/request';
import type {TableStoreType} from '@/stores/TableStore';
import {TableStore} from '@/stores/TableStore';
import type {IRootStore} from '@/stores/type';
import type {IButtonGroupItem} from '@/constants/types';
import {FILTER_TYPE} from '@/constants';

export type IProduct = {
    id: number;
    name: string;
    status: string;
    description?: string;
    bu?: string;
    staff_fio?: string;
    created_by?: {
        first_name?: string;
        last_name?: string;
    };
};

type ICreateProduct = Omit<IProduct, 'id'>;

export type IGetProducts = {
    count: number;
    next?: string;
    previous?: string;
    results: IProduct[];
};

export type ITableBodyProduct = {
    id: string | number;
    name: string;
    status: string | null;
    description: string | null;
    staff_fio: string | null;
    bu?: string | null;
    author?: string;
};

export type IProductsStore = {
    getProducts: (immediately?: boolean) => Promise<void>;
    createProduct: ({productInfo}: { productInfo: ICreateProduct }) => void;
    updateProduct: ({productId, productInfo}: { productId: string | number; productInfo: ICreateProduct }) => void;
    deleteProduct: ({productId}: { productId: string | number }) => void;
    selectedFilter?: IButtonGroupItem<string>;
};

export type ProductsStoreStoreWithTableStore<T> = IProductsStore & TableStoreType<T>;

export class ProductsStore
    extends TableStore<ITableBodyProduct>
    implements ProductsStoreStoreWithTableStore<ITableBodyProduct> {
    @observable
    public rootStore: IRootStore;

    constructor(rootStore: IRootStore) {
        super();
        makeObservable(this);
        this.rootStore = rootStore;

        reaction(
            () => this.selectedPage,
            (value, _previousValue) => {
                if (this.maxSelectedPage <= value) {
                    this.getProducts();
                }
            },
        );

        reaction(
            () => this.selectedFilter?.type,
            (value, previousValue) => {
                this.handleChangePage(null, 0);
                if (previousValue !== value) {
                    this.getProducts();
                }
            },
        );

        reaction(
            () => this.searchString,
            (value, previousValue) => {
                if (previousValue !== value) {
                    this.getProducts();
                }
            },
        );
    }

    @observable
    public selectedFilter?: IButtonGroupItem<string>;

    @action
    getProducts = async (immediately?: boolean) => {
        const isSearch = this.searchString || this.searchString?.trim();

        this.rootStore.requestTemplate({
            immediately,
            errorMessage: 'Ошибка получения данных продуктов',
            callback: async () => {
                const limit = isSearch ? 10 : this.rowsPerPageChange;
                const offset = isSearch ? 0 : this.rowsPerPageChange * this.selectedPage;

                const searchString = isSearch ? this.searchString?.trim() : undefined;
                const filter =
                    this.selectedFilter?.type === FILTER_TYPE.ALL || !this.selectedFilter?.type
                        ? undefined
                        : this.selectedFilter?.type;
                const {data} = await createAxiosRequest<
                    { offset: number; limit: number; status?: string; search?: string },
                    IGetProducts
                >({
                    path: apiPath.products,
                    method: 'GET',
                    params: {offset, limit, status: filter, search: searchString},
                    useToken: true,
                });
                runInAction(() => {
                    this.limit = limit;
                    this.summary = data.count;
                    this.offset = offset;
                    const tableData = mapProductsToTableBody(data.results);
                    this.tableBody = [...tableData];
                })
            },
        });
    };

    @action
    createProduct = ({productInfo}: { productInfo: ICreateProduct }) => {
        this.rootStore.requestTemplate({
            finallyAction: () => this.getProducts(),
            errorMessage: 'Ошибка создания продукта',
            callback: async () => {
                await createAxiosRequest({
                    path: apiPath.products,
                    method: 'POST',
                    data: {...productInfo},
                    useToken: true,
                });
            },
        });
    };

    @action
    updateProduct = ({productId, productInfo}: { productId: string | number; productInfo: ICreateProduct }) => {
        this.rootStore.requestTemplate({
            finallyAction: () => this.getProducts(),
            errorMessage: 'Ошибка обновления продукта',
            callback: async () => {
                await createAxiosRequest({
                    path: apiPath.productById.replace('{id}', `${productId}`),
                    method: 'PATCH',
                    data: {...productInfo},
                    useToken: true,
                });
            },
        });
    };

    @action
    deleteProduct = ({productId}: { productId: string | number }) => {
        this.rootStore.requestTemplate({
            finallyAction: () => this.getProducts(),
            errorMessage: 'Ошибка удаления продукта',
            callback: async () => {
                await createAxiosRequest({
                    path: apiPath.productById.replace('{id}', `${productId}`),
                    method: 'DELETE',
                    useToken: true,
                });
            },
        });
    };
}

const mapProductsToTableBody = (rawList: IProduct[]): ITableBodyProduct[] => {
    return rawList?.map((item) => ({
        id: item.id,
        name: item.name,
        status: item.status,
        description: item.description,
        staff_fio: item?.staff_fio,
        bu: item?.bu,
        author: `${item?.created_by?.first_name} ${item?.created_by?.last_name}`,
    })) as ITableBodyProduct[];
};
