import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import { TableTemplate } from '@/components/Templates/TableTemplate';
import { ModalDelete } from '@/components/common/ModalDelete';
import { TableRowStyled, TableCellStyled } from '@/components/Templates/TableComponentStyled';
import { LabelLikeHintWithText } from '@/components/common/LabelLikeHintWithText';
import type { ITableBodyBusinessUnit } from '@/stores/BusinessUnitStore';
import { useRootStore } from '@/stores';
import { useModalDelete } from '@/components/common/hooks/useModalDelete';
import { MoreMenuTabData } from '@/components/Administering/AdministrationTabData/MoreMenuTabData';
import { dateFormatterDefault } from '@/utils';
import { color} from '@/constants/colors';

const tableHeader = ['Название', 'Изменен', ' '];

const RenderBodyBusinessUnitTable = ({
  row,
  filled,
  openModal,
  setEdit,
}: {
  row: ITableBodyBusinessUnit;
  filled: boolean;
  openModal: (id: ITableBodyBusinessUnit) => void;
  setEdit: (value?: ITableBodyBusinessUnit) => void;
}) => {
  const { id, name, description, updatedBy, updated } = row;

  const handleDelete = () => {
    openModal(row);
  };

  const handleEdit = () => {
    setEdit(row);
  };

  return (
    <TableRowStyled key={id} sx={{ backgroundColor: filled ? color.light : color.transparent }}>
      <TableCellStyled verticalAlign="middle">
        <LabelLikeHintWithText reverse title={description} value={name} />
      </TableCellStyled>
      <TableCellStyled verticalAlign="middle">
        <LabelLikeHintWithText reverse title={updated ? dateFormatterDefault(updated) : '-'} value={updatedBy} />
      </TableCellStyled>
      <TableCellStyled align="right">
        <MoreMenuTabData onDelete={handleDelete} onEdit={handleEdit} />
      </TableCellStyled>
    </TableRowStyled>
  );
};

export const BusinessUnitTable = observer(({ setEdit }: { setEdit: (value?: ITableBodyBusinessUnit) => void }) => {
  const { open, handleClose, handleOpen, deletedValue } = useModalDelete<ITableBodyBusinessUnit>();
  const { isLoading, businessUnitStore } = useRootStore();

  useEffect(() => {
    businessUnitStore.getBusinessUnits();
  }, []);

  const handleDelete = () => {
    if (deletedValue?.id) {
      businessUnitStore.deleteBusinessUnit({ id: deletedValue.id });
      handleClose();
    }
  };

  return (
    <>
      <TableTemplate<ITableBodyBusinessUnit>
        tableHeader={tableHeader}
        RenderComponent={({ row, filled }) => (
          <RenderBodyBusinessUnitTable row={row} filled={filled} openModal={handleOpen} setEdit={setEdit} />
        )}
        store={businessUnitStore}
        isLoading={isLoading}
      />
      <ModalDelete
        uniqId="BusinessUnit_delete_modal"
        open={open}
        handleClose={handleClose}
        handleDelete={handleDelete}
        title="Вы действительно хотите удалить подразделение?"
        descriptions={[deletedValue?.name, deletedValue?.description, deletedValue?.updatedBy ?? '']}
      />
    </>
  );
});
