import { useState } from 'react';

export function useDrawerToggle() {
  const [open, setOpen] = useState(true);

  const toggleOpen = () => {
    setOpen(!open);
  };

  return {
    toggleOpen,
    open,
  };
}
