import {observable, makeObservable, action, reaction, computed, runInAction} from 'mobx';
import { FILTER_TYPE_REPORT } from '@/constants';
import { apiPath } from '@/constants/api';
import type { IButtonGroupItem } from '@/constants/types';
import { createAxiosRequest } from '@/utils/request';
import type { TableStoreType } from '@/stores/TableStore';
import { TableStore } from '@/stores/TableStore';
import type { IContragentReportTableFinancialResults, IRootStore } from '@/stores/type';

export type IDataRowFinancialResults = {
  id: number;
  form_startvalue: number;
  form_endvalue: number;
  inn: string;
  ogrn: number;
  form_year: number;
  organizationtype: string;
  form_code: number;
  form_string_name: string;
  capital_portion: number | null;
};
export type IGetContragentReports<T> = {
  count: number;
  next?: string;
  previous?: string;
  results: T[];
};

export type IContragentTableStoreWithIFinancialResultsStore<T> = IContragentReportTableFinancialResults & TableStoreType<T>;

export class ContragentReportTableFinancialResults
  extends TableStore<IDataRowFinancialResults>
  implements IContragentTableStoreWithIFinancialResultsStore<IDataRowFinancialResults>
{
  @observable
  public rootStore: IRootStore;

  @observable
  public results: IDataRowFinancialResults[];

  @observable
  public selectedFilter?: IButtonGroupItem<string>;

  @observable
  public selectedAccountId?: string | number;

  constructor(rootStore: IRootStore) {
    super();
    makeObservable(this);
    this.rootStore = rootStore;

    reaction(
      () => this.rowsPerPageChange,
      (value, _previousValue) => {
        if (this.maxSelectedPage <= value) {
          this.selectedFilter?.type === FILTER_TYPE_REPORT.BALANCE
            this.getReportsTable();
        }
      },
    );

    reaction(
      () => this.selectedPage,
      (value, _previousValue) => {
        if (this.maxSelectedPage <= value) {
          this.selectedFilter?.type === FILTER_TYPE_REPORT.BALANCE
            this.getReportsTable();
        }
      },
    );

    reaction(
      () => this.selectedFilter?.type,
      (value, previousValue) => {
        if (previousValue !== value) {
          this.tableBody = [];
          this.selectedPage = 0;
          value === FILTER_TYPE_REPORT.BALANCE 
          this.getReportsTable();
        }
      },
    );

    reaction(
        () => this.selectedAccountId,
        (value, _previousValue) => {
          if (value !== _previousValue) {
            if (this.selectedFilter?.type === FILTER_TYPE_REPORT.BALANCE) {
              this.getReportsTable();
            }
          }
        },
    );

    reaction(
      () => this.searchString,
      (value, previousValue) => {
        if (previousValue !== value) {
          this.selectedFilter?.type === FILTER_TYPE_REPORT.BALANCE
            this.getReportsTable();
        }
      },
    );
  }

  @computed
  public get isSearch() {
    return this.searchString || this.searchString?.trim();
  }

  @action
  getReportsTable = async () => {
    this.rootStore.requestTemplate({
      errorMessage: 'Ошибка получения отчета о финансовых результатах',
      callback: async () => {
        const limit = 500;
        const offset = 0;
        const { data } = await createAxiosRequest<
          { offset: number; limit: number },
          IGetContragentReports<IDataRowFinancialResults>
        >({
          path: apiPath.konturFinancialResults.replace('{account_pk}', `${this.selectedAccountId}`),
          method: 'GET',
          params: { limit, offset },
          useToken: true,
        });
        runInAction(() => {
          this.limit = limit;
          this.summary = data.count;
          this.offset = offset;
          const tableData = data.results;
          this.tableBody = [...tableData];
          this.results = [...tableData];
        })
      },
    });
  };

  @action
  clear = () => {
    this.selectedFilter = undefined;
  };
}
