import React, { useState } from 'react';

import type { ITableBodyAdministrationDataAttribute } from '@/stores/freskoApi/AdministrationDataAttributeStore';
import { AdministrationDataDetailTabAttributesModal } from '@/components/Administering/AdministrationTabData/AdministrationDataDetail/AdministrationDataDetailTab/AdministrationDataDetailTabAttributes/AdministrationDataDetailTabAttributesModal';
import { AdministrationDataDetailTabAttributesTable } from '@/components/Administering/AdministrationTabData/AdministrationDataDetail/AdministrationDataDetailTab/AdministrationDataDetailTabAttributes/AdministrationDataDetailTabAttributesTable';

export const AdministrationDataDetailTabAttributes = () => {
  const [editAttribute, setEditAttribute] = useState<ITableBodyAdministrationDataAttribute | undefined>(undefined);
  return (
    <>
      <AdministrationDataDetailTabAttributesTable setEditAttribute={setEditAttribute} />
      <AdministrationDataDetailTabAttributesModal editAttribute={editAttribute} setEditAttribute={setEditAttribute} />
    </>
  );
};

AdministrationDataDetailTabAttributes.displayName = 'AdministrationDataDetailTabAttributes';
