/* eslint-disable camelcase */
import {action, makeObservable, observable, reaction, runInAction} from 'mobx';
import { apiPath } from '@/constants/api';
import { createAxiosRequest } from '@/utils/request';
import type { TableStoreType } from '@/stores/TableStore';
import { TableStore } from '@/stores/TableStore';
import type { IRootStore } from '@/stores/type';

export type ICoverageTeamStore = {
  getCoverageTeamsByApplicationId: (accountId: string) => void;
  createCoverageTeam: ({
    role,
    status,
    account,
    employee,
    postn,
  }: {
    role?: string | null;
    status?: string;
    account: number;
    employee?: string | number;
    postn: number;
  }) => void;
  deleteCoverageTeam: ({ id }: { id: string | number }) => void;
  editCoverageTeam: ({
    id,
    role,
    status,
    account,
    employee,
    postn,
  }: {
    id: string | number;
    role?: string | null;
    status?: string;
    account: number;
    employee?: string | number;
    postn: number;
  }) => void;
  selectedId: string;
};

export type ICoverageByApplicationId = {
  id: number;
  role: null;
  status: string;
  created: string;
  updated: string;
  account: number;
  employee: number;
  postn: number;
  created_by?: {
    pk: number;
    username?: string;
    last_name?: string;
    first_name?: string;
    middle_name?: string;
    email?: string;
    url?: string;
    role?: string;
    phone?: string;
  };
  updated_by?: {
    pk: number;
    username?: string;
    last_name?: string;
    first_name?: string;
    middle_name?: string;
    email?: string;
    url?: string;
    role?: string;
    phone?: string;
  };
};

export type ICoverageTeam = {
  id: number;
  role?: string;
  status?: string;
  account: number;
  employee?: number | string;
  postn: number;
  updated?: string;
  created?: string;
  created_by?: {
    pk: number;
    username?: string;
    last_name?: string;
    first_name?: string;
    middle_name?: string;
    email?: string;
    url?: string;
    role?: string;
    phone?: string;
  };
  updated_by?: {
    pk: number;
    username?: string;
    last_name?: string;
    first_name?: string;
    middle_name?: string;
    email?: string;
    url?: string;
    role?: string;
    phone?: string;
  };
};

export type IGetCoverageTeamByApplicationId = {
  count: number;
  next?: string;
  previous?: string;
  results: ICoverageByApplicationId[];
};

export type ITableBodyCoverageTeam = {
  id: number;
  role: string;
  status: string;
  account: number;
  employee: number;
  postn: number;
  updatedBy: string;
  updated: string;
};

const mapCoverageByApplicationTeamToTableBody = (rawList: ICoverageByApplicationId[]): ITableBodyCoverageTeam[] => {
  return rawList?.map((item) => ({
    id: item.id,
    role: item.role ?? '',
    status: item.status,
    account: item.account,
    employee: item?.employee,
    postn: item.postn,
    updatedBy: item.updated_by?.first_name
      ? `${item.updated_by?.first_name} ${item.updated_by?.middle_name} ${item.updated_by?.last_name}`
      : item.updated_by?.username ?? `-`,
    updated: item?.updated ?? item.created,
  })) as ITableBodyCoverageTeam[];
};

export type ICoverageTeamStoreWithITableStore<T> = ICoverageTeamStore & TableStoreType<T>;

export class CoverageTeamStore
  extends TableStore<ITableBodyCoverageTeam>
  implements ICoverageTeamStoreWithITableStore<ITableBodyCoverageTeam>
{
  @observable
  public rootStore: IRootStore;

  @observable
  public selectedId: string;

  constructor(rootStore: IRootStore) {
    super();
    makeObservable(this);
    this.rootStore = rootStore;

    reaction(
      () => this.rowsPerPageChange,
      (value) => {
        if (this.maxSelectedPage <= value) {
          this.getCoverageTeamsByApplicationId(this.selectedId);
        }
      },
    );

    reaction(
      () => this.selectedPage,
      (value) => {
        if (this.maxSelectedPage <= value) {
          this.getCoverageTeamsByApplicationId(this.selectedId);
        }
      },
    );
  }

  @action
  getCoverageTeamsByApplicationId = (accountId: string) => {
    this.rootStore.requestTemplate({
      errorMessage: `Ошибка получения данных команды`,
      callback: async () => {
        const limit = this.rowsPerPageChange;
        const offset = this.rowsPerPageChange * this.selectedPage;

        const { data } = await createAxiosRequest<{ limit?: number; offset?: number }, IGetCoverageTeamByApplicationId>(
          {
            path: `${apiPath.getCoverageTeamByContragentId?.replace('{account_pk}', accountId)}`,
            method: 'GET',
            useToken: true,
            params: { limit, offset },
          },
        );
        runInAction(() => {
          this.limit = limit;
          this.summary = data.count;
          this.offset = offset;
          this.tableBody = mapCoverageByApplicationTeamToTableBody(data.results);
        })
      },
    });
  };

  @action
  createCoverageTeam = ({
    role,
    status,
    account,
    employee,
    postn,
  }: {
    role?: string | null;
    status?: string;
    account: number;
    employee?: string | number;
    postn: number;
  }) => {
    return this.rootStore.requestTemplate({
      errorMessage: `Ошибка создания команды`,
      finallyAction: () => this.getCoverageTeamsByApplicationId(this.selectedId),
      callback: async () => {
        await createAxiosRequest({
          path: `${apiPath.crCoverageTeam}`,
          method: 'POST',
          data: { role, status, postn, account, employee },
          useToken: true,
        });
      },
    });
  };

  @action
  editCoverageTeam = ({
    id,
    role,
    status,
    account,
    employee,
    postn,
  }: {
    id: string | number;
    role?: string | null;
    status?: string;
    account: number;
    employee?: string | number;
    postn: number;
  }) => {
    this.rootStore.requestTemplate({
      errorMessage: `Ошибка редактирования команды`,
      finallyAction: () => this.getCoverageTeamsByApplicationId(this.selectedId),
      callback: async () => {
        await createAxiosRequest<any, any>({
          path: apiPath.crudCoverageTeamById?.replace('{id}', `${id}`),
          method: 'PATCH',
          data: { role, status, postn, account, employee },
          useToken: true,
        });
      },
    });
  };

  @action
  deleteCoverageTeam = ({ id }: { id: string | number }) => {
    this.rootStore.requestTemplate({
      errorMessage: `Ошибка удаления команды ${id}`,
      finallyAction: () => this.getCoverageTeamsByApplicationId(this.selectedId),
      callback: async () => {
        await createAxiosRequest({
          path: apiPath.crudCoverageTeamById?.replace('{id}', `${id}`),
          method: 'DELETE',
          useToken: true,
        });
      },
    });
  };
}
