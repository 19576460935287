import React from "react";
import { observer } from "mobx-react";

import { TableCellStyled } from "@/components/Templates/TableComponentStyled";
import { LabelLikeHintWithText } from "@/components/common/LabelLikeHintWithText";
import { AssessmentTemplateAttributeModel } from "@/stores/Models/AdministrationAssessmentTemplateAttributeModel";
import { Dropdown } from "@/components/atoms/Dropdown";
import { BOOLEAN_LOWER_MAP_TO_DISPLAYNAME } from "@/components/Administering/ModelEvaluation/ModelEvaluationDetail/ModelEvaluationDetailTab/ModelEvaluationDetailTabAttributes/ModelEvaluationAttributeTabParams";
import { SimpleInputCustom } from "@/components/Administering/ModelEvaluation/ModelEvaluationDetail/ModelEvaluationDetailTab/ModelEvaluationDetailTabAttributes/components/SimpleInputCustom";
import { color } from '@/constants/colors';

export const EvaluationForExceptions = observer(({ modelData, disabled }: { modelData: AssessmentTemplateAttributeModel, disabled: boolean }) => {

  if (!modelData.is_boolean_score) {
    const onChangeInputSave = (val: Record<string, string>) => {
      //@ts-ignore
      modelData.out_of_range_score = Object.values(val)[0];
    }

    return (
      <TableCellStyled>
        <LabelLikeHintWithText title='Оценка при исключениях'>
          <SimpleInputCustom
            typeVal="number"
            disabled={disabled}
            sx={{ backgroundColor: modelData.fieldChanged['out_of_range_score'] ? color.light : color.transparent, width: '115px' }}
            required
            fieldName="out_of_range_score"
            autoComplete="off"
            size="small"
            onChange={onChangeInputSave}
            value={modelData.out_of_range_score}
            error={false}
          />
        </ LabelLikeHintWithText>
      </ TableCellStyled>
    );
  }

  const onChangeDropdownSave = (_: string, value: string) => {
    //@ts-ignore
    modelData.out_of_range_bool_score = value
  }

  return (
    <TableCellStyled>
      <LabelLikeHintWithText title='Оценка при исключениях'>
        <Dropdown
          disabled={disabled}
          mb={0}
          dropdownWidth="115px"
          sx={{ backgroundColor: modelData.fieldChanged['out_of_range_bool_score'] ? color.light : color.transparent }}
          onChange={onChangeDropdownSave}
          key={'type'}
          label={''}
          title={''}
          id={'out_of_range_bool_score'}
          value={modelData.out_of_range_bool_score}
          dropdownItems={[
            {
              label: BOOLEAN_LOWER_MAP_TO_DISPLAYNAME.false,
              value: false,
            },
            {
              label: BOOLEAN_LOWER_MAP_TO_DISPLAYNAME.true,
              value: true,
            },
          ]}
        />
      </ LabelLikeHintWithText>
    </ TableCellStyled>
  );
})