import * as React from 'react';
import { styled } from '@mui/system';
import { TextareaAutosize, TextareaAutosizeProps } from '@mui/material';
import { color } from '@/constants/colors';

type EmptyTextareaProps = {
  placeholder: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  ariaLabel: string;
};

const StyledTextareaAutosize = styled(TextareaAutosize)<TextareaAutosizeProps>(({ value }) => ({
  width: 'calc(100% - 40px)',
  fontFamily: 'Raleway, sans-serif',
  fontSize: '13px',
  padding: '12px 20px',
  borderRadius: '12px',
  color: color.black,
  border: `1px solid ${value ? color.light_green : color.light_gray}`,
  resize: 'none',
  '&:focus': {
    outline: 0,
    borderColor: color.light_green,
  },
  '&::placeholder': {
    fontFamily: 'Raleway',
    color: color.light_gray,
  },
}));

export default function TextareaAutosizeStyled({ placeholder, value, onChange, ariaLabel }: EmptyTextareaProps) {
  return (
    <StyledTextareaAutosize
      aria-label={ariaLabel}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      minRows={5}
      maxRows={5}
    />
  );
}
