import { useState } from 'react';
import { dark, light } from '@/constants/Theme';

export function useCustomTheme() {
  const [isDarkTheme, setIsDarkTheme] = useState(false);
  const [theme, setTheme] = useState(light);

  const changeTheme = () => {
    setIsDarkTheme(!isDarkTheme);
    setTheme(isDarkTheme ? dark : light);
  };

  return {
    changeTheme,
    isDarkTheme,
    theme,
  };
}
