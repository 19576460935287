import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';

import { Box, IconButton } from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import CircleIcon from '@mui/icons-material/Circle';

import { TableTemplate } from '@/components/Templates/TableTemplate';
import { useRootStore } from '@/stores';
import type { TableApplications } from '@/stores/ApplicationsStore';
import { APP_STATUS } from '@/constants/backendEnum';
import { APP_ACC_ROLE, APP_STATUS_CUSTOM } from '@/constants/backendEnum';
import { RETURN_ROUTE_APPLICATION, ROUTES } from '@/constants';
import { TableRowStyled, TableCellStyled, TableCellSmallPadding } from '@/components/Templates/TableComponentStyled';
import { useModalDelete } from '@/components/common/hooks/useModalDelete';
import { ModalDelete } from '@/components/common/ModalDelete';
import { color } from '@/constants/colors';
import { dateFormatterDefault, swopKeysAndValues } from '@/utils';
import { SelectStyled, nonClickableTags } from '@/components/atoms/Select/SelectStyled';

const tableHeader = [
  '',
  '№ заявки',
  'Дата заявки',
  'Продукт',
  'Клиент',
  'ИНН',
  'Роль контрагента',
  'Тип заявки',
  'Клиентский менеджер',
  'Ответственный сотрудник',
  'Статус заявки',
  '',
];
const tableHeaderWidth = [
  '3.09%',
  '8.96%',
  '7.17%',
  '7.91%',
  '8.1%',
  '7.35%',
  '9.15%',
  '12.73%',
  '11.93%',
  '12.73%',
  '7.73%',
  '3.09%',
];

const selectTitle = (
  <>
    Выберите статус <br /> заявки
  </>
);

const RenderBodyApplicationsTable = ({
  row,
  filled,
  handleOpenDeleteModal,
  contragentId,
}: {
  row: TableApplications;
  filled: boolean;
  handleOpenDeleteModal: (value: TableApplications) => void;
  contragentId?: string;
}) => {
  const [status, setStatus] = useState<string>();
  const navigate = useNavigate();
  const { contragentsStore, applicationsStore, applicationsPageDetailsStore } = useRootStore();

  const onClick: React.MouseEventHandler<HTMLTableRowElement> = (
    e: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
  ) => {
    if (e.target instanceof HTMLElement && nonClickableTags.includes(e.target.tagName)) {
      return;
    }
    contragentsStore.clear();
    navigate(`${ROUTES.applications}/${row.id}`, {
      state: { route: RETURN_ROUTE_APPLICATION, contragenId: contragentId },
    });
  };

  const handleDelete = (e: any) => {
    e.stopPropagation();
    handleOpenDeleteModal(row);
  };

  const role = row?.role ? APP_ACC_ROLE[row.role] : '-';

  const handleSelectChange = (value: string | undefined) => {
    setStatus(value);
  };

  useEffect(() => {
    const statusKey = swopKeysAndValues(APP_STATUS);

    if (statusKey && status && contragentId) {
      applicationsPageDetailsStore.updateStatusOrStageByApplicationId({
        applicationId: row.id as string,
        status: statusKey[status],
        isPageContragentApplication: true,
      });
    }
  }, [status]);

  return (
    <TableRowStyled
      key={row.id}
      sx={[
        { backgroundColor: filled ? color.light : color.transparent },
        {
          '&:hover .deleteButton': {
            opacity: 1,
            transition: 'opacity 0.2s ease-in-out',
          },
        },
      ]}
      onClick={onClick}
    >
      <TableCellSmallPadding>
        {APP_STATUS_CUSTOM[row.status] === 'Новая' && <CircleIcon sx={{ fontSize: '10px', color: color.emerald }} />}
      </TableCellSmallPadding>
      <TableCellStyled>{row.number}</TableCellStyled>
      <TableCellStyled>{dateFormatterDefault(row.created)}</TableCellStyled>
      <TableCellStyled>{row.productName}</TableCellStyled>
      <TableCellStyled>{row.contragent}</TableCellStyled>
      <TableCellStyled>{row.inn}</TableCellStyled>
      <TableCellStyled>{role}</TableCellStyled>
      <TableCellStyled>{row.typeApplication}</TableCellStyled>
      <TableCellStyled>{row.client_manager}</TableCellStyled>
      <TableCellStyled>{row.responsible_user}</TableCellStyled>
      <TableCellStyled>
        <SelectStyled
          defValue={APP_STATUS[row.status as keyof typeof APP_STATUS]}
          items={APP_STATUS}
          title={selectTitle}
          onChange={handleSelectChange}
        />
      </TableCellStyled>
      <TableCellSmallPadding>
        <Box sx={[{ opacity: '0', transition: 'opacity 0.15s ease-in-out' }]} className="deleteButton">
          <IconButton color="primary" aria-label="удалить" component="label" onClick={handleDelete}>
            <DeleteOutlinedIcon color="error" />
          </IconButton>
        </Box>
      </TableCellSmallPadding>
    </TableRowStyled>
  );
};

export const ContragentApplicationTable = observer(() => {
  const { applicationsStore, applicationsPageDetailsStore, isLoading } = useRootStore();
  const { open, handleClose, handleOpen, deletedValue: application } = useModalDelete<TableApplications>();
  const { id } = useParams<{ id: string }>();

  useLayoutEffect(() => {
    if ((applicationsStore.renderedRows?.length === 0 || applicationsStore.contragentId !== id) && id) {
      applicationsStore.setContragenId(id);
    }
  }, [id, applicationsStore]);

  const handleDelete = () => {
    if (id && application?.id) {
      applicationsPageDetailsStore.deleteConditionApp({ contragenId: id, applicationId: application.id });
    }
    handleClose();
  };

  return (
    <>
      <TableTemplate<TableApplications>
        tableHeader={tableHeader}
        tableHeaderWidth={tableHeaderWidth}
        RenderComponent={(props) => (
          <RenderBodyApplicationsTable {...props} contragentId={id} handleOpenDeleteModal={handleOpen} />
        )}
        store={applicationsStore}
        isLoading={isLoading}
      />
      <ModalDelete
        uniqId="Data_table_modal"
        open={open}
        handleClose={handleClose}
        handleDelete={handleDelete}
        title={`Вы действительно хотите удалить заявку №${application?.number}?`}
        descriptions={`Статус заявки: "${APP_STATUS_CUSTOM[application?.status as keyof typeof APP_STATUS_CUSTOM]}"`}
      />
    </>
  );
});
