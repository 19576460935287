import React, { Dispatch, SetStateAction, MutableRefObject } from 'react';
import style from '@/components/Contagents/ContactTabs/ContractReportTable.module.scss';

import { Table, TableBody, TableCell, TableRow, TableHead } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';

import { color } from '@/constants/colors';
import { StaticRow, TableCellPeriod } from '@/components/Contagents/ContactTabs/ContractReportTableBalance';
import { RemovingSpaces } from '@/utils/reportTable';

export type RowData = {
  row1100: number[];
  row1200: number[];
  row1110: number[];
  row1120: number[];
  row1130: number[];
  row1140: number[];
  row1150: number[];
  row1160: number[];
  row1170: number[];
  row1180: number[];
  row1190: number[];
  row1210: number[];
  row1220: number[];
  row1230: number[];
  row1240: number[];
  row1250: number[];
  row1260: number[];
  row1300: number[];
  row1310: number[];
  row1320: number[];
  row1340: number[];
  row1350: number[];
  row1360: number[];
  row1370: number[];
  row1400: number[];
  row1410: number[];
  row1420: number[];
  row1430: number[];
  row1450: number[];
  row1500: number[];
  row1510: number[];
  row1520: number[];
  row1530: number[];
  row1540: number[];
  row1550: number[];
  row1600: number[];
  row1700: number[];
};

interface ContractReportTableErrorBalanceProps {
  activeYear?: string[];
  formattedDate: (date: string) => string;
  StaticRowsError: StaticRow[];
  years: string[];
  inputRefs: MutableRefObject<(HTMLInputElement | null)[]>;
  valueWithSpaces: (value: string | null) => string;
  setIsRowModified: Dispatch<SetStateAction<{ [key: string]: boolean }>>;
  tableDataPeriod: Record<string, TableCellPeriod>;
}

const ContractReportTableErrorBalance: React.FC<ContractReportTableErrorBalanceProps> = ({
  activeYear,
  formattedDate,
  StaticRowsError,
  years,
  inputRefs,
  valueWithSpaces,
  setIsRowModified,
  tableDataPeriod,
}) => {
  const handleInputChangePeriod = (event: React.ChangeEvent<HTMLInputElement>, keyPeriod: string, index: number) => {
    setIsRowModified((prevModified) => ({
      ...prevModified,
      [keyPeriod]: true,
    }));
  };

  const RowData: RowData = {
    row1100: years.map((year) => parseInt(RemovingSpaces(tableDataPeriod[`1100-${year}`].value.toString()), 10)),
    row1200: years.map((year) => parseInt(RemovingSpaces(tableDataPeriod[`1200-${year}`].value.toString()), 10)),
    row1110: years.map((year) => parseInt(RemovingSpaces(tableDataPeriod[`1110-${year}`].value.toString()), 10)),
    row1120: years.map((year) => parseInt(RemovingSpaces(tableDataPeriod[`1120-${year}`].value.toString()), 10)),
    row1130: years.map((year) => parseInt(RemovingSpaces(tableDataPeriod[`1130-${year}`].value.toString()), 10)),
    row1140: years.map((year) => parseInt(RemovingSpaces(tableDataPeriod[`1140-${year}`].value.toString()), 10)),
    row1150: years.map((year) => parseInt(RemovingSpaces(tableDataPeriod[`1150-${year}`].value.toString()), 10)),
    row1160: years.map((year) => parseInt(RemovingSpaces(tableDataPeriod[`1160-${year}`].value.toString()), 10)),
    row1170: years.map((year) => parseInt(RemovingSpaces(tableDataPeriod[`1170-${year}`].value.toString()), 10)),
    row1180: years.map((year) => parseInt(RemovingSpaces(tableDataPeriod[`1180-${year}`].value.toString()), 10)),
    row1190: years.map((year) => parseInt(RemovingSpaces(tableDataPeriod[`1190-${year}`].value.toString()), 10)),
    row1210: years.map((year) => parseInt(RemovingSpaces(tableDataPeriod[`1210-${year}`].value.toString()), 10)),
    row1220: years.map((year) => parseInt(RemovingSpaces(tableDataPeriod[`1220-${year}`].value.toString()), 10)),
    row1230: years.map((year) => parseInt(RemovingSpaces(tableDataPeriod[`1230-${year}`].value.toString()), 10)),
    row1240: years.map((year) => parseInt(RemovingSpaces(tableDataPeriod[`1240-${year}`].value.toString()), 10)),
    row1250: years.map((year) => parseInt(RemovingSpaces(tableDataPeriod[`1250-${year}`].value.toString()), 10)),
    row1260: years.map((year) => parseInt(RemovingSpaces(tableDataPeriod[`1260-${year}`].value.toString()), 10)),
    row1300: years.map((year) => parseInt(RemovingSpaces(tableDataPeriod[`1300-${year}`].value.toString()), 10)),
    row1310: years.map((year) => parseInt(RemovingSpaces(tableDataPeriod[`1310-${year}`].value.toString()), 10)),
    row1320: years.map((year) => parseInt(RemovingSpaces(tableDataPeriod[`1320-${year}`].value.toString()), 10)),
    row1340: years.map((year) => parseInt(RemovingSpaces(tableDataPeriod[`1340-${year}`].value.toString()), 10)),
    row1350: years.map((year) => parseInt(RemovingSpaces(tableDataPeriod[`1350-${year}`].value.toString()), 10)),
    row1360: years.map((year) => parseInt(RemovingSpaces(tableDataPeriod[`1360-${year}`].value.toString()), 10)),
    row1370: years.map((year) => parseInt(RemovingSpaces(tableDataPeriod[`1370-${year}`].value.toString()), 10)),
    row1400: years.map((year) => parseInt(RemovingSpaces(tableDataPeriod[`1400-${year}`].value.toString()), 10)),
    row1410: years.map((year) => parseInt(RemovingSpaces(tableDataPeriod[`1410-${year}`].value.toString()), 10)),
    row1420: years.map((year) => parseInt(RemovingSpaces(tableDataPeriod[`1420-${year}`].value.toString()), 10)),
    row1430: years.map((year) => parseInt(RemovingSpaces(tableDataPeriod[`1430-${year}`].value.toString()), 10)),
    row1450: years.map((year) => parseInt(RemovingSpaces(tableDataPeriod[`1450-${year}`].value.toString()), 10)),
    row1500: years.map((year) => parseInt(RemovingSpaces(tableDataPeriod[`1500-${year}`].value.toString()), 10)),
    row1510: years.map((year) => parseInt(RemovingSpaces(tableDataPeriod[`1510-${year}`].value.toString()), 10)),
    row1520: years.map((year) => parseInt(RemovingSpaces(tableDataPeriod[`1520-${year}`].value.toString()), 10)),
    row1530: years.map((year) => parseInt(RemovingSpaces(tableDataPeriod[`1530-${year}`].value.toString()), 10)),
    row1540: years.map((year) => parseInt(RemovingSpaces(tableDataPeriod[`1540-${year}`].value.toString()), 10)),
    row1550: years.map((year) => parseInt(RemovingSpaces(tableDataPeriod[`1550-${year}`].value.toString()), 10)),
    row1600: years.map((year) => parseInt(RemovingSpaces(tableDataPeriod[`1600-${year}`].value.toString()), 10)),
    row1700: years.map((year) => parseInt(RemovingSpaces(tableDataPeriod[`1700-${year}`].value.toString()), 10)),
  };

  const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, rowIndex: number, indexYear: number) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      let nextRowIndex = rowIndex + 1;

      if (nextRowIndex < StaticRowsError.length) {
        let nextInput = inputRefs.current[nextRowIndex * years.length + indexYear];

        while (nextInput && nextInput.disabled) {
          nextRowIndex++;
          nextInput = inputRefs.current[nextRowIndex * years.length + indexYear];
        }

        if (nextInput) {
          nextInput.focus();
        }
      }
    }
  };

  return (
    <TableContainer
      component={Paper}
      sx={{ minWidth: 600, maxWidth: 900, overflow: 'auto', backgroundColor: `${color.light_carmine_pink}` }}
      className={style.tableErrorContainer}
    >
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell
              sx={{ minWidth: '427px', border: `1px solid ${color.white_light}`, fontWeight: '700', p: '6px' }}
              align="right"
            >
              Формула расчета
            </TableCell>
            {activeYear &&
              activeYear.map((year) => (
                <TableCell
                  key={year}
                  sx={{
                    minWidth: '155px',
                    border: `1px solid ${color.white_light}`,
                    fontWeight: '700',
                    p: '6px',
                    maxWidth: '120px',
                  }}
                  align="center"
                >
                  Расхождение за {formattedDate(year)}
                </TableCell>
              ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {StaticRowsError.map((staticRow, index) => {
            const { code } = staticRow;

            return (
              <TableRow key={index}>
                <TableCell sx={{ border: `1px solid ${color.white_light}`, p: '6px' }} align="right">
                  {staticRow.name}
                </TableCell>
                {years.map((year, indexYear) => {
                  const keyPeriod = `${code}-${year}`;

                  let inputValue = '';

                  if (code === 1600_1700 && activeYear && activeYear.includes(year)) {
                    const value1600 = RowData.row1600[indexYear] || 0;
                    const value1700 = RowData.row1700[indexYear] || 0;

                    const difference = value1600 - value1700;

                    inputValue = difference !== 0 ? difference.toString() : '';

                    return (
                      <TableCell
                        sx={{ p: '6px 16px', border: `1px solid ${color.white_light}`, maxWidth: '120px' }}
                        key={keyPeriod}
                      >
                        <input
                          ref={(el) => inputRefs.current.push(el)}
                          className={style.calculationInput}
                          type="text"
                          value={valueWithSpaces(inputValue)}
                          onChange={(e) => handleInputChangePeriod(e, keyPeriod, index)}
                          onKeyDown={(e) => handleInputKeyDown(e, index, indexYear)}
                          disabled
                        />
                      </TableCell>
                    );
                  }
                  if (code === 1100_1200_1600 && activeYear && activeYear.includes(year)) {
                    const value1100 = RowData.row1100[indexYear] || 0;
                    const value1200 = RowData.row1200[indexYear] || 0;
                    const value1600 = RowData.row1600[indexYear] || 0;

                    const difference = value1100 + value1200 - value1600;

                    inputValue = difference !== 0 ? difference.toString() : '';

                    return (
                      <TableCell
                        sx={{ p: '6px 16px', border: `1px solid ${color.white_light}`, maxWidth: '120px' }}
                        key={keyPeriod}
                      >
                        <input
                          ref={(el) => inputRefs.current.push(el)}
                          className={style.calculationInput}
                          type="text"
                          value={valueWithSpaces(inputValue)}
                          onChange={(e) => handleInputChangePeriod(e, keyPeriod, index)}
                          onKeyDown={(e) => handleInputKeyDown(e, index, indexYear)}
                          disabled
                        />
                      </TableCell>
                    );
                  }
                  if (code === 1300_1400_1500_1700 && activeYear && activeYear.includes(year)) {
                    const value1300 = RowData.row1300[indexYear] || 0;
                    const value1400 = RowData.row1400[indexYear] || 0;
                    const value1500 = RowData.row1500[indexYear] || 0;
                    const value1700 = RowData.row1700[indexYear] || 0;

                    const difference = value1300 + value1400 + value1500 - value1700;

                    inputValue = difference !== 0 ? difference.toString() : '';
                    return (
                      <TableCell
                        sx={{ p: '6px 16px', border: `1px solid ${color.white_light}`, maxWidth: '120px' }}
                        key={keyPeriod}
                      >
                        <input
                          ref={(el) => inputRefs.current.push(el)}
                          className={style.calculationInput}
                          type="text"
                          value={valueWithSpaces(inputValue)}
                          onChange={(e) => handleInputChangePeriod(e, keyPeriod, index)}
                          onKeyDown={(e) => handleInputKeyDown(e, index, indexYear)}
                          disabled
                        />
                      </TableCell>
                    );
                  }
                  if (code === 1210_1220_1230_1240_1250_1260_1200 && activeYear && activeYear.includes(year)) {
                    const value1210 = RowData.row1210[indexYear] || 0;
                    const value1220 = RowData.row1220[indexYear] || 0;
                    const value1230 = RowData.row1230[indexYear] || 0;
                    const value1240 = RowData.row1240[indexYear] || 0;
                    const value1250 = RowData.row1250[indexYear] || 0;
                    const value1260 = RowData.row1260[indexYear] || 0;
                    const value1200 = RowData.row1200[indexYear] || 0;

                    const difference =
                      value1210 + value1220 + value1230 + value1240 + value1250 + value1260 - value1200;

                    inputValue = difference !== 0 ? difference.toString() : '';
                    return (
                      <TableCell
                        sx={{ p: '6px 16px', border: `1px solid ${color.white_light}`, maxWidth: '120px' }}
                        key={keyPeriod}
                      >
                        <input
                          ref={(el) => inputRefs.current.push(el)}
                          className={style.calculationInput}
                          type="text"
                          value={valueWithSpaces(inputValue)}
                          onChange={(e) => handleInputChangePeriod(e, keyPeriod, index)}
                          onKeyDown={(e) => handleInputKeyDown(e, index, indexYear)}
                          disabled
                        />
                      </TableCell>
                    );
                  }
                  if (
                    code === 1110_1120_1130_1140_1150_1160_1170_1180_1100 &&
                    activeYear &&
                    activeYear.includes(year)
                  ) {
                    const value1110 = RowData.row1110[indexYear] || 0;
                    const value1120 = RowData.row1120[indexYear] || 0;
                    const value1130 = RowData.row1130[indexYear] || 0;
                    const value1140 = RowData.row1140[indexYear] || 0;
                    const value1150 = RowData.row1150[indexYear] || 0;
                    const value1160 = RowData.row1160[indexYear] || 0;
                    const value1170 = RowData.row1170[indexYear] || 0;
                    const value1180 = RowData.row1180[indexYear] || 0;
                    const value1190 = RowData.row1190[indexYear] || 0;
                    const value1100 = RowData.row1100[indexYear] || 0;

                    const difference =
                      value1110 +
                      value1120 +
                      value1130 +
                      value1140 +
                      value1150 +
                      value1160 +
                      value1170 +
                      value1180 +
                      value1190 -
                      value1100;

                    inputValue = difference !== 0 ? difference.toString() : '';
                    return (
                      <TableCell
                        sx={{ p: '6px 16px', border: `1px solid ${color.white_light}`, maxWidth: '120px' }}
                        key={keyPeriod}
                      >
                        <input
                          ref={(el) => inputRefs.current.push(el)}
                          className={style.calculationInput}
                          type="text"
                          value={valueWithSpaces(inputValue)}
                          onChange={(e) => handleInputChangePeriod(e, keyPeriod, index)}
                          onKeyDown={(e) => handleInputKeyDown(e, index, indexYear)}
                          disabled
                        />
                      </TableCell>
                    );
                  }
                  if (code === 1410_1420_1430_1450_1400 && activeYear && activeYear.includes(year)) {
                    const value1410 = RowData.row1410[indexYear] || 0;
                    const value1420 = RowData.row1420[indexYear] || 0;
                    const value1430 = RowData.row1430[indexYear] || 0;
                    const value1450 = RowData.row1450[indexYear] || 0;
                    const value1400 = RowData.row1400[indexYear] || 0;
                    const difference = value1410 + value1420 + value1430 + value1450 - value1400;

                    inputValue = difference !== 0 ? difference.toString() : '';
                    return (
                      <TableCell
                        sx={{ p: '6px 16px', border: `1px solid ${color.white_light}`, maxWidth: '120px' }}
                        key={keyPeriod}
                      >
                        <input
                          ref={(el) => inputRefs.current.push(el)}
                          className={style.calculationInput}
                          type="text"
                          value={valueWithSpaces(inputValue)}
                          onChange={(e) => handleInputChangePeriod(e, keyPeriod, index)}
                          onKeyDown={(e) => handleInputKeyDown(e, index, indexYear)}
                          disabled
                        />
                      </TableCell>
                    );
                  }
                  if (code === 1310_1320_1340_1350_1360_1370_1300 && activeYear && activeYear.includes(year)) {
                    const value1310 = RowData.row1310[indexYear] || 0;
                    const value1320 = RowData.row1320[indexYear] || 0;
                    const value1340 = RowData.row1340[indexYear] || 0;
                    const value1350 = RowData.row1350[indexYear] || 0;
                    const value1360 = RowData.row1360[indexYear] || 0;
                    const value1370 = RowData.row1370[indexYear] || 0;
                    const value1300 = RowData.row1300[indexYear] || 0;

                    const difference =
                      value1310 + value1320 + value1340 + value1350 + value1360 + value1370 - value1300;

                    inputValue = difference !== 0 ? difference.toString() : '';
                    return (
                      <TableCell
                        sx={{ p: '6px 16px', border: `1px solid ${color.white_light}`, maxWidth: '120px' }}
                        key={keyPeriod}
                      >
                        <input
                          ref={(el) => inputRefs.current.push(el)}
                          className={style.calculationInput}
                          type="text"
                          value={valueWithSpaces(inputValue)}
                          onChange={(e) => handleInputChangePeriod(e, keyPeriod, index)}
                          onKeyDown={(e) => handleInputKeyDown(e, index, indexYear)}
                          disabled
                        />
                      </TableCell>
                    );
                  }
                  if (code === 1510_1520_1530_1540_1550_1500 && activeYear && activeYear.includes(year)) {
                    const value1510 = RowData.row1510[indexYear] || 0;
                    const value1520 = RowData.row1520[indexYear] || 0;
                    const value1530 = RowData.row1530[indexYear] || 0;
                    const value1540 = RowData.row1540[indexYear] || 0;
                    const value1550 = RowData.row1550[indexYear] || 0;
                    const value1500 = RowData.row1500[indexYear] || 0;
                    const difference = value1510 + value1520 + value1530 + value1540 + value1550 - value1500;

                    inputValue = difference !== 0 ? difference.toString() : '';
                    return (
                      <TableCell
                        sx={{ p: '6px 16px', border: `1px solid ${color.white_light}`, maxWidth: '120px' }}
                        key={keyPeriod}
                      >
                        <input
                          ref={(el) => inputRefs.current.push(el)}
                          className={style.calculationInput}
                          type="text"
                          value={valueWithSpaces(inputValue)}
                          onChange={(e) => handleInputChangePeriod(e, keyPeriod, index)}
                          onKeyDown={(e) => handleInputKeyDown(e, index, indexYear)}
                          disabled
                        />
                      </TableCell>
                    );
                  }
                  return null;
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ContractReportTableErrorBalance;
