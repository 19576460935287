import React from 'react';

import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

import { color } from '@/constants/colors';

export type IBadgeType<T> = {
  type: keyof T;
  text?: string;
  children: any;
  badgeColorsMap: { [x: string]: string };
  isSmall?: boolean;
  isWarning?: boolean;
  isFirst?: boolean;
  width?: string;
};

export const BadgeTemplate = <T extends { [x: string]: string }>({
  type,
  text,
  children,
  badgeColorsMap,
  isSmall,
  isWarning,
  isFirst,
  width,
}: IBadgeType<T>) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      sx={{
        width: width ? `${width}` : 'auto',
        marginRight: isSmall ? '6px' : 'auto',
        marginLeft: isSmall && !isFirst ? '6px' : undefined,
        padding: isSmall ? '2px 4px' : '3px 8px',
        height: isSmall ? '18px' : '20px',
        backgroundColor: badgeColorsMap[type as string] || color.persian_green,
        borderRadius: '2px',
        textDecorationLine: 'none',
      }}
    >
      <Typography
        color={color.white}
        sx={{
          fontWeight: '400',
          fontSize: isSmall ? '0.775rem' : '0.875rem',
          lineHeight: '1.43',
          letterSpacing: '0.01071em',
          whiteSpace: 'nowrap',
        }}
      >
        {text ?? children ?? ''}
      </Typography>
      {isWarning && <WarningAmberIcon sx={{ color: color.white, width: '16px', height: '16px', marginLeft: '8px' }} />}
    </Box>
  );
};
