import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import { ButtonStyled } from '@/components/atoms/Button';
import { DrawerCustom } from '@/components/atoms/DrawerCustom';
import { useRootStore } from '@/stores';
import { DrawerLikeModal } from '@/components/common/DrawerLikeModal';
import { ITableBodyAdministrationData } from '@/stores/freskoApi/AdministrationDataStore';

const templateInputJoinWithLabelList = (data: ITableBodyAdministrationData | undefined) => [

  {
    id: 'name',
    label: 'Имя объекта',
    value: data?.bdName,
  },
  {
    id: 'db_layout',
    placeholder: 'Слой объекта в бд',
    label: 'Слой',
    value: data?.objectBdName,
  },
  {
    id: 'db_object',
    placeholder: 'Имя объекта в бд',
    label: 'Объект',
    value: data?.objectName,
  },
  {
    id: 'db_obj_prim_id_attr_name',
    label: 'Атрибут идентификатора',
    value: data?.primName,
  },
  {
    id: 'description',
    label: 'Описание',
    value: data?.description,
  },
  {
    id: 'filter_spec',
    label: 'Фильтр',
    value: data?.filter,
  }
];

type Props = {
  editData?: ITableBodyAdministrationData;
  setEditData: (value: ITableBodyAdministrationData | undefined) => void;
  isRequestByIdAfterEdit?: boolean;
  isOnlyEdit?: boolean;
}

export const AdministrationAddTabDataModal = observer(({
  editData,
  setEditData,
  isRequestByIdAfterEdit,
  isOnlyEdit,
}: Props) => {
  const {
    administrationDataStore,
    uiStateStore: { open },
    isLoading,
  } = useRootStore();

  const [openAdd, setOpenAdd] = useState<boolean>(false);

  const toggleOpen = () => {
    if (openAdd) {
      return closeModal();
    };

    return openModal();
  };

  const closeModal = () => {
    setOpenAdd(false);
    setEditData(undefined);
  }

  const openModal = () => {
    setOpenAdd(true);
  }

  useEffect(() => {
    if (editData) {
      openModal();
    }
  }, [editData])

  const handleSaveForm = async (event: React.FormEvent<HTMLFormElement>) => {
    const data = new FormData(event.currentTarget);
    const name = data.get('name') as string;
    const db_layout = data.get('db_layout') as string;
    const db_object = data.get('db_object') as string;
    const db_obj_prim_id_attr_name = data.get('db_obj_prim_id_attr_name') as string;
    const description = data.get('description') as string;
    const filter_spec = data.get('filter_spec') as string;

    if (editData) {
      administrationDataStore.patchAdministrationDataById({
        id: `${editData.id}`,
        isRequestByIdAfterEdit,
        patchData: {
          id: editData.id as number,
          name: name ?? editData?.bdName,
          db_object: db_object ?? editData?.objectName,
          db_layout: db_layout ?? editData?.objectBdName,
          db_obj_prim_id_attr_name: db_obj_prim_id_attr_name ?? editData?.primName,
          description: description ?? editData.description,
          filter_spec: filter_spec ?? editData?.filter
        }
      })

      setEditData(undefined);
      closeModal();

      return;
    }

    administrationDataStore.createAdministrationData({
      createData: {
        name,
        db_object,
        db_layout,
        db_obj_prim_id_attr_name,
        description,
        filter_spec,
      }
    })

    closeModal();
  }

  if (isOnlyEdit) {
    return (
      <DrawerLikeModal
        title={editData ? "Изменить объект" : "Добавить объект"}
        open={openAdd}
        toggleOpen={toggleOpen}
        items={templateInputJoinWithLabelList(editData)}
        saveForm={handleSaveForm}
        buttonConfirmText={editData ? "Сохранить" : "Создать объект"}
        isDisabled={isLoading}
      ></DrawerLikeModal>
    )
  }

  return (
    <>
      <DrawerCustom open={open}>
        <ButtonStyled
          text="добавить объект"
          startIcon={<AddCircleOutlineIcon />}
          onClick={toggleOpen}
          disabled={false}
        />
        <DrawerLikeModal
          title={editData ? "Изменить объект" : "Добавить объект"}
          open={openAdd}
          toggleOpen={toggleOpen}
          items={templateInputJoinWithLabelList(editData)}
          saveForm={handleSaveForm}
          buttonConfirmText={editData ? "Сохранить" : "Создать объект"}
          isDisabled={isLoading}
        ></DrawerLikeModal>
      </DrawerCustom>
    </>
  );
});