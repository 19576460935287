import React, { useState, useMemo, useEffect } from 'react';

import type { SelectChangeEvent } from '@mui/material';
import { Box, FormControl, MenuItem, Select, styled } from '@mui/material';

import { OFFSET, WIDTH } from '@/constants/sizes';
import { InputLabelStyled } from '@/components/atoms/InputLabelStyled';

export const SelectStyled = styled(Select)(({ theme }) => ({
  '& .MuiSelect-select': {
    padding: '8px 10px',
  },
}));

type IDropdownItem = {
  value: string | number | boolean;
  label: string;
  code?: string;
};

type IDropdownBase = {
  title?: string;
  propWidth?: boolean;
  dropdownHeight?: boolean;
  id: string;
  dropdownItems: IDropdownItem[];
  onChange: (name: string, value: string) => void;

  [k: string]: any;
};

export const DropdownBase = ({
  title,
  id,
  dropdownItems,
  dropdownHeight,
  onChange,
  propWidth,
  disabledPlaceholder = true,
  ...props
}: IDropdownBase) => {
  const [value, setValue] = useState<string | number>(props?.value ?? '');

  useEffect(() => {
    if (props.forceSet) {
      setValue(props?.value);
    }
  }, [props?.value]);

  useEffect(() => {
    if (props.required && id) {
      props.handleGlobalError && props.handleGlobalError({ [id]: Boolean(!value) });
    }

    return () => {
      if (props.required && id) {
        props.handleGlobalError && props.handleGlobalError({ [id]: Boolean(false) });
      }
    };
  }, []);

  const handleChange = (event: SelectChangeEvent<unknown>, child: React.ReactNode) => {
    const selectedValue = event.target.value as string;
    if (onChange) {
      onChange(id, selectedValue);
      props.handleGlobalError && props.handleGlobalError({ [id]: false });
    }
    setValue(selectedValue);
  };

  const placeHolderItem = useMemo(
    () => [
      title ? (
        <MenuItem key="ds" value="" disabled={disabledPlaceholder}>
          <em>{title}</em>
        </MenuItem>
      ) : (
        <Box key="ds" />
      ),
    ],
    [],
  );

  const renderItems = useMemo(
    () =>
      dropdownItems.map(({ value, label, code }) => (
        // @ts-ignore
        <MenuItem
          sx={{ width: propWidth ? '460px' : '100%', whiteSpace: 'normal' }}
          key={label}
          code={code}
          value={value}
        >
          {`${code ? `${code}\u00A0\u00A0` : ''}${label}`}
        </MenuItem>
      )),
    [dropdownItems, propWidth],
  );
  return (
    <FormControl sx={{ width: props?.dropdownWidth ?? WIDTH.s, ...props.sx }}>
      <SelectStyled
        disabled={props.disabled}
        labelId={id}
        id={id}
        value={value?.toString() ?? title}
        onChange={handleChange}
        displayEmpty
        sx={{
          minHeight: '40px',
          borderRadius: '2px',
          boxSizing: 'border-box',
          maxHeight: '40px',
        }}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: dropdownHeight ? '' : '500px',
            },
          },
        }}
      >
        {[placeHolderItem, ...renderItems]}
      </SelectStyled>
    </FormControl>
  );
};

export interface IDropdown extends IDropdownBase {
  label?: string;
  propWidth?: boolean;
  dropdownHeight?: boolean;
  onChange: (name: string, value: string) => void;
}

export const Dropdown = ({ title, id, dropdownItems, label, onChange, ...props }: IDropdown & { [k: string]: any }) => {
  useEffect(() => {
    if (props?.value) {
      onChange(id, props?.value);
    }
  }, []);

  return (
    <Box
      mb={props.mb ?? OFFSET.xs}
      mr={props?.mr}
      sx={{
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        minHeight: '40px',
        maxHeight: '40px',
        flex: '1 0 auto',
      }}
    >
      {label && <InputLabelStyled>{label}</InputLabelStyled>}
      <DropdownBase title={title} id={id} dropdownItems={dropdownItems} onChange={onChange} {...props} />
    </Box>
  );
};
