import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';

import { Box } from '@mui/system';
import { IconButton, Typography } from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

import { LabelLikeHintWithText } from '@/components/common/LabelLikeHintWithText';
import { useModalDelete } from '@/components/common/hooks/useModalDelete';
import { ModalDelete } from '@/components/common/ModalDelete';
import { LeftJoin, InnerJoin } from '@/assets';
import {
  MapAdministrationDataJoinToTableBody,
  mapAdministrationDataJoinToTableBody,
} from '@/stores/freskoApi/AdministrationDataObjectJoinStore';
import { useRootStore } from '@/stores';
import { color } from '@/constants/colors';

type ConnectionItemProps = {
  selected: boolean;
  item: any;
  handleOpenDeleteModal: (val: any) => void;
  handleSelectItem: (val: any) => void;
  isFirst: boolean;
};

type Props = {
  setSelectedConnection: (selectConnection: MapAdministrationDataJoinToTableBody[0]) => void;
};

export const AdministrationDataDetailTabConnectionsConnectionsList = observer(({ setSelectedConnection }: Props) => {
  const {
    open,
    handleClose,
    handleOpen,
    deletedValue: connection,
  } = useModalDelete<MapAdministrationDataJoinToTableBody[0]>();
  const { administrationDataObjectJoinStore } = useRootStore();
  const { id } = useParams<{ id: string }>();
  const [selectedConnectionLocal, setSelectedConnectionLocal] = useState<
    MapAdministrationDataJoinToTableBody[0] | undefined
  >();

  const handleChangeSelectConnection = useCallback(
    (val: MapAdministrationDataJoinToTableBody[0]) => {
      setSelectedConnection(val);
      setSelectedConnectionLocal(val);
    },
    [setSelectedConnection],
  );

  const connectionData = useMemo(
    () => mapAdministrationDataJoinToTableBody(administrationDataObjectJoinStore.dropdownJoinValuesByObjectId),
    [administrationDataObjectJoinStore.dropdownJoinValuesByObjectId],
  );

  useEffect(() => {
    if (!selectedConnectionLocal) {
      if (connectionData) {
        handleChangeSelectConnection(connectionData?.[0]);
      }
    }
  }, [connectionData, handleChangeSelectConnection, selectedConnectionLocal, setSelectedConnection]);

  const handleDelete = () => {
    if (connection && id) {
      administrationDataObjectJoinStore.deleteAdministrationDataObjectJoinById({
        id: connection.id,
        data_object_id: id,
      });
      handleClose();
    }
  };

  return (
    <>
      <Typography
        component="h2"
        variant="h6"
        color="primary"
        gutterBottom
        sx={{
          display: 'flex',
          justifyContent: 'center',
          fontStyle: 'normal',
          fontWeight: 700,
          fontSize: '14px',
          lineHeight: '16px',
          color: color.light_black,
          paddingBottom: '11px',
          paddingTop: '17px',
        }}
      >
        {'Соединение'}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {connectionData?.map((item, index) => (
          <ConnectionItems
            key={index}
            selected={item.id === selectedConnectionLocal?.id}
            isFirst={index === 0}
            item={item}
            handleOpenDeleteModal={handleOpen}
            handleSelectItem={handleChangeSelectConnection}
          />
        ))}
      </Box>
      <ModalDelete
        uniqId={'Data_table_modal'}
        open={open}
        handleClose={handleClose}
        handleDelete={handleDelete}
        title="Вы действительно хотите удалить соединение?"
        descriptions={[connection?.displayName ?? '', connection?.objectName ?? '', connection?.objectBdName ?? '']}
      />
    </>
  );
});

const ConnectionItems = ({ selected, item, isFirst, handleSelectItem, handleOpenDeleteModal }: ConnectionItemProps) => {
  const handleDelete = () => {
    handleOpenDeleteModal(item);
  };

  const onSelectItem = () => {
    handleSelectItem(item);
  };

  return (
    <Box
      sx={[
        {
          display: 'flex',
          width: '100%',
          padding: '15px',
          backgroundColor: selected ? color.light : color.transparent,
          cursor: 'pointer',
          borderTop: isFirst ? `1px solid ${color.pale_green}` : 'unset',
        },
        {
          '&:hover *': {
            visibility: 'visible',
          },
        },
      ]}
      onClick={onSelectItem}
    >
      <Box
        sx={{
          marginRight: '15px',
        }}
      >
        {item.connectionType === true ? <LeftJoin /> : <InnerJoin />}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flex: 1,
        }}
      >
        <LabelLikeHintWithText reverse title={`${item.objectBdName} / ${item.objectName}`} value={item.displayName} />
      </Box>
      <Box sx={[{ visibility: 'collapse' }]}>
        <IconButton color="primary" aria-label="удалить" component="label" onClick={handleDelete}>
          <DeleteOutlinedIcon color="error" />
        </IconButton>
      </Box>
    </Box>
  );
};
