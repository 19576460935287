import React, { useState, useMemo, useEffect } from 'react';
import { observer } from 'mobx-react';

import { Box, Checkbox, FormControlLabel } from '@mui/material';

import { useRootStore } from '@/stores';
import { DrawerLikeModal } from '@/components/common/DrawerLikeModal';
import { TemplateInputWithLabel } from '@/components/atoms/TemplateInputWithLabel';
import { WIDTH } from '@/constants/sizes';
import { Dropdown } from '@/components/atoms/Dropdown';
import type { ITableBodyEmployee } from '@/stores/EmployeesStore';
import { useValidation } from '@/hooks/useValidation';
import { ROLE } from '@/constants';

const templateInputWithLabelListBeforeDropDown = (selectedEmployee?: ITableBodyEmployee) => [
  {
    id: 'userName',
    label: 'Имя пользователя ⃰',
    required: true,
    autoComplete: 'nope',
    value: selectedEmployee?.nickName,
  },
  {
    id: 'firstName',
    placeholder: 'Не обязательно',
    label: 'Имя',
    autoComplete: 'nope',
    value: selectedEmployee?.firstName,
  },
  {
    id: 'lastName',
    placeholder: 'Не обязательно',
    label: 'Фамилия',
    autoComplete: 'nope',
    value: selectedEmployee?.lastName,
  },
  {
    id: 'secondName',
    placeholder: 'Не обязательно',
    label: 'Отчество',
    value: selectedEmployee?.middleName,
  },
];

const templateInputWithLabelListAfterDropDown = (selectedEmployee?: ITableBodyEmployee) => [
  {
    id: 'phone',
    label: 'Телефон',
    required: true,
    value: selectedEmployee?.phone,
  },
  {
    id: 'email',
    placeholder: 'Не обязательно',
    label: 'E-mail',
    value: selectedEmployee?.email,
  },
  {
    id: 'password',
    placeholder: 'Не обязательно',
    label: 'Изменить пароль',
  },
];

export const EmployeesEditModal = observer(
  ({
    selectedEmployee,
    handleOpen,
    open,
  }: {
    selectedEmployee?: ITableBodyEmployee;
    handleOpen: () => void;
    open: boolean;
  }) => {
    const { positionStore, employeesStore, isLoading, employeeAsUserStore, businessUnitStore, userStore } =
      useRootStore();
    const { setFormHasError, value, onChangeDropdown, formHasErrorRaw, isError } = useValidation();
    const [checkboxState, setCheckboxState] = useState(Boolean(selectedEmployee?.primary));

    const onChangeCheckbox = () => {
      setCheckboxState((checked) => !checked);
    };

    useEffect(() => {
      setCheckboxState(Boolean(selectedEmployee?.primary));
    }, [selectedEmployee]);

    const dropdownItemsPos = useMemo(() => {
      if (positionStore.allPositions) {
        return positionStore.allPositions.map(({ id, name, bu }) => ({
          label: `${name} - ${businessUnitStore?.allBusinessUnits?.find((some) => some.id === bu)?.name}`,
          value: id,
        }));
      }

      return [];
    }, [businessUnitStore?.allBusinessUnits, positionStore.allPositions]);

    const handleSaveForm = async (event: React.FormEvent<HTMLFormElement>) => {
      const data = new FormData(event.currentTarget);

      const userName = data.get('userName') as string;
      const lastName = data.get('lastName') as string;
      const firstName = data.get('firstName') as string;
      const secondName = data.get('secondName') as string;
      const phone = data.get('phone') as string;
      const email = data.get('email') as string;
      const password = data.get('password') as string | undefined;
      const postn = value.postn as number;
      const superVisor = checkboxState;
      const position = positionStore.allPositions?.find((item) => item.id === postn);
      const view_set = value.view_set as string;

      if (userName && phone && selectedEmployee?.id) {
        employeesStore
          .updateEmployee(selectedEmployee.id, {
            userName,
            lastName,
            firstName,
            middleName: secondName,
            email,
            role: null,
            phone,
            isStaff: superVisor,
            password,
            view_set,
          })
          .then(() => {
            if (position) {
              const edit = employeeAsUserStore.allEmployeeAsUsers?.find((some) => some.user === selectedEmployee.id);
              if (edit) {
                employeeAsUserStore.editEmployeeAsUser({
                  id: selectedEmployee.id,
                  user_id: +selectedEmployee.id,
                  employee_first_name: firstName,
                  employee_last_name: lastName,
                  employee_email: email,
                  postn_id: position.id,
                  bu_id: position.bu,
                });
              } else {
                employeeAsUserStore.createEmployeeAsUser({
                  user_id: +selectedEmployee.id,
                  employee_first_name: firstName,
                  employee_last_name: lastName,
                  employee_email: email,
                  postn_id: position.id,
                  bu_id: position.bu,
                });
              }
            }
          });
        handleOpen();
      }
    };

    return (
      <>
        <DrawerLikeModal
          title="Редактировать"
          open={open}
          toggleOpen={handleOpen}
          saveForm={handleSaveForm}
          buttonConfirmText="Сохранить"
          isDisabled={isLoading || isError}
        >
          {templateInputWithLabelListBeforeDropDown(selectedEmployee)?.map(({ id, placeholder, label, ...other }) => (
            <TemplateInputWithLabel
              handleGlobalError={setFormHasError}
              key={id}
              id={id}
              isError={formHasErrorRaw?.[id] ?? false}
              name={id}
              placeholder={placeholder}
              label={label}
              style={{ width: WIDTH.s }}
              {...other}
            />
          ))}
          <Dropdown
            handleGlobalError={setFormHasError}
            onChange={onChangeDropdown}
            key="postn"
            isError={formHasErrorRaw?.postn ?? false}
            value={employeeAsUserStore.employeeAsUsersById(selectedEmployee?.id)?.postn}
            label="Должность"
            title="Должность"
            id="postn"
            dropdownItems={dropdownItemsPos}
            required
          />

          <Dropdown
            handleGlobalError={setFormHasError}
            onChange={onChangeDropdown}
            key="view_set"
            isError={formHasErrorRaw?.view_set ?? false}
            label="Интерфейс"
            title="Интерфейс"
            id="view_set"
            value={selectedEmployee?.view_set}
            disabled={!userStore?.profileInfo?.is_staff}
            dropdownItems={[
              {
                label: 'Админ',
                value: ROLE.ADMIN,
              },
              {
                label: 'Стандартный',
                value: ROLE.GENERAL,
              },
              {
                label: 'Смартфакт',
                value: ROLE.SMARTFACT,
              },
              {
                label: 'Балтийский лизинг',
                value: ROLE.BALTICLEASING,
              },
              {
                label: 'ПСБ Лизинг',
                value: ROLE.PSBL,
              },
            ]}
            required
          />

          {templateInputWithLabelListAfterDropDown(selectedEmployee)?.map(({ id, placeholder, label, ...other }) => (
            <TemplateInputWithLabel
              handleGlobalError={setFormHasError}
              key={id}
              id={id}
              name={id}
              isError={formHasErrorRaw?.[id] ?? false}
              placeholder={placeholder}
              label={label}
              style={{ width: WIDTH.s }}
              {...other}
            />
          ))}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
            }}
          >
            <Box />
            <Box sx={{ width: WIDTH.s }}>
              <FormControlLabel
                control={<Checkbox onChange={onChangeCheckbox} checked={checkboxState} />}
                label="Суперпользователь"
              />
            </Box>
          </Box>
        </DrawerLikeModal>
      </>
    );
  },
);
