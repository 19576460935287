import { action, makeObservable, observable } from 'mobx';

import { AuthStore } from '@/stores/AuthStore';
import { ContragentsStore } from '@/stores/ContragentsStore';
import { ContragentReportStore } from '@/stores/ContragentReportStore';
import { ContragentReportBalancePeriodStore } from '@/stores/ContragentReportBalancePeriodStore';
import { ContragentReportTableFinancialResults } from '@/stores/ContragentReportTableFinancialResults';
import { ContragentReportTableBalanceStore } from '@/stores/ContragentReportTableBalanceStore';
import { ContragentReportTablePerQuarter } from '@/stores/ContragentReportTablePerQuarter';
import { ContragentReportTableLTM } from '@/stores/ContragentReportTableLTM';
import { UserStore } from '@/stores/UserStore';
import { UiStateStore } from '@/stores/UiStateStore';
import { ApplicationsStore } from '@/stores/ApplicationsStore';
import { ContactPersonsStore } from '@/stores/ContactPersonsStore';
import { ApplicationsPageDetailsStore } from '@/stores/ApplicationsPageDetailsStore';
import { EmployeesStore } from '@/stores/EmployeesStore';
import { ScoringPageStore } from '@/stores/ScoringPageStore';
import { CompanyGroupsStore } from '@/stores/CompanyGroupStore';
import { AdministrationDataStore } from '@/stores/freskoApi/AdministrationDataStore';
import { AdministrationAssessmentTemplateStore } from '@/stores/freskoApi/AdministrationAssessmentTemplateStore';
import { AdministrationDataAttributeStore } from '@/stores/freskoApi/AdministrationDataAttributeStore';
import { AdministrationDataObjectJoinStore } from '@/stores/freskoApi/AdministrationDataObjectJoinStore';
import { AdministrationAssessmentStore } from '@/stores/freskoApi/AdministrationAssessmentStore';
import { ProductsStore } from '@/stores/ProductsStore';
import { AccountsDebtorsLimitsStore } from '@/stores/1c/AccountsDebtorsLimitsStore';
import { BusinessUnitStore } from '@/stores/BusinessUnitStore';
import { PositionStore } from '@/stores/PositionStore';
import { CoverageTeamStore } from '@/stores/CoverageTeamStore';
import { EmployeeAsUserStore } from '@/stores/EmployeeAsUserStore';
import { AppSalesRepStore } from '@/stores/AppSalesRepStore';
import { MonitoringStore } from '@/stores/MonitoringStore';

export class RootStore {
  authStore: AuthStore;

  userStore: UserStore;

  contragentsStore: ContragentsStore;

  uiStateStore: UiStateStore;

  monitoringStore: MonitoringStore;

  applicationsStore: ApplicationsStore;

  applicationsPageDetailsStore: ApplicationsPageDetailsStore;

  contactPersonsStore: ContactPersonsStore;

  employeesStore: EmployeesStore;

  scoringPageStore: ScoringPageStore;

  contragentReportStore: ContragentReportStore;

  contragentReportTableBalanceStore: ContragentReportTableBalanceStore;

  contragentReportTablePerQuarter: ContragentReportTablePerQuarter;

  contragentReportTableLTM: ContragentReportTableLTM;

  contragentReportBalancePeriodStore: ContragentReportBalancePeriodStore;

  contragentReportTableFinancialResults: ContragentReportTableFinancialResults;

  companyGroupsStore: CompanyGroupsStore;

  administrationDataStore: AdministrationDataStore;

  administrationAssessmentTemplateStore: AdministrationAssessmentTemplateStore;

  administrationDataAttributeStore: AdministrationDataAttributeStore;

  administrationDataObjectJoinStore: AdministrationDataObjectJoinStore;

  administrationAssessmentStore: AdministrationAssessmentStore;

  productsStore: ProductsStore;

  accountsDebtorsLimitsStore: AccountsDebtorsLimitsStore;

  businessUnitStore: BusinessUnitStore;

  positionStore: PositionStore;

  coverageTeamStore: CoverageTeamStore;

  appSalesRepStore: AppSalesRepStore;

  employeeAsUserStore: EmployeeAsUserStore;

  @observable
  public isLoading: boolean = false;

  @observable
  public requestError?: string;

  @observable
  public requestSuccess?: string;

  @observable
  public localWarning?: string;

  @action
  public setIsLoading = (val: boolean) => (this.isLoading = val);

  @action
  public setRequestError = (val?: string) => (this.requestError = val);

  @action
  public setRequestSuccess = (val?: string) => (this.requestSuccess = val);

  @action
  public setLocalWarning = (val?: string) => (this.localWarning = val);

  @action
  public requestTemplate = async ({
    callback,
    errorMessage,
    catchAction,
    finallyAction,
    immediately,
    checkErrorBeforeFinal = true,
    isReturned,
    successMessage,
  }: {
    callback: (...args: any[]) => Promise<any>;
    errorMessage: string;
    catchAction?: () => void;
    finallyAction?: (() => void | Promise<void>) | Array<() => void | Promise<void>>;
    immediately?: boolean;
    checkErrorBeforeFinal?: boolean;
    isReturned?: boolean;
    successMessage?: string;
  }) => {
    let returnedVal;
    try {
      if (!this.isLoading || immediately) {
        this.setIsLoading(true);
        this.setRequestError(undefined);
        if (isReturned) {
          returnedVal = await callback();
        } else {
          await callback();
        }
        if (!this.requestError) {
          this.setRequestSuccess(successMessage);
        }
      }
    } catch (e: any) {
      catchAction && catchAction();
      this.setRequestError(e?.response?.data?.error_msg ?? e?.response?.data?.detail ?? errorMessage);
    } finally {
      this.setIsLoading(false);
      const isHasErrorInTry = checkErrorBeforeFinal ? Boolean(this.requestError) : false;

      if (!isHasErrorInTry) {
        if (finallyAction) {
          if (Array.isArray(finallyAction)) {
            for (const action of finallyAction) {
              try {
                await action();
              } catch (e) {
                console.error('Error in finallyAction:', e);
              }
            }
          } else {
            await finallyAction();
          }
        }

        if (isReturned) {
          return returnedVal;
        }
      }
    }
  };

  constructor() {
    makeObservable(this);

    this.authStore = new AuthStore(this);
    this.userStore = new UserStore(this);
    this.contragentsStore = new ContragentsStore(this);
    this.contragentReportStore = new ContragentReportStore(this);
    this.contragentReportTableBalanceStore = new ContragentReportTableBalanceStore(this);
    this.contragentReportBalancePeriodStore = new ContragentReportBalancePeriodStore(this);
    this.contragentReportTableFinancialResults = new ContragentReportTableFinancialResults(this);
    this.contragentReportTablePerQuarter = new ContragentReportTablePerQuarter(this);
    this.contragentReportTableLTM = new ContragentReportTableLTM(this);
    this.uiStateStore = new UiStateStore();
    this.applicationsStore = new ApplicationsStore(this);
    this.monitoringStore = new MonitoringStore(this);
    this.contactPersonsStore = new ContactPersonsStore(this);
    this.applicationsPageDetailsStore = new ApplicationsPageDetailsStore(this);
    this.employeesStore = new EmployeesStore(this);
    this.scoringPageStore = new ScoringPageStore(this);
    this.companyGroupsStore = new CompanyGroupsStore(this);
    this.administrationDataStore = new AdministrationDataStore(this);
    this.administrationAssessmentTemplateStore = new AdministrationAssessmentTemplateStore(this);
    this.administrationDataAttributeStore = new AdministrationDataAttributeStore(this);
    this.administrationDataObjectJoinStore = new AdministrationDataObjectJoinStore(this);
    this.administrationAssessmentStore = new AdministrationAssessmentStore(this);
    this.productsStore = new ProductsStore(this);
    this.accountsDebtorsLimitsStore = new AccountsDebtorsLimitsStore(this);
    this.businessUnitStore = new BusinessUnitStore(this);
    this.positionStore = new PositionStore(this);
    this.coverageTeamStore = new CoverageTeamStore(this);
    this.appSalesRepStore = new AppSalesRepStore(this);
    this.employeeAsUserStore = new EmployeeAsUserStore(this);
  }
}
