import React from 'react';
import { observer } from 'mobx-react';

import Box from '@mui/material/Box';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';

import { TableTemplate } from '@/components/Templates/TableTemplate';
import { TableRowStyled, TableCellStyled } from '@/components/Templates/TableComponentStyled';
import type { EmployeesPageStoreStoreWithTableStore, ITableBodyEmployee } from '@/stores/EmployeesStore';
import { LabelLikeHintWithText } from '@/components/common/LabelLikeHintWithText';
import { ModalDelete } from '@/components/common/ModalDelete';
import { useModalDelete } from '@/components/common/hooks/useModalDelete';
import { MoreMenuTabData } from '@/components/Administering/AdministrationTabData/MoreMenuTabData';
import { useRootStore } from '@/stores';
import { color } from '@/constants/colors';

const tableHeader = ['Имя пользователя', 'Имя', 'Телефон', 'Email', 'Должность', ''];

const RenderBodyAnalysisTable = observer(
  ({
    row,
    filled,
    openModal,
    setSelectedEmployee,
  }: {
    row: ITableBodyEmployee;
    filled: boolean;
    openModal: (id: ITableBodyEmployee) => void;
    setSelectedEmployee: (val?: ITableBodyEmployee) => void;
  }) => {
    const { positionStore, employeeAsUserStore, businessUnitStore, userStore } = useRootStore();

    const handleDelete = () => {
      openModal(row);
    };

    const handleEdit = () => {
      setSelectedEmployee(row);
    };

    const user = employeeAsUserStore?.allEmployeeAsUsers?.find((some) => some.user === row.id);
    const position = positionStore?.allPositions?.find((some) => some.id === user?.postn);
    const businessUnit = businessUnitStore?.allBusinessUnits?.find((some) => some.id === position?.bu);

    return (
      <TableRowStyled key={row.id} sx={{ backgroundColor: filled ? color.light : color.transparent }}>
        <TableCellStyled>
          <Box sx={{ display: 'flex' }}>
            {row.primary && <StarBorderOutlinedIcon sx={{ mr: '12px', color: color.light_green }} />}
            {row.nickName}
          </Box>
        </TableCellStyled>
        <TableCellStyled>
          <LabelLikeHintWithText title={row.lastName} value={row.firstName} reverse />
        </TableCellStyled>
        <TableCellStyled>{row.phone}</TableCellStyled>
        <TableCellStyled>{row.email}</TableCellStyled>
        <TableCellStyled>
          <LabelLikeHintWithText reverse title={businessUnit?.name} value={position?.name} />
        </TableCellStyled>
        <TableCellStyled align="right">
          {userStore?.profileInfo?.is_staff && <MoreMenuTabData onDelete={handleDelete} onEdit={handleEdit} />}
        </TableCellStyled>
      </TableRowStyled>
    );
  },
);

export const EmployeesTable = observer(
  ({
    store,
    isLoading,
    setSelectedEmployee,
  }: {
    store: EmployeesPageStoreStoreWithTableStore<ITableBodyEmployee>;
    isLoading: boolean;
    setSelectedEmployee: (val?: ITableBodyEmployee) => void;
  }) => {
    const { employeeAsUserStore } = useRootStore();
    const { open, handleClose, handleOpen, deletedValue: deletedEmployee } = useModalDelete<ITableBodyEmployee>();

    const handleDelete = () => {
      if (deletedEmployee?.id) {
        store.deleteEmployees({ id: deletedEmployee.id });
        try {
          employeeAsUserStore.deleteEmployeeAsUser({ id: deletedEmployee.id });
        } catch {
          // noop
        } finally {
          handleClose();
        }
      }
    };

    return (
      <>
        <TableTemplate<ITableBodyEmployee>
          tableHeader={tableHeader}
          RenderComponent={(props) => (
            <RenderBodyAnalysisTable {...props} openModal={handleOpen} setSelectedEmployee={setSelectedEmployee} />
          )}
          store={store}
          isLoading={isLoading}
          isHidePagination
        />
        <ModalDelete
          uniqId="Employee_delete_modal"
          open={open}
          handleClose={handleClose}
          handleDelete={handleDelete}
          title="Вы действительно хотите удалить пользователя?"
          descriptions={[`${deletedEmployee?.firstName} ${deletedEmployee?.lastName}`, deletedEmployee?.nickName ?? '']}
        />
      </>
    );
  },
);
