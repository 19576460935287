/* eslint-disable camelcase */
import { observable, makeObservable, action, computed, runInAction } from 'mobx';
import { apiPath } from '@/constants/api';
import type { APP_SR_ROLE_TO_ROLE } from '@/constants/backendEnum';
import { APP_SR_ROLE } from '@/constants/backendEnum';
import { createAxiosRequest } from '@/utils/request';
import type { IUserStore, IRootStore } from '@/stores/type';

export type IProfile = {
  pk: number;
  username: string;
  last_name?: string;
  first_name?: string;
  middle_name?: string;
  email?: string;
  url: string;
  password: string;
  role?: string;
  phone?: string;
  is_staff?: string;
  view_set: string | null;
  postn_id: number;
  company_name?: string;
  employee?: string;
};

export type IDictionary = {
  id: 1;
  val_type: string;
  lang_code: string;
  lang: 'RUS' | 'ENG';
  display_value: string;
};

export type IResponse<T> = {
  count: number;
  next?: string;
  previous?: string;
  results: T[];
};

export class UserStore implements IUserStore {
  private rootStore: IRootStore;

  @observable
  public loading: boolean = false;

  @observable
  public results: IProfile;

  @observable
  private loadingListOfVal: boolean = false;

  @observable
  public email?: string;

  @observable
  public login?: string;

  @observable
  public profileInfo?: IProfile;

  @observable
  public dictionary: IDictionary[];

  constructor(rootStore: IRootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  @action
  getFullName = () =>
    `${this.profileInfo?.first_name ?? ''} ${this.profileInfo?.middle_name ?? ''} ${this.profileInfo?.last_name ?? ''}`
      .replace('  ', ' ')
      .trim();

  @action
  getProfileInfo = async () => {
    if (!this.loading) {
      this.rootStore.requestTemplate({
        errorMessage: 'Ошибка получения данных пользователя',
        immediately: !this.profileInfo && !this.loading,
        callback: async () => {
          try {
            runInAction(() => {
              this.loading = true;
            });

            const { data } = await createAxiosRequest<unknown, IProfile>({
              path: apiPath.profile,
              method: 'GET',
              useToken: true,
            });

            runInAction(() => {
              if (APP_SR_ROLE?.[data?.role as unknown as keyof typeof APP_SR_ROLE_TO_ROLE] || data?.role === null) {
                this.profileInfo = data;
              } else {
                this.clear();
                this.rootStore?.authStore
                    ?.logout()
                    .then(() => this.rootStore.setRequestError('Не верное имя пользователя или пароль'));
              }
              this.results = data;
            });
          } catch {
            // noop
          } finally {
            runInAction(() => {
              this.loading = false;
            });
          }
        },
      });
    }
  };

  @action
  geListOfVal = async () => {
    if (!this.loadingListOfVal) {
      this.rootStore.requestTemplate({
        errorMessage: 'Ошибка получения словаря',
        immediately: !this.dictionary,
        callback: async () => {
          try {
            this.loadingListOfVal = true;

            const { data } = await createAxiosRequest<{ limit: number; offset: number }, IResponse<IDictionary>>({
              path: apiPath.listOfVal,
              method: 'GET',
              params: { limit: 500, offset: 0 },
              useToken: true,
            });
            runInAction(() => {
              this.dictionary = data?.results;
            })
          } catch {
            // noop
          } finally {
            this.loadingListOfVal = false;
          }
        },
      });
    }
  };

  @action
  clear = () => {
    this.profileInfo = undefined;
    this.login = undefined;
    this.email = undefined;
  };

  @computed
  public get dictForScoreSetting() {
    const filtered = this.dictionary?.filter((item) => item.lang === 'RUS' && item.val_type === 'SCO_PAR_NAME');
    const result: { [k: string]: string } = {};
    filtered?.map((item) => (result[item.lang_code] = item.display_value));

    return result;
  }
}
