import React, { useEffect, useLayoutEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';

import { Checkbox } from '@mui/material';

import { TableTemplate } from '@/components/Templates/TableTemplate';
import type { TableBodyContragents } from '@/stores/ContragentsStore';
import { useRootStore } from '@/stores';
import { TableRowStyled, TableCellStyled } from '@/components/Templates/TableComponentStyled';
import { color } from '@/constants/colors';
import { PROBLEM_LEVEL, USER_COMPANY_GROUPS } from '@/constants/backendEnum';
import { SelectStyled, nonClickableTags } from '../atoms/Select/SelectStyled';
import { swopKeysAndValues, transformNamesFromArr } from '@/utils';

export type ITable = {
  tableRow: any[];
  tableCell: any[];
};

const selectTitle = (
  <>
    Выберите зону <br /> проблемности
  </>
);

const tableHeader = [
  'Наименование',
  'ИНН',
  'Лимит',
  'Загрузка',
  'ГК Контрагента',
  'Статус в нашей ГК',
  'Зона проблемности',
];
const tableHeaderWidth = ['25%', '10%', '10%', '10%', '17%', '17%', '11%'];

const RenderBodyContragentsTable = ({ row, filled }: { row: TableBodyContragents; filled: boolean }) => {
  const [problemLevel, setProblemLevel] = useState<string>();
  const { contragentsStore } = useRootStore();
  const navigate = useNavigate();

  const statusOurCompanyGroups = transformNamesFromArr(row.userCompanyGroups, USER_COMPANY_GROUPS).join(', ');

  const onClick: React.MouseEventHandler<HTMLTableRowElement> = (
    e: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
  ) => {
    if (e.target instanceof HTMLElement && nonClickableTags.includes(e.target.tagName)) {
      return;
    }
    if (navigate) {
      navigate(`${row.id}`);
    }

    contragentsStore.getAccountById(`${row.id}`);
  };

  const handleSelectChange = (value: string | undefined) => {
    setProblemLevel(value);
  };

  useEffect(() => {
    const colorKey = swopKeysAndValues(PROBLEM_LEVEL);

    if (colorKey && problemLevel) {
      contragentsStore.patchAccountById({
        id: `${row.id}`,
        isContragentPage: true,
        patchParameters: { problem_level: colorKey[problemLevel] },
      });
    }
  }, [problemLevel]);

  return (
    <TableRowStyled key={row.id} sx={{ backgroundColor: filled ? color.light : color.transparent }} onClick={onClick}>
      <TableCellStyled>{row.name}</TableCellStyled>
      <TableCellStyled>{row.inn}</TableCellStyled>
      <TableCellStyled>
        <Checkbox checked={row.isHasLimit} disabled />
      </TableCellStyled>
      <TableCellStyled>
        <Checkbox checked={row.isHasLoad} disabled />
      </TableCellStyled>
      <TableCellStyled>{row.companyGroupName}</TableCellStyled>
      <TableCellStyled>{statusOurCompanyGroups || '-'}</TableCellStyled>
      <TableCellStyled>
        <SelectStyled
          defValue={PROBLEM_LEVEL[row.problemLevel as keyof typeof PROBLEM_LEVEL]}
          items={PROBLEM_LEVEL}
          title={selectTitle}
          onChange={handleSelectChange}
        />
      </TableCellStyled>
    </TableRowStyled>
  );
};

export const ContragentsTable = observer(() => {
  const { contragentsStore, isLoading } = useRootStore();

  useLayoutEffect(() => {
    if (!contragentsStore.renderedRows?.length || contragentsStore.renderedRows?.length === 0) {
      contragentsStore.getAccounts();
    }
  }, []);

  return (
    <TableTemplate<TableBodyContragents>
      tableHeader={tableHeader}
      tableHeaderWidth={tableHeaderWidth}
      RenderComponent={(props) => <RenderBodyContragentsTable {...props} />}
      store={contragentsStore}
      isLoading={isLoading}
    />
  );
});
