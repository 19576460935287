import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useRootStore } from '@/stores';
import { DrawerLikeModal } from '@/components/common/DrawerLikeModal';
import { WIDTH } from '@/constants/sizes';
import { TemplateInputWithLabel, TemplateWithLabel } from '@/components/atoms/TemplateInputWithLabel';
import { AutocompleteInn } from '@/components/atoms/AutocompleteInn';
import TabStyled from '@/components/atoms/TabStyled';

type ApplicationAddConditionModalGuarantorsProps = {
  openAdd: boolean;
  toggleOpen: () => void;
};
interface Item {
  [name: string]: any;
}

const templateInputWithLabelList = [
  {
    id: 'lastName',
    label: 'ФИО',
    required: true,
  },
  {
    id: 'inn',
    required: true,
    label: 'ИНН',
  },
];
const templateInnList = [
  {
    id: 'inn',
    required: true,
    label: 'ИНН',
  },
];

export const ApplicationAddConditionModalGuarantors = observer(
  ({ openAdd, toggleOpen }: ApplicationAddConditionModalGuarantorsProps) => {
    const { isLoading, applicationsPageDetailsStore } = useRootStore();
    const { id } = useParams<{ id: string }>();
    const [values, setValues] = useState<Item>({});
    const [isIndividual, setIsIndividual] = React.useState(0);
    const [formHasErrorRaw, setFormHasErrorRaw] = useState<Record<string, boolean>>({});
    const [isError, setIsError] = useState<boolean>(false);

    const setFormHasError = (val: any) => {
      setFormHasErrorRaw((formHasErrorRaw) => ({ ...(formHasErrorRaw ?? {}), ...val }));
    };

    const handleSaveForm = async (event: React.FormEvent<HTMLFormElement>) => {
      if (id && values)
        applicationsPageDetailsStore.createGuarantorsByApplicationId({
          applicationId: id,
          inn: Number(values.inn),
          fio: values.lastName,
        });
      toggleOpen();
      setValues({});
    };

    useEffect(() => {
      if (!openAdd) {
        setValues({});
      }
    }, [openAdd]);

    const handleChange = (event: React.SyntheticEvent, newValue: number): void => {
      setValues({});
      setIsIndividual(newValue);
    };

    useEffect(() => {
      setIsError(Object.values(formHasErrorRaw).some((item) => item));
    }, [formHasErrorRaw]);

    const isFormDisabled = isError || Object.keys(values).length === 0 || values.inn === '';

    return (
      <>
        <DrawerLikeModal
          title="Добавить поручителя"
          open={openAdd}
          toggleOpen={toggleOpen}
          saveForm={handleSaveForm}
          buttonConfirmText="Создать поручителя"
          isDisabled={isFormDisabled}
          isLoading={isLoading}
        >
          <TabStyled value={isIndividual} onChange={handleChange} />
          {isIndividual === 0
            ? templateInputWithLabelList.map(({ label, id, required, ...props }) => (
                <TemplateInputWithLabel
                  handleGlobalError={setFormHasError}
                  key={id}
                  id={id}
                  name={id}
                  value={values?.[id]}
                  handleChange={(e: any) => setValues((prev) => ({ ...prev, ...e }))}
                  label={label}
                  required={required}
                  {...props}
                  sx={{ minWidth: WIDTH.s, margin: 0 }}
                />
              ))
            : templateInnList.map(({ id, required, label }) => (
                <TemplateWithLabel key={id} id={id} required={required} name={id} label={label}>
                  <AutocompleteInn
                    sx={{ minWidth: WIDTH.s, margin: 0 }}
                    onChange={(value: any) => {
                      if (value && value.data) {
                        setValues((prev) => ({ ...prev, [id]: value.data.inn }));
                      } else {
                        setValues((prev) => ({ ...prev, [id]: '' }));
                      }
                    }}
                  />
                </TemplateWithLabel>
              ))}
        </DrawerLikeModal>
      </>
    );
  },
);
