import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Reorder } from 'framer-motion';
import style from '@/components/Administering/ModelEvaluation/ModelEvaluationDetail/ModelEvaluationDetailTab/ModelEvaluationDetailTabAttributes/RenderRowTabAttributesTable.module.scss';

import { Autocomplete, Box, IconButton } from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import { useModalDelete } from '@/components/common/hooks/useModalDelete';
import { LabelLikeHintWithText } from '@/components/common/LabelLikeHintWithText';
import { ModalDelete } from '@/components/common/ModalDelete';
import { TableTemplateWithoutStoreAttribute } from '@/components/Templates/TableTemplateWithoutStoreAttribute';
import { ButtonStyled } from '@/components/atoms/Button';
import { Badge, BadgeType } from '@/components/atoms/Bage/Badge';
import { useRootStore } from '@/stores';
import { DrawerLikeModal } from '@/components/common/DrawerLikeModal';
import { Dropdown } from '@/components/atoms/Dropdown';
import { useValidation } from '@/hooks/useValidation';
import { TYPE_PARAMS_TO_DISPLAYNAME } from './ModelEvaluationAttributeTabParams';
import { TYPE_PARAMS } from '@/constants';
import { TemplateInputWithLabel } from '@/components/atoms/TemplateInputWithLabel';
import { OFFSET, WIDTH } from '@/constants/sizes';
import { VALUE_TYPE } from '@/constants/backendEnum';
import { mathRoundTwoDecimals } from '@/utils';
import type { AssessmentTemplateAttributeModel } from '@/stores/Models/AdministrationAssessmentTemplateAttributeModel';
import type { AssessmentTemplateAttributeModelStore } from '@/stores/Models/AssessmentTemplateAttributeModelStore';
import { TextFieldStyled } from '@/components/atoms/TextFieldStyled';
import { InputLabelStyled } from '@/components/atoms/InputLabelStyled';
import { color } from '@/constants/colors';

const likeTableHeader = ['#', 'Название', 'Вес', ''];

type Props = {
  setInfo: (selectAttribute: AssessmentTemplateAttributeModel | undefined) => void;
  attributeSet: AssessmentTemplateAttributeModel[];
  store: AssessmentTemplateAttributeModelStore;
  isStatusDisabled: boolean;
};

type AttributeItemProps = {
  item?: any;
  row?: AssessmentTemplateAttributeModel;
  handleOpenDeleteModal: (val: AssessmentTemplateAttributeModel) => void;
  handleSelectItem: (val: AssessmentTemplateAttributeModel) => void;
  filled?: boolean;
  attributeSet: AssessmentTemplateAttributeModel[];
  selectedItem?: AssessmentTemplateAttributeModel;
  refs: Record<string, React.RefObject<HTMLTableRowElement>>;
  index: number;
  isLast?: boolean;
  isFirst?: boolean;
};

export const ModelEvaluationAttributeTabDataList = ({ setInfo, attributeSet, store, isStatusDisabled }: Props) => {
  const {
    open,
    handleClose,
    handleOpen,
    deletedValue: attribute,
  } = useModalDelete<AssessmentTemplateAttributeModel>(isStatusDisabled);
  const [selectedConnection, setSelectedConnection] = useState<AssessmentTemplateAttributeModel | undefined>(
    {} as AssessmentTemplateAttributeModel,
  );
  const { id: idData } = useParams<{ id: string }>();

  const refs = attributeSet.reduce<Record<string, React.RefObject<HTMLTableRowElement>>>((acc, value) => {
    acc[`${value.id}`] = React.createRef<HTMLTableRowElement>();
    return acc;
  }, {});

  const scrollToRow = (id?: number) => {
    if (id) {
      refs?.[`${id}`]?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  useEffect(() => {
    if (attributeSet && selectedConnection?.id) {
      const item = attributeSet.find(({ id }) => id === selectedConnection.id);
      if (item) {
        setSelectedConnection(item);
        setInfo(item);
        scrollToRow(item?.id);
      }
    } else if (attributeSet) {
      setSelectedConnection(attributeSet?.[0]);
      setInfo(attributeSet?.[0]);
      scrollToRow(attributeSet?.[0]?.id);
    }
  }, [attributeSet]);

  const handleChangeSelectAttribute = (val: AssessmentTemplateAttributeModel) => {
    setSelectedConnection(val);
    setInfo(val);
    scrollToRow(val?.id);
  };

  const handleDelete = () => {
    if (attribute && idData) {
      store.deleteTemplateAttribute({ pageId: idData, attributeId: attribute.id });
      setSelectedConnection(undefined);
      setInfo(undefined);
    }
    handleClose();
  };
  const [reorder, setReorder] = useState<AssessmentTemplateAttributeModel[]>(attributeSet);

  useEffect(() => {
    setReorder(attributeSet);
  }, [attributeSet]);
  const handleUpdateData = async (reorder: AssessmentTemplateAttributeModel[]) => {
    {
      if (idData && reorder) {
        reorder = reorder.map((item, index) => {
          item.sequence = index + 1;
          item._sequence = index + 1;
          return item;
        });
        await store.updateAssessmentTemplateAttributes({
          pageId: idData,
          data: reorder,
        });
      }
    }
  };

  return (
    <>
      <div
        style={{
          backgroundColor: color.white,
          width: '100%',
          maxHeight: 'calc(100vh - 471px)',
          overflow: 'auto',
        }}
      >
        <TableTemplateWithoutStoreAttribute<AssessmentTemplateAttributeModel>
          isHideBorders
          tableHeader={likeTableHeader}
          sxTableWrapper={{
            maxHeight: 'calc(100vh - 421px)',
            overflow: 'auto',
          }}
          RenderComponent={(props) => <></>}
          store={{
            renderedRows: attributeSet,
          }}
          isLoading={false}
        />

        <Reorder.Group
          as="div"
          axis="y"
          values={reorder}
          onReorder={(reorderedItems) => {
            setReorder(reorderedItems);
            handleUpdateData(reorderedItems);
          }}
        >
          {reorder.map((item, index) => {
            return (
              <Reorder.Item as="div" key={JSON.stringify(item)} value={item}>
                <RenderRowTabAttributesTable
                  key={item?.id ?? index}
                  row={item}
                  filled={index % 2 !== 0}
                  isLast={reorder?.length - 1 === index}
                  isFirst={index === 0}
                  index={index}
                  item={item}
                  handleOpenDeleteModal={handleOpen}
                  selectedItem={selectedConnection}
                  handleSelectItem={handleChangeSelectAttribute}
                  refs={refs}
                  attributeSet={attributeSet}
                />
              </Reorder.Item>
            );
          })}
        </Reorder.Group>
      </div>
      <Box mt={OFFSET.xxs}>
        <ModalAdd
          isStatusDisabled={isStatusDisabled}
          store={store}
          setSelectedConnection={setSelectedConnection}
          setInfo={setInfo}
        />
      </Box>
      <ModalDelete
        uniqId="Data_table_modal"
        open={open}
        handleClose={handleClose}
        handleDelete={handleDelete}
        title="Вы действительно хотите удалить атрибут?"
        descriptions={[attribute?.data_obj_attr_name ?? '', attribute?.sequence ?? '', attribute?.weight ?? '']}
      />
    </>
  );
};

const getBadgeStatus = (weight: number, needWeight: number) => {
  const percent = weight / needWeight;
  if (percent > 0.75) {
    return BadgeType.success;
  }

  if (percent > 0.5) {
    return BadgeType.preWarning;
  }

  return BadgeType.warning;
};

const RenderRowTabAttributesTable = ({
  selectedItem,
  row,
  handleOpenDeleteModal,
  handleSelectItem,
  refs,
  item,
  index,
}: AttributeItemProps) => {
  if (!row) {
    return <></>;
  }

  const handleDelete = (e: any) => {
    e.stopPropagation();
    handleOpenDeleteModal(row);
  };

  const onSelectItem = () => {
    handleSelectItem(row);
  };

  const hasWight = Boolean(row.weight) && row.sequence;

  const getColor = () => {
    let selectedColor;

    if (selectedItem === row) {
      selectedColor = color.light;
    }

    return selectedColor;
  };
  return (
    <>
      <div
        className={style.RenderRowTabAttributesTable}
        ref={refs[`${item.id}`]}
        key={`${item.id}_${item.sequence}`}
        style={{ backgroundColor: getColor() }}
        onClick={onSelectItem}
      >
        <div className={style.RenderRowTabAttributesTable__titleFirst}>
          <LabelLikeHintWithText title="" value={index + 1} />
        </div>
        <div className={style.RenderRowTabAttributesTable__titleSecond}>
          <LabelLikeHintWithText title="" value={item.name} />
        </div>
        <div className={style.RenderRowTabAttributesTable__titleThird}>
          {hasWight && <Badge type={getBadgeStatus(item.weight, 2)}>{mathRoundTwoDecimals(item.weight)}</Badge>}
        </div>
        <div className={style.RenderRowTabAttributesTable__titleFourth}>
          <Box sx={[{ opacity: '0', transition: 'opacity 0.15s ease-in-out' }]}>
            <IconButton color="primary" aria-label="удалить" component="label" onClick={handleDelete}>
              <DeleteOutlinedIcon color="error" />
            </IconButton>
          </Box>
        </div>
      </div>
    </>
  );
};

const templateInputJoinWithLabelList = [
  {
    id: 'name',
    label: 'Название',
    require: true,
  },
  {
    id: 'description',
    label: 'Описание',
  },
];

const ModalAdd = ({
  store,
  setInfo,
  setSelectedConnection,
  isStatusDisabled,
}: {
  store: AssessmentTemplateAttributeModelStore;
  setInfo: (selectAttribute: AssessmentTemplateAttributeModel | undefined) => void;
  setSelectedConnection: React.Dispatch<React.SetStateAction<AssessmentTemplateAttributeModel | undefined>>;
  isStatusDisabled: boolean;
}) => {
  const { isLoading, administrationAssessmentTemplateStore } = useRootStore();

  const { id: idData } = useParams<{ id: string }>();

  const [openAdd, setOpenAdd] = useState<boolean>(false);
  const { value, onChangeDropdown, handleChangeInput } = useValidation();

  const toggleOpen = () => {
    setOpenAdd(!openAdd);
    onChangeDropdown('data_obj_attr_name', '');
    handleChangeInput({ description: null });
  };

  const contextField = [
    { label: 'Вводится вручную', value: '' },
    ...administrationAssessmentTemplateStore.dataContext.map(({ name }) => ({ label: name, value: name })),
  ];

  const handleSaveForm = async (event: React.FormEvent<HTMLFormElement>) => {
    const data = new FormData(event.currentTarget);

    const sequence = Math.max(0, ...store.templateAttrByTemplateId.map((item) => item.sequence)) + 1;
    const name = data.get('name') as string;
    const description = data.get('description') as string;
    const value_type = value?.value_type;
    const { data_obj_attr_name } = value;

    if (idData) {
      store
        .addAssessmentTemplateAttribute({
          pageId: idData,
          data: {
            sequence: sequence ?? 1,
            asmnt_tmpl: Number.parseInt(idData, 10),
            is_boolean_score: false,
            value_type,
            out_of_range_bool_score: false,
            data_obj_attr_name,
            weight: 0.0,
            out_of_range_score: 0.0,
            description,
            name,
          },
        })
        .then((item) => {
          if (item) {
            setSelectedConnection(item);
            setInfo(item);
          }
        });
    }
    toggleOpen();
  };

  const initValAutocomplete = useMemo(() => contextField?.find((item) => item.value === ''), [contextField]);

  useEffect(() => {
    if (administrationAssessmentTemplateStore.dataContext) {
      const dataType = administrationAssessmentTemplateStore.dataContext.find(({ name }) => {
        if (value.data_obj_attr_name === '') {
          return VALUE_TYPE.Integer;
        }
        return name === value.data_obj_attr_name;
      });
      onChangeDropdown('value_type', VALUE_TYPE[dataType?.data_type as keyof typeof VALUE_TYPE] ?? VALUE_TYPE.Id);
      handleChangeInput({ name: contextField?.find((item) => item.value === value?.data_obj_attr_name ?? '')?.label });
    }
  }, [value.data_obj_attr_name]);

  const valueType = useMemo(
    () => (value.data_obj_attr_name === '' ? VALUE_TYPE.Integer : value?.value_type ?? TYPE_PARAMS.Helper),
    [value.data_obj_attr_name, value?.value_type],
  );

  return (
    <>
      <ButtonStyled
        text="добавить"
        startIcon={<AddCircleOutlineIcon />}
        disabled={isStatusDisabled || store.isLoadingPatch}
        variant="outlined"
        customType="success"
        onClick={toggleOpen}
      />
      <DrawerLikeModal
        title="Добавить атрибут"
        open={openAdd}
        toggleOpen={toggleOpen}
        saveForm={handleSaveForm}
        buttonConfirmText="Добавить атрибут"
        isDisabled={isStatusDisabled || isLoading}
      >
        <Box
          mb="16px"
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            minHeight: '40px',
            maxHeight: '40px',
            flex: '1 0 auto',
          }}
        >
          <InputLabelStyled>Данные</InputLabelStyled>
          <Box sx={{ width: '285px' }}>
            <AutocompleteData
              onChange={onChangeDropdown}
              dataForDropdown={contextField}
              initialValue={value.data_obj_attr_name ?? initValAutocomplete}
            />
          </Box>
        </Box>
        {templateInputJoinWithLabelList?.map(({ id, label, ...other }) => (
          <TemplateInputWithLabel
            forceSet
            isError={false}
            key={id}
            id={id}
            name={id}
            label={label}
            style={{ width: WIDTH.s }}
            autoComplete="off"
            value={value?.[id]}
            handleChange={handleChangeInput}
            {...other}
          />
        ))}

        <Dropdown
          mb={0}
          forceSet
          dropdownWidth="285px"
          onChange={onChangeDropdown}
          key="value_type"
          label="Тип значения"
          title=""
          id="value_type"
          value={valueType}
          dropdownItems={[
            {
              label: TYPE_PARAMS_TO_DISPLAYNAME.Interval,
              value: TYPE_PARAMS.Interval,
            },
            {
              label: TYPE_PARAMS_TO_DISPLAYNAME.Categorical,
              value: TYPE_PARAMS.Categorical,
            },
            {
              label: TYPE_PARAMS_TO_DISPLAYNAME.Helper,
              value: TYPE_PARAMS.Helper,
            },
            {
              label: TYPE_PARAMS_TO_DISPLAYNAME.Logical,
              value: TYPE_PARAMS.Logical,
            },
          ]}
        />
      </DrawerLikeModal>
    </>
  );
};

const AutocompleteData = React.memo(
  ({
    onChange,
    dataForDropdown,
    initialValue,
  }: {
    initialValue?: { label: string; value: string };
    onChange?: (name: string, value: string) => void;
    dataForDropdown?: { label: string; value: string }[];
  }) => {
    const handleSelectedValue = (event: any, newValue: { label: string; value: string } | null) => {
      if ((newValue?.value || newValue?.value === '') && onChange) {
        onChange('data_obj_attr_name', newValue.value);
      }
    };

    useEffect(() => {
      if (initialValue || initialValue === '') {
        if (typeof initialValue === 'string') {
          if (initialValue === '') {
            const lab = dataForDropdown?.find((item) => item.value === '')?.label;
            // @ts-ignore
            handleSelectedValue(undefined, { label: lab, value: '' });
            return;
          }
          handleSelectedValue(undefined, { label: initialValue, value: initialValue });
        } else {
          handleSelectedValue(undefined, initialValue);
        }
      }
    }, []);

    return (
      <Autocomplete
        options={dataForDropdown ?? []}
        openOnFocus
        autoComplete
        blurOnSelect
        id="data_obj_attr_name"
        defaultValue={{ label: 'Вводится вручную', value: '' }}
        onChange={handleSelectedValue}
        getOptionLabel={(option) => option.label}
        renderInput={(params) => <TextFieldStyled {...params} label="" sx={{ padding: 0 }} size="small" />}
      />
    );
  },
);
