import React, { useState } from 'react';

import { ITableBodyAdministrationData } from '@/stores/freskoApi/AdministrationDataStore';
import { AdministrationAddTabDataModal } from '@/components/Administering/AdministrationTabData/AdministrationAddTabDataModal';
import { AdministrationTabDataTable } from '@/components/Administering/AdministrationTabData/AdministrationTabDataTable';

export const AdministrationTabData = () => {
  const [data, setData] = useState<ITableBodyAdministrationData | undefined>(undefined);

  return (
    <>
      <AdministrationTabDataTable setEditData={setData}/>
      <AdministrationAddTabDataModal editData={data} setEditData={setData}/>
    </>
  );
}

AdministrationTabData.displayName = "AdministrationTabData"