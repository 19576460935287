import React from 'react';
import type { MouseEventHandler } from 'react';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import type { ButtonType } from '@/components/atoms/Button/constants';
import { ButtonColorStyledContained } from '@/components/atoms/Button/ButtonStyledContained';
import { ButtonColorStyledOutlined } from '@/components/atoms/Button/ButtonColorStyledOutlined';
import { color } from '@/constants/colors';

const VariantType = {
  text: 'text',
  outlined: 'outlined',
  contained: 'contained',
};

export type ButtonStyledType = {
  customType?: keyof typeof ButtonType;
  text?: string | JSX.Element;
  variant?: keyof typeof VariantType;
  disabled?: boolean;
  startIcon?: JSX.Element;
  height?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  isSubmit?: boolean;
  isFilter?: boolean;
  isLoading?: boolean;
  [k: string]: any;
};

export const ButtonStyled = ({
  customType,
  text,
  variant = 'contained',
  disabled = false,
  startIcon,
  height,
  onClick,
  isSubmit,
  isFilter,
  isLoading,
  ...others
}: ButtonStyledType) => {
  if (variant === 'contained') {
    return (
      <ButtonColorStyledContained
        {...others}
        props={{ customType: customType ?? 'default', height, isFilter }}
        disabled={disabled || isLoading}
        variant={variant}
        startIcon={isLoading ? <CircularProgress color="success" size={24} /> : startIcon}
        onClick={onClick}
        // onKeyDown={handleKeyDown}
        type={isSubmit ? 'submit' : 'button'}
      >
        {text}
      </ButtonColorStyledContained>
    );
  }
  if (variant === 'outlined' && (customType === 'error' || customType === 'success')) {
    return (
      <ButtonColorStyledOutlined
        {...others}
        // onKeyDown={handleKeyDown}
        props={{ customType: customType ?? 'default', height }}
        disabled={disabled || isLoading}
        variant={variant}
        startIcon={isLoading ? <CircularProgress color="success" size={24} /> : startIcon}
        onClick={onClick}
      >
        {text}
      </ButtonColorStyledOutlined>
    );
  }

  return (
    <Button
      onClick={onClick}
      sx={{
        padding: '8px 20px',
        color: color.black,
        borderRadius: '12px',
      }}
    >
      {text}
    </Button>
  );
};
