import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import { ButtonStyled } from '@/components/atoms/Button';
import { DrawerCustom } from '@/components/atoms/DrawerCustom';
import { useRootStore } from '@/stores';
import { DrawerLikeModal } from '@/components/common/DrawerLikeModal';
import { useValidation } from '@/hooks/useValidation';
import { Dropdown } from '@/components/atoms/Dropdown';
import type { ITableBodyAdministrationAssessmentTemplate } from '@/stores/freskoApi/AdministrationAssessmentTemplateStore';

const templateInputJoinWithLabelList = (editData?: ITableBodyAdministrationAssessmentTemplate) => [
  {
    id: 'name',
    label: 'Название',
    value: editData?.name,
    required: !(editData && editData?.status !== 'Draft'),
    disabled: editData && editData?.status !== 'Draft',
  },
  {
    id: 'description',
    label: 'Описание',
    value: editData?.description,
  },
];

export const ModelEvaluationModal = observer(
  ({
    editData,
    setEditData,
  }: {
    editData?: ITableBodyAdministrationAssessmentTemplate;
    setEditData: (val: ITableBodyAdministrationAssessmentTemplate | undefined) => void;
  }) => {
    const {
      employeeAsUserStore,
      userStore,
      businessUnitStore,
      isLoading,
      administrationAssessmentTemplateStore,
      uiStateStore: { open },
    } = useRootStore();

    const [openAdd, setOpenAdd] = useState<boolean>(false);
    const [buDisabled, setBuDisabled] = useState<boolean>(false);

    const { value, setFormHasError, isError, onChangeDropdown, prevValues } = useValidation();

    useEffect(() => {
      if (prevValues?.context_name && prevValues?.context_name !== value?.context_name) {
        onChangeDropdown('context_object_name', undefined);
      }
    }, [onChangeDropdown, prevValues?.context_name, value?.context_name]);

    const contextField = administrationAssessmentTemplateStore.dataContextDropdown?.map(({ name }) => ({
      label: name,
      value: name,
    }));

    useEffect(() => {
      if (prevValues?.context_name !== value?.context_name) {
        onChangeDropdown('context_object_name', undefined);
      }
    }, [onChangeDropdown, value?.context_name]);

    const contextObjNameField = useMemo(() => {
      let dataPreset = administrationAssessmentTemplateStore.dataContextObjectDropdown;

      if (value?.context_name) {
        const { id: selectedDataContextId } =
          administrationAssessmentTemplateStore.dataContextDropdown?.find(({ name }) => name === value.context_name) ||
          {};

        dataPreset = dataPreset.filter(({ data_context }) => data_context === selectedDataContextId);
      }

      return dataPreset?.map(({ name }) => ({
        label: name,
        value: name,
      }));
    }, [
      administrationAssessmentTemplateStore.dataContextDropdown,
      administrationAssessmentTemplateStore.dataContextObjectDropdown,
      value.context_name,
    ]);

    useEffect(() => {
      if (editData?.contextName && editData?.contextObjectName) {
        administrationAssessmentTemplateStore.getDataContextBy({
          context_name: editData?.contextName,
          context_object_name: editData?.contextObjectName,
        });
      }
    }, [administrationAssessmentTemplateStore, editData?.contextName, editData?.contextObjectName]);

    useEffect(() => {
      return () => {
        administrationAssessmentTemplateStore.dataContext = [];
      };
    }, []);

    const closeModal = () => {
      setOpenAdd(false);
      setEditData(undefined);
      onChangeDropdown('context_name', undefined);
      onChangeDropdown('context_object_name', undefined);
    };

    const openModal = () => {
      setOpenAdd(true);
    };

    const toggleOpen = () => {
      if (openAdd) {
        return closeModal();
      }

      return openModal();
    };

    useEffect(() => {
      if (editData) {
        openModal();
      }
    }, [editData]);

    useEffect(() => {
      setBuDisabled(!userStore.profileInfo?.is_staff);
      if (!editData) {
        const empl = employeeAsUserStore.allEmployeeAsUsers?.find((some) => some.user === userStore.profileInfo?.pk);
        onChangeDropdown('bu', empl?.bu);
      }
    }, [employeeAsUserStore.allEmployeeAsUsers, userStore.profileInfo?.is_staff, userStore.profileInfo?.pk]);

    const dropdownItems = useMemo(() => {
      if (businessUnitStore.allBusinessUnits) {
        return businessUnitStore.allBusinessUnits.map(({ id, name }) => ({
          label: name,
          value: id,
        }));
      }

      return [];
    }, [businessUnitStore.allBusinessUnits]);

    const handleSaveForm = async (event: React.FormEvent<HTMLFormElement>) => {
      const data = new FormData(event.currentTarget);
      const name = data.get('name') as string;
      const description = data.get('description') as string;
      const context_object_name = value?.context_object_name;
      const context_name = value?.context_name;
      const bu = value?.bu;

      if (editData) {
        administrationAssessmentTemplateStore.updateModel({
          isGetAllModel: true,
          modelId: editData.id,
          dataModel: {
            description: description ?? editData?.description,
            name: name ?? editData?.name,
            context_object_name: context_object_name ?? editData?.contextObjectName,
            context_name: context_name ?? editData?.contextName,
            bu: bu ?? editData?.bu,
          },
        });

        setEditData(undefined);
        toggleOpen();

        return;
      }

      administrationAssessmentTemplateStore.createAdministrationAssessmentTemplate({
        name,
        description,
        context_object_name,
        context_name,
        bu,
      });

      toggleOpen();
    };
    return (
      <>
        <DrawerCustom open={open}>
          <ButtonStyled
            text="Добавить модель"
            startIcon={<AddCircleOutlineIcon />}
            onClick={toggleOpen}
            disabled={isLoading}
          />
          <DrawerLikeModal
            title={editData ? 'Изменить модель' : 'Добавить модель'}
            open={openAdd}
            toggleOpen={toggleOpen}
            items={templateInputJoinWithLabelList(editData)}
            saveForm={handleSaveForm}
            buttonConfirmText={editData ? 'Изменить модель' : 'Добавить модель'}
            isDisabled={isLoading || isError || !value?.context_name || !value?.context_object_name}
          >
            <Dropdown
              required
              mb="16px"
              onChange={onChangeDropdown}
              key="context_name"
              label="Контекст"
              title=""
              dropdownWidth="285px"
              id="context_name"
              value={value?.context_name ?? editData?.contextName}
              dropdownItems={[contextField[0]]}
              handleGlobalError={setFormHasError}
            />
            <Dropdown
              required
              mb="16px"
              dropdownWidth="285px"
              forceSet
              onChange={onChangeDropdown}
              key="context_object_name"
              label="Объект оценки"
              title=""
              id="context_object_name"
              value={value?.context_object_name ?? editData?.contextObjectName}
              dropdownItems={contextObjNameField}
              handleGlobalError={setFormHasError}
            />
            <Dropdown
              mb="16px"
              disabled={buDisabled}
              onChange={onChangeDropdown}
              key="bu"
              label="Подразделение"
              disabledPlaceholder={false}
              title="Подразделение"
              id="bu"
              value={value?.bu ?? editData?.bu}
              dropdownItems={dropdownItems}
              handleGlobalError={setFormHasError}
              required
            />
          </DrawerLikeModal>
        </DrawerCustom>
      </>
    );
  },
);
