import * as React from 'react';

import { styled } from '@mui/material/styles';
import type { SwitchProps } from '@mui/material/Switch';
import Switch from '@mui/material/Switch';

import { color } from '@/constants/colors';

export const SwitchStyled = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 25,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    color: color.gray,
    padding: 0,
    margin: 1,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(17px)',
      color: color.green,
      '& + .MuiSwitch-track': {
        boxSizing: 'border-box',
        backgroundColor: color.white,
        opacity: 1,
        border: `1px solid ${color.silver}`,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: color.white,
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: color.gray,
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      boxSizing: 'border-box',
      backgroundColor: color.white,
      opacity: 1,
      border: `1px solid ${color.silver}`,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 23,
    height: 23,
  },
  '& .MuiSwitch-track': {
    boxSizing: 'border-box',
    borderRadius: 26 / 2,
    backgroundColor: color.white,
    border: `1px solid ${color.silver}`,
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));
