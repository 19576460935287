import React from 'react';
import { Fade } from '@mui/material';
import { Box } from '@mui/system';

import { OFFSET, WIDTH } from '@/constants/sizes';
import { Dropdown } from '@/components/atoms/Dropdown';
import { TemplateInputWithLabel } from '@/components/atoms/TemplateInputWithLabel';
import { SimpleInput } from '@/components/ScoringSettingsPageDetails/ScoringSettingsPageDetailsTable';
import { TableCellStyled } from '@/components/Templates/TableComponentStyled';

export enum EditableField {
  'simpleInput' = 'simpleInput',
  'dropdown' = 'dropdown',
  'inputRequired' = 'inputRequired',
}

type TableCellEditableType = {
  type: EditableField;
  fieldName: string;
  initialVal: any;
  handleChange: (..._: any[]) => void;
  isEdit: boolean;
  formHasErrorRaw?: Record<string, boolean>;
  required?: boolean;
  setFormHasError?: (val: any) => void;
  editVal?: any;
  dropdownInfo?: {
    disabled?: boolean;
    placeholder?: string;
    dropdownItems: {
      label: string;
      value: string;
    }[];
    value?: string;
  };
  align?: any;
};

export const TableCellEditable = ({
  type,
  initialVal,
  fieldName,
  handleChange,
  isEdit,
  dropdownInfo,
  setFormHasError,
  formHasErrorRaw,
  align,
  required,
  editVal,
}: TableCellEditableType) => {
  if (type === EditableField.dropdown && !dropdownInfo) {
    throw new Error(`для типа ${EditableField.dropdown} необходимо передать dropdownInfo`);
  }

  if (type === EditableField.inputRequired && (!formHasErrorRaw || !setFormHasError)) {
    throw new Error(`для типа ${EditableField.inputRequired} необходимо передать setFormHasError и formHasErrorRaw`);
  }

  const componentsMapIsEdit: Record<EditableField, JSX.Element> = {
    [EditableField.simpleInput]: <SimpleInput initialVal={initialVal} fieldName={fieldName} onChange={handleChange} />,
    [EditableField.dropdown]: <></>,
    [EditableField.inputRequired]: (
      <TemplateInputWithLabel
        handleGlobalError={setFormHasError}
        key={fieldName}
        id={fieldName}
        isError={formHasErrorRaw?.[fieldName] ?? false}
        name={fieldName}
        value={editVal ?? initialVal}
        required={required}
        handleChange={handleChange}
        mb={OFFSET.none}
      />
    ),
  };

  if (dropdownInfo) {
    componentsMapIsEdit[EditableField.dropdown] = (
      <Dropdown
        disabled={dropdownInfo?.disabled}
        mb={OFFSET.none}
        onChange={handleChange}
        title={dropdownInfo?.placeholder ?? ''}
        id={fieldName}
        dropdownItems={dropdownInfo?.dropdownItems}
        value={dropdownInfo?.value ?? dropdownInfo.dropdownItems.find(({ value }) => initialVal === value)}
        dropdownWidth={WIDTH.auto}
      />
    );
  }

  const componentsMap = {
    [EditableField.simpleInput]: initialVal,
    [EditableField.dropdown]: initialVal,
    [EditableField.inputRequired]: initialVal,
  };

  return (
    <TableCellStyled verticalAlign="middle" align={align}>
      <Fade in={isEdit}>
        <Box sx={{ display: isEdit ? 'flex' : 'none' }}>{componentsMapIsEdit[type]}</Box>
      </Fade>
      <Fade in={!isEdit}>
        <Box sx={{ display: !isEdit ? 'flex' : 'none' }}>{componentsMap[type]}</Box>
      </Fade>
    </TableCellStyled>
  );
};
