import React, { useLayoutEffect } from 'react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';

import StarBorderIcon from '@mui/icons-material/StarBorder';

import { TableTemplate } from '@/components/Templates/TableTemplate';
import { useRootStore } from '@/stores';
import { ITableBodyContactPersons } from '@/stores/ContactPersonsStore';
import { TableRowStyled, TableCellStyled } from '@/components/Templates/TableComponentStyled';
import { withEditable } from '@/hoc/withEditable';
import { apiPath } from '@/constants/api';
import { EditableField, TableCellEditable } from '@/components/Templates/TableCellEditable';
import { useModalDelete } from '@/components/common/hooks/useModalDelete';
import { ModalDelete } from '@/components/common/ModalDelete';
import { EditButtons } from '@/components/Templates/EditButtons';
import { useValidation } from '@/hooks/useValidation';
import { color } from '@/constants/colors';

export type ITable = {
  tableRow: any[];
  tableCell: any[];
};
const tableHeader = ['', 'ФИО', 'Должность', 'Телефон', 'Email', ''];
const tableHeaderWidth = ['4%', '22%', '22%', '22%', '22%', '8%'];

const RenderBodyContactPersonsTableRaw = ({
  row,
  filled,
  isEdit,
  handleDelete,
  handleCloseEdit,
  handleOpenEdit,
}: {
  row: ITableBodyContactPersons;
  filled: boolean;
  isEdit: boolean;
  handleOpenEdit: () => void;
  handleCloseEdit: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  handleDelete: (val: ITableBodyContactPersons) => void;
}) => {
  const { id } = useParams<{ id: string }>();
  const { contactPersonsStore } = useRootStore();
  const { value, isError, formHasErrorRaw, setFormHasError, handleChangeInput } = useValidation();
  const usedUrl = apiPath.updateContactPersonInfo.replace('{account_pk}', `${id}`).replace('{id}', `${row.id}`);

  const handleSave = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    handleCloseEdit(e);
    const isNeedUpdate = Object.keys(value).some((key) => key);
    if (isNeedUpdate && id) {
      const fioLikeArray = value?.fio?.split(' ');

      contactPersonsStore.updateContragentContactPersonInfo({
        url: usedUrl,
        paramsToPatch: {
          last_name: fioLikeArray?.[1],
          first_name: fioLikeArray?.[0],
          middle_name: fioLikeArray?.[2],
          phone: value?.phone,
          email: value?.email,
          position_name: value?.position,
        },
        contragentId: id,
      });
    }
  };

  const deleteContactPerson = () => {
    handleDelete(row);
  };

  return (
    <TableRowStyled key={row.id} sx={{ backgroundColor: filled ? color.light : color.transparent }}>
      <TableCellStyled sx={{ textAlign: 'center', lineHeight: 0 }} verticalAlign="middle">
        {row.isPrimaryContact && <StarBorderIcon sx={{ color: color.emerald, fontSize: '20px' }} />}
      </TableCellStyled>
      <TableCellEditable
        initialVal={row.fio}
        fieldName={'fio'}
        handleChange={handleChangeInput}
        type={EditableField.inputRequired}
        isEdit={isEdit}
        setFormHasError={setFormHasError}
        formHasErrorRaw={formHasErrorRaw}
        required
      />
      <TableCellEditable
        initialVal={row.position}
        fieldName={'position'}
        handleChange={handleChangeInput}
        type={EditableField.inputRequired}
        isEdit={isEdit}
        setFormHasError={setFormHasError}
        formHasErrorRaw={formHasErrorRaw}
        required
      />
      <TableCellEditable
        initialVal={row.phone}
        fieldName={'phone'}
        handleChange={handleChangeInput}
        type={EditableField.inputRequired}
        isEdit={isEdit}
        required={false}
        setFormHasError={setFormHasError}
        formHasErrorRaw={formHasErrorRaw}
      />
      <TableCellEditable
        initialVal={row.email}
        fieldName={'email'}
        handleChange={handleChangeInput}
        type={EditableField.simpleInput}
        isEdit={isEdit}
      />
      <EditButtons
        isEdit={isEdit}
        isError={isError}
        handleSave={handleSave}
        handleDelete={deleteContactPerson}
        handleClose={handleCloseEdit}
        handleOpen={handleOpenEdit}
      />
    </TableRowStyled>
  );
};

const RenderBodyContactPersonsTable = withEditable(RenderBodyContactPersonsTableRaw);

export const ContactPersonsTable = observer(() => {
  const { open, handleClose, handleOpen, deletedValue: contactPerson } = useModalDelete<ITableBodyContactPersons>();

  const { contactPersonsStore, isLoading } = useRootStore();
  const { id } = useParams<{ id: string }>();

  const handleDelete = () => {
    if (id && contactPerson?.id) {
      contactPersonsStore.deleteContragentContactPersonInfo({ contragentId: id, contactId: contactPerson?.id });
      handleClose();
    }
  };

  useLayoutEffect(() => {
    if ((contactPersonsStore.renderedRows.length === 0 || contactPersonsStore.contragentId !== id) && id) {
      contactPersonsStore.setContragenId(id);
    }
  }, []);

  return (
    <>
      <TableTemplate<ITableBodyContactPersons>
        tableHeader={tableHeader}
        tableHeaderWidth={tableHeaderWidth}
        RenderComponent={(props) => <RenderBodyContactPersonsTable {...props} handleDelete={handleOpen} />}
        store={contactPersonsStore}
        isLoading={isLoading}
      />
      <ModalDelete
        uniqId={'Contact_delete_modal'}
        open={open}
        handleClose={handleClose}
        handleDelete={handleDelete}
        title="Вы действительно хотите удалить контактное лицо?"
        descriptions={[contactPerson?.fio, contactPerson?.position, contactPerson?.phone, contactPerson?.email]}
      />
    </>
  );
});
