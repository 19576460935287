import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';

import { Box } from '@mui/material';

import { OFFSET } from '@/constants/sizes';
import { BreadrcrumbsWithWrapper } from '@/components/common/Breadrcrumbs';
import { LabelLikeHintWithText } from '@/components/common/LabelLikeHintWithText';
import type { TabBarItem } from '@/components/common/TabBarStyled';
import { TabBarStyled } from '@/components/common/TabBarStyled';
import { MoreMenuTabData } from '@/components/Administering/AdministrationTabData/MoreMenuTabData';
import { useModalDelete } from '@/components/common/hooks/useModalDelete';
import { ModalDelete } from '@/components/common/ModalDelete';
import {
  AdministrationDataDetailTabAttributes,
  AdministrationDataDetailTabConnections,
} from '@/components/Administering/AdministrationTabData/AdministrationDataDetail/AdministrationDataDetailTab';
import { useRootStore } from '@/stores';
import { dateFormatterWithHoursMinutes } from '@/utils';
import { AdministrationAddTabDataModal } from '@/components/Administering/AdministrationTabData/AdministrationAddTabDataModal';
import type { ITableBodyAdministrationData } from '@/stores/freskoApi/AdministrationDataStore';
import { ROLE, ROUTES } from '@/constants';
import { FilterComponent } from '@/components/atoms/FilterComponent';
import { color } from '@/constants/colors';

const tabBarItems: TabBarItem[] = [
  {
    name: 'Атрибуты',
    component: () => <AdministrationDataDetailTabAttributes />,
    permissions: [ROLE.SMARTFACT, ROLE.GENERAL, ROLE.BALTICLEASING, ROLE.PSBL],
  },
  {
    name: 'Соединения',
    component: () => <AdministrationDataDetailTabConnections />,
    permissions: [ROLE.SMARTFACT, ROLE.GENERAL, ROLE.BALTICLEASING, ROLE.PSBL],
  },
];

export const AdministrationDataDetailRaw = () => {
  const { administrationDataStore, userStore } = useRootStore();
  const { id: idData } = useParams<{ id: string }>();
  const [selectedTab, setSelectedTab] = useState(tabBarItems[0]);
  const { open, handleClose, handleOpen, deletedValue } = useModalDelete<any>();
  const [data, setData] = useState<ITableBodyAdministrationData | undefined>(undefined);
  const navigate = useNavigate();

  useEffect(() => {
    if (idData) {
      administrationDataStore.getAdministrationDataById(idData);
    }
  }, []);

  const onChangeTab = (currentTab: string) => {
    setSelectedTab(tabBarItems.find(({ name }) => name === currentTab) ?? tabBarItems[0]);
  };

  const onDelete = () => {
    handleOpen(administrationDataStore?.selectedAdministrationDataById);
  };

  const onEdit = () => {
    if (administrationDataStore.selectedAdministrationDataById) {
      setData(administrationDataStore.selectedAdministrationDataById as ITableBodyAdministrationData);
    }
  };

  const { bdName, changeName, changeDate, objectName, objectBdName, filter, primName } =
    administrationDataStore.selectedAdministrationDataById;

  return (
    <>
      <Box mb={OFFSET.xxxl}>
        <BreadrcrumbsWithWrapper
          breadCrumbItems={[
            {
              name: 'Данные',
              action: () => navigate(`${ROUTES.settings}#2`),
            },
            {
              name: bdName ?? '-',
            },
          ]}
        />
        <Box
          sx={{
            height: '88px',
            mrgingTop: '24px',
            background: color.white,
            border: `1px solid ${color.pale_green}`,
            borderRadius: '4px',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexDirection: 'row',
            display: 'flex',
            padding: '0 24px',
          }}
        >
          <LabelLikeHintWithText title="Название" value="">
            <LabelLikeHintWithText reverse title={`${objectBdName} / ${objectName}`} value={bdName} />
          </LabelLikeHintWithText>
          {filter && (
            <LabelLikeHintWithText title="Фильтр">
              <Box
                sx={{
                  marginBottom: '0.35em',
                  color: color.light_black,
                  fontStyle: 'normal',
                  fontWeight: '400',
                  fontSize: '14px',
                  lineHeight: '16px',
                }}
              >
                <FilterComponent filterText={filter ?? ''} />
              </Box>
            </LabelLikeHintWithText>
          )}
          <LabelLikeHintWithText title="Идентификатор" value={primName} />
          <LabelLikeHintWithText title="Изменен" value="">
            <LabelLikeHintWithText reverse title={dateFormatterWithHoursMinutes(changeDate)} value={changeName} />
          </LabelLikeHintWithText>
          <MoreMenuTabData onDelete={onDelete} onEdit={onEdit} />
        </Box>
        <Box
          sx={{
            mt: OFFSET.sm,
            mb: OFFSET.sm,
            display: 'flex',
            justifyContent: 'space-between',
            borderBottom: `1px solid ${color.gray}`,
          }}
        >
          <TabBarStyled
            items={tabBarItems}
            selected={selectedTab}
            onChangeTab={onChangeTab}
            userRole={userStore.profileInfo?.view_set}
          />
        </Box>
        {selectedTab.component()}
      </Box>
      <ModalDelete
        uniqId="Data_modal_delete"
        open={open}
        handleClose={handleClose}
        handleDelete={onDelete}
        title="Вы действительно хотите удалить объект?"
        descriptions={[deletedValue?.displayName ?? '', deletedValue?.changeName ?? '', deletedValue?.objectName ?? '']}
      />
      <AdministrationAddTabDataModal setEditData={setData} editData={data} isRequestByIdAfterEdit isOnlyEdit />
    </>
  );
};

AdministrationDataDetailRaw.displayName = 'AdministrationDataDetail';

export const AdministrationDataDetail = observer(AdministrationDataDetailRaw);
