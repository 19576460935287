import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import { Box } from '@mui/material';

import { OFFSET } from '@/constants/sizes';
import { iconList, iconListBottom } from '@/components/common/Drawer';
import { Title } from '@/components/atoms/Title';
import { color } from '@/constants/colors';

export const useIconCurrentRoute = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const currentBaseRoute = location.pathname.split('/')?.[1];
  const CurrentBaseIcon = [...iconList, ...iconListBottom].find(
    ({ linkTo }) => linkTo === `/${currentBaseRoute}`,
  )?.IconComponent;

  return {
    navigateToBaseRoute: () => navigate(`/${currentBaseRoute}`),
    CurrentBaseIcon,
  };
};

const ArrowDivider = () => (
  <ArrowForwardOutlinedIcon
    sx={{
      color: color.light_green,
      width: '30px',
      height: '30px',
      marginRight: OFFSET.sm,
      marginLeft: OFFSET.sm,
    }}
  />
);

type BreadrcrumbsItemType = {
  name: string;
  action?: () => void;
};

type BreadrcrumbsType = {
  breadCrumbItems: BreadrcrumbsItemType[];
};

export const BreadrcrumbsWithWrapper = ({ breadCrumbItems }: BreadrcrumbsType) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignContent: 'center',
        marginTop: '10px',
        mb: OFFSET.sm,
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Breadrcrumbs breadCrumbItems={breadCrumbItems} />
    </Box>
  );
};

export const Breadrcrumbs = ({ breadCrumbItems }: BreadrcrumbsType) => {
  const { CurrentBaseIcon, navigateToBaseRoute } = useIconCurrentRoute();

  return (
    <>
      {CurrentBaseIcon && (
        <Box sx={{ cursor: 'pointer' }}>
          <CurrentBaseIcon stroke={color.light_green} onClick={navigateToBaseRoute} width={30} height={30} />
        </Box>
      )}
      {breadCrumbItems?.map(({ name, action }, index) => (
        <React.Fragment key={index}>
          <ArrowDivider />
          <Title onClick={action} sx={index === breadCrumbItems?.length - 1 ? { whiteSpace: 'break-spaces' } : {}}>
            {name ?? '-'}
          </Title>
        </React.Fragment>
      ))}
      <Box
        sx={{
          display: 'flex',
          flex: '1',
        }}
      />
    </>
  );
};

BreadrcrumbsWithWrapper.displayName = 'BreadrcrumbsWithWrapper';
Breadrcrumbs.displayName = 'Breadrcrumbs';
