import { observer } from 'mobx-react';
import React from 'react';
import { Navigate } from 'react-router-dom';

import { ROLE, ROUTES } from '@/constants';
import { useRootStore } from '@/stores';

interface Props {
  component: React.ComponentType;
  path?: string;
  roles: Array<string>;
}

export const PrivateRoute: React.FC<Props> = observer(({ component: RouteComponent, roles }) => {
  const { authStore, userStore } = useRootStore();

  if (!authStore.isAuth) {
    return <Navigate to={ROUTES.login} />;
  }

  if (roles.length === 0) {
    return <RouteComponent />;
  }

  const userHasRequiredRole =
    !!roles.includes(userStore.profileInfo?.view_set ?? '') || userStore.profileInfo?.view_set === ROLE.ADMIN;

  if (!userHasRequiredRole && userStore.profileInfo) {
    return <Navigate to={ROUTES.notFound} replace />;
  }

  return <RouteComponent />;
});
