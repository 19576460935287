import React from 'react';
import { useNavigate } from 'react-router-dom';

import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const settings = [
  {
    primary: 'Профиль',
    linkTo: '/profile',
  },
  {
    primary: 'Выход',
    linkTo: '/logout',
  },
];

export const UserMenu = () => {
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const navigation = useNavigate();

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handelClickUserMenuItem = (path: string) => {
    navigation(path);
    setAnchorElUser(null);
  };

  return (
    <Box sx={{ flexGrow: 0 }}>
      <Tooltip title="Open settings">
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
          {/* TODO ГДЕ ИЗОБРАЖЕНИЕ ИЗ ПРОФИЛЯ */}
          <Avatar alt="Remy Sharp" />
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: '45px' }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {settings.map(({ primary, linkTo }) => (
          <UserMenuItem key={primary} primary={primary} path={linkTo} handleClick={handelClickUserMenuItem} />
        ))}
      </Menu>
    </Box>
  );
};

const UserMenuItem = ({
  primary,
  handleClick,
  path,
}: {
  primary: string;
  path: string;
  handleClick: (path: string) => void;
}) => {
  const onClick = () => handleClick(path);

  return (
    <MenuItem key={primary} onClick={onClick}>
      <Typography textAlign="center">{primary}</Typography>
    </MenuItem>
  );
};
