import React, { useEffect, useState } from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import { DrawerCustom } from '@/components/atoms/DrawerCustom';
import { ButtonStyled } from '@/components/atoms/Button';
import { DrawerLikeModal } from '@/components/common/DrawerLikeModal';
import { useRootStore } from '@/stores';
import type { ITableBodyBusinessUnit } from '@/stores/BusinessUnitStore';

const templateInputBusinessUnit = (editData?: ITableBodyBusinessUnit) => [
  {
    id: 'name',
    label: 'Название',
    value: editData?.name,
    required: true,
  },
  {
    id: 'description',
    label: 'Описание',
    value: editData?.description,
  },
];

export const BusinessUnitModal = ({
  editData,
  setEditData,
}: {
  editData?: ITableBodyBusinessUnit;
  setEditData: (val?: ITableBodyBusinessUnit) => void;
}) => {
  const {
    businessUnitStore,
    isLoading,
    uiStateStore: { open },
  } = useRootStore();

  const [openAdd, setOpenAdd] = useState<boolean>(false);

  const closeModal = () => {
    setOpenAdd(false);
    setEditData(undefined);
  };

  const openModal = () => {
    setOpenAdd(true);
  };

  const toggleOpen = () => {
    if (openAdd) {
      return closeModal();
    }

    return openModal();
  };

  useEffect(() => {
    if (editData) {
      openModal();
    }
  }, [editData]);

  const handleSaveForm = async (event: React.FormEvent<HTMLFormElement>) => {
    const data = new FormData(event.currentTarget);
    const name = data.get('name') as string; // r
    const description = data.get('description') as string;

    if (editData) {
      businessUnitStore.editBusinessUnit({
        id: editData.id,
        name: name ?? editData?.name,
        description: description ?? editData?.description,
      });
    } else {
      businessUnitStore.createBusinessUnit({
        name,
        description,
      });
    }

    closeModal();
  };

  return (
    <>
      <DrawerCustom open={open}>
        <ButtonStyled text="добавить подразделение" startIcon={<AddCircleOutlineIcon />} onClick={toggleOpen} />
      </DrawerCustom>
      <DrawerLikeModal
        title="Добавить подразделение"
        open={openAdd}
        toggleOpen={toggleOpen}
        saveForm={handleSaveForm}
        items={templateInputBusinessUnit(editData)}
        buttonConfirmText={editData ? 'Изменить' : 'добавить'}
        isLoading={isLoading}
      />
    </>
  );
};
