import React, { useLayoutEffect } from 'react';
import { observer } from 'mobx-react';

import { TableTemplate } from '@/components/Templates/TableTemplate';
import { useRootStore } from '@/stores';
import { TableRowStyled, TableCellStyled } from '@/components/Templates/TableComponentStyled';
import { Badge } from '@/components/atoms/Bage/Badge';
import type { ITableBodyProduct } from '@/stores/ProductsStore';
import { MoreMenuTabData } from '@/components/Administering/AdministrationTabData/MoreMenuTabData';
import { FILTER_TYPE_TO_BADGE, FILTER_TYPE_TO_DISPLAY } from '@/components/ProductsPage/ProductsPage';
import { useModalDelete } from '@/components/common/hooks/useModalDelete';
import { ModalDelete } from '@/components/common/ModalDelete';
import { color } from '@/constants/colors';

export type ITable = {
  tableRow: any[];
  tableCell: any[];
};
const tableHeader = ['#', 'Название', 'Статус', 'Подразделение', 'Описание', 'Автор', ''];

const RenderBodyProductTable = observer(
  ({
    row,
    filled,
    openDeleteModal,
    setEditProduct,
    index,
  }: {
    row: ITableBodyProduct;
    filled: boolean;
    openDeleteModal: (val: ITableBodyProduct) => void;
    setEditProduct: (val: ITableBodyProduct) => void;
    index: number;
  }) => {
    const { businessUnitStore } = useRootStore();
    const onDelete = () => {
      openDeleteModal(row);
    };

    const onEdit = () => {
      setEditProduct(row);
    };
    const businessUnit = businessUnitStore?.allBusinessUnits?.find(
      (some) => some?.id?.toString() === row?.bu?.toString(),
    )?.name;

    return (
      <TableRowStyled key={row.id} sx={{ backgroundColor: filled ? color.light : color.transparent }}>
        <TableCellStyled>{index + 1}</TableCellStyled>
        <TableCellStyled>{row.name}</TableCellStyled>
        <TableCellStyled>
          <Badge type={FILTER_TYPE_TO_BADGE[row.status as keyof typeof FILTER_TYPE_TO_BADGE]}>
            {FILTER_TYPE_TO_DISPLAY[row.status as keyof typeof FILTER_TYPE_TO_BADGE]}
          </Badge>
        </TableCellStyled>
        <TableCellStyled>{businessUnit ?? '-'}</TableCellStyled>
        <TableCellStyled>{row.description}</TableCellStyled>
        <TableCellStyled>{row?.author ?? '-'}</TableCellStyled>
        <TableCellStyled align="right">
          <MoreMenuTabData onDelete={onDelete} onEdit={onEdit} />
        </TableCellStyled>
      </TableRowStyled>
    );
  },
);

export const ProductsTable = observer(({ setEditProduct }: { setEditProduct: (val: ITableBodyProduct) => void }) => {
  const { productsStore, isLoading } = useRootStore();
  const { open, handleClose, handleOpen, deletedValue: deleteProduct } = useModalDelete<ITableBodyProduct>();

  useLayoutEffect(() => {
    if (!productsStore.renderedRows?.length || productsStore.renderedRows?.length === 0) {
      productsStore.getProducts();
    }
  }, []);

  const handleDelete = () => {
    if (deleteProduct) {
      productsStore.deleteProduct({ productId: deleteProduct.id });
      handleClose();
    }
  };

  return (
    <>
      <TableTemplate<ITableBodyProduct>
        tableHeader={tableHeader}
        RenderComponent={(props) => (
          <RenderBodyProductTable {...props} openDeleteModal={handleOpen} setEditProduct={setEditProduct} />
        )}
        store={productsStore}
        isLoading={isLoading}
      />
      <ModalDelete
        uniqId="product_table_modal"
        open={open}
        handleClose={handleClose}
        handleDelete={handleDelete}
        title="Вы действительно хотите удалить продукт?"
        descriptions={[deleteProduct?.name ?? '', deleteProduct?.description ?? '']}
      />
    </>
  );
});
