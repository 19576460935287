import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useLocation, useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';

import { OFFSET } from '@/constants/sizes';
import { Title } from '@/components/atoms/Title';
import { ScoringPageTable } from '@/components/ScoringPage/ScoringPageTable';
import type { TabBarItem } from '@/components/common/TabBarStyled';
import { TabBarStyled } from '@/components/common/TabBarStyled';
import { AdministrationTabData } from '@/components/Administering/AdministrationTabData/AdministrationTabData';
import { ModelEvaluation } from '@/components/Administering/ModelEvaluation/ModelEvaluation';
import { ROLE } from '@/constants';
import { useRootStore } from '@/stores';
import { color } from '@/constants/colors';

const tabBarItems: TabBarItem[] = [
  {
    name: 'Модели оценки',
    component: () => <ModelEvaluation />,
    hash: '#1',
    permissions: [ROLE.GENERAL, ROLE.SMARTFACT, ROLE.BALTICLEASING, ROLE.PSBL],
  },
  {
    name: 'Данные',
    component: () => <AdministrationTabData />,
    hash: '#2',
    permissions: [ROLE.GENERAL, ROLE.SMARTFACT, ROLE.BALTICLEASING, ROLE.PSBL],
  },
  {
    name: 'Параметры скоринга',
    component: () => <ScoringPageTable />,
    hash: '#3',
    permissions: [ROLE.ADMIN],
  },
];

export const ScoringSettingsPage = observer(() => {
  const { userStore } = useRootStore();
  const location = useLocation();
  const navigate = useNavigate();
  const [renderComponent, setRenderComponent] = useState(tabBarItems[0]);

  const onChangeTab = (currentTab: string) => {
    const component =
      tabBarItems.find(({ name, hash }) => name === currentTab || hash === currentTab) ?? tabBarItems[0];
    setRenderComponent(component);
    if (renderComponent?.hash && component.hash) {
      navigate(component.hash);
    }
  };

  useEffect(() => {
    if (location?.hash !== renderComponent?.hash) {
      onChangeTab(location?.hash);
    }
  }, []);

  const { component, name } = renderComponent;

  return (
    <Box mb={OFFSET.xxxl}>
      <Box
        sx={{
          display: 'flex',
          alignContent: 'center',
          mt: '10px',
          mb: 0,
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Title>{name}</Title>
      </Box>
      <Box
        sx={{
          mt: OFFSET.sm,
          mb: OFFSET.sm,
          display: 'flex',
          justifyContent: 'space-between',
          borderBottom: `1px solid ${color.gray}`,
        }}
      >
        <TabBarStyled
          items={tabBarItems}
          selected={renderComponent}
          onChangeTab={onChangeTab}
          userRole={userStore.profileInfo?.view_set}
        />
      </Box>
      {component()}
    </Box>
  );
});
