import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import InputAdornment from '@mui/material/InputAdornment';
import CircularProgress from '@mui/material/CircularProgress';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { SelectChangeEvent, Typography } from '@mui/material';

import { OFFSET, WIDTH } from '@/constants/sizes';
import { Title } from '@/components/atoms/Title';
import { useRootStore } from '@/stores';
import { PageApplicationTable } from '@/components/ApplicationsPage/PageApplicationTable';
import { ApplicationModalCreate } from '@/components/ApplicationsPage/ApplicationModalCreate';
import { TextFieldOutlinedStyled } from '@/components/atoms/TextFieldStyled';
import { Hint } from '@/components/atoms/Hint';
import { getNextDay, pluralize } from '@/utils';
import { ButtonStyled } from '@/components/atoms/Button';
import { MonitoringDatePicker } from '@/components/Monitoring/MonitoringDatePicker/MonitoringDatePicker';
import { FilterItem, FilterType } from '@/constants/types';
import { APPLICATION_TYPE, APP_STATUS } from '@/constants/backendEnum';
import { FilterStatus, useFilters } from '@/components/atoms/FilterStatus';
import { IApplicationsStoreStoreWithITableStore, TableApplications } from '@/stores/ApplicationsStore';
import { runInAction } from 'mobx';

const filterLabels = {
  PRODUCT: 'Продукт',
  APPLICATION_TYPE: 'Тип заявки',
  APPLICATION_STATUS: 'Статус заявки',
};

const filterItems = (applicationsStore: IApplicationsStoreStoreWithITableStore<TableApplications>): FilterItem[] => [
  {
    label: filterLabels.PRODUCT,
    items: applicationsStore?.createAccountSelectValue ?? {},
    width: '88px',
  },
  {
    label: filterLabels.APPLICATION_TYPE,
    items: APPLICATION_TYPE,
    width: '100px',
  },
  {
    label: filterLabels.APPLICATION_STATUS,
    items: APP_STATUS,
    width: '120px',
  },
];

const initialFilterStatus: FilterType = {
  [filterLabels.PRODUCT]: [],
  [filterLabels.APPLICATION_TYPE]: [],
  [filterLabels.APPLICATION_STATUS]: [],
};

export const ApplicationsPage = observer(() => {
  const { applicationsPageDetailsStore, applicationsStore, isLoading, userStore } = useRootStore();
  const [query, setQuery] = useState<string | undefined>(undefined);
  const [openAdd, setOpenAdd] = useState<boolean>(false);
  const [filterStatus, setFilterStatus] = useState<FilterType>(initialFilterStatus);
  const [selectedFilters, setSelectedFilters] = useState<FilterType>(initialFilterStatus);
  const [dateStartSignal, setDateStartSignal] = useState<string>('');
  const [dateEndSignal, setDateEndSignal] = useState<string>('');
  const { open: openFilter, handleOpen, handleClose } = useFilters();

  const toggleOpen = () => {
    if (!isLoading) {
      setOpenAdd(!openAdd);
    }
  };

  const onChangeSearch = (e: any) => {
    setQuery(e.target.value);
  };

  useEffect(() => {
    let handler: NodeJS.Timeout;

    if (query?.length === 0) {
      handler = setTimeout(() => {
        applicationsPageDetailsStore.searchString = query;
      }, 1000);
    }

    if (isLoading || (query?.length as number) < 2) {
      return undefined;
    }

    handler = setTimeout(() => {
      runInAction(() => {
        applicationsPageDetailsStore.searchString = query;
      });
    }, 1000);

    return () => {
      if (handler) {
        clearTimeout(handler);
      }
    };
  }, [isLoading, applicationsPageDetailsStore, query]);

  const isSuperAdmin = userStore.profileInfo?.is_staff;

  const handleChangeFilter = (filterKey: string) => (e: SelectChangeEvent) => {
    const { value } = e.target;
    setFilterStatus((prevStatus) => ({
      ...prevStatus,
      [filterKey]: typeof value === 'string' ? value.split(',') : value,
    }));
  };

  const handleResetFilter = (filterKey: string) => (e: React.MouseEvent) => {
    if (e.target instanceof SVGSVGElement) {
      setFilterStatus((prevStatus) => ({
        ...prevStatus,
        [filterKey]: [],
      }));
      setSelectedFilters((prevStatus) => ({
        ...prevStatus,
        [filterKey]: [],
      }));
    }
    setFilterStatus((prevStatus) => ({
      ...prevStatus,
      [filterKey]: [],
    }));
    setSelectedFilters((prevStatus) => ({
      ...prevStatus,
      [filterKey]: [],
    }));
  };

  const handleConfirmFilter = (filterKey: string, label: string) => () => {
    setSelectedFilters((prevSelectedFilters) => ({
      ...prevSelectedFilters,
      [filterKey]: filterStatus[filterKey],
    }));

    handleClose(label);
  };

  useEffect(() => {
    runInAction(() => {
      applicationsPageDetailsStore.selectedProduct = selectedFilters[filterLabels.PRODUCT].join(',');
    });
  }, [selectedFilters[filterLabels.PRODUCT]]);

  useEffect(() => {
    runInAction(() => {
      applicationsPageDetailsStore.selectedApplicationType = selectedFilters[filterLabels.APPLICATION_TYPE].join(',');
    });
  }, [selectedFilters[filterLabels.APPLICATION_TYPE]]);

  useEffect(() => {
    runInAction(() => {
      applicationsPageDetailsStore.selectedApplicationStatus =
        selectedFilters[filterLabels.APPLICATION_STATUS].join(',');
    });
  }, [selectedFilters[filterLabels.APPLICATION_STATUS]]);

  useEffect(() => {
    runInAction(() => {
      applicationsPageDetailsStore.selectedCreatedDateGte = dateStartSignal;
    });
  }, [dateStartSignal]);

  useEffect(() => {
    const dateEndSignalNextDay = getNextDay(dateEndSignal);
    runInAction(() => {
      applicationsPageDetailsStore.selectedCreatedDateLte = dateEndSignalNextDay;
    });
  }, [dateEndSignal]);

  return (
    <Box mb={OFFSET.xxxl}>
      <Title props={{ mt: '10px' }}>Заявки</Title>
      {applicationsPageDetailsStore.summary > 0 && (
        <Hint props={{ mt: OFFSET.xxxxs }}>
          {pluralize({ count: applicationsPageDetailsStore.summary, words: ['заявка', 'заявки', 'заявок'] })}
        </Hint>
      )}
      <Box
        sx={{
          mt: OFFSET.sm,
          mb: OFFSET.sm,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '20px',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <Typography sx={{ fontSize: '14px', lineHeight: '20px' }}>c</Typography>
            <MonitoringDatePicker setDate={setDateStartSignal} width="150px" />
            <Typography sx={{ fontSize: '14px', lineHeight: '20px' }}>по</Typography>
            <MonitoringDatePicker setDate={setDateEndSignal} width="150px" />
          </Box>
          {filterItems(applicationsStore).map((filterItem) => (
            <FilterStatus
              key={filterItem.label}
              filterLabel={filterItem.label}
              selectTitle={filterItem.label}
              selectItems={filterItem.items}
              handleOpen={() => handleOpen(filterItem.label)}
              handleClose={() => handleClose(filterItem.label)}
              handleConfirm={handleConfirmFilter(filterItem.label, filterItem.label)}
              handleChangeStatus={handleChangeFilter(filterItem.label)}
              handleResetStatus={handleResetFilter(filterItem.label)}
              statusName={filterStatus[filterItem.label]}
              open={openFilter(filterItem.label)}
              width={filterItem.width}
            />
          ))}
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '23px',
          }}
        >
          <TextFieldOutlinedStyled
            onChange={onChangeSearch}
            id="search"
            name="search"
            size="small"
            type="text"
            autoComplete="no"
            placeholder="Найти..."
            sx={{ width: WIDTH.s }}
            value={query ?? ''}
            endAdornment={
              isLoading && (
                <InputAdornment position="end">
                  <CircularProgress size={20} />
                </InputAdornment>
              )
            }
            startAdornment={
              <InputAdornment position="start">
                <SearchOutlinedIcon />
              </InputAdornment>
            }
          />
          <ButtonStyled text="Добавить заявку" startIcon={<AddCircleOutlineIcon />} onClick={toggleOpen} />
        </Box>
      </Box>
      <Box sx={{ m: 0, mt: OFFSET.m }}>
        <Grid item xs={12}>
          <Paper
            sx={{
              p: 3,
              display: 'flex',
              flexDirection: 'column',
              padding: '0px',
              boxShadow: '0',
              bApplicationRadius: 0,
              backgroundColor: 'transparent',
            }}
          >
            <PageApplicationTable />
          </Paper>
        </Grid>
      </Box>
      <ApplicationModalCreate openAdd={openAdd} toggleOpen={toggleOpen} />
    </Box>
  );
});
