import React, { useEffect, useState } from 'react';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import { useRootStore } from '@/stores';
import { ContactPersonModal } from '@/components/Contagents/ContactTabs/ContactPersonModal';
import { ContactPersonsTable } from '@/components/Contagents/ContactTabs/ContactPersonsTable';
import { ButtonStyled } from '@/components/atoms/Button';
import { Box } from '@mui/material';
import { OFFSET } from '@/constants/sizes';

export const ContactPersons = () => {
  const [openAdd, setOpenAdd] = useState<boolean>(false);

  const { contactPersonsStore } = useRootStore();
  useEffect(() => {
    return () => {
      contactPersonsStore.clear();
    };
  }, []);

  const toggleOpen = () => {
    setOpenAdd(!openAdd);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          marginBottom: OFFSET.s,
          marginTop: `-${OFFSET.xxxxs}`,
        }}
      >
        <ButtonStyled text="Добавить контактное лицо" startIcon={<AddCircleOutlineIcon />} onClick={toggleOpen} />
      </Box>
      <ContactPersonsTable />
      <ContactPersonModal openAdd={openAdd} toggleOpen={toggleOpen} />
    </>
  );
};
