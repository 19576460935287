import { observable, makeObservable, action } from 'mobx';
import type { IUiStateStore } from '@/stores/type';

export class UiStateStore implements IUiStateStore {
  @observable
  public open: boolean = true;

  @observable
  public isContragentScreen: boolean = false;

  constructor() {
    makeObservable(this);
  }

  @action
  public toggleDrawer = () => {
    this.open = !this.open;
  };

  @action
  public toggleContragent = () => {
    this.isContragentScreen = !this.isContragentScreen;
  };
}
