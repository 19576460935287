/* eslint-disable camelcase */
import { action, makeObservable, observable, reaction, runInAction } from 'mobx';
import { apiPath } from '@/constants/api';
import { createAxiosRequest } from '@/utils/request';
import type { TableStoreType } from '@/stores/TableStore';
import { TableStore } from '@/stores/TableStore';
import type { IRootStore } from '@/stores/type';

export type IAppSalesRepStore = {
  getAppSalesRepByApplicationId: (accountId: string) => void;
  createAppSalesRep: ({
    role,
    application_id,
    employee,
    postn,
    status,
  }: {
    role?: string | null;
    application_id: number;
    employee?: string | number;
    postn: number;
    status?: string;
  }) => void;
  deleteAppSalesRep: ({ id }: { id: string | number }) => void;
  editAppSalesRep: ({
    id,
    role,
    application_id,
    employee,
    postn,
    status,
  }: {
    id: string | number;
    role?: string | null;
    application_id: number;
    employee?: string | number;
    postn: number;
    status?: string;
  }) => void;
  selectedId: string;
};

export type IAppSalesRepTeam = {
  id: number;
  role: null;
  status?: string;
  created_by?: {
    pk: number;
    username?: string;
    last_name?: string;
    first_name?: string;
    middle_name?: string;
    email?: string;
    url?: string;
    role?: string;
    phone?: string;
  };
  updated_by?: {
    pk: number;
    username?: string;
    last_name?: string;
    first_name?: string;
    middle_name?: string;
    email?: string;
    url?: string;
    role?: string;
    phone?: string;
  };
  created: string;
  updated: string;
  application: number;
  employee: number;
  postn: number;
};

export type IGetAppSalesRepStoreTeam = {
  count: number;
  next?: string;
  previous?: string;
  results: IAppSalesRepTeam[];
};

export type ITableBodyAppSalesRep = {
  id: number;
  role?: null | string;
  application_id: number;
  employee: number;
  postn: number;
  updatedBy?: string;
  updated?: string;
  status?: string;
};

const mapAppSalesRepStoreToTableTeamBody = (rawList: IAppSalesRepTeam[]): ITableBodyAppSalesRep[] => {
  return rawList?.map((item) => ({
    id: item.id,
    role: item.role,
    application_id: item.application,
    employee: item?.employee,
    postn: item.postn,
    updatedBy: item.updated_by?.first_name
      ? `${item.updated_by?.first_name} ${item.updated_by?.middle_name} ${item.updated_by?.last_name}`
      : item.updated_by?.username ?? `-`,
    updated: item?.updated ?? item.created,
    status: item?.status,
  })) as ITableBodyAppSalesRep[];
};

export type IAppSalesRepStoreWithITableStore<T> = IAppSalesRepStore & TableStoreType<T>;

export class AppSalesRepStore
  extends TableStore<ITableBodyAppSalesRep>
  implements IAppSalesRepStoreWithITableStore<ITableBodyAppSalesRep>
{
  @observable
  public rootStore: IRootStore;

  @observable
  selectedId: string;

  constructor(rootStore: IRootStore) {
    super();
    makeObservable(this);
    this.rootStore = rootStore;

    reaction(
      () => this.rowsPerPageChange,
      (value) => {
        if (this.maxSelectedPage <= value) {
          this.getAppSalesRepByApplicationId(this.selectedId);
        }
      },
    );

    reaction(
      () => this.selectedPage,
      (value) => {
        if (this.maxSelectedPage <= value) {
          this.getAppSalesRepByApplicationId(this.selectedId);
        }
      },
    );
  }

  @action
  getAppSalesRepByApplicationId = (accountId: string) => {
    this.rootStore.requestTemplate({
      errorMessage: `Ошибка получения данных команды`,
      callback: async () => {
        const limit = this.rowsPerPageChange;
        const offset = this.rowsPerPageChange * this.selectedPage;

        const { data } = await createAxiosRequest<{ limit?: number; offset?: number }, IGetAppSalesRepStoreTeam>({
          path: `${apiPath.getAppSalesRepTeamByContragentId?.replace('{application_pk}', accountId)}`,
          method: 'GET',
          useToken: true,
          params: { limit, offset },
        });
        runInAction(() => {
          this.limit = limit;
          this.summary = data.count;
          this.offset = offset;
          this.tableBody = mapAppSalesRepStoreToTableTeamBody(data.results);
        })
      },
    });
  };

  @action
  createAppSalesRep = ({
    role,
    application_id,
    employee,
    postn,
    status,
  }: {
    role?: string | null;
    application_id: number;
    employee?: string | number;
    postn: number;
    status?: string;
  }) => {
    return this.rootStore.requestTemplate({
      errorMessage: `Ошибка создания команды`,
      finallyAction: () => this.getAppSalesRepByApplicationId(this.selectedId),
      callback: async () => {
        await createAxiosRequest({
          path: `${apiPath.crAppSalesRep}`,
          method: 'POST',
          data: { role, postn, application: application_id, employee, status },
          useToken: true,
        });
      },
    });
  };

  @action
  editAppSalesRep = ({
    id,
    role,
    application_id,
    employee,
    postn,
    status,
  }: {
    id: string | number;
    role?: string | null;
    application_id: number;
    employee?: string | number;
    postn: number;
    status?: string;
  }) => {
    this.rootStore.requestTemplate({
      errorMessage: `Ошибка редактирования команды`,
      finallyAction: () => this.getAppSalesRepByApplicationId(this.selectedId),
      callback: async () => {
        await createAxiosRequest<any, any>({
          path: apiPath.crudAppSalesRepById?.replace('{id}', `${id}`),
          method: 'PATCH',
          data: { role, postn, application: application_id, employee, status },
          useToken: true,
        });
      },
    });
  };

  @action
  deleteAppSalesRep = ({ id }: { id: string | number }) => {
    this.rootStore.requestTemplate({
      errorMessage: `Ошибка удаления команды ${id}`,
      finallyAction: () => this.getAppSalesRepByApplicationId(this.selectedId),
      callback: async () => {
        await createAxiosRequest({
          path: apiPath.crudAppSalesRepById?.replace('{id}', `${id}`),
          method: 'DELETE',
          useToken: true,
        });
      },
    });
  };
}
