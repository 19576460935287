import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';

import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { Button } from '@mui/material';

import { OFFSET } from '@/constants/sizes';
import { useRootStore } from '@/stores';
import { LabelLikeHintWithText } from '@/components/common/LabelLikeHintWithText';
import { Badge } from '@/components/atoms/Bage/Badge';
import { MetabaseIframe } from '@/utils/metabase';
import { dateFormatterWithHoursMinutes } from '@/utils';
import { ANALYTICS_STATUS } from '@/constants/backendEnum';
import { ROUTES } from '@/constants';
import { BreadrcrumbsWithWrapper } from '@/components/common/Breadrcrumbs';
import { color } from '@/constants/colors';

export const ApplicationScoringPage = observer(() => {
  const { applicationsPageDetailsStore } = useRootStore();
  const { id, scoringId } = useParams<{ id: string; scoringId: string }>();

  useEffect(() => {
    if (id && scoringId) {
      applicationsPageDetailsStore.getScoringByApplicationIdByScoringId({ applicationId: id, scoringId });
    }
  }, [id, applicationsPageDetailsStore, scoringId]);

  const { created, status, overall_score, error_msg } =
    applicationsPageDetailsStore.scoringByApplicationIdByScoringId ?? {};

  const navigate = useNavigate();

  const goToNumApplication = () => {
    navigate(`${ROUTES.applications}/${id}`, { replace: true });
  };

  return (
    <Box mb={OFFSET.xxxl}>
      <BreadrcrumbsWithWrapper
        breadCrumbItems={[
          {
            name: applicationsPageDetailsStore?.detailApplicationById?.num ?? '-',
            action: goToNumApplication,
          },
          {
            name: 'Скоринг',
          },
        ]}
      />
      <Box
        sx={{
          height: '88px',
          mrgingTop: '24px',
          background: color.light,
          border: `1px solid ${color.pale_green}`,
          borderRadius: '4px',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexDirection: 'row',
          display: 'flex',
          padding: '0 24px',
        }}
      >
        <LabelLikeHintWithText title="Дата оценки" value={dateFormatterWithHoursMinutes(created)} />

        {error_msg ? (
          <Tooltip title={error_msg} followCursor>
            <Button disableElevation disableRipple disableFocusRipple disableTouchRipple>
              <LabelLikeHintWithText title="Аналитика">
                <Badge type={status?.toLowerCase()}>{ANALYTICS_STATUS[status] ?? '-'}</Badge>
              </LabelLikeHintWithText>
            </Button>
          </Tooltip>
        ) : (
          <LabelLikeHintWithText title="Аналитика">
            <Badge type={status?.toLowerCase()}>{ANALYTICS_STATUS[status] ?? '-'}</Badge>
          </LabelLikeHintWithText>
        )}

        <LabelLikeHintWithText title="Оценка Клиента">
          <Badge type={status?.toLowerCase()}>{overall_score ?? '-'}</Badge>
        </LabelLikeHintWithText>
      </Box>
      <Box
        sx={{
          mt: OFFSET.sm,
          mb: OFFSET.sm,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      />
      {/* TODO надо id-шник передать */}
      <MetabaseIframe dashboard={14} params={{ id: `${scoringId}` }} />
    </Box>
  );
});
