import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { TransitionGroup } from 'react-transition-group';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import List from '@mui/material/List';
import { Box } from '@mui/system';
import { ListItem, Slide } from '@mui/material';

import { useRootStore } from '@/stores';

const slideTransition = (props: any) => {
  return <Slide {...props} direction="left" />;
};

type AlertPropsMutate = AlertProps & {
  message: string;
  index: any;
  id: number;
  removeItem: (id: number) => void;
  forceHide: boolean;
};

const Alert = React.forwardRef<HTMLDivElement, AlertPropsMutate>(function Alert(
  { index, forceHide, removeItem, id, ...props },
  ref,
) {
  const [open, setOpen] = React.useState(true);

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
    if (removeItem) {
      removeItem(id);
    }
  };

  React.useEffect(() => {
    if (forceHide) {
      handleClose();
    }
  }, [forceHide, handleClose]);

  return (
    <Snackbar
      open={open}
      key={id}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      sx={{ zIndex: 1400 - 10 * index }}
      TransitionComponent={slideTransition}
    >
      <MuiAlert
        elevation={6}
        ref={ref}
        variant="filled"
        onClose={handleClose}
        severity={props.severity}
        sx={{ width: '100%', mb: `${24 + index * 65}px`, whiteSpace: 'pre-line' }}
      >
        {props.message}
      </MuiAlert>
    </Snackbar>
  );
});

export const CustomizedSnackbars = observer(() => {
  const MAX_ITEM_IN_QUE = 4;

  const { requestSuccess, setRequestSuccess, requestError, setRequestError, localWarning, setLocalWarning } = useRootStore();

  const [queue, setQueue] = React.useState<any[]>([]);
  const [timer, setTimer] = React.useState<any | undefined>(undefined);

  const updateIndex = () => {
    setQueue((que) => {
      const updatedQueIndex = que.map((que, index) => {
        que.index = index;
        return que;
      });
      return [...updatedQueIndex];
    });

    if (timer) {
      clearTimeout(timer);
      setTimer(undefined);
    }
  };

  const removeItemFromQue = (queueItemId: any) => {
    setQueue((que) => {
      const newQue = que.filter(({ id }) => id !== queueItemId);
      return [...newQue];
    });

    if (timer) {
      clearTimeout(timer);
    }

    setTimer(setTimeout(updateIndex, 500));
  };

  React.useEffect(() => {
    if (localWarning || requestError) {
      const nextItem = {
        message: localWarning ?? requestError,
        severity: requestError ? 'error' : 'warning',
        id: new Date().getTime(),
        index: queue?.length,
      };

      setQueue((prevItems) => {
        return [...prevItems, nextItem];
      });

      setRequestError(undefined);
      setLocalWarning(undefined);
    }
  }, [requestError, localWarning, setRequestError, setLocalWarning, queue?.length]);

  React.useEffect(() => {
    if (requestSuccess) {
      const nextItem = {
        message: requestSuccess,
        severity: 'success',
        id: new Date().getTime(),
        index: queue?.length,
      };

      setQueue((prevItems) => {
        return [...prevItems, nextItem];
      });

      setRequestSuccess(undefined);
    }
  }, [requestSuccess, setRequestSuccess, queue?.length]);

  return (
    <Box>
      <List>
        <TransitionGroup>
          {queue.map((que) => (
            <ListItem key={que.id}>
              <Alert
                {...que}
                key={que.id}
                removeItem={removeItemFromQue}
                forceHide={queue.length > MAX_ITEM_IN_QUE ? que.index - (queue.length - MAX_ITEM_IN_QUE) < 0 : false}
              />
            </ListItem>
          ))}
        </TransitionGroup>
      </List>
    </Box>
  );
});
