import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box } from '@mui/system';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { SvgIcon, Tooltip } from '@mui/material';

import { TableRowStyled, TableTemplate } from '@/components/Templates/TableTemplate';
import type { IAssessmentAttr, ITableBodyAssessment } from '@/stores/freskoApi/AdministrationAssessmentStore';
import { useRootStore } from '@/stores';
import { Badge, BadgeColorsMap } from '@/components/atoms/Bage/Badge';
import { Char, Patch } from '@/assets/index';
import { TableCellStyled } from '@/components/Templates/TableComponentStyled';
import { dateFormatterWithHoursMinutes, formatSum, mathRoundTwoDecimalsAssessment } from '@/utils';
import { ButtonStyled } from '@/components/atoms/Button';
import { LabelLikeHintWithText } from '@/components/common/LabelLikeHintWithText';
import { TableTemplateWithoutStore } from '@/components/Templates/TableTemplateWithoutStore';
import { BOOLEAN_LOWER_MAP_TO_DISPLAYNAME } from '@/components/Administering/ModelEvaluation/ModelEvaluationDetail/ModelEvaluationDetailTab/ModelEvaluationDetailTabAttributes/ModelEvaluationAttributeTabParams';
import { TYPE_PARAMS } from '@/constants';
import { DrawerCustom } from '@/components/atoms/DrawerCustom';
import { DrawerLikeModal } from '@/components/common/DrawerLikeModal';
import { withEditable } from '@/hoc/withEditable';
import { EditableField, TableCellEditable } from '@/components/Templates/TableCellEditable';
import { useValidation } from '@/hooks/useValidation';
import { EditButtons } from '@/components/Templates/EditButtons';
import { CombineFilterAndCards } from './components/CombineFilterAndCards';
import { color } from '@/constants/colors';
import {runInAction} from "mobx";

const tableHeader = ['#', 'Модель', 'Дата оценки', 'Статус', 'Оценка', 'Информация', 'Детали'];

const STATUS_ASSESSMENT = {
  Created: 'Новая',
  ActionRequired: 'Требуется действие',
  SuccessfullyAssessed: 'Успешно',
  Warning: 'Внимание',
  Failed: '4 - Ошибка',
  Changed: 'Изменен',
};

const RenderRowAssessment = ({
  row,
  filled,
  changeDetail,
  index,
}: {
  row: ITableBodyAssessment;
  filled: boolean;
  changeDetail: (row: ITableBodyAssessment) => void;
  index: number;
}) => {
  const { id, model, object, dateAssessment, status, score, helpers } = row;

  const onRowClick = () => {
    changeDetail(row);
  };

  return (
    <TableRowStyled
      key={row.id}
      sx={{ backgroundColor: filled ? color.light : color.transparent }}
      onClick={onRowClick}
    >
      <TableCellStyled>{index + 1}</TableCellStyled>
      <TableCellStyled>
        {' '}
        <LabelLikeHintWithText reverse title={object} value={model} />
      </TableCellStyled>
      <TableCellStyled>{dateFormatterWithHoursMinutes(dateAssessment)}</TableCellStyled>
      <TableCellStyled>
        <Badge type={status}>{STATUS_ASSESSMENT[status as keyof typeof STATUS_ASSESSMENT] ?? status}</Badge>
      </TableCellStyled>
      <TableCellStyled>
        <Badge type={status}>{mathRoundTwoDecimalsAssessment(score ?? 0)}</Badge>
      </TableCellStyled>
      <TableCellStyled>
        {helpers?.map((item) => {
          const val = Object.values(item ?? {});
          return (
            <Box mr="4xp" mb="4px">
              <Badge type="Inactive">{val?.[0]}</Badge>
            </Box>
          );
        })}
      </TableCellStyled>
      <TableCellStyled align="right">
        <Char />
      </TableCellStyled>
    </TableRowStyled>
  );
};

export const Assessment = observer(() => {
  const {
    administrationAssessmentStore,
    isLoading,
    uiStateStore: { open },
  } = useRootStore();
  const { id: idData } = useParams<{ id: string }>();
  const [detail, setDetail] = useState<ITableBodyAssessment | undefined>(undefined);
  const [openAdd, setOpenAdd] = useState<boolean>(false);
  const [badge, setBadge] = useState<Record<string, string | number>[]>([]);

  const toggleOpen = () => {
    setOpenAdd(!openAdd);
    if (openAdd) {
      setBadge([]);
    }
  };

  useEffect(() => {
    runInAction(() => {
      administrationAssessmentStore.selectedOrderId = idData;

      return () => (administrationAssessmentStore.selectedOrderId = undefined);
    })
  }, [idData]);

  useEffect(() => {
    administrationAssessmentStore.getAssessment();
  }, [administrationAssessmentStore.selectedOrderId]);

  useEffect(() => {
    if (idData) {
      administrationAssessmentStore.getAssessmentGetAvailableAsmntTmpl(idData);
    }
  }, [administrationAssessmentStore, idData]);

  if (detail) {
    return <AssessmentDetails setDetail={setDetail} detail={detail} />;
  }

  const handleSaveForm = () => {
    if (idData) {
      administrationAssessmentStore.makeNewAssessmentById({ id: idData, bundleData: badge });
      toggleOpen();
    }
  };

  return (
    <>
      <TableTemplate<ITableBodyAssessment>
        tableHeader={tableHeader}
        RenderComponent={(props) => <RenderRowAssessment key={props.index} {...props} changeDetail={setDetail} />}
        store={administrationAssessmentStore}
        isLoading={isLoading}
      />
      <DrawerCustom open={open}>
        <ButtonStyled
          text="Выполнить оценку"
          isLoading={administrationAssessmentStore.isLoading}
          onClick={toggleOpen}
        />
        <DrawerLikeModal
          title="Выполнить оценку"
          open={openAdd}
          toggleOpen={toggleOpen}
          saveForm={handleSaveForm}
          buttonConfirmText={`Выполнить оценку ${badge.length > 0 ? `(${badge.length})` : ''}`}
          isDisabled={isLoading}
        >
          <CombineFilterAndCards setBadge={setBadge} />
        </DrawerLikeModal>
      </DrawerCustom>
    </>
  );
});

const likeTableHeader = ['#', 'Атрибут', 'Значение', 'Оценка', 'Метки', ''];

const RenderScore = ({
  status,
  value_type,
  score,
  boolean_score,
  is_boolean_score,
}: {
  status?: string;
  value_type?: string;
  score: number;
  boolean_score?: boolean;
  is_boolean_score?: boolean;
}) => {
  if (value_type === TYPE_PARAMS.Helper || (score === null && boolean_score === null)) {
    return null;
  }

  return (
    <Badge type={status as any}>
      {is_boolean_score
        ? BOOLEAN_LOWER_MAP_TO_DISPLAYNAME[boolean_score?.toString() as keyof typeof BOOLEAN_LOWER_MAP_TO_DISPLAYNAME]
        : mathRoundTwoDecimalsAssessment(score)}
    </Badge>
  );
};

const RenderRowTabAttributesTable = ({
  row,
  filled,
  isEdit,
  handleOpenEdit,
  handleCloseEdit,
}: {
  row: IAssessmentAttr;
  filled: boolean;
  isEdit: boolean;
  handleOpenEdit: () => void;
  handleCloseEdit: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}) => {
  const { administrationAssessmentStore } = useRootStore();
  const { value, isError, formHasErrorRaw, setFormHasError, handleChangeInput } = useValidation();

  const {
    id,
    value: valueFromResponse,
    score,
    tags,
    status,
    name,
    data_obj_attr_name,
    boolean_score,
    value_type,
    sequence,
    is_boolean_score,
    error_msg,
  } = row;

  const handleEditDone = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const subValues = () => {
      const valToString = value.value?.toString();
      if (value_type === TYPE_PARAMS.Categorical) {
        return { category_value: Number.isNaN(+value.value) ? valToString : +value.value, value: valToString };
      }
      if (value_type === TYPE_PARAMS.Interval) {
        return { number_value: +value.value, value: valToString };
      }
      if (value_type === 'Boolean') {
        return { boolean_value: value.value, value: valToString };
      }
    };

    if (value?.value && id && row.assessment) {
      administrationAssessmentStore.patchAssessmentAttrById({
        asmnt_pk: row.assessment,
        id,
        data: {
          status: 'Changed',
          ...subValues(),
        },
      });
    }

    handleCloseEdit(e);
  };

  return (
    <TableRowStyled key={id} sx={{ backgroundColor: filled ? color.light : color.transparent }} onClick={() => {}}>
      <TableCellStyled verticalAlign="inherit">
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box>{sequence}</Box>
          <Box ml="8px" sx={{ margin: 'auto' }}>
            {status !== 'SuccessfullyAssessed' &&
              status !== 'SuccessfullyAssessed' &&
              status !== 'Changed' &&
              error_msg && (
                <Tooltip title={error_msg} followCursor>
                  <SvgIcon>
                    <WarningAmberIcon sx={{ color: BadgeColorsMap[status as string] }} />
                  </SvgIcon>
                </Tooltip>
              )}
            {status === 'Changed' && (
              <Box
                sx={{
                  display: 'flex',
                  width: '24px',
                  height: '24px',
                }}
              >
                <Patch />
              </Box>
            )}
          </Box>
        </Box>
      </TableCellStyled>
      <TableCellStyled verticalAlign="middle">
        <LabelLikeHintWithText reverse title={data_obj_attr_name} value={name} />
      </TableCellStyled>
      <TableCellEditable
        initialVal={
          (valueFromResponse && Number.isNaN(+valueFromResponse)) ||
          valueFromResponse === null ||
          valueFromResponse === ''
            ? valueFromResponse
            : valueFromResponse && formatSum(+valueFromResponse, true)
        }
        editVal={valueFromResponse}
        fieldName="value"
        handleChange={handleChangeInput}
        type={value_type === 'Boolean' ? EditableField.dropdown : EditableField.inputRequired}
        isEdit={isEdit}
        setFormHasError={setFormHasError}
        formHasErrorRaw={formHasErrorRaw}
        dropdownInfo={{
          dropdownItems: [
            {
              label: BOOLEAN_LOWER_MAP_TO_DISPLAYNAME.false,
              // @ts-ignore
              value: false,
            },
            {
              label: BOOLEAN_LOWER_MAP_TO_DISPLAYNAME.true,
              // @ts-ignore
              value: true,
            },
          ],
        }}
        required
      />
      <TableCellStyled verticalAlign="inherit">
        <RenderScore
          status={status}
          value_type={value_type}
          // @ts-ignore
          score={score}
          boolean_score={boolean_score}
          is_boolean_score={is_boolean_score}
          error_message={error_msg}
        />
      </TableCellStyled>
      <TableCellStyled verticalAlign="inherit">
        <Box sx={{ display: 'flex', justifyContent: 'start', flexWrap: 'wrap' }}>
          {tags?.split(';')?.map((tagName) => (
            <Box mr="8px" sx={{ display: 'flex' }} key={tagName}>
              <Badge type="New">{tagName}</Badge>
            </Box>
          ))}
        </Box>
      </TableCellStyled>
      <EditButtons
        isEdit={isEdit}
        isError={isError}
        handleOpen={handleOpenEdit}
        handleClose={handleCloseEdit}
        handleSave={handleEditDone}
      />
    </TableRowStyled>
  );
};

const RenderRowTabAttributesTableWithEdit = withEditable(RenderRowTabAttributesTable);

const AssessmentDetails = observer(
  ({
    detail,
    setDetail,
  }: {
    setDetail: (row?: ITableBodyAssessment | undefined) => void;
    detail: ITableBodyAssessment;
  }) => {
    const { administrationAssessmentStore, isLoading } = useRootStore();
    const { id, dateAssessment, status, score, asmnt_session_uuid, model, object, helpers } = detail;

    useEffect(() => {
      administrationAssessmentStore.getAssessmentAttrById(id);
    }, [id]);

    const onArrowClick = () => {
      setDetail(undefined);
    };

    const reAssessment = () => {
      administrationAssessmentStore.reAssessmentById({ id, data: { asmnt_session_uuid } })?.then(() => {
        administrationAssessmentStore.getAssessmentById(id).then((data) => {
          setDetail(data);
          administrationAssessmentStore.getAssessment();
        });
      });
    };

    return (
      <>
        <ArrowBackIcon
          onClick={onArrowClick}
          sx={{
            color: color.light_green,
            marginBottom: '19px',
          }}
        />
        <Box
          sx={{
            display: 'flex',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexBasis: '250px',
              backgroundColor: color.pale_green,
              padding: '24px',
              justifyContent: 'center',
              borderRadius: '5px',
              flexDirection: 'column',
              height: 'fit-content',
              marginRight: '17px',
            }}
          >
            <LabelLikeHintWithText mb="14px" title="Модель" value={model} />
            <LabelLikeHintWithText mb="14px" title="Объект" value={object} />
            <LabelLikeHintWithText
              mb="14px"
              title="Дата оценки"
              value={dateFormatterWithHoursMinutes(dateAssessment)}
            />
            <LabelLikeHintWithText mb="14px" title="Статус">
              {' '}
              <Badge type={status}>{STATUS_ASSESSMENT[status as keyof typeof STATUS_ASSESSMENT] ?? status}</Badge>
            </LabelLikeHintWithText>
            <LabelLikeHintWithText mb="14px" title="Оценка">
              {' '}
              <Badge type={status}>{mathRoundTwoDecimalsAssessment(score)}</Badge>
            </LabelLikeHintWithText>
            {helpers?.length && helpers?.length > 0 ? (
              helpers?.map((item) => {
                const entr = Object.entries(item)?.[0];
                return <LabelLikeHintWithText mb="14px" title={entr?.[0]} value={entr?.[1]} />;
              })
            ) : (
              <></>
            )}
            <ButtonStyled
              text="Рассчитать"
              onClick={reAssessment}
              isLoading={isLoading || administrationAssessmentStore.isLoading}
            />
          </Box>
          {administrationAssessmentStore.assessmentAttributes ? (
            <TableTemplateWithoutStore<IAssessmentAttr>
              isHideBorders
              tableHeader={likeTableHeader}
              RenderComponent={(props) => <RenderRowTabAttributesTableWithEdit {...props} />}
              store={{
                renderedRows: administrationAssessmentStore.assessmentAttributes,
              }}
              isLoading={isLoading || administrationAssessmentStore.isLoading}
            />
          ) : (
            <></>
          )}
        </Box>
      </>
    );
  },
);
