import {observable, makeObservable, action, runInAction} from 'mobx';
import {apiPath} from '@/constants/api';
import {SETTINGS_STATUS_TO_STATUS} from '@/constants/backendEnum';
import {TAB_BAR_TYPE} from '@/pages/ScoringSettingsPageDetails';
import {createAxiosRequest} from '@/utils/request';
import {IRootStore} from '@/stores/type';

export type IClientSettings = {
    id: number;
    rule_name: string;
    diapason: string | null;
    score: number | null;
    weight: string | null;
    dt: string | null;
    stop_factor?: boolean;
}

export type ISetting = {
    id: number;
    version: number;
    name: string;
    status: string;
    effective_end_date: string | null;
    effective_start_date: string;
    description: string;
};

type IGetResponse<T> = {
    count: number;
    next?: string;
    previous?: string;
    results: T[];
};

export type IScoringPageStore = {
    rootStore: IRootStore;
    settings: ISetting[];
    settingsResponseIsEmpty?: boolean;
    getSettings: () => Promise<void>;

    getSettingsClientById: (id: string | number) => Promise<void>;
    settingsClient: IClientSettings[];

    getRelationClientById: (id: string | number) => Promise<void>;
    settingsRelation: IClientSettings[];

    getDebtorClientById: (id: string | number) => Promise<void>;
    settingsDebtor: IClientSettings[];

    duplicateAlgorithm: (id: string | number) => Promise<ISetting>;
    silentPatchAlgorithmData: ({url, paramsToPatch, type}: {
        url: string,
        paramsToPatch: Partial<IClientSettings>,
        type: string
    }) => Promise<void>;
    draftAlgorithmToActive: (id: string | number) => Promise<ISetting>;
}

export class ScoringPageStore implements IScoringPageStore {
    @observable
    public rootStore: IRootStore;

    @observable
    public settings: ISetting[] = [];

    @observable
    public settingsClient: IClientSettings[] = [];

    @observable
    public settingsDebtor: IClientSettings[] = [];

    @observable
    public settingsRelation: IClientSettings[] = [];

    @observable
    public settingsResponseIsEmpty: boolean = false;

    private isLoading: boolean = false;
    private isLoadingClientSettings: boolean = false;

    constructor(rootStore: IRootStore) {
        makeObservable(this);
        this.rootStore = rootStore;
    }

    @action
    getSettings = async () => {
        if (!this.isLoading) {
            this.rootStore.requestTemplate({
                errorMessage: 'Ошибка получения данных настроек скоринга',
                callback: async () => {
                    try {
                        this.isLoading = true;
                        const {data} = await createAxiosRequest<{
                            offset: number;
                            limit: number
                        }, IGetResponse<ISetting>>({
                            path: apiPath.settings,
                            method: 'GET',
                            params: {offset: 0, limit: 100},
                            useToken: true,
                        });
                        runInAction(() => {
                            if (!data?.results?.length) {
                                this.settingsResponseIsEmpty = true;
                            }
                            this.settings = data.results;
                        })
                    } catch {
                    } finally {
                        this.isLoading = false;
                    }
                }
            });
        }
    };

    @action
    getSettingsClientById = async (id: string | number) => {
        if (!this.isLoadingClientSettings) {
            this.rootStore.requestTemplate({
                immediately: true,
                errorMessage: 'Ошибка получения данных настроек скоринга клиента',
                callback: async () => {
                    this.isLoadingClientSettings = true;
                    try {
                        const {data} = await createAxiosRequest<{
                            offset: number;
                            limit: number
                        }, IGetResponse<IClientSettings>>({
                            path: apiPath.settingsClientById.replace('{id}', `${id}`),
                            params: {offset: 0, limit: 100},
                            method: 'GET',
                            useToken: true,
                        });
                        runInAction(() => {
                            this.settingsClient = data.results;
                        })
                    } finally {
                        this.isLoadingClientSettings = false;
                    }
                },
            });
        }
    };

    @action
    getDebtorClientById = async (id: string | number) => {
        this.rootStore.requestTemplate({
            errorMessage: 'Ошибка получения данных настроек скоринга дебитора',
            callback: async () => {
                const {data} = await createAxiosRequest<{
                    offset: number;
                    limit: number
                }, IGetResponse<IClientSettings>>({
                    path: apiPath.settingsDebtorById.replace('{id}', `${id}`),
                    params: {offset: 0, limit: 100},
                    method: 'GET',
                    useToken: true,
                });
                runInAction(() => {
                    this.settingsDebtor = data.results;
                })
            },
        });
    };

    @action
    getRelationClientById = async (id: string | number) => {
        this.rootStore.requestTemplate({
            errorMessage: 'Ошибка получения данных настроек скоринга связей',
            callback: async () => {
                const {data} = await createAxiosRequest<{
                    offset: number;
                    limit: number
                }, IGetResponse<IClientSettings>>({
                    path: apiPath.settingsRelationById.replace('{id}', `${id}`),
                    params: {offset: 0, limit: 100},
                    method: 'GET',
                    useToken: true,
                });
                runInAction(() => {
                    this.settingsRelation = data.results;
                })
            },
        });
    };

    @action
    duplicateAlgorithm = async (id: string | number, type?: string) => {
        return this.rootStore.requestTemplate({
            errorMessage: 'Ошибка дублирования активной настройки',
            isReturned: true,
            callback: async () => {

                this.settingsDebtor = [];
                this.settingsRelation = [];
                this.settingsClient = [];

                const {data} = await createAxiosRequest({
                    path: apiPath.settingsDuplicate.replace('{id}', `${id}`),
                    method: 'POST',
                    useToken: true,
                });

                return data;
            },
        });
    };

    @action
    silentPatchAlgorithmData = async ({url, paramsToPatch, type}: {
        url: string,
        paramsToPatch: Partial<IClientSettings>,
        type: string
    }) => {
        const {data} = await createAxiosRequest<Partial<IClientSettings>, Partial<IClientSettings>>({
            path: url,
            method: 'PATCH',
            data: paramsToPatch,
            useToken: true,
        });

        runInAction(() => {
            if (type === TAB_BAR_TYPE.DEBTOR) {
                const patchedSettingIndex = this.settingsDebtor.findIndex((item) => item.id === paramsToPatch.id);
                this.settingsDebtor[patchedSettingIndex] = {...this.settingsDebtor[patchedSettingIndex], ...data};
                this.settingsDebtor = [...this.settingsDebtor];
            } else if (type === TAB_BAR_TYPE.CLIENT) {
                const patchedSettingIndex = this.settingsClient.findIndex((item) => item.id === paramsToPatch.id);
                this.settingsClient[patchedSettingIndex] = {...this.settingsClient[patchedSettingIndex], ...data};
                this.settingsClient = [...this.settingsClient];
            } else if (type === TAB_BAR_TYPE.RELATED) {
                const patchedSettingIndex = this.settingsRelation.findIndex((item) => item.id === paramsToPatch.id);
                this.settingsRelation[patchedSettingIndex] = {...this.settingsRelation[patchedSettingIndex], ...data};
                this.settingsRelation = [...this.settingsRelation];
            }
        })
    };

    @action
    draftAlgorithmToActive = async (id: string | number) => {
        return this.rootStore.requestTemplate({
            errorMessage: 'Ошибка активации черновика',
            isReturned: true,
            callback: async () => {
                const currentDraftId = this.settings.findIndex((item) => item.id.toString() === id.toString());

                const {data} = await createAxiosRequest<{ name: string, status: string }, ISetting>({
                    path: apiPath.draftToActive.replace('{id}', `${id}`),
                    method: 'PATCH',
                    data: {
                        name: this.settings[currentDraftId].name,
                        status: SETTINGS_STATUS_TO_STATUS.Active,
                    },
                    useToken: true,
                });

                return data;
            },
        });
    };
}