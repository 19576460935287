import React from 'react';
import Typography from '@mui/material/Typography';
import { OFFSET } from '@/constants/sizes';

interface IInputLabelStyled {
  children?: React.ReactNode;
  [k: string]: any;
}

export const InputLabelStyled = ({ children, ...props }: IInputLabelStyled) => {
  return (
    <Typography
      sx={{
        mt: OFFSET.m,
        mb: OFFSET.m,
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '16px',
        color: 'black',
        whiteSpace: 'nowrap',
        ...props.sx,
      }}
    >
      {children}
    </Typography>
  );
};
