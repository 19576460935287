/* eslint-disable camelcase */
import {observable, makeObservable, action, runInAction} from 'mobx';
import {apiPath} from '@/constants/api';
import {createAxiosRequest} from '@/utils/request';
import type {TableStoreType} from '@/stores/TableStore';
import {TableStore} from '@/stores/TableStore';
import type {IRootStore} from '@/stores/type';

export interface IEmployeesStore {
    rootStore: IRootStore;
    getEmployees: () => void;
    tableBody?: ITableBodyEmployee[];
    oneTimeAllUsersLoaded: boolean;

    createEmployees: ({
                          userName,
                          lastName,
                          firstName,
                          middleName,
                          email,
                          role,
                          phone,
                          isStaff,
                          password,
                          view_set,
                      }: {
        userName: string;
        lastName?: string;
        firstName?: string;
        middleName?: string;
        email?: string;
        role?: number | null;
        phone: string;
        isStaff?: boolean;
        password: string;
        view_set: string;
    }) => Promise<IEmployee>;

    updateEmployee: (
        id: string | number,
        {
            userName,
            lastName,
            firstName,
            middleName,
            email,
            role,
            phone,
            isStaff,
            password,
            view_set,
            isMe,
        }: {
            userName: string;
            lastName?: string;
            firstName?: string;
            middleName?: string;
            email?: string;
            role?: string | null;
            phone: string;
            isStaff?: boolean;
            password?: string;
            isMe?: boolean;
            view_set: string;
        },
    ) => Promise<void>;

    deleteEmployees: ({id}: { id: string | number }) => Promise<void>;
    allEmployees: IEmployee[];
    getAllEmployee: () => void;
}

export type IEmployee = {
    pk: number;
    username: string;
    first_name?: string;
    last_name: string;
    middle_name: string;
    email: string;
    url: string;
    is_staff: boolean | null;
    phone?: string;
    role?: string;
    view_set?: string;
};

export type IGetEmployees = {
    count: number;
    next?: string;
    previous?: string;
    results: IEmployee[];
};

export type ITableBodyEmployee = {
    id: string | number;
    nickName: string;
    firstName: string | null;
    lastName: string | null;
    phone: string | null;
    email: string;
    primary: boolean | null;
    role?: string;
    middleName?: string;
    view_set: string;
};

const mapAccountToTableBody = (rawList: IEmployee[]): ITableBodyEmployee[] => {
    return rawList?.map((item) => ({
        id: item.pk,
        nickName: item.username,
        firstName: item?.first_name,
        lastName: item?.last_name,
        phone: item.phone,
        email: item.email,
        primary: item.is_staff,
        role: item?.role ?? '-',
        middleName: item?.middle_name,
        view_set: item.view_set,
    })) as ITableBodyEmployee[];
};

export type EmployeesPageStoreStoreWithTableStore<T> = IEmployeesStore & TableStoreType<T>;

export class EmployeesStore
    extends TableStore<ITableBodyEmployee>
    implements EmployeesPageStoreStoreWithTableStore<ITableBodyEmployee> {
    @observable
    public rootStore: IRootStore;

    @observable
    public allEmployees: IEmployee[] = [];

    @observable
    public isLoading: boolean = false;

    @observable
    public oneTimeAllUsersLoaded: boolean = false;

    constructor(rootStore: IRootStore) {
        super();
        makeObservable(this);
        this.rootStore = rootStore;
    }

    @action
    getEmployees = async () => {
        const {setIsLoading, setRequestError, isLoading} = this.rootStore;

        try {
            if (!isLoading) {
                setRequestError(undefined);
                setIsLoading(true);

                const {data} = await createAxiosRequest<{ offset: number; limit: number }, IGetEmployees>({
                    path: apiPath.employees,
                    method: 'GET',
                    params: {offset: 0, limit: 100},
                    useToken: true,
                });
                runInAction(() => {
                    this.summary = data.count;
                    const tableData = mapAccountToTableBody(data.results);
                    // this.tableBody = [...(this.tableBody ?? []), ...tableData];
                    this.tableBody = [...tableData];
                })
            }
        } catch (e) {
            setRequestError('Ошибка получения данных пользователей');
        } finally {
            setIsLoading(false);
        }
    };

    @action
    getAllEmployee = () => {
        this.rootStore.requestTemplate({
            errorMessage: `Ошибка получения данных всех пользователей`,
            immediately: true,
            callback: async () => {
                if (!this.isLoading) {
                    this.oneTimeAllUsersLoaded = true;
                    try {
                        this.isLoading = true;
                        const {data} = await createAxiosRequest<{ limit?: number; offset?: number }, IGetEmployees>({
                            path: `${apiPath.employees}`,
                            method: 'GET',
                            useToken: true,
                            params: {limit: this.summary ?? 100, offset: 0},
                        });
                        runInAction(() => {
                            this.allEmployees = data.results;
                        })
                    } catch {
                        // noop
                    } finally {
                        this.isLoading = false;
                    }
                }
            },
        });
    };

    @action
    createEmployees = async ({
                                 userName,
                                 lastName,
                                 firstName,
                                 middleName,
                                 email,
                                 role,
                                 phone,
                                 isStaff,
                                 password,
                                 view_set,
                             }: {
        userName: string;
        lastName?: string;
        firstName?: string;
        middleName?: string;
        email?: string;
        role?: number | null;
        phone: string;
        isStaff?: boolean;
        password: string;
        view_set: string;
    }) => {
        return this.rootStore.requestTemplate({
            errorMessage: `Ошибка получения создания пользователя`,
            finallyAction: this.getEmployees,
            isReturned: true,
            callback: async () => {
                const {data} = await createAxiosRequest<any, IEmployee>({
                    path: apiPath.employees,
                    method: 'POST',
                    data: {
                        username: userName,
                        last_name: lastName,
                        first_name: firstName,
                        middle_name: middleName,
                        email,
                        role,
                        phone,
                        is_staff: isStaff,
                        password,
                        view_set,
                    },
                    useToken: true,
                });

                return data;
            },
        });
    };

    @action
    updateEmployee = async (
        id: string | number,
        {
            userName,
            lastName,
            firstName,
            middleName,
            email,
            role,
            phone,
            isStaff,
            password,
            isMe,
            view_set,
        }: {
            userName: string;
            lastName?: string;
            firstName?: string;
            middleName?: string;
            email?: string;
            role?: string | null;
            phone: string;
            isStaff?: boolean;
            password?: string;
            isMe?: boolean;
            view_set: string;
        },
    ) => {
        if (password) {
            this.changePasswordEmployees({id, password});
        }
        this.rootStore.requestTemplate({
            immediately: true,
            errorMessage: `Ошибка обновления данных пользователя`,
            finallyAction: () => {
                this.getEmployees();
                if (isMe) {
                    this.rootStore.userStore.getProfileInfo();
                }
            },
            callback: async () => {
                await createAxiosRequest<any, any>({
                    path: apiPath.employeesById.replace('{id}', id?.toString()),
                    method: 'PATCH',
                    data: {
                        username: userName,
                        last_name: lastName,
                        first_name: firstName,
                        middle_name: middleName,
                        email,
                        role,
                        phone,
                        is_staff: isStaff,
                        view_set,
                    },
                    useToken: true,
                });
            },
        });
    };

    @action
    deleteEmployees = async ({id}: { id: string | number }) => {
        this.rootStore.requestTemplate({
            errorMessage: `Ошибка получения удаления пользователя`,
            finallyAction: this.getEmployees,
            callback: async () => {
                await createAxiosRequest<any, any>({
                    path: apiPath.employeesById.replace('{id}', `${id}`),
                    method: 'DELETE',
                    useToken: true,
                });
            },
        });
    };

    @action
    changePasswordEmployees = async ({id, password}: { id: string | number; password?: string }) => {
        this.rootStore.requestTemplate({
            immediately: true,
            successMessage: 'Пароль изменен',
            errorMessage: `Не удалось изменить пароль`,
            callback: async () => {
                await createAxiosRequest<any, any>({
                    path: apiPath.changePassword.replace('{id}', `${id}`),
                    method: 'POST',
                    data: {
                        password,
                    },
                    useToken: true,
                });
            },
        });
    };
}
