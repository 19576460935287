import type { ReactNode } from 'react';
import React, { createContext, useContext } from 'react';
import { RootStore } from '@/stores/RootStore';
import type { RootStoreModel } from '@/stores/type';

let store: RootStoreModel;

const StoreContext = createContext<RootStoreModel | undefined>(undefined);

export function RootStoreProvider({ children }: { children: ReactNode }) {
  const root = store ?? new RootStore();

  return <StoreContext.Provider value={root}>{children}</StoreContext.Provider>;
}

export function useRootStore() {
  const context = useContext(StoreContext);
  if (context === undefined) {
    throw new Error('useRootStore must be used within RootStoreProvider');
  }

  return context;
}
