import React, { Dispatch, SetStateAction, FC } from 'react';
import ruLocale from 'date-fns/locale/ru';
import { format } from 'date-fns';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { useValidation } from '@/hooks/useValidation';
import { TemplateInputWithLabel } from '@/components/atoms/TemplateInputWithLabel';

interface MonitoringDatePickerProps {
  setDateRiskSignal?: React.Dispatch<React.SetStateAction<string>>;
  setDate?: Dispatch<SetStateAction<string>>;
  placeholder?: boolean;
  width?: string;
}

export const MonitoringDatePicker: FC<MonitoringDatePickerProps> = ({
  setDate,
  setDateRiskSignal,
  placeholder,
  width,
}) => {
  const { value, handleChangeInput } = useValidation();

  const handleChange = (value: Date | null) => {
    if (value instanceof Date && !isNaN(value.getTime())) {
      const formattedDate = format(new Date(value), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");
      handleChangeInput({ date: formattedDate });
      if (setDate) {
        setDate(formattedDate);
      }
      if (setDateRiskSignal) {
        setDateRiskSignal(formattedDate);
      }
    } else {
      if (setDate) {
        setDate('');
      }
      if (setDateRiskSignal) {
        setDateRiskSignal('');
      }
    }
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ruLocale}>
        <DatePicker
          value={placeholder ? value?.date ?? new Date() : value?.date ?? null}
          onChange={handleChange}
          maxDate={new Date()}
          renderInput={(params) => (
            <TemplateInputWithLabel
              {...params}
              key="date"
              id="Дата выявления сигнала"
              name="date"
              label=""
              style={{ width: width ? width : '100%' }}
              mb={0}
              sx={{ fontSize: '13px', height: '36px' }}
            />
          )}
        />
      </LocalizationProvider>
    </>
  );
};
