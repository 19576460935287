import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { Box, IconButton, TextareaAutosize } from '@mui/material';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';

import { OFFSET } from '@/constants/sizes';
import { BreadrcrumbsWithWrapper } from '@/components/common/Breadrcrumbs';
import { LabelLikeHintWithText } from '@/components/common/LabelLikeHintWithText';
import type { TabBarItem } from '@/components/common/TabBarStyled';
import { TabBarStyled } from '@/components/common/TabBarStyled';
import { ButtonStyled } from '@/components/atoms/Button';
import {
  ModelEvaluationDetailTabData,
  ModelEvaluationAttributeTabData,
} from '@/components/Administering/ModelEvaluation/ModelEvaluationDetail/ModelEvaluationDetailTab';
import { useRootStore } from '@/stores';
import { DrawerLikeModal } from '@/components/common/DrawerLikeModal';
import { useValidation } from '@/hooks/useValidation';
import { Dropdown } from '@/components/atoms/Dropdown';
import { TemplateInputWithLabel } from '@/components/atoms/TemplateInputWithLabel';
import { ROLE, ROUTES } from '@/constants';
import { SETTINGS_STATUS, SETTINGS_STATUS_TO_STATUS, STATUS_TO_STATUS } from '@/constants/backendEnum';
import { BoxOutlined } from '@/assets';
import { StatusBadge } from '@/components/atoms/Bage/StatusBadge';
import { color } from '@/constants/colors';

const tabBarItems: TabBarItem[] = [
  {
    name: 'Данные',
    component: () => <ModelEvaluationDetailTabData />,
    permissions: [ROLE.SMARTFACT, ROLE.GENERAL, ROLE.BALTICLEASING, ROLE.PSBL],
  },
  {
    name: 'Атрибуты',
    component: () => <ModelEvaluationAttributeTabData />,
    permissions: [ROLE.SMARTFACT, ROLE.GENERAL, ROLE.BALTICLEASING, ROLE.PSBL],
  },
];

export const ACTION_BY_STATUS = {
  [SETTINGS_STATUS_TO_STATUS.Active]: {
    button: {
      name: 'Изменить',
      icon: <BoxOutlined stroke={color.white} />,
    },
  },
  [SETTINGS_STATUS_TO_STATUS.Draft]: {
    button: {
      name: 'Активировать',
      icon: <PlayArrowOutlinedIcon />,
    },
  },
  [SETTINGS_STATUS_TO_STATUS.Archive]: {
    button: {
      name: undefined,
      icon: undefined,
      action: undefined,
    },
  },
};

const useButtonSettings = () => {
  const { administrationAssessmentTemplateStore } = useRootStore();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const currentSetting = administrationAssessmentTemplateStore.selectedAdministrationAssessmentTemplateById;

  const buttonSettings = useMemo(
    () => currentSetting && ACTION_BY_STATUS[currentSetting.status as keyof typeof SETTINGS_STATUS_TO_STATUS]?.button,
    [currentSetting],
  );

  const buttonAction = () => {
    if (currentSetting) {
      let activateAction = () => {
        console.error(`проблема со статусом ${currentSetting.status}, или id ${id}`);
      };
      if (currentSetting.status === SETTINGS_STATUS_TO_STATUS.Active && id) {
        activateAction = async () => {
          const response = await administrationAssessmentTemplateStore.reviseModelVersion({ modelId: id });

          if (response) {
            // обнуляем данные что бы автоматом перезапросить после редиректа
            navigate(`${ROUTES.settings}${ROUTES.administration}/model/${response.id}`, { replace: true });
          }
        };
      } else if (currentSetting.status === SETTINGS_STATUS_TO_STATUS.Draft && id) {
        activateAction = async () => {
          const response = await administrationAssessmentTemplateStore.updateModelVersion({
            modelId: id,
            status: SETTINGS_STATUS_TO_STATUS.Active,
          });
          // обнуляем данные что бы автоматом перезапросить после редиректа
          if (response) {
            navigate(`${ROUTES.settings}${ROUTES.administration}/model/${response.id}`, { replace: true });
          }
        };
      }

      return activateAction();
    }
  };

  const type = () => {
    if (currentSetting?.status === SETTINGS_STATUS_TO_STATUS.Draft) {
      return STATUS_TO_STATUS.New;
    }

    if (currentSetting?.status === SETTINGS_STATUS_TO_STATUS.Active) {
      return STATUS_TO_STATUS.Active;
    }

    return STATUS_TO_STATUS.Inactive;
  };

  return {
    buttonSettings,
    buttonAction,
    type,
  };
};

export const ModelEvaluationDetail = observer(() => {
  const { id: idData } = useParams<{ id: string }>();
  const [selectedTab, setSelectedTab] = useState(tabBarItems[0]);
  const { administrationAssessmentTemplateStore, userStore } = useRootStore();
  const navigate = useNavigate();

  const onChangeTab = (currentTab: string) => {
    setSelectedTab(tabBarItems.find(({ name }) => name === currentTab) ?? tabBarItems[0]);
  };

  useEffect(() => {
    if (idData) {
      administrationAssessmentTemplateStore.getAdministrationAssessmentTemplateById(idData);
    }
  }, [administrationAssessmentTemplateStore, idData]);

  const { name, status, version, context_object_name, created_by, updated_by } =
    administrationAssessmentTemplateStore.selectedAdministrationAssessmentTemplateById || {};

  const { buttonSettings, buttonAction, type } = useButtonSettings();

  return (
    <>
      <Box mb={OFFSET.xxxl}>
        <BreadrcrumbsWithWrapper
          breadCrumbItems={[
            {
              name: 'Модели оценки',
              action: () => navigate(`${ROUTES.settings}#1`),
            },
            {
              name: name ?? '-',
            },
          ]}
        />
        <Box
          sx={{
            height: '88px',
            mrgingTop: '24px',
            background: color.light,
            border: `1px solid ${color.pale_green}`,
            borderRadius: '4px',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexDirection: 'row',
            display: 'flex',
            padding: '0 24px',
          }}
        >
          <LabelLikeHintWithText title="Название" value={name} />
          <LabelLikeHintWithText title="Объект оценки" value={`${context_object_name}`} />
          <LabelLikeHintWithText title="Автор" value={updated_by ?? created_by} />
          <LabelLikeHintWithText title="Статус">
            <StatusBadge
              type={type() as any}
              text={SETTINGS_STATUS[status as keyof typeof SETTINGS_STATUS]}
              children={undefined}
            />
          </LabelLikeHintWithText>
          <LabelLikeHintWithText title="Версия" value={version} />
          {buttonSettings && buttonSettings.name && (
            <ButtonStyled text={buttonSettings.name} startIcon={buttonSettings.icon} onClick={buttonAction} />
          )}
          {status === SETTINGS_STATUS_TO_STATUS.Draft && <ModalEditParent />}
        </Box>
        <Box
          sx={{
            mt: OFFSET.sm,
            mb: OFFSET.sm,
            display: 'flex',
            justifyContent: 'space-between',
            borderBottom: `1px solid ${color.gray}`,
          }}
        >
          <TabBarStyled
            items={tabBarItems}
            selected={selectedTab}
            onChangeTab={onChangeTab}
            userRole={userStore.profileInfo?.view_set}
          />
        </Box>
        {selectedTab.component()}
      </Box>
    </>
  );
});

const templateItem = ({ name, version, description }: { name?: string; version?: number; description?: string }) => [
  {
    id: 'name',
    label: 'Название',
    require: true,
    disabled: false,
    value: name,
  },
  {
    id: 'description',
    label: 'Описание',
    value: description,
  },
];

export const ModalEditParent = observer(() => {
  const { businessUnitStore, userStore, administrationAssessmentTemplateStore, isLoading } = useRootStore();

  const [buDisabled, setBuDisabled] = useState<boolean>(false);
  const { value, onChangeDropdown, handleChangeInput, prevValues, clearPrevValues } = useValidation();
  const [openAdd, setOpenAdd] = useState<boolean>(false);
  const { id: idData } = useParams<{ id: string }>();

  const { context_name, context_object_name, is_suitable, name, version, description, bu } =
    administrationAssessmentTemplateStore.selectedAdministrationAssessmentTemplateById;

  const closeModal = () => {
    setOpenAdd(false);
  };

  const openModal = () => {
    setOpenAdd(true);
  };
  const toggleOpen = () => {
    if (openAdd) {
      clearPrevValues();
      onChangeDropdown('context_object_name', context_object_name);
      onChangeDropdown('context_name', context_name);
      return closeModal();
    }

    return openModal();
  };

  useEffect(() => {
    if (context_name && context_object_name) {
      administrationAssessmentTemplateStore.getDataContextBy({ context_name, context_object_name });
    }
  }, [administrationAssessmentTemplateStore, context_name, context_object_name]);

  useEffect(() => {
    setBuDisabled(!userStore.profileInfo?.is_staff);
    onChangeDropdown('bu', bu);
  }, [userStore.profileInfo?.is_staff, userStore.profileInfo?.pk]);

  useEffect(() => {
    administrationAssessmentTemplateStore.getDropdownContextValues();
  }, []);

  useEffect(() => {
    return () => {
      administrationAssessmentTemplateStore.dataContext = [];
    };
  }, []);

  useEffect(() => {
    if (prevValues?.context_name && prevValues?.context_name !== value?.context_name) {
      onChangeDropdown('context_object_name', undefined);
    }
  }, [onChangeDropdown, prevValues?.context_name, value?.context_name]);

  useEffect(() => {
    onChangeDropdown('context_object_name', context_object_name);
    onChangeDropdown('context_name', context_name);
  }, []);

  const contextField = administrationAssessmentTemplateStore.dataContextDropdown?.map(({ name }) => ({
    label: name,
    value: name,
  }));

  const contextObjNameField = useMemo(() => {
    let dataPreset = administrationAssessmentTemplateStore.dataContextObjectDropdown;

    if (value?.context_name) {
      const { id: selectedDataContextId } =
        administrationAssessmentTemplateStore.dataContextDropdown?.find(({ name }) => name === value.context_name) ||
        {};

      dataPreset = dataPreset.filter(({ data_context }) => data_context === selectedDataContextId);
    }

    return dataPreset?.map(({ name }) => ({
      label: name,
      value: name,
    }));
  }, [
    administrationAssessmentTemplateStore.dataContextDropdown,
    administrationAssessmentTemplateStore.dataContextObjectDropdown,
    value.context_name,
  ]);

  const dropdownItems = useMemo(() => {
    if (businessUnitStore.allBusinessUnits) {
      return businessUnitStore.allBusinessUnits.map(({ id, name }) => ({
        label: name,
        value: id,
      }));
    }

    return [];
  }, [businessUnitStore.allBusinessUnits]);

  const handleSaveForm = async (event: React.FormEvent<HTMLFormElement>) => {
    const data = new FormData(event.currentTarget);
    const name = data.get('name') as string;
    const description = data.get('description') as string;
    const context_name = value?.context_name;
    const context_object_name = value?.context_object_name;
    const is_suitable = value?.is_suitable;
    const bu = value?.bu;

    if (idData) {
      administrationAssessmentTemplateStore.updateModel({
        modelId: idData,
        dataModel: {
          description,
          name,
          context_object_name,
          context_name,
          is_suitable,
          bu,
        },
      });
    }

    closeModal();
  };

  return (
    <>
      <IconButton aria-label="edit">
        <ModeEditOutlinedIcon sx={{ color: color.mountain_meadow }} onClick={openModal} />
      </IconButton>
      <DrawerLikeModal
        title="Изменить модель"
        open={openAdd}
        toggleOpen={toggleOpen}
        saveForm={handleSaveForm}
        buttonConfirmText="Изменить модель"
        // @ts-ignore
        items={templateItem({ name, version, description })}
        isDisabled={isLoading || !value?.context_name || !value?.context_object_name}
      >
        <Dropdown
          mb="16px"
          onChange={onChangeDropdown}
          key="context_name"
          label="Контекст"
          title=""
          dropdownWidth="285px"
          id="context_name"
          value={value?.context_name}
          dropdownItems={contextField}
          required
        />
        <Dropdown
          mb="16px"
          dropdownWidth="285px"
          onChange={onChangeDropdown}
          key="context_object_name"
          label="Объект оценки"
          title="Объект оценки"
          id="context_object_name"
          forceSet
          value={value?.context_object_name}
          dropdownItems={contextObjNameField}
          required
        />

        <Dropdown
          mb="16px"
          disabled={buDisabled}
          onChange={onChangeDropdown}
          key="bu"
          label="Подразделение"
          disabledPlaceholder={false}
          title="Подразделение"
          id="bu"
          value={value?.bu}
          dropdownItems={dropdownItems}
          required
        />
        <TemplateInputWithLabel key="is_suitable" id="is_suitable" name="is_suitable" label="Условие">
          <TextareaAutosize
            aria-label="Выражение"
            placeholder="Выражение"
            value={value.is_suitable ?? is_suitable}
            onChange={(e) => handleChangeInput({ is_suitable: e.target.value })}
            maxRows={2}
            style={{
              maxWidth: '262px',
              width: '262px',
              maxHeight: '30px',
              fontSize: '16px',
              padding: '8px 10px',
              fontFamily: 'Raleway',
            }}
          />
        </TemplateInputWithLabel>
      </DrawerLikeModal>
    </>
  );
});
