import {action, makeObservable, observable, runInAction} from 'mobx';
import { apiPath } from '@/constants/api';
import { createAxiosRequest } from '@/utils/request';
import { TableStoreType, TableStore } from '@/stores/TableStore';
import { IRootStore } from '@/stores/type';

export type ICompanyGroupsStore = {
  getCompanyGroups: ({ name }: { name?: string }) => void;
  getCompanyGroupsById: ({ id }: { id: string }) => Promise<TableBodyCompanyGroup>;
  createCompanyGroup: ({ name }: { name: string }) => Promise<TableBodyCompanyGroup>;
  results: TableBodyCompanyGroup;
};

export type GetCompanyGroups = {
  count: number;
  next?: string;
  previous?: string;
  results: TableBodyCompanyGroup[];
};

export type TableBodyCompanyGroup = {
  id?: number;
  name: string;
};

export type ICompanyGroupsStoreWithITableStore<T> = ICompanyGroupsStore & TableStoreType<T>;

export class CompanyGroupsStore
  extends TableStore<TableBodyCompanyGroup>
  implements ICompanyGroupsStoreWithITableStore<TableBodyCompanyGroup>
{
  @observable
  public rootStore: IRootStore;

  @observable
  public results: TableBodyCompanyGroup;

  constructor(rootStore: IRootStore) {
    super();
    makeObservable(this);
    this.rootStore = rootStore;
  }

  @action
  getCompanyGroups = () => {
    this.rootStore.requestTemplate({
      errorMessage: `Ошибка получения данных группы контрагента`,
      callback: async () => {
        const isSearch = this.searchString || this.searchString?.trim();

        const limit = isSearch ? 10 : this.rowsPerPageChange;
        const offset = isSearch ? 0 : this.rowsPerPageChange * this.selectedPage;

        const searchString = isSearch ? this.searchString?.trim() : undefined;

        const { data } = await createAxiosRequest<
          { name?: string; search?: string; limit?: number; offset?: number },
          GetCompanyGroups
        >({
          path: `${apiPath.companyGroups}`,
          method: 'GET',
          useToken: true,
          params: { search: searchString, limit, offset },
        });
        runInAction(() => {
          this.limit = limit;
          this.summary = data.count;
          this.offset = offset;
          this.tableBody = [...data.results];
        })
      },
    });
  };

  @action
  getCompanyGroupsById = ({ id }: { id: string }) => {
    return this.rootStore.requestTemplate({
      isReturned: true,
      errorMessage: `Ошибка получения данных группы контрагента ${id}`,
      callback: async () => {
        const { data } = await createAxiosRequest<
          { name: string; search: string; limit?: number; offet?: number },
          TableBodyCompanyGroup
        >({
          path: `${apiPath.companyGroupsById?.replace('{id}', id)}`,
          method: 'GET',
          useToken: true,
        });
        runInAction(() => {
          this.results = data;
        })
        return data;
      },
    });
  };

  @action
  createCompanyGroup = ({ name }: { name: string }): Promise<TableBodyCompanyGroup> => {
    return this.rootStore.requestTemplate({
      errorMessage: `Ошибка создания группы контрагента ${name}`,
      isReturned: true,
      callback: async () => {
        const { data } = await createAxiosRequest<{ name: string }, TableBodyCompanyGroup>({
          path: `${apiPath.companyGroups}`,
          method: 'POST',
          data: { name },
          useToken: true,
        });

        return data;
      },
    });
  };
}
