import { observable, makeObservable, action, reaction, runInAction } from 'mobx';
import { FILTER_TYPE } from '@/constants';
import { apiPath } from '@/constants/api';
import type { STATUS, RISK_LEVEL, PROBLEM_LEVEL } from '@/constants/backendEnum';
import type { IButtonGroupItem } from '@/constants/types';
import { createAxiosRequest } from '@/utils/request';
import type { TableStoreType } from '@/stores/TableStore';
import { TableStore } from '@/stores/TableStore';
import type { IContragentsStore, IRootStore } from '@/stores/type';
import { formattedDateToday } from '@/utils';

export interface Owner {
  amount: number;
  con_counterpaty: number;
  dt: string;
  fio_owner: string;
  id: number;
  innfl: string;
  part: number;
  fullname?: string;
}

export interface IAccountWIthDetail extends IAccount {
  principalactivity_code: string;
  principalactivity_name: string;
  staff_fio: string;
  staff_role: string;
  owners: Owner[];
  phones: string;
  company_group?: number;
  company_description?: string;
  site?: string;
  activities?: string;
  segment?: string;
  address?: string;
  registrationdate?: string;
  complementary_activities?: {
    code?: string;
    text?: string;
    date?: string;
  } | null;
  account_extension?: {
    id?: number;
    limit?: number;
    amountpp?: number;
    amountdt?: number;
    provision?: string;
    product?: string;
    scheme?: string;
    percent?: number;
    account?: number;
    source?: string;
  } | null;
  industry: string;
}

type AccountPostResult = {
  inn: string;
  status: string;
  id: number;
};

export type IAccount = {
  cntrprt?: number;
  created?: string;
  name?: string;
  head: {
    fio?: string;
    position?: string;
    innfl?: string;
  };
  primary_contact?: string;
  position?: string;
  fio?: string;
  id: number;
  inn: string;
  is_phys_legal_addr?: boolean;
  primary_email?: string;
  primary_phone?: string;
  status?: keyof typeof STATUS;
  updated?: string;
  risk_level: keyof typeof RISK_LEVEL; // "LOW, HIGH"
  risk_level_display: string;
  kpp?: string;
  created_by?: {
    pk: number;
    username?: string;
    last_name?: string;
    first_name?: string;
    middle_name?: string;
    email?: string;
    url?: string;
    role?: string;
    phone?: string;
  };
  is_has_limit: boolean;
  is_has_charge: boolean;
  account_extension?: {
    id?: number;
    limit?: number;
    amountpp?: number;
    amountdt?: number;
    provision?: string;
    product?: string;
    scheme?: string;
    percent?: number;
    account?: number;
  } | null;
  company_group_name?: string;
  ctrl_company_group?: string;
  problem_level?: keyof typeof PROBLEM_LEVEL;
  user_company_groups?: string[];
  results?: AccountPostResult[];
};

export type IGetAccounts = {
  count: number;
  next?: string;
  previous?: string;
  results: IAccount[];
};

type Counts = {
  updated: number;
  created: number;
  error: number;
};

export type TableBodyContragents = {
  id: string | number;
  inn: string;
  name: string;
  status: keyof typeof STATUS;
  manager: string;
  contact: string;
  monitoring: string;
  isHasLimit: boolean;
  clientManager: string;
  isHasLoad: boolean;
  companyGroupName: string;
  ctrlCompanyGroup: string;
  problemLevel: keyof typeof PROBLEM_LEVEL;
  userCompanyGroups: string[];
};

export type IContragentsStoreWithITableStore<T> = IContragentsStore & TableStoreType<T>;

export class ContragentsStore
  extends TableStore<TableBodyContragents>
  implements IContragentsStoreWithITableStore<TableBodyContragents>
{
  @observable
  public rootStore: IRootStore;

  @observable
  public results: IAccount[];

  @observable
  public selectedFilter?: IButtonGroupItem<string>;

  @observable
  public selectedContragent?: IAccountWIthDetail;

  @observable
  public selectedProblemLevel?: string;

  @observable
  public selectedLimitCharge?: string;

  @observable
  public selectedCompanyGroups?: string;

  private isLoading: boolean = false;

  constructor(rootStore: IRootStore) {
    super();
    makeObservable(this);
    this.rootStore = rootStore;

    reaction(
      () => this.selectedProblemLevel,
      (value, previousValue) => {
        if (previousValue !== value && previousValue !== undefined) {
          this.getAccounts();
        }
      },
    );

    reaction(
      () => this.selectedLimitCharge,
      (value, previousValue) => {
        if (previousValue !== value && previousValue !== undefined) {
          this.getAccounts();
        }
      },
    );

    reaction(
      () => this.selectedCompanyGroups,
      (value, previousValue) => {
        if (previousValue !== value && previousValue !== undefined) {
          this.getAccounts();
        }
      },
    );

    reaction(
      () => this.rowsPerPageChange,
      (value) => {
        if (this.maxSelectedPage <= value) {
          this.getAccounts();
        }
      },
    );

    reaction(
      () => this.selectedPage,
      (value) => {
        if (this.maxSelectedPage <= value) {
          this.getAccounts();
        }
      },
    );

    reaction(
      () => this.selectedFilter?.type,
      (value, previousValue) => {
        this.handleChangePage(null, 0);
        if (previousValue !== value) {
          this.getAccounts();
        }
      },
    );

    reaction(
      () => this.searchString,
      (value, previousValue) => {
        if (previousValue !== value) {
          this.selectedPage = 0;
          this.getAccounts();
        }
      },
    );
  }

  @action
  getAccounts = async (immediately?: boolean) => {
    const isSearch = this.searchString || this.searchString?.trim();
    const companyName = this.rootStore?.userStore?.results?.company_name;

    this.rootStore.requestTemplate({
      immediately,
      errorMessage: 'Ошибка получения данных контрагентов',
      callback: async () => {
        const limit = isSearch ? 10 : this.rowsPerPageChange;
        const offset = this.rowsPerPageChange * this.selectedPage;
        const problemLevel = this.selectedProblemLevel;
        const limitChargeFilter = this.selectedLimitCharge;

        let companyGroups = this.selectedCompanyGroups;
        if (limitChargeFilter && companyName) {
          if (!companyGroups) {
            companyGroups = companyName;
          } else if (!companyGroups.includes(companyName)) {
            companyGroups = `${companyGroups}`;
          }
        }

        const searchString = isSearch ? this.searchString?.trim() : undefined;
        const filter =
          this.selectedFilter?.type === FILTER_TYPE.ALL || !this.selectedFilter?.type
            ? undefined
            : this.selectedFilter?.type;
        const { data } = await createAxiosRequest<
          {
            offset: number;
            limit: number;
            status?: string;
            search?: string;
            problem_level?: string;
            limit_or_charge?: string;
            company_groups?: string;
          },
          IGetAccounts
        >({
          path: apiPath.accounts,
          method: 'GET',
          params: {
            offset,
            limit,
            status: filter,
            search: searchString,
            problem_level: problemLevel,
            limit_or_charge: limitChargeFilter,
            company_groups: companyGroups,
          },
          useToken: true,
        });
        runInAction(() => {
          this.limit = limit;
          this.summary = data.count;
          this.offset = offset;
          this.results = data.results;
          const tableData = mapAccountsToTableBody(data.results);
          this.tableBody = [...tableData];
        });
      },
    });
  };

  @action
  getAccountById = async (id: string) => {
    if (!this.isLoading) {
      this.rootStore.requestTemplate({
        errorMessage: `Ошибка получения данных контрагента ${id}`,
        callback: async () => {
          try {
            runInAction(() => {
              this.isLoading = true;
            });

            const { data } = await createAxiosRequest<{}, IAccountWIthDetail>({
              path: `${apiPath.accountsById.replace('{id}', id)}`,
              method: 'GET',
              useToken: true,
            });

            runInAction(() => {
              this.selectedContragent = data;
            });
          } finally {
            runInAction(() => {
              this.isLoading = false;
            });
          }
        },
      });
    }
  };

  @action
  patchAccountById = async ({
    id,
    applicationId,
    isContragentPage,
    patchParameters,
  }: {
    id: string;
    applicationId?: string;
    isContragentPage?: boolean;
    patchParameters: Partial<IAccountWIthDetail>;
  }) => {
    this.rootStore.requestTemplate({
      finallyAction: () => {
        if (applicationId) {
          this.rootStore.applicationsPageDetailsStore.getContragentDetailsByApplicationId(applicationId);
        } else if (isContragentPage) {
          this.getAccounts();
        }
        this.getAccountById(id);
      },
      errorMessage: `Ошибка обновления данных контрагента ${id}`,
      callback: async () => {
        await createAxiosRequest<{}, IAccountWIthDetail>({
          path: `${apiPath.accountsById.replace('{id}', id)}`,
          method: 'PATCH',
          data: { ...patchParameters },
          useToken: true,
        });
      },
    });
  };

  @action
  putAccountById = async ({ id, putParameters }: { id: string; putParameters: Partial<IAccountWIthDetail> }) => {
    const { setRequestSuccess } = this.rootStore;

    this.rootStore.requestTemplate({
      successMessage: 'Данные успешно сохранены',
      errorMessage: `Ошибка обновления данных контрагента ${id}`,
      finallyAction: () => {
        this.getAccountById(id);
        this.getAccounts(true);
      },
      callback: async (successMessage?: string) => {
        await createAxiosRequest<{}, IAccountWIthDetail>({
          path: `${apiPath.accountsById.replace('{id}', id)}`,
          method: 'PUT',
          data: { ...putParameters },
          useToken: true,
        });

        setRequestSuccess(successMessage);
      },
    });
  };

  @action
  createAccount = async (innArray: { inn: string }[]) => {
    const counts: Counts = {
      updated: 0,
      created: 0,
      error: 0,
    };

    await this.rootStore.requestTemplate({
      errorMessage: 'Ошибка создания контрагента',
      finallyAction: () => {
        this.tableBody = [];
        this.getAccounts();
      },
      callback: async () => {
        const { data } = await createAxiosRequest<any, any>({
          path: `${apiPath.accounts}`,
          method: 'POST',
          data: {
            accounts: innArray,
          },
          useToken: true,
        });

        if (data) {
          data.results.forEach(({ inn, status }: { inn: string; status: string }, index: number) => {
            if (status === 'updated') {
              counts.updated++;
              setTimeout(() => {
                this.rootStore.setLocalWarning(`Контрагент с ИНН: ${inn} обновлен`);
              }, index * 1000);
            } else if (status === 'created') {
              counts.created++;
            } else if (status === 'error') {
              counts.error++;
            }
          });
        }
      },
    });

    setTimeout(() => {
      this.rootStore.setLocalWarning(
        `Успешно добавлено: ${counts.created}\nОбновлено: ${counts.updated}\nНекорректный ИНН: ${counts.error}`,
      );
    }, (counts.updated + 2) * 1000);
  };

  @action
  downloadContragentsAll = async () => {
    const { setIsLoading, setRequestError, isLoading } = this.rootStore;
    const problemLevel = this.selectedProblemLevel;
    const limitChargeFilter = this.selectedLimitCharge;
    const companyGroups = this.selectedCompanyGroups;
    const searchString = this.searchString?.trim();

    try {
      if (!isLoading) {
        setIsLoading(false);
        const response = await createAxiosRequest<{}, any>({
          path: apiPath.downloadContragentsAll,
          method: 'GET',
          useToken: true,
          responseType: true,
          params: {
            search: searchString,
            problem_level: problemLevel,
            limit_or_charge: limitChargeFilter,
            company_groups: companyGroups,
          },
        });
        const contentType = response.headers['content-type'];
        const url = window.URL.createObjectURL(new Blob([response.data], { type: contentType }));
        const link = document.createElement('a');

        link.href = url;
        link.setAttribute('download', `Список контрагентов ${formattedDateToday}.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    } catch (e) {
      setRequestError('Ошибка выгрузки контрагентов');
    } finally {
      setIsLoading(false);
    }
  };

  @action
  clear = () => {
    this.selectedContragent = undefined;
    this.searchString = undefined;
    this.selectedFilter = undefined;
  };
}

export const mapAccountsToTableBody = (rawList: IAccount[]): TableBodyContragents[] => {
  return rawList.map((item) => ({
    id: item.id,
    inn: item.inn,
    name: item?.name,
    status: item.status ?? 'Inactive',
    manager: item?.head?.fio ?? item?.head?.position ?? '',
    contact: item?.primary_contact ?? '',
    monitoring: item.risk_level_display ?? 'Нет данных',
    isHasLimit: item.is_has_limit,
    clientManager: item.created_by?.first_name
      ? `${item.created_by?.first_name} ${item.created_by?.middle_name} ${item.created_by?.last_name}`
      : item.created_by?.username ?? `-`,
    isHasLoad: item.is_has_charge,
    companyGroupName: item?.company_group_name ?? '-',
    ctrlCompanyGroup: item?.ctrl_company_group ?? '-',
    problemLevel: item?.problem_level ?? '-',
    userCompanyGroups: item?.user_company_groups ?? [],
  })) as TableBodyContragents[];
};
