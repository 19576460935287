import React from 'react';
import { observer } from 'mobx-react';

import { SwitchStyled } from '@/components/Administering/ModelEvaluation/ModelEvaluationDetail/ModelEvaluationDetailTab/ModelEvaluationDetailTabAttributes/components/Switch';
import { TableCellStyled } from '@/components/Templates/TableComponentStyled';
import { LabelLikeHintWithText } from '@/components/common/LabelLikeHintWithText';
import type { AssessmentTemplateAttributeModel } from '@/stores/Models/AdministrationAssessmentTemplateAttributeModel';
import { color } from '@/constants/colors';

export const LogicalEvaluation = observer(
  ({ modelData, disabled }: { modelData: AssessmentTemplateAttributeModel; disabled: boolean }) => {
    const onChange = (_: any, value: boolean) => {
      modelData.is_boolean_score = value;
    };

    return (
      <TableCellStyled>
        <LabelLikeHintWithText title="Логическая оценка" titleStyle={{ color: color.black }}>
          <SwitchStyled checked={modelData.is_boolean_score} onChange={onChange} disabled={disabled} />
        </LabelLikeHintWithText>
      </TableCellStyled>
    );
  },
);
