import React from 'react';
import { STATUS_TO_STATUS } from '@/constants/backendEnum';
import { BadgeTemplate } from '@/components/atoms/Bage/BadgeTemplate';
import { color } from '@/constants/colors';

export const BadgeType = {
  info: 'info',
  success: 'success',
  preWarning: 'preWarning',
  warning: 'warning',
  error: 'error',
  failed: 'failed',
  /// Статусы ордеров
  New: 'New',
  'In Progress': 'In Progress',
  Rejected: 'Rejected',
  Approved: 'Approved',
  Revoked: 'Revoked',
  Сlarification: 'Сlarification',
  [STATUS_TO_STATUS.Inactive]: STATUS_TO_STATUS.Inactive,
  [STATUS_TO_STATUS.Active]: STATUS_TO_STATUS.Active,

  /// ASSESSMENT
  Created: 'Created',
  SuccessfullyAssessed: 'SuccessfullyAssessed',
  Warning: 'Warning',
  ActionRequired: 'ActionRequired',
  Failed: 'ActionRequired',
  Changed: 'Changed',
};

export const BadgeColorsMap = {
  [BadgeType.info]: color.aquamarine_crayola,
  [BadgeType.success]: color.emerald,
  [BadgeType.preWarning]: color.pear,
  [BadgeType.warning]: color.dahlia_yellow,
  [BadgeType.error]: color.light_carmine_pink,
  [BadgeType.failed]: color.light_carmine_pink,
  /// Статусы ордеров
  [BadgeType.New]: color.royal_blue,
  [BadgeType.Created]: color.royal_blue,
  [BadgeType.Changed]: color.royal_blue,
  [BadgeType['In Progress']]: color.royal_blue,
  [BadgeType.Rejected]: color.light_carmine_pink,
  [BadgeType.Failed]: color.light_carmine_pink,
  [BadgeType.Approved]: color.emerald,
  [BadgeType.Revoked]: color.dahlia_yellow,
  [BadgeType.Warning]: color.dahlia_yellow,
  [BadgeType.ActionRequired]: color.dahlia_yellow,
  [BadgeType['Сlarification']]: color.dahlia_yellow,
  [STATUS_TO_STATUS.Inactive]: color.gray,
  [STATUS_TO_STATUS.Active]: color.light_green,
  [BadgeType.SuccessfullyAssessed]: color.light_green,
};

export type IBadgeType = {
  type: keyof typeof BadgeType;
  text?: string;
  children?: any;
  width?: string;
};

export const Badge = (props: IBadgeType) => (
  // @ts-ignore
  <BadgeTemplate<IBadgeType & typeof BadgeColorsMap> {...props} badgeColorsMap={BadgeColorsMap} />
);
