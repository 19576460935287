export const OFFSET = {
  xxxxs: '4px',
  xxxs: '8px',
  xxs: '12px',
  xs: '16px',
  s: '20px',
  sm: '24px',
  m: '32px',
  l: '40px',
  xl: '48px',
  xxl: '56px',
  xxxl: '64px',
  none: '0px',
  auto: 'auto',
} as const;

export const WIDTH = {
  auto: '100%',
  xs: '160px',
  s: '286px',
  m: '352px',
  l: '448px',
  xl: '544px',
  xxl: '736px',
  half: '50%',
} as const;
