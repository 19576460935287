import React, { useState } from 'react';
import { BusinessUnitTable } from '@/components/Group/BusinessUnit/BusinessUnitTable';
import { BusinessUnitModal } from '@/components/Group/BusinessUnit/BusinessUnitModal';
import type { ITableBodyBusinessUnit } from '@/stores/BusinessUnitStore';

export const BusinessUnit = () => {
  const [editData, setEditData] = useState<ITableBodyBusinessUnit | undefined>(undefined);
  return (
    <>
      <BusinessUnitTable setEdit={setEditData} />
      <BusinessUnitModal setEditData={setEditData} editData={editData} />
    </>
  );
};
