import React, { useState } from 'react';

import { Box, MenuItem } from '@mui/material';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

import { MoreMenuButton } from '@/components/ApplicationsPage/MoreMenu';
import { color } from '@/constants/colors';
import { OFFSET } from '@/constants/sizes';

type MoreMenuTabDataProps = {
  onEdit?: () => void;
  onDelete?: () => void;
  onDuplicate?: () => void;
  handleDownload?: () => void;
  toDownloadAll?: boolean;
  toDownloadById?: boolean;
  download?: boolean;
};

export const MoreMenuTabData = ({
  onEdit,
  onDelete,
  onDuplicate,
  toDownloadAll,
  toDownloadById,
  handleDownload,
  download,
}: MoreMenuTabDataProps) => {
  const [open, setOpen] = useState<React.FormEvent<HTMLFormElement> | null>(null);

  const handleOpenMenu = (event: any) => {
    event.stopPropagation();
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = (event: any) => {
    event.stopPropagation();
    setOpen(null);
  };
  
  const handleDownloadMenu = (event: any) => {
    handleCloseMenu(event);
    if (handleDownload) {
      handleDownload();
    }
  };

  const handleEdit = (e: any) => {
    handleCloseMenu(e);
    if (onEdit) {
      onEdit();
    }
  };

  const handleDelete = (e: any) => {
    handleCloseMenu(e);
    if (onDelete) {
      onDelete();
    }
  };

  const handleDuplicate = (e: any) => {
    handleCloseMenu(e);
    if (onDuplicate) {
      onDuplicate();
    }
  };

  return (
    <MoreMenuButton
      open={open}
      onClose={handleCloseMenu}
      onOpen={handleOpenMenu}
      download={download}
      actions={
        <>
          {onEdit ? (
            <MenuItem onClick={handleEdit}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <Box>Изменить</Box>
                <Box>
                  <ModeEditOutlinedIcon sx={{ color: color.light_gray, marginLeft: '16px' }} />
                </Box>
              </Box>
            </MenuItem>
          ) : null}

          {toDownloadAll ? (
            <MenuItem onClick={handleDownloadMenu}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', gap: '20px' }}>
                <Box>Скачать всё</Box>
                <Box>
                  <FileDownloadOutlinedIcon style={{ width: OFFSET.s, height: OFFSET.s, marginRight: '0px' }} />
                </Box>
              </Box>
            </MenuItem>
          ) : null}

          {toDownloadById ? (
            <MenuItem onClick={handleDownloadMenu}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', gap: '20px' }}>
                <Box>Скачать</Box>
                <Box>
                  <FileDownloadOutlinedIcon style={{ width: OFFSET.s, height: OFFSET.s, marginRight: '0px' }} />
                </Box>
              </Box>
            </MenuItem>
          ) : null}

          {onDuplicate ? (
            <MenuItem onClick={handleDuplicate}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <Box>Дублировать</Box>
                <Box>
                  <CopyAllIcon sx={{ color: color.light_gray, marginLeft: '16px' }} />
                </Box>
              </Box>
            </MenuItem>
          ) : null}

          {onDelete ? (
            <MenuItem onClick={handleDelete}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <Box>Удалить</Box>
                <Box>
                  <DeleteOutlinedIcon sx={{ color: color.light_gray, marginLeft: '16px' }} />
                </Box>
              </Box>
            </MenuItem>
          ) : null}
        </>
      }
    />
  );
};
