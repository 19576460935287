// @ts-nocheck
import React from 'react';

import { STATUS_TO_STATUS } from '@/constants/backendEnum';
import { BadgeTemplate } from '@/components/atoms/Bage/BadgeTemplate';
import { color } from '@/constants/colors';

const BadgeColorsMap = {
  [STATUS_TO_STATUS.New]: color.royal_blue,
  [STATUS_TO_STATUS.Inactive]: color.gray,
  [STATUS_TO_STATUS.Active]: color.light_green,
  Error: color.light_carmine_pink,
};

export type IStatusBadgeType = {
  type: keyof typeof STATUS_TO_STATUS;
  text?: string;
  children?: any;
  isSmall?: boolean;
  isWarning?: boolean;
  isFirst?: boolean;
};

export const StatusBadge = (props: IStatusBadgeType) => (
  <BadgeTemplate<IStatusBadgeType & typeof BadgeColorsMap> {...props} badgeColorsMap={BadgeColorsMap} />
);
