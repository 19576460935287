/* eslint-disable camelcase */
import {action, makeObservable, observable, reaction, runInAction} from 'mobx';
import { apiPath } from '@/constants/api';
import { createAxiosRequest } from '@/utils/request';
import type { TableStoreType } from '@/stores/TableStore';
import { TableStore } from '@/stores/TableStore';
import type { IRootStore } from '@/stores/type';

export type IEmployeeAsUserStore = {
  getEmployeeAsUsers: () => void;
  createEmployeeAsUser: ({
    user_id,
    employee_first_name,
    employee_last_name,
    employee_email,
    postn_id,
    bu_id,
  }: IEmployeeAsUserCreate) => void;
  deleteEmployeeAsUser: ({ id }: { id: string | number }) => void;
  editEmployeeAsUser: ({
    id,
    user_id,
    employee_first_name,
    employee_last_name,
    employee_email,
    postn_id,
    bu_id,
  }: IEmployeeAsUserEdit) => void;
  allEmployeeAsUsers?: IEmployeeAsUser[];
  getAllEmployeeAsUsers: () => void;
  employeeAsUsersById: (employeeId?: number | string) => IEmployeeAsUser | undefined;
};

export type IEmployeeAsUser = {
  user: number;
  employee_first_name: string;
  employee_last_name: string;
  employee_email: string;
  postn: number;
  bu: number;
};

export type IGetEmployeeAsUser = {
  count: number;
  next?: string;
  previous?: string;
  results: IEmployeeAsUser[];
};

export type ITableBodyEmployeeAsUser = {
  id: number;
  // name: string;
  // description?: string;
  bu: number;
  updatedBy?: string;
  updated?: string;
};

const mapEmployeeAsUserToTableBody = (rawList: IEmployeeAsUser[]): ITableBodyEmployeeAsUser[] => {
  return rawList?.map((item) => ({
    id: item.user,
    // name: `${item.name}`,
    // description: item?.description,
    bu: item.bu,
    updatedBy: `-`,
    updated: '-',
  })) as ITableBodyEmployeeAsUser[];
};

export type IEmployeeAsUserStoreWithITableStore<T> = IEmployeeAsUserStore & TableStoreType<T>;

export class EmployeeAsUserStore
  extends TableStore<ITableBodyEmployeeAsUser>
  implements IEmployeeAsUserStoreWithITableStore<ITableBodyEmployeeAsUser>
{
  @observable
  public rootStore: IRootStore;

  // используется как значения для дропдауна
  @observable
  public allEmployeeAsUsers?: IEmployeeAsUser[];

  @observable
  public isLoading?: boolean = false;

  constructor(rootStore: IRootStore) {
    super();
    makeObservable(this);
    this.rootStore = rootStore;

    reaction(
      () => this.rowsPerPageChange,
      (value) => {
        if (this.maxSelectedPage <= value) {
          this.getEmployeeAsUsers();
        }
      },
    );

    reaction(
      () => this.selectedPage,
      (value) => {
        if (this.maxSelectedPage <= value) {
          this.getEmployeeAsUsers();
        }
      },
    );
  }

  @action
  employeeAsUsersById(employeeId?: number | string) {
    return this.allEmployeeAsUsers?.find((employee) => employee.user?.toString() === employeeId?.toString());
  }

  @action
  getEmployeeAsUsers = () => {
    this.rootStore.requestTemplate({
      errorMessage: `Ошибка получения данных сотрудника`,
      callback: async () => {
        const limit = this.rowsPerPageChange;
        const offset = this.rowsPerPageChange * this.selectedPage;

        const { data } = await createAxiosRequest<{ limit?: number; offset?: number }, IGetEmployeeAsUser>({
          path: `${apiPath.crEmployeeAsUser}`,
          method: 'GET',
          useToken: true,
          params: { limit, offset },
        });
        runInAction(() => {
          this.limit = limit;
          this.summary = data.count;
          this.offset = offset;
          this.tableBody = mapEmployeeAsUserToTableBody(data.results);
        })
      },
    });
  };

  @action
  getAllEmployeeAsUsers = () => {
    this.rootStore.requestTemplate({
      errorMessage: `Ошибка получения данных всех должностей`,
      immediately: true,
      callback: async () => {
        if (!this.isLoading) {
          try {
            this.isLoading = true;
            const { data } = await createAxiosRequest<{ limit?: number; offset?: number }, IGetEmployeeAsUser>({
              path: `${apiPath.crEmployeeAsUser}`,
              method: 'GET',
              useToken: true,
              params: { limit: 100, offset: 0 },
            });
            runInAction(() => {
              this.allEmployeeAsUsers = data.results;
            })
          } catch {
            // noop
          } finally {
            this.isLoading = false;
          }
        }
      },
    });
  };

  @action
  getEmployeeAsUsersById = ({ id }: { id: string }) => {
    return this.rootStore.requestTemplate({
      isReturned: true,
      errorMessage: `Ошибка получения данных сотрудника ${id}`,
      callback: async () => {
        const { data } = await createAxiosRequest<
          { name: string; search: string; limit?: number; offet?: number },
          ITableBodyEmployeeAsUser
        >({
          path: `${apiPath.crudEmployeeAsUserById?.replace('{id}', id)}`,
          method: 'GET',
          useToken: true,
        });

        return data;
      },
    });
  };

  @action
  createEmployeeAsUser = ({
    user_id,
    employee_first_name,
    employee_last_name,
    employee_email,
    postn_id,
    bu_id,
  }: IEmployeeAsUserCreate) => {
    return this.rootStore.requestTemplate({
      errorMessage: `Ошибка создания сотрудника ${employee_first_name} ${employee_last_name} ${employee_email}`,
      immediately: true,
      finallyAction: () => this.getAllEmployeeAsUsers(),
      callback: async () => {
        await createAxiosRequest({
          path: `${apiPath.crEmployeeAsUser}`,
          method: 'POST',
          data: {
            user_id,
            employee_first_name,
            employee_last_name,
            employee_email,
            postn_id,
            bu_id,
          },
          useToken: true,
        });
      },
    });
  };

  @action
  editEmployeeAsUser = ({
    id,
    user_id,
    employee_first_name,
    employee_last_name,
    employee_email,
    postn_id,
    bu_id,
  }: IEmployeeAsUserEdit) => {
    this.rootStore.requestTemplate({
      errorMessage: `Ошибка редактирования сотрудника`,
      immediately: true,
      finallyAction: () => this.getAllEmployeeAsUsers(),
      callback: async () => {
        await createAxiosRequest<any, any>({
          path: apiPath.crudEmployeeAsUserById?.replace('{id}', `${id}`),
          method: 'PATCH',
          data: {
            user: user_id,
            employee_first_name,
            employee_last_name,
            employee_email,
            postn_id,
            bu_id,
          },
          useToken: true,
        });
      },
    });
  };

  @action
  deleteEmployeeAsUser = ({ id }: { id: string | number }) => {
    this.rootStore.requestTemplate({
      errorMessage: `Ошибка удаления сотрудника ${id}`,
      finallyAction: () => this.getAllEmployeeAsUsers(),
      callback: async () => {
        await createAxiosRequest({
          path: apiPath.crudEmployeeAsUserById?.replace('{id}', `${id}`),
          method: 'DELETE',
          useToken: true,
        });
      },
    });
  };
}

export type IEmployeeAsUserCreate = {
  user_id: number;
  employee_first_name?: string;
  employee_last_name?: string;
  employee_email?: string;
  postn_id: number;
  bu_id: number;
};

export type IEmployeeAsUserEdit = IEmployeeAsUserCreate & {
  id: string | number;
};
