import React, { useEffect, useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react';

import { Box } from '@mui/material';

import { OFFSET } from '@/constants/sizes';
import { CardsAssessment } from '@/components/Administering/Assessment/components/CardsAssessment';
import { FilterAssessment } from '@/components/Administering/Assessment/components/FilterAssessment';
import type { CardsItem } from '@/components/Administering/Assessment/components/types';
import { useRootStore } from '@/stores';

export const CombineFilterAndCards = observer(
  ({ setBadge }: { setBadge: (some: Record<string, string | number>[]) => void }) => {
    const { administrationAssessmentStore } = useRootStore();
    const { innerHeight: height } = window;

    const { context_objects, assessment_templates } = administrationAssessmentStore?.assessmentAvailableAsmntTmpl || {};

    const [filtersSelected, setFiltersSelected] = useState<string[]>([]);
    const [cardsSelected, setCardsSelected] = useState<CardsItem[]>([]);
    const [filteredCards, setFilteredCards] = useState<CardsItem[]>(assessment_templates);
    const refSizeFiltersBox = useRef<any>();

    useEffect(() => {
      if (filtersSelected.length > 0) {
        setFilteredCards(
          assessment_templates.filter((item: CardsItem) => filtersSelected.includes(item.context_object_name)),
        );
      } else {
        setFilteredCards(assessment_templates);
      }
    }, [assessment_templates, filtersSelected]);

    useEffect(() => {
      if (cardsSelected.length > 0) {
        setBadge(
          cardsSelected.map(({ assessment_template_name, context_object_id }) => ({
            assessment_template_name,
            context_object_id,
          })),
        );
      } else {
        setBadge([]);
      }
    }, [cardsSelected]);

    // 62 - header
    // 36 - button
    // 32 = 64 - padding
    // 50 - доп оффсет
    const size = useMemo(
      () => height - (62 + 64 + 36 + (refSizeFiltersBox?.current?.clientHeight ?? 0) + 50),
      [height, refSizeFiltersBox.current],
    );

    return (
      <>
        <Box mb={OFFSET.sm}>
          <FilterAssessment
            innerRef={refSizeFiltersBox}
            filters={context_objects}
            filtersSelected={filtersSelected}
            setFiltersSelected={setFiltersSelected}
          />
        </Box>
        <Box sx={{ maxHeight: size, overflow: 'auto' }}>
          <CardsAssessment cards={filteredCards} cardsSelected={cardsSelected} setCardsSelected={setCardsSelected} />
        </Box>
      </>
    );
  },
);
