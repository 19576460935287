// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TextareaStyled_textArea__6GYqM {
  height: auto;
  min-height: 75px;
  font-family: "Raleway", sans-serif;
  font-size: 13px;
  padding: 12px 20px;
  border-radius: 12px;
  border: 1px solid #ccc;
  color: #000000;
  border: 1px solid #bdbdbd;
  resize: vertical;
  overflow: auto;
}
.TextareaStyled_textArea__6GYqM:focus {
  border-color: #5dc689;
}
.TextareaStyled_textArea__6GYqM:focus, .TextareaStyled_textArea__6GYqM:not(:placeholder-shown) {
  outline: 0;
}
.TextareaStyled_textArea__6GYqM::placeholder {
  font-family: "Raleway";
  color: #bdbdbd;
}`, "",{"version":3,"sources":["webpack://./src/components/atoms/TextareaStyled.module.scss","webpack://./src/constants/_colors.scss"],"names":[],"mappings":"AAEA;EACE,YAAA;EACA,gBAAA;EACA,kCAAA;EACA,eAAA;EACA,kBAAA;EACA,mBAAA;EACA,sBAAA;EACA,cCVM;EDWN,yBAAA;EACA,gBAAA;EACA,cAAA;AADF;AAGE;EACE,qBCJM;ADGV;AAIE;EAEE,UAAA;AAHJ;AAME;EACE,sBAAA;EACA,cCrBS;ADiBb","sourcesContent":["@import '@/constants/colors';\n\n.textArea {\n  height: auto;\n  min-height: 75px;\n  font-family: 'Raleway', sans-serif;\n  font-size: 13px;\n  padding: 12px 20px;\n  border-radius: 12px;\n  border: 1px solid #ccc;\n  color: $black;\n  border: 1px solid $gray_smoke;\n  resize: vertical;\n  overflow: auto;\n\n  &:focus {\n    border-color: $emerald;\n  }\n\n  &:focus,\n  &:not(:placeholder-shown) {\n    outline: 0;\n  }\n\n  &::placeholder {\n    font-family: 'Raleway';\n    color: $gray_smoke;\n  }\n}\n","$black: #000000;\n$white: #ffffff;\n$grey: #6e6e6e;\n$slightly_grey: #e6e6e6;\n$light-gray: #def3db;\n$gray_smoke: #bdbdbd;\n$red: #ff0000;\n$heavenly: #61dafb;\n$granite_grey: #282c34;\n$periwinkle_crayola: #f1f9f7;\n$pale_blue: #d7d7d8;\n$dull_grey: #616161;\n$emerald: #5dc689;\n$light_green: #1dc255;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textArea": `TextareaStyled_textArea__6GYqM`
};
export default ___CSS_LOADER_EXPORT___;
