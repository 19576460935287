import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { EditButtons } from '@/components/Templates/EditButtons';
import { TableCellStyled, TableRowStyled } from '@/components/Templates/TableComponentStyled';
import { TableTemplateWithoutStore } from '@/components/Templates/TableTemplateWithoutStore';
import { ModalDelete } from '@/components/common/ModalDelete';
import { useModalDelete } from '@/components/common/hooks/useModalDelete';
import { ROUTES } from '@/constants';
import { LEGAL_ENTITY_TYPE, PROBLEM_LEVEL } from '@/constants/backendEnum';
import { color } from '@/constants/colors';
import { withEditable } from '@/hoc/withEditable';
import { RETURN_TABS } from '@/pages/ApplicationPageDetails';
import { useRootStore } from '@/stores';
import type { IApplicationGuarantor } from '@/stores/ApplicationsPageDetailsStore';
import { SelectStyled } from '@/components/atoms/Select/SelectStyled';
import {runInAction} from "mobx";

export type ITableBodyGuarantors = {
  id: string | number;
  type: string;
  inn: string;
  company: string | null;
  fio: string;
  monitoring?: string | number | null;
  account: number;
  problem_level?: string;
};

const tableHeader = ['Поручитель', 'ИНН', 'Тип', 'Зона проблемности', ''];
const tableHeaderWidth = ['23.45%', '23.45%', '23.45%', '23.45%', '3.1%', '3.1%'];

const RenderBodyGuarantorsTableRaw = ({
  row,
  filled,
  isLast,
  isEdit,
  handleOpenEdit,
  handleCloseEdit,
  handleOpenDeleteModal,
}: {
  row: ITableBodyGuarantors;
  filled: boolean;
  isLast?: boolean;
  isEdit: boolean;
  toggleEdit: () => void;
  handleOpenEdit: () => void;
  handleCloseEdit: () => void;
  handleOpenDeleteModal: (row: ITableBodyGuarantors) => void;
}) => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { applicationsPageDetailsStore } = useRootStore();

  const onRowCLick = () => {
    navigate(`${ROUTES.contragents}/${row.account}`, { state: { route: RETURN_TABS[1], applicationId: id } });
  };

  const handleOpenDelete = (e: React.MouseEvent<HTMLLabelElement>) => {
    e.stopPropagation();
    handleOpenDeleteModal(row);
  };

  return (
    <TableRowStyled
      key={row.id}
      onClick={onRowCLick}
      sx={{ backgroundColor: filled ? color.light : color.transparent }}
    >
      <TableCellStyled verticalAlign="middle">
        {row.type === 'Юридическое лицо' ? row.company : row.fio}
      </TableCellStyled>
      <TableCellStyled verticalAlign="middle">{row.inn}</TableCellStyled>
      <TableCellStyled verticalAlign="middle">{row.type}</TableCellStyled>
      <TableCellStyled textAlign="left" verticalAlign="middle">
        {row.problem_level && (
          <SelectStyled
            defValue={PROBLEM_LEVEL[row.problem_level as keyof typeof PROBLEM_LEVEL]}
            items={PROBLEM_LEVEL}
            title={''}
            disabled
            justifyContent={'start'}
          />
        )}
      </TableCellStyled>
      <EditButtons
        isEdit={isEdit}
        disabled={!applicationsPageDetailsStore.isPossibleEdit}
        handleOpen={handleOpenEdit}
        handleClose={handleCloseEdit}
        handleDelete={handleOpenDelete}
      />
    </TableRowStyled>
  );
};

const RenderBodyGuarantorsTable = withEditable(RenderBodyGuarantorsTableRaw);

const mapToLikeStore = (data: IApplicationGuarantor[]) =>
  data.map((item, index) => ({
    id: item.id,
    type: LEGAL_ENTITY_TYPE[item.legal_entity_type] ?? '-',
    inn: item?.account_inn ?? '-',
    company: item?.account_name ?? '-',
    fio: item?.fullname ?? '-',
    monitoring: item?.account_risk_level ?? '-',
    account: item.account,
    problem_level: item?.account_problem_level,
  }));

export const ApplicationAddTableGuarantors = observer(() => {
  const { applicationsPageDetailsStore, isLoading } = useRootStore();
  const { id } = useParams<{ id: string }>();

  const { open, handleClose, handleOpen, deletedValue: guarantor } = useModalDelete<ITableBodyGuarantors>();

  useEffect(() => {
    if (id) {
      applicationsPageDetailsStore.getGuarantorsByApplicationId({ applicationId: id });
    }

    return () => {
      runInAction(() => {
        applicationsPageDetailsStore.guarantorsApplicationById = [];
      })
    };
  }, [id]);

  const likeStore =
    applicationsPageDetailsStore.guarantorsApplicationById &&
    mapToLikeStore(applicationsPageDetailsStore.guarantorsApplicationById);

  const handleDelete = () => {
    if (id && guarantor?.id) {
      applicationsPageDetailsStore.deleteGuarantors({ applicationId: id, id: guarantor?.id });
      handleClose();
    }
  };

  return (
    <>
      <TableTemplateWithoutStore<ITableBodyGuarantors>
        tableHeader={tableHeader}
        tableHeaderWidth={tableHeaderWidth}
        RenderComponent={(props) => <RenderBodyGuarantorsTable {...props} handleOpenDeleteModal={handleOpen} />}
        store={{
          renderedRows: likeStore ?? [],
        }}
        isLoading={isLoading}
      />
      <ModalDelete
        uniqId="Guarantors_delete_modal"
        open={open}
        handleClose={handleClose}
        handleDelete={handleDelete}
        title="Вы действительно хотите удалить поручителя?"
        descriptions={[guarantor?.company ?? '', guarantor?.fio ?? '', guarantor?.inn ?? '', guarantor?.type ?? '']}
      />
    </>
  );
});
