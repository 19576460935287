import React from 'react';

import { Box } from '@mui/system';

import { ReactComponent as Empty } from '@/assets/empty.svg';

export const EmptyTable = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Empty width="50em" height="25em" />
    </Box>
  );
}