import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';

import { Box, Slide } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { FileUpload } from '@mui/icons-material';

import { DrawerCustom } from '@/components/atoms/DrawerCustom';
import { ButtonStyled } from '@/components/atoms/Button';
import { useRootStore } from '@/components/../stores';
import { DrawerLikeModal } from '@/components/common/DrawerLikeModal';
import { Dropdown } from '@/components/atoms/Dropdown';
import { FORMAT_DOCUMENT_REPORT, FORMAT_DOCUMENT_REPORT_KEY } from '@/constants/backendEnum';
import { useValidation } from '@/hooks/useValidation';
import { FileUploader } from '@/components/atoms/FileUploader';
import { InputLabelStyled } from '@/components/atoms/InputLabelStyled';

const templateDropdownList = [
  {
    id: 'format_select',
    label: 'Формат документа',
    placeholder: 'Выбрать...',
    value: FORMAT_DOCUMENT_REPORT_KEY.excel,
    dropdownItems: [
      {
        label: FORMAT_DOCUMENT_REPORT.excel,
        value: FORMAT_DOCUMENT_REPORT_KEY.excel,
      },
      {
        label: FORMAT_DOCUMENT_REPORT.word,
        value: FORMAT_DOCUMENT_REPORT_KEY.word,
      },
    ],
  },
];

export const ContractReportModal = observer(() => {
  const { id } = useParams<{ id: string }>();
  const [openSuccess, setOpenSuccess] = React.useState<boolean>(false);
  const slideTransition = (props: any) => {
    return <Slide {...props} direction="left" />;
  };
  const {
    contragentReportStore,
    isLoading,
    uiStateStore: { open },
  } = useRootStore();

  const { value, onChangeDropdown, handleChangeInput } = useValidation();

  enum ModalType {
    download = 'download',
    upload = 'upload',
  }

  const [openDownloadModal, setOpenDownloadModald] = useState<boolean>(false);
  const [openUploadModal, setOpenUploadModald] = useState<boolean>(false);

  const toggleOpen = (type: ModalType) => {
    if (type === ModalType.download) {
      setOpenDownloadModald(!openDownloadModal);
    } else if (type === ModalType.upload) {
      setOpenUploadModald(!openUploadModal);
    }
  };

  const handleChangeFile = (value: File) => {
    handleChangeInput({ file: value });
  };

  const handleSaveFormDownload = async (event: React.FormEvent<HTMLFormElement>) => {
    const file = value['file'] as File[];

    if (id) {
      try {
        const response = await contragentReportStore.createReports({
          contragentId: id,
          file,
        });
        toggleOpen(ModalType.download);
        setOpenSuccess(true);
      } catch (error) {
        console.error('Ошибка при отправке данных:', error);
      }
    }
  };

  const handleSaveFormUpload = async (event: React.FormEvent<HTMLFormElement>) => {
    const format = value['format_select'] as keyof typeof FORMAT_DOCUMENT_REPORT;

    if (id) {
      contragentReportStore.uploadReport({
        contragentId: id,
        format,
      });

      toggleOpen(ModalType.upload);
    }
  };

  return (
    <>
      <DrawerCustom open={open} isContractReportModal={true}>
        <ButtonStyled
          text="загрузить отчетность"
          startIcon={<AddCircleOutlineIcon />}
          onClick={() => toggleOpen(ModalType.download)}
        />
        <ButtonStyled
          text="Выгрузить отчетность"
          startIcon={<FileUpload />}
          onClick={() => toggleOpen(ModalType.upload)}
        />
      </DrawerCustom>
      <DrawerLikeModal
        title="Отчетность"
        open={openDownloadModal}
        toggleOpen={() => toggleOpen(ModalType.download)}
        saveForm={handleSaveFormDownload}
        buttonConfirmText="загрузить"
        isDisabled={isLoading}
        isLoading={isLoading}
        hideSpacer
        isFormUpload={true}
      >
        <InputLabelStyled>Файл</InputLabelStyled>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '50%',
          }}
        >
          <FileUploader
            sx={{ marginRight: '24px', flex: '1 0 auto', marginBottom: '22px' }}
            handleFile={handleChangeFile}
            hideInput
          />
        </Box>
      </DrawerLikeModal>

      <DrawerLikeModal
        title="Выгрузить отчетность"
        open={openUploadModal}
        toggleOpen={() => toggleOpen(ModalType.upload)}
        saveForm={handleSaveFormUpload}
        buttonConfirmText="Выгрузить"
        isDisabled={isLoading}
        hideSpacer
        isFormUpload={true}
      >
        {templateDropdownList.map(({ label, id, placeholder, dropdownItems, value }) => (
          <Dropdown
            onChange={onChangeDropdown}
            key={id}
            label={label}
            title={placeholder}
            id={id}
            dropdownItems={dropdownItems}
            value={value}
          />
        ))}
      </DrawerLikeModal>
    </>
  );
});
