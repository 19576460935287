import {observable, makeObservable, action, reaction, computed, runInAction} from 'mobx';
import {FILTER_TYPE_REPORT} from '@/constants';
import {apiPath} from '@/constants/api';
import type {IButtonGroupItem} from '@/constants/types';
import {createAxiosRequest} from '@/utils/request';
import type {TableStoreType} from '@/stores/TableStore';
import {TableStore} from '@/stores/TableStore';
import type {IContragentReportBalancePeriodStore, IRootStore} from '@/stores/type';
import {IDataLTM} from '@/stores/ContragentReportTableLTM';
import {IDataPerQuarter} from '@/stores/ContragentReportTablePerQuarter';

export type IDataBalancePeriod = {
    id: number;
    code: number;
    value: number;
    report_date: string;
    parameter_date: string;
    inn: string;
    from_api: boolean;
};

export type IGetContragentReports<T> = {
    count: number;
    next?: string;
    previous?: string;
    results: T[];
};


export type IContragentTableStoreWithIBalancePeriodStore<T> = IContragentReportBalancePeriodStore & TableStoreType<T>;

export class ContragentReportBalancePeriodStore
    extends TableStore<IDataBalancePeriod>
    implements IContragentTableStoreWithIBalancePeriodStore<IDataBalancePeriod> {
    @observable
    public rootStore: IRootStore;

    @observable
    public results: IDataBalancePeriod[];

    @observable
    public selectedFilter?: IButtonGroupItem<string>;

    @observable
    public selectedAccountId?: string | number;

    constructor(rootStore: IRootStore) {
        super();
        makeObservable(this);
        this.rootStore = rootStore;

        reaction(
            () => this.rowsPerPageChange,
            (value, _previousValue) => {
                if (this.maxSelectedPage <= value) {
                    this.selectedFilter?.type === FILTER_TYPE_REPORT.BALANCE
                        ? this.getReportsBalancePeriodId()
                        : this.getReportsBalancePeriod();
                }
            },
        );

        reaction(
            () => this.selectedPage,
            (value, _previousValue) => {
                if (this.maxSelectedPage <= value) {
                    this.selectedFilter?.type === FILTER_TYPE_REPORT.BALANCE
                        ? this.getReportsBalancePeriodId()
                        : this.getReportsBalancePeriod();
                }
            },
        );

        reaction(
            () => this.selectedFilter?.type,
            (value, previousValue) => {
                if (previousValue !== value) {
                    this.tableBody = [];
                    this.selectedPage = 0;
                    value === FILTER_TYPE_REPORT.BALANCE ? this.getReportsBalancePeriodId() : this.getReportsBalancePeriod();
                }
            },
        );

        reaction(
            () => this.selectedAccountId,
            (value, _previousValue) => {
                if (value !== _previousValue) {
                    if (this.selectedFilter?.type === FILTER_TYPE_REPORT.BALANCE) {
                        this.getReportsBalancePeriod();
                    }
                }
            },
        );

        reaction(
            () => this.searchString,
            (value, previousValue) => {
                if (previousValue !== value) {
                    this.selectedFilter?.type === FILTER_TYPE_REPORT.BALANCE
                        ? this.getReportsBalancePeriodId()
                        : this.getReportsBalancePeriod();
                }
            },
        );
    }

    @computed
    public get isSearch() {
        return this.searchString || this.searchString?.trim();
    }

    @action
    getReportsBalancePeriod = async () => {
        this.rootStore.requestTemplate({
            errorMessage: 'Ошибка получения отчета баланса',
            callback: async () => {
                const limit = 1500;
                const offset = 0;
                const {data} = await createAxiosRequest<
                    { offset: number; limit: number },
                    IGetContragentReports<IDataBalancePeriod>
                >({
                    path: apiPath.konturBalancePeriod.replace('{account_pk}', `${this.selectedAccountId}`),
                    method: 'GET',
                    params: {limit, offset},
                    useToken: true,
                });
                runInAction(() => {
                    this.limit = limit;
                    this.summary = data.count;
                    this.offset = offset;
                    const tableData = data.results;
                    this.tableBody = [...tableData];
                    this.results = [...tableData];
                })
            },
        });
    };

    @action
    getReportsBalancePeriodId = async () => {
        this.rootStore.requestTemplate({
            errorMessage: 'Ошибка получения отчета по балансу',
            callback: async () => {
                const searchString = this.isSearch ? this.searchString?.trim() : undefined;

                const limit = this.isSearch ? 10 : this.rowsPerPageChange;
                const offset = this.isSearch ? 0 : this.rowsPerPageChange * this.selectedPage;
                const {data} = await createAxiosRequest<
                    { offset: number; limit: number; search?: string },
                    IGetContragentReports<IDataBalancePeriod>
                >({
                    path: apiPath.konturBalancePeriod.replace('{account_pk}', '690'),
                    method: 'GET',
                    params: {offset, limit, search: searchString},
                    useToken: true,
                });
              runInAction(() => {
                this.limit = limit;
                this.summary = data.count;
                this.offset = offset;
                const tableData = data.results;
                this.tableBody = [...tableData];
              })
            },
        });
    };

    @action
    createReports = async (
        dataToSave: {
            code: number;
            value: number | null;
            report_date: string;
            parameter_date: string;
            inn: string;
        }[],
    ) => {
        const {setRequestSuccess} = this.rootStore;

        this.rootStore.requestTemplate({
            errorMessage: 'Данные введены неверно',
            successMessage: 'Данные успешно сохранены',
            finallyAction: [
                async () => {
                    await this.rootStore.contragentReportTablePerQuarter.getReportsTable();
                },
                async () => {
                    await this.getReportsBalancePeriod();
                    await this.getReportsBalancePeriod();
                    await this.getReportsBalancePeriod();
                },
                async () => {
                    await this.rootStore.contragentReportTableLTM.getReportsTable();
                },
            ],
            callback: async (successMessage?: string) => {
                const requestData = dataToSave.map((item) => ({
                    code: item.code,
                    value: item.value,
                    report_date: item.report_date,
                    parameter_date: item.parameter_date,
                    inn: item.inn,
                }));

                await createAxiosRequest<{}, any>({
                    path: apiPath.konturBalancePeriod.replace('{account_pk}', `${this.selectedAccountId}`),
                    method: 'POST',
                    isFile: false,
                    data: requestData,
                    useToken: true,
                });

                setRequestSuccess(successMessage);
            },
        });
    };

    @action
    clear = () => {
        this.selectedFilter = undefined;
    };
}
