import React from 'react';

import { Box, Typography } from '@mui/material';

import { HomePageCaption } from '@/assets/index';
import { color } from '@/constants/colors';

export const HomePage = () => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '80vh',
          gap: '20px'
        }}
      >
        <HomePageCaption />
        <Typography
          component="h3"
          variant="h6"
          color={color.black}
          gutterBottom
          sx={{
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '24px',
            margin: '0',
            lineHeight: '14px',
            opacity: '0.9'
          }}
        >
          Здесь пока ничего нет
        </Typography>
      </Box>
    </>
  );
};
