import React, { useMemo, useState } from 'react';
import { observer } from 'mobx-react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box, styled } from '@mui/material';
import UnfoldMoreOutlinedIcon from '@mui/icons-material/UnfoldMoreOutlined';

import type { TableStoreType } from '@/stores/TableStore';
import { PaginationCustom } from '@/components/Templates/components/PaginationCustom';
import { TableRowSkeleton } from '@/components/Templates/components/TableRowSkeleton';
import { EmptyTable } from '@/components/Templates/components/EmptyTable';
import { color } from '@/constants/colors';
import { formatToCurrency } from '@/utils';
import { useRootStore } from '@/stores';

export type ITable<T> = {
  totalVisible?: boolean;
  tableHeader: string[];
  tableHeaderWidth?: string[];
  tableTitle?: string | JSX.Element;
  store: TableStoreType<T>;
  isLoading: boolean;
  RenderComponent: ({ row, filled, index }: { row: T; filled: boolean; index: number }) => JSX.Element;
  isHidePagination?: boolean;
  isSkipEmptyImage?: boolean;
  isPageApplicationTable?: boolean;
};

export const TableRowStyled = styled(TableRow)(({ theme }) => ({
  borderBottom: `1px solid ${color.pale_green}`,
}));

export const TableCellStyled = styled(TableCell)(({ theme }) => ({
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '14px',
  lineHeight: '16px',
  padding: '16px 8px',
  borderBottom: `1px solid ${color.pale_green}`,
}));

export const TableTemplate = observer(<T extends { [k: string]: any }>(props: ITable<T>) => {
  const {
    totalVisible,
    tableHeader,
    tableHeaderWidth,
    RenderComponent,
    isLoading,
    store,
    isHidePagination,
    isSkipEmptyImage,
    isPageApplicationTable,
  } = props;

  const rows = useMemo(() => Array.apply(null, Array(store.rowsPerPageChange)), [store.rowsPerPageChange]);

  const sum = store.renderedRows
    .map((el, index) => el.loadedLimitAmount)
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

  if (isSkipEmptyImage && !store.renderedRows?.length && !isLoading) {
    return null;
  }

  if (!store.renderedRows?.length && !isLoading) {
    return <EmptyTable />;
  }

  return (
    <>
      <Box sx={{ backgroundColor: color.white }}>
        <Table size="medium" sx={{ border: `1px solid ${color.pale_green}` }}>
          <TableHead>
            <TableRowStyled>
              {tableHeader.map((name, index) => {
                return (
                  <TableCellStyled
                    align={
                      (isPageApplicationTable && tableHeader?.length - 2 === index) || tableHeader?.length - 1 === index
                        ? 'right'
                        : undefined
                    }
                    key={index}
                    sx={tableHeaderWidth ? { width: tableHeaderWidth[index] } : undefined}
                  >
                    {name}
                  </TableCellStyled>
                );
              })}
            </TableRowStyled>
          </TableHead>

          <TableBody>
            {isLoading ? (
              <TableRowSkeleton rows={rows} cells={tableHeader} />
            ) : (
              store.renderedRows.map((el, index) => (
                <RenderComponent key={el?.id ?? index} row={el} filled={index % 2 === 0} index={index} />
              ))
            )}
          </TableBody>
          {totalVisible && (
            <TableHead>
              <TableRowStyled>
                <TableCellStyled>Итого</TableCellStyled>
                <TableCellStyled></TableCellStyled>
                <TableCellStyled></TableCellStyled>
                <TableCellStyled>{formatToCurrency(sum)}</TableCellStyled>
              </TableRowStyled>
            </TableHead>
          )}
        </Table>
      </Box>
      <Box
        mt="22px"
        mb="20px"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {!isHidePagination && (
          <>
            <PaginationCustom
              rowsPerPageChange={store.rowsPerPageChange}
              summary={store.summary}
              searchString={store?.searchString}
              selectedPage={store.selectedPage}
              handleChangePage={store.handleChangePage}
              disabled={isLoading}
            />
          </>
        )}
      </Box>
    </>
  );
});

export const TableTemplateMonitoring = observer(<T extends { [k: string]: any }>(props: ITable<T>) => {
  const {
    tableHeader,
    tableHeaderWidth,
    RenderComponent,
    isLoading,
    store,
    isHidePagination,
    isSkipEmptyImage,
    isPageApplicationTable,
  } = props;
  const { monitoringStore } = useRootStore();
  const rows = useMemo(() => Array.apply(null, Array(store.rowsPerPageChange)), [store.rowsPerPageChange]);

  const [statusSignal, setStatusSignal] = useState<boolean>(false);
  const [dateIdentified, setDateIdentified] = useState<boolean>(false);

  const handleSortByDate = () => {
    setDateIdentified((prevValue) => !prevValue);
    monitoringStore.selectedOrdering = `${dateIdentified ? '-' : ''}date_identified`;
  };

  const handleSortByDateStatus = () => {
    setStatusSignal((prevValue) => !prevValue);
    monitoringStore.selectedOrdering = `${statusSignal ? '' : '-'}date_status`;
  };

  if (isSkipEmptyImage && !store.renderedRows?.length && !isLoading) {
    return null;
  }

  if (!store.renderedRows?.length && !isLoading) {
    return <EmptyTable />;
  }

  return (
    <>
      <Box sx={{ backgroundColor: color.white }}>
        <Table size="medium" sx={{ border: `1px solid ${color.pale_green}` }}>
          <TableHead>
            <TableRowStyled>
              {tableHeader.map((name, index) => {
                return (
                  <TableCellStyled
                    align={
                      (isPageApplicationTable && tableHeader?.length - 2 === index) || tableHeader?.length - 1 === index
                        ? 'right'
                        : undefined
                    }
                    key={index}
                    sx={isPageApplicationTable && tableHeaderWidth ? { width: tableHeaderWidth[index] } : undefined}
                  >
                    {name === 'Дата выявления' ? (
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          cursor: 'pointer',
                        }}
                        onClick={handleSortByDate}
                      >
                        {name}
                        <UnfoldMoreOutlinedIcon />
                      </Box>
                    ) : (
                      ''
                    )}
                    {name === 'Дата статуса' ? (
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          cursor: 'pointer',
                        }}
                        onClick={handleSortByDateStatus}
                      >
                        {name}
                        <UnfoldMoreOutlinedIcon />
                      </Box>
                    ) : (
                      ''
                    )}
                    {name !== 'Дата выявления' && name !== 'Дата статуса' && name}
                  </TableCellStyled>
                );
              })}
            </TableRowStyled>
          </TableHead>

          <TableBody>
            {isLoading ? (
              <TableRowSkeleton rows={rows} cells={tableHeader} />
            ) : (
              store.renderedRows.map((el, index) => (
                <RenderComponent key={el?.id ?? index} row={el} filled={index % 2 === 0} index={index} />
              ))
            )}
          </TableBody>
        </Table>
      </Box>
      <Box
        mt="22px"
        mb="20px"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {!isHidePagination && (
          <PaginationCustom
            rowsPerPageChange={store.rowsPerPageChange}
            summary={store.summary}
            searchString={store?.searchString}
            selectedPage={store.selectedPage}
            handleChangePage={store.handleChangePage}
            disabled={isLoading}
          />
        )}
      </Box>
    </>
  );
});
