import React, { FC } from 'react';
import style from '@/components/Contagents/ContactTabs/ContractReportTable.module.scss';

import { valueWithSpaces, yearsKonturString } from '@/utils/reportTable';
import { TableCell } from '@/components/Contagents/ContactTabs/ContractReportTableBalanceKontur';

interface ContractReportTableInputBalanceKonturProps {
  tableData: Record<string, TableCell>;
  code: number;
}

const ContractReportTableInputKontur: FC<ContractReportTableInputBalanceKonturProps> = (props) => {
  const {
    tableData,
    code,
  } = props;

  return (
    <>
      {yearsKonturString.slice(0, 10).map((year, indexYear) => {
        const keyPeriod = `${code}-${year}`;
        const cellData = tableData[keyPeriod];

        return (
          <td className={style.balanceTable__year} key={indexYear}>
            <input
              className={style.balanceTable__year__value}
              type="text"
              value={
                cellData
                  ? cellData.form_endvalue !== 0
                    ? valueWithSpaces(cellData.form_endvalue.toString())
                    : ''
                  : ''
              }
              disabled={true}
            />
          </td>
        );
      })}
    </>
  );
};

export default ContractReportTableInputKontur;
