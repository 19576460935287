import { observable, makeObservable, action } from 'mobx';
import { apiPath } from '@/constants/api';
import { createAxiosRequest } from '@/utils/request';
import type { IAuthStore, IRootStore } from '@/stores/type';

export class AuthStore implements IAuthStore {
  @observable
  public rootStore?: IRootStore;

  @observable
  public isLoading: boolean = false;

  @observable
  public token?: string = localStorage.getItem('token') ?? undefined;

  @observable
  public isAuth: boolean = Boolean(this.token);

  constructor(rootStore?: IRootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  @action
  public logout = async () => {
    try {
      this.isLoading = true;

      await createAxiosRequest<{ refresh: string }, { refresh: string }>({
        path: apiPath.logout,
        data: {
          refresh: localStorage.getItem('refresh') as string,
        },
        method: 'POST',
      });
    } catch (e) {
      console.log(e);
    } finally {
      localStorage.removeItem('token');
      localStorage.removeItem('refresh');
      this.token = undefined;
      this.isAuth = false;
      this.isLoading = false;
    }
  };

  getApiTokenAuth = async ({ username, password }: { username: string; password: string }) => {
    let response;

    if (!this.isLoading) {
      try {
        this.isLoading = true;

        const requestParams = { username, password };
        response = await createAxiosRequest<typeof requestParams, { access: string; refresh: string }>({
          path: apiPath.login,
          data: requestParams,
          method: 'POST',
        });
        this.token = response.data.access;
        localStorage.setItem('token', `${this.token}`);
        localStorage.setItem('refresh', response.data.refresh);
        this.isAuth = true;
      } catch (e) {
        console.log(e);
        response = e;
        this.logout();
      } finally {
        this.isLoading = false;
      }
    }

    return response;
  };
}
