import { APP_FACT_TYPE, APP_FACT_TYPE_TO_FACT_TYPE } from '@/constants/backendEnum';
import { format as formatDate, addDays, parseISO } from 'date-fns';

const monthNames = ['янв.', 'фев.', 'март', 'апр.', 'май', 'июнь', 'июль', 'авг.', 'сент.', 'окт.', 'ноя.', 'дек.'];

const fullMonthNames = [
  'января',
  'февраля',
  'марта',
  'апреля',
  'мая',
  'июня',
  'июля',
  'августа',
  'сентября',
  'октября',
  'ноября',
  'декабря',
];

export const dateFormatter = (incomeDate?: string, isFullMonthName?: boolean) => {
  if (!incomeDate) {
    return '';
  }

  const date = new Date(incomeDate);
  const monthName = isFullMonthName ? fullMonthNames[date.getMonth()] : monthNames[date.getMonth()];
  const day = date.getDate() > 9 ? date.getDate() : '0' + date.getDate();

  return `${day} ${monthName} ${date.getFullYear()}`;
};

export const dateFormatterWithHoursMinutes = (incomeDate?: string) => {
  if (!incomeDate) {
    return '';
  }
  const date = new Date(incomeDate);

  const dateWithMonth = dateFormatter(incomeDate);
  const time = date.getHours() > 9 ? date.getHours() : '0' + date.getHours();
  const minutes = date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes();

  return `${dateWithMonth} в ${time}:${minutes}`;
};

export const formatToCurrency = (val?: number) => {
  if (!val) {
    return '';
  }

  return val
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, '$& ')
    ?.toString()
    ?.replace('.', ',');
};

export const formatLimitInput = (number: string) => {
  return number.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

export const dateFormatterDefault = (incomeDate?: string) => {
  if (!incomeDate) {
    return '';
  }

  const date = new Date(incomeDate);
  const usedMonth = date.getMonth() + 1;
  const monthName = usedMonth > 9 ? usedMonth : '0' + usedMonth;
  const day = date.getDate() > 9 ? date.getDate() : '0' + date.getDate();

  return `${day}.${monthName}.${date.getFullYear()}`;
};

export const objectKeyUseLikeKeyValue = <T extends Record<string, string>>(obj: T): Record<keyof T, keyof T> => {
  const result: Record<keyof T, keyof T> | Record<string, string> = {}; //

  for (const key in obj) {
    result[key] = key;
  }

  return result as Record<keyof T, keyof T>;
};

export const swopKeysAndValues = <T extends Record<string, string>>(obj: T): Record<string, keyof T> => {
  const result: Record<string, keyof T> = {};

  for (const key in obj) {
    const value = obj[key];
    result[value] = key;
  }

  return result;
};

export const pluralize = ({ count, words }: { count: number; words: string[] }) => {
  const plural = getPlural(words);
  return `${count} ${plural(count)}`;
};

export var getPlural = function (words: string[]) {
  return function (number: number) {
    var value = Math.abs(number);
    value %= 100;
    if (value >= 5 && value <= 20) {
      return words[2];
    }
    value %= 10;
    if (value === 1) {
      return words[0];
    }
    if (value >= 2 && value <= 4) {
      return words[1];
    }
    return words[2];
  };
};

export const mathRoundTwoDecimalsAssessment = (num: number) => (Math.round(num * 100) / 100).toFixed(2);

export const mathRoundTwoDecimals = (num: number) => {
  const roundedNum = Math.round(num * 100) / 100;
  return roundedNum.toFixed(2);
};

export const numberWithWhiteSpace = (num: number | string) => num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
export const removeTwoZeroAfterDot = (num: number | string) => num?.toString().replace(/\.00/g, '');

const format = [numberWithWhiteSpace, removeTwoZeroAfterDot, mathRoundTwoDecimals];
const formatAssessment = [numberWithWhiteSpace, removeTwoZeroAfterDot, mathRoundTwoDecimalsAssessment];

export const formatSum = (num: number, assesment?: boolean) =>
  (assesment ? formatAssessment : format).reduceRight<number | string>(
    (prevVal, callback) => callback(prevVal as number),
    num,
  );

export const capitalizeFirstLetter = (string?: string) => {
  if (!string) {
    return string;
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const transformNamesFromArr = (incomeNamesArr: string[], mappingsObj: { [key: string]: string }): string[] => {
  return incomeNamesArr.map((name) => {
    return mappingsObj[name] || name;
  });
};
const today = new Date();
const year = today.getFullYear();
const month = String(today.getMonth() + 1).padStart(2, '0');
const day = String(today.getDate()).padStart(2, '0');
export const formattedDateToday = `${year}-${month}-${day}`;

export const formatDateToday = formatDate(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");

export const getNextDay = (incomeDate: string): string => {
  if (!incomeDate) {
    return '';
  }
  const date = parseISO(incomeDate);
  const nextDay = addDays(date, 1);
  const newDate = formatDate(nextDay, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");
  return newDate;
};
