import { useEffect, useState } from 'react';

export const useYears = () => {
  const [years, setYears] = useState<string[]>([]);

  useEffect(() => {
    const getInitialDates = () => {
      const currentDate: Date = new Date();
      const initialDates: string[] = [];
      const quarterEndMonths: number[] = [3, 6, 9, 12];
    
      for (let i = 0; i < 11; i++) {
        const quarterIndex = Math.floor(currentDate.getMonth() / 3);
        const nextQuarterEndMonth = quarterEndMonths[quarterIndex];
        const nextDate: Date = new Date(currentDate.getFullYear(), nextQuarterEndMonth, 0);
        const formattedDate: string = formatDate(nextDate);
        initialDates.push(formattedDate);
    
        currentDate.setMonth(currentDate.getMonth() - 3);
      }
    
      return initialDates.reverse();
    };

    setYears(getInitialDates());

    const updateYears = () => {
      setYears((prevYears) => {
        const currentDate: Date = new Date();
        const nextDate: Date = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);
        const formattedNextDate: string = formatDate(nextDate);
        if (!prevYears.includes(formattedNextDate)) {
          if (prevYears.length >= 10) {
            prevYears.pop();
          }
          return [formattedNextDate, ...prevYears];
        } else {
          return prevYears;
        }
      });
    };

    const timerId = setTimeout(updateYears, 1000 * 60 * 60 * 24);

    return () => {
      clearTimeout(timerId);
    };
  }, []);

  return { years, setYears };
};

export const formatDate = (date: Date): string => {
  const year: number = date.getFullYear();
  const month: number = date.getMonth() + 1;
  const day: number = date.getDate();
  const formattedMonth: string = month < 10 ? '0' + month : String(month);
  const formattedDay: string = day < 10 ? '0' + day : String(day);
  return `${year}-${formattedMonth}-${formattedDay}`;
};
