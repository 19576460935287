import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';

import Box from '@mui/material/Box';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';

import { OFFSET } from '@/constants/sizes';
import { LabelLikeHintWithText } from '@/components/common/LabelLikeHintWithText';
import { StatusBadge } from '@/components/atoms/Bage/StatusBadge';
import { ButtonStyled } from '@/components/atoms/Button';
import { ScoringSettingsPageDetailsTable } from '@/components/ScoringSettingsPageDetails/ScoringSettingsPageDetailsTable';
import type { TabBarItem } from '@/components/common/TabBarStyled';
import { TabBarStyled } from '@/components/common/TabBarStyled';
import { useRootStore } from '@/stores';
import { mapToLikeStore } from '@/components/ScoringPage/ScoringPageTable';
import { SETTINGS_STATUS, SETTINGS_STATUS_TO_STATUS, STATUS_TO_STATUS } from '@/constants/backendEnum';
import { BoxOutlined } from '@/assets';
import { BreadrcrumbsWithWrapper } from '@/components/common/Breadrcrumbs';
import { ROLE, ROUTES } from '@/constants';
import { color } from '@/constants/colors';

export const TAB_BAR_TYPE = {
  CLIENT: 'client',
  DEBTOR: 'debtor',
  RELATED: 'related',
};

export const ACTION_BY_STATUS = {
  [SETTINGS_STATUS_TO_STATUS.Active]: {
    button: {
      name: 'Изменить',
      icon: <BoxOutlined stroke={color.white} />,
    },
  },
  [SETTINGS_STATUS_TO_STATUS.Draft]: {
    button: {
      name: 'Активировать',
      icon: <PlayArrowOutlinedIcon />,
    },
  },
  [SETTINGS_STATUS_TO_STATUS.Archive]: {
    button: {
      name: undefined,
      icon: undefined,
      action: undefined,
    },
  },
};

const tabBarItems: TabBarItem[] = [
  {
    name: 'Клиент',
    component: <ScoringSettingsPageDetailsTable type={TAB_BAR_TYPE.CLIENT} />,
    permissions: [ROLE.SMARTFACT, ROLE.GENERAL, ROLE.BALTICLEASING, ROLE.PSBL],
  },
  {
    name: 'Дебитор',
    component: <ScoringSettingsPageDetailsTable type={TAB_BAR_TYPE.DEBTOR} />,
    permissions: [ROLE.SMARTFACT, ROLE.BALTICLEASING, ROLE.PSBL],
  },
  {
    name: 'Связи',
    component: <ScoringSettingsPageDetailsTable type={TAB_BAR_TYPE.RELATED} />,
    permissions: [ROLE.SMARTFACT, ROLE.GENERAL, ROLE.BALTICLEASING, ROLE.PSBL],
  },
];

const TabBarSample = ({ userRole }: { userRole?: string | null }) => {
  const memoisedTB = useMemo(() => tabBarItems, []);
  const [selectedTab, setSelectedTab] = useState(memoisedTB[0]);

  const onChangeTab = (currentTab: string) => {
    const selected = memoisedTB.find(({ name }) => name === currentTab);
    setSelectedTab(selected ?? memoisedTB[0]);
  };

  return (
    <>
      <Box
        sx={{
          mt: OFFSET.sm,
          mb: OFFSET.sm,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <TabBarStyled items={memoisedTB} selected={selectedTab} onChangeTab={onChangeTab} userRole={userRole} />
      </Box>
      {selectedTab.component}
    </>
  );
};

const useButtonSettings = () => {
  const { scoringPageStore } = useRootStore();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const currentSetting = useMemo(
    () => scoringPageStore.settings.find((item) => item.id.toString() === id),
    [id, scoringPageStore.settings],
  );

  const formattedCurrentSetting = useMemo(
    () => currentSetting && mapToLikeStore([currentSetting])?.[0],
    [currentSetting],
  );

  const buttonSettings = useMemo(
    () => currentSetting && ACTION_BY_STATUS[currentSetting.status as keyof typeof SETTINGS_STATUS_TO_STATUS]?.button,
    [currentSetting],
  );

  const buttonAction = () => {
    if (currentSetting) {
      let activateAction = () => {
        console.error(`проблема со статусом ${currentSetting.status}, или id ${id}`);
      };
      if (currentSetting.status === SETTINGS_STATUS_TO_STATUS.Active && id) {
        activateAction = async () => {
          const response = await scoringPageStore.duplicateAlgorithm(id);

          if (response) {
            // обнуляем данные что бы автоматом перезапросить после редиректа
            scoringPageStore.settings = [];
            navigate(`${ROUTES.settings}/${response.id}`, { replace: true });
          }
        };
      } else if (currentSetting.status === SETTINGS_STATUS_TO_STATUS.Draft && id) {
        activateAction = async () => {
          const response = await scoringPageStore.draftAlgorithmToActive(id);
          // обнуляем данные что бы автоматом перезапросить после редиректа
          if (response) {
            scoringPageStore.settings = [];
            navigate(`${ROUTES.settings}/${response.id}`, { replace: true });
          }
        };
      }

      return activateAction();
    }
  };

  const type = () => {
    if (formattedCurrentSetting?.status === SETTINGS_STATUS_TO_STATUS.Draft) {
      return STATUS_TO_STATUS.New;
    }

    if (formattedCurrentSetting?.status === SETTINGS_STATUS_TO_STATUS.Active) {
      return STATUS_TO_STATUS.Active;
    }

    return STATUS_TO_STATUS.Inactive;
  };

  return {
    currentSetting,
    formattedCurrentSetting,
    buttonSettings,
    buttonAction,
    type,
  };
};

export const ScoringSettingsPageDetails = observer(() => {
  const { scoringPageStore, userStore } = useRootStore();

  useEffect(() => {
    if (!scoringPageStore.settings || scoringPageStore.settings?.length === 0) {
      scoringPageStore.getSettings();
    }
  });

  const { currentSetting, formattedCurrentSetting, buttonSettings, buttonAction, type } = useButtonSettings();

  return (
    <Box mb={OFFSET.xxxl}>
      <BreadrcrumbsWithWrapper
        breadCrumbItems={[
          {
            name: currentSetting?.name ?? '-',
          },
        ]}
      />
      <Box
        sx={{
          height: '88px',
          mrgingTop: '24px',
          background: color.light,
          border: `1px solid ${color.pale_green}`,
          borderRadius: '4px',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexDirection: 'row',
          display: 'flex',
          padding: '0 24px',
        }}
      >
        <LabelLikeHintWithText title="Название" value={formattedCurrentSetting?.name} />
        <LabelLikeHintWithText title="Статус">
          <StatusBadge type={type() as any}>
            {SETTINGS_STATUS[formattedCurrentSetting?.status as keyof typeof SETTINGS_STATUS]}
          </StatusBadge>
        </LabelLikeHintWithText>
        <LabelLikeHintWithText title="Версия" value={formattedCurrentSetting?.version} />
        <LabelLikeHintWithText title="Период действия" value={formattedCurrentSetting?.period} />
        {buttonSettings && buttonSettings.name && (
          <ButtonStyled text={buttonSettings.name} startIcon={buttonSettings.icon} onClick={buttonAction} />
        )}
      </Box>
      <Box
        sx={{
          mt: OFFSET.sm,
          mb: OFFSET.sm,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      />
      <TabBarSample userRole={userStore.profileInfo?.view_set} />
    </Box>
  );
});
