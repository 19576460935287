import { styled } from '@mui/system';
import { Badge, badgeClasses } from '@mui/material';
import { color } from '@/constants/colors';

export const BadgeStyled = styled(Badge)(
  ({ theme, ...props }) => `
  & .${badgeClasses.badge} {
    min-width: 20px;
    height: 20px;
    color: ${color.white};
    font-weight: 600;
    font-size: 10px;
    text-align: center;
    background: ${color.light_green};
  }
  `,
);
