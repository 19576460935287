import {makeObservable, observable, runInAction} from 'mobx';
import {apiPath} from '@/constants/api';
import {createAxiosRequest} from '@/utils/request';
import {TableStoreType, TableStore} from '@/stores/TableStore';
import type {IRootStore} from '@/stores/type';

export type IAdministrationDataAttribute = {
    id: number;
    name: string;
    db_object_column?: string;
    calc_expression?: string;
    join?: string;
    description?: string;
    created?: string;
    modified?: string;
    created_by?: string;
    modified_by?: string;
    data_object?: string;
    data_type?: string;
};

type IModifiedAdministrationDataAttribute = Omit<IAdministrationDataAttribute, 'id'>;

export type ITableBodyAdministrationDataAttribute = {
    id: number;
    bdName: string;
    columnName?: string;
    connectionName?: string;
    expressionName?: string;
    dataType?: string;
    description?: string;
};

export interface IAdministrationDataAttributeStore {
    rootStore: IRootStore;
    getAdministrationDataAttribute: ({dataObjectId}: { dataObjectId: string }) => void;
    getAdministrationDataAttributeById: (id: string | number) => void;
    deleteAdministrationDataAttributeById: (dataObjectId: string, id: string | number) => void;
    editAdministrationDataAttributeById: (
        dataObjectId: string,
        {dataAttr, id}: { id: string; dataAttr: IAdministrationDataAttribute },
    ) => void;
    createAdministrationDataAttribute: (
        dataObjectId: string,
        {dataAttr}: { dataAttr: IModifiedAdministrationDataAttribute },
    ) => void;
    tableBody?: ITableBodyAdministrationDataAttribute[];
    isLoading: boolean;
    selectedAdministrationDataAttributeById: ITableBodyAdministrationDataAttribute | Record<string, string>;
}

export type IAdministrationDataAttributeStoreWithITableStore<T> = IAdministrationDataAttributeStore & TableStoreType<T>;

export type IGetAdministrationDataAttribute = IGetWithPagination<IAdministrationDataAttribute>;

export class AdministrationDataAttributeStore
    extends TableStore<ITableBodyAdministrationDataAttribute>
    implements IAdministrationDataAttributeStoreWithITableStore<ITableBodyAdministrationDataAttribute> {
    @observable
    public rootStore: IRootStore;

    @observable
    public isLoading: boolean = false;

    @observable
    public selectedAdministrationDataAttributeById: ITableBodyAdministrationDataAttribute | Record<string, any> = {};

    constructor(rootStore: IRootStore) {
        super();
        makeObservable(this);
        this.rootStore = rootStore;
    }

    public rowsPerPageChange = 100;

    public getAdministrationDataAttributeById(id: string | number) {
        if (!this.isLoading) {
            this.rootStore.requestTemplate({
                errorMessage: `Ошибка получения данных атрибутов`,
                callback: async () => {
                    try {
                        this.isLoading = true;

                        const {data} = await createAxiosRequest<{}, IAdministrationDataAttribute>({
                            path: apiPath.dataObjAttrById.replace('{id}', `${id}`),
                            method: 'GET',
                            useToken: true,
                        });
                        runInAction(() => {
                            this.selectedAdministrationDataAttributeById = mapAdministrationDataToTableBody([data])?.[0];
                        })
                    } finally {
                        this.isLoading = false;
                    }
                },
            });
        }
    }

    public getAdministrationDataAttribute({dataObjectId}: { dataObjectId: string }) {
        if (!this.isLoading) {
            this.rootStore.requestTemplate({
                immediately: true,
                errorMessage: `Ошибка получения данных атрибутов`,
                callback: async () => {
                    try {
                        this.isLoading = true;

                        const limit = this.rowsPerPageChange;
                        const offset = this.rowsPerPageChange * this.selectedPage;
                        const {data} = await createAxiosRequest<
                            { offset: number; limit: number },
                            IGetAdministrationDataAttribute
                        >({
                            path: apiPath.dataObjAttrByDataId.replace('{data_object_pk}', `${dataObjectId}`),
                            method: 'GET',
                            params: {offset: this.rowsPerPageChange * this.selectedPage, limit: this.rowsPerPageChange},
                            useToken: true,
                        });
                        runInAction(() => {
                            this.limit = limit;
                            this.summary = data.count;
                            this.offset = offset;
                            const tableData = mapAdministrationDataToTableBody(data.results);
                            this.tableBody = [...tableData];
                        })
                    } finally {
                        runInAction(() => {
                            this.isLoading = false;
                        })
                    }
                },
            });
        }
    }

    public deleteAdministrationDataAttributeById(dataObjectId: string, id: string | number) {
        if (!this.isLoading) {
            this.rootStore.requestTemplate({
                finallyAction: () => this.getAdministrationDataAttribute({dataObjectId}),
                errorMessage: `Ошибка удаления данных атрибута`,
                callback: async () => {
                    try {
                        this.isLoading = true;

                        await createAxiosRequest<{}, IAdministrationDataAttribute>({
                            path: apiPath.dataObjAttrById.replace('{id}', `${id}`),
                            method: 'DELETE',
                            useToken: true,
                        });
                    } finally {
                        this.isLoading = false;
                    }
                },
            });
        }
    }

    public editAdministrationDataAttributeById(
        dataObjectId: string,
        {dataAttr, id}: { dataAttr: IModifiedAdministrationDataAttribute; id: string },
    ) {
        if (!this.isLoading) {
            this.rootStore.requestTemplate({
                finallyAction: () => this.getAdministrationDataAttribute({dataObjectId}),
                errorMessage: `Ошибка удаления изменения атрибута`,
                callback: async () => {
                    try {
                        this.isLoading = true;

                        await createAxiosRequest<{}, IAdministrationDataAttribute>({
                            path: apiPath.dataObjAttrById.replace('{id}', `${id}`),
                            method: 'PATCH',
                            data: {...dataAttr},
                            useToken: true,
                        });
                    } finally {
                        this.isLoading = false;
                    }
                },
            });
        }
    }

    public createAdministrationDataAttribute(
        dataObjectId: string,
        {dataAttr}: { dataAttr: IModifiedAdministrationDataAttribute },
    ) {
        if (!this.isLoading) {
            this.rootStore.requestTemplate({
                finallyAction: () => this.getAdministrationDataAttribute({dataObjectId}),
                errorMessage: `Ошибка удаления создания атрибута`,
                callback: async () => {
                    try {
                        this.isLoading = true;

                        await createAxiosRequest<{}, IAdministrationDataAttribute>({
                            path: apiPath.dataObjAttr,
                            method: 'POST',
                            data: {...dataAttr},
                            useToken: true,
                        });
                    } finally {
                        this.isLoading = false;
                    }
                },
            });
        }
    }
}

const mapAdministrationDataToTableBody = (data: IAdministrationDataAttribute[]) =>
    data.map((item) => ({
        id: item.id,
        bdName: item.name,
        columnName: item?.db_object_column,
        connectionName: item?.join,
        expressionName: item?.calc_expression,
        created: item.created,
        createdBy: item.created_by,
        dataType: item.data_type,
        description: item?.description,
    }));
