import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import { DrawerCustom } from '@/components/atoms/DrawerCustom';
import { ButtonStyled } from '@/components/atoms/Button';
import { DrawerLikeModal } from '@/components/common/DrawerLikeModal';
import { useRootStore } from '@/stores';
import type { ITableBodyPosition } from '@/stores/PositionStore';
import { Dropdown } from '@/components/atoms/Dropdown';
import { useValidation } from '@/hooks/useValidation';

const templateInputPosition = (editData?: ITableBodyPosition) => [
  {
    id: 'name',
    label: 'Название',
    value: editData?.name,
    required: true,
  },
  {
    id: 'description',
    label: 'Описание',
    value: editData?.description,
  },
];

export const PositionModal = observer(
  ({ editData, setEditData }: { editData?: ITableBodyPosition; setEditData: (val?: ITableBodyPosition) => void }) => {
    const {
      businessUnitStore,
      positionStore,
      isLoading,
      uiStateStore: { open },
    } = useRootStore();

    const { value, onChangeDropdown } = useValidation();

    const [openAdd, setOpenAdd] = useState<boolean>(false);

    const closeModal = () => {
      setOpenAdd(false);
      setEditData(undefined);
    };

    const openModal = () => {
      setOpenAdd(true);
    };

    const toggleOpen = () => {
      if (openAdd) {
        return closeModal();
      }

      return openModal();
    };

    useEffect(() => {
      if (editData) {
        openModal();
      }
    }, [editData]);

    const handleSaveForm = async (event: React.FormEvent<HTMLFormElement>) => {
      const data = new FormData(event.currentTarget);
      const name = data.get('name') as string; // r
      const description = data.get('description') as string;
      const { bu } = value;

      if (bu) {
        if (editData) {
          positionStore.editPosition({
            id: editData.id,
            name: name ?? editData?.name,
            description: description ?? editData?.description,
            bu: bu ?? editData.bu,
          });
        } else {
          positionStore.createPosition({
            name,
            description,
            bu,
          });
        }
      }

      closeModal();
    };

    const dropdownItems = useMemo(() => {
      if (businessUnitStore.allBusinessUnits) {
        return businessUnitStore.allBusinessUnits.map(({ id, name }) => ({
          label: name,
          value: id,
        }));
      }

      return [];
    }, [businessUnitStore.allBusinessUnits]);

    return (
      <>
        <DrawerCustom open={open}>
          <ButtonStyled text="добавить должность" startIcon={<AddCircleOutlineIcon />} onClick={toggleOpen} />
        </DrawerCustom>
        <DrawerLikeModal
          title={editData ? 'Изменить должность' : 'Добавить должность'}
          open={openAdd}
          toggleOpen={toggleOpen}
          saveForm={handleSaveForm}
          items={templateInputPosition(editData)}
          buttonConfirmText={editData ? 'Изменить' : 'добавить'}
          isLoading={isLoading}
        >
          <Dropdown
            mb="16px"
            onChange={onChangeDropdown}
            key="bu"
            label="Подразделение"
            disabledPlaceholder={false}
            title="Подразделение"
            id="bu"
            value={editData?.bu}
            dropdownItems={dropdownItems}
          />
        </DrawerLikeModal>
      </>
    );
  },
);
