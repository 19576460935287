import React, { useEffect, useMemo } from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';

import { WIDTH } from '@/constants/sizes';
import { TextFieldStyled } from '@/components/atoms/TextFieldStyled';
import { createAxiosRequestDadata } from '@/utils/request';
import { DefaultHttpCache, HttpCache } from '@/utils/http-cache-dadata';
import { DaDataSuggestion, DaDataParty } from '@/utils/dadataTypes';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

const guardianTypeIsDadata = (value: string | DaDataSuggestion<DaDataParty>): value is DaDataSuggestion<DaDataParty> =>
    (value as DaDataSuggestion<DaDataParty>)?.value !== undefined;

export const AutocompleteInn = (props: any) => {
  const [open, setOpen] = React.useState(false);
  const [query, setQuery] = React.useState<string>(props?.value?.value ?? '');
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [options, setOptions] = React.useState<readonly DaDataSuggestion<DaDataParty>[]>([]);
  const selectedDadataValue = React.useRef<DaDataSuggestion<DaDataParty> | null | string>(props?.value ?? '');

  const handleSelectedValue = (
      _event: any,
      value: DaDataSuggestion<DaDataParty> | null | string,
      _reason: any,
      _details?: any,
  ) => {
    if (props.onChange && value) {
      props.onChange(value);
      if (props?.handleChangeInput) {
        props.handleChangeInput({ autocompleteInn: '' });
      }
    }
    selectedDadataValue.current = value;
  };

  const disableSearchDadata = useMemo(
      () => props?.disableClearable && props?.freeSolo && props?.disableSearchDadata,
      [props?.disableClearable, props?.disableSearchDadata, props?.freeSolo],
  );

  const handleChange = (event: any) => {
    setQuery(event.target.value);
    if (props?.handleChangeInput) {
      props.handleChangeInput({ autocompleteInn: event.target.value });
    }
  };

  const handleInputChange = (event: React.ChangeEvent<{}>, value: string, reason: string) => {
    if (reason === 'clear') {
      props.onChange(undefined);
    }
  };

  const httpCache = (): HttpCache | null => {
    const cache = DefaultHttpCache.shared;
    return cache;
  };

  const requestSuggestion = React.useCallback(async () => {
    if (!disableSearchDadata) {
      setIsLoading(true);
      try {
        // Запрос дадаты
        const response = await createAxiosRequestDadata({
          query,
          cache: httpCache(),
        });
        setOptions([...response]);
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    }
  }, [disableSearchDadata, query]);

  useEffect(() => {
    if (!Boolean(query?.trim()) || isLoading) {
      return undefined;
    }

    const handler = setTimeout(() => {
      requestSuggestion();
    }, 500);

    return () => {
      if (handler) {
        clearTimeout(handler);
      }
    };
  }, [isLoading, query, requestSuggestion]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const onKeyDown = (event: any) => {
    if ((!open || options.length === 0) && props?.onKeyDown) {
      try {
        props.onKeyDown(event);
      } catch (e) {
        console.log('onKeyDown', 'AutocompleteInn', { e });
      }
    }
  };

  return (
      <Autocomplete
          onKeyDown={onKeyDown}
          disableClearable={props?.disableClearable}
          freeSolo={props?.freeSolo}
          id="asynchronous-demo"
          sx={{ width: WIDTH.s, ...props?.sx }}
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          isOptionEqualToValue={(option, value) => option.data.inn === (value as DaDataSuggestion<DaDataParty>)?.data?.inn}
          getOptionLabel={(option) => {
            if (guardianTypeIsDadata(option)) {
              return `${option?.value} ИНН:${option?.data.inn}`;
            }
            return option;
          }}
          options={options}
          loading={isLoading}
          popupIcon={<></>}
          renderOption={(props, option, { inputValue }) => {
            const name = option.value;
            const inn = option.data.inn;
            const matchesInn = match(inn, inputValue);
            const partsInn = parse(inn, matchesInn);

            const matchesName = match(name, inputValue);
            const partsName = parse(name, matchesName);

            return (
                <li {...props}>
                  <div>
                    {partsName.map((part, index) => (
                        <span
                            key={index}
                            style={{
                              fontWeight: part.highlight ? 700 : 400,
                            }}
                        >
                  {part.text}
                </span>
                    ))}
                    &nbsp;ИНН:
                    {partsInn.map((part, index) => (
                        <span
                            key={index}
                            style={{
                              fontWeight: part.highlight ? 700 : 400,
                            }}
                        >
                  {part.text}
                </span>
                    ))}
                  </div>
                </li>
            );
          }}
          onChange={handleSelectedValue}
          onInputChange={handleInputChange}
          renderInput={(params) => (
              <TextFieldStyled
                  {...params}
                  label=""
                  placeholder={props.placeholder}
                  onChange={handleChange}
                  sx={{ width: '100%', padding: 0 }}
                  size="small"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                        <React.Fragment>
                          {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                    ),
                  }}
              />
          )}
      />
  );
};

AutocompleteInn.displayName = 'AutocompleteInn';
