/* eslint-disable camelcase */
import { action, makeObservable, observable, reaction, runInAction } from 'mobx';
import { apiPath } from '@/constants/api';
import { createAxiosRequest } from '@/utils/request';
import type { TableStoreType } from '@/stores/TableStore';
import { TableStore } from '@/stores/TableStore';
import type { IRootStore } from '@/stores/type';

export type IBusinessUnitStore = {
  getBusinessUnits: () => void;
  createBusinessUnit: ({ name, description }: { name: string; description: string }) => void;
  deleteBusinessUnit: ({ id }: { id: string | number }) => void;
  editBusinessUnit: ({ id, name, description }: { id: string | number; name: string; description: string }) => void;
  allBusinessUnits?: IBusinessUnit[];
  getAllBusinessUnits: () => void;
};

export type IBusinessUnit = {
  id: number;
  name: string;
  description?: string;
  status?: string;
  updated?: string;
  created?: string;
  created_by?: {
    pk: number;
    username?: string;
    last_name?: string;
    first_name?: string;
    middle_name?: string;
    email?: string;
    url?: string;
    role?: string;
    phone?: string;
  };
  updated_by?: {
    pk: number;
    username?: string;
    last_name?: string;
    first_name?: string;
    middle_name?: string;
    email?: string;
    url?: string;
    role?: string;
    phone?: string;
  };
};

export type IGetBusinessUnit = {
  count: number;
  next?: string;
  previous?: string;
  results: IBusinessUnit[];
};

export type ITableBodyBusinessUnit = {
  id: number;
  name: string;
  description?: string;
  updatedBy?: string;
  updated?: string;
};

const mapBusinessUnitToTableBody = (rawList: IBusinessUnit[]): ITableBodyBusinessUnit[] => {
  return rawList?.map((item) => ({
    id: item.id,
    name: `${item.name}`,
    description: item?.description,
    updatedBy: item.updated_by?.first_name
      ? `${item.updated_by?.first_name} ${item.updated_by?.middle_name} ${item.updated_by?.last_name}`
      : item.updated_by?.username ?? `-`,
    updated: item?.updated ?? item.created,
  })) as ITableBodyBusinessUnit[];
};

export type BusinessUnitStoreWithTableStore<T> = IBusinessUnitStore & TableStoreType<T>;

export class BusinessUnitStore
  extends TableStore<ITableBodyBusinessUnit>
  implements BusinessUnitStoreWithTableStore<ITableBodyBusinessUnit>
{
  @observable
  public rootStore: IRootStore;

  // используется как значения для дропдауна
  @observable
  public allBusinessUnits?: IBusinessUnit[];

  @observable
  public isLoading?: boolean = false;

  constructor(rootStore: IRootStore) {
    super();
    makeObservable(this);
    this.rootStore = rootStore;

    reaction(
      () => this.rowsPerPageChange,
      (value) => {
        if (this.maxSelectedPage <= value) {
          this.getBusinessUnits();
        }
      },
    );

    reaction(
      () => this.selectedPage,
      (value) => {
        if (this.maxSelectedPage <= value) {
          this.getBusinessUnits();
        }
      },
    );
  }

  @action
  getBusinessUnits = () => {
    this.rootStore.requestTemplate({
      errorMessage: `Ошибка получения данных подразделений`,
      callback: async () => {
        const limit = this.rowsPerPageChange;
        const offset = this.rowsPerPageChange * this.selectedPage;

        const { data } = await createAxiosRequest<{ limit?: number; offset?: number }, IGetBusinessUnit>({
          path: `${apiPath.crBusinessUnit}`,
          method: 'GET',
          useToken: true,
          params: { limit, offset },
        });
        runInAction(() => {
          this.limit = limit;
          this.summary = data.count;
          this.offset = offset;
          this.tableBody = mapBusinessUnitToTableBody(data.results);
        })
      },
    });
  };

  @action
  getAllBusinessUnits = () => {
    this.rootStore.requestTemplate({
      errorMessage: `Ошибка получения данных всех подразделений`,
      immediately: true,
      callback: async () => {
        if (!this.isLoading) {
          try {
            this.isLoading = true;
            const { data } = await createAxiosRequest<{ limit?: number; offset?: number }, IGetBusinessUnit>({
              path: `${apiPath.crBusinessUnit}`,
              method: 'GET',
              useToken: true,
              params: { limit: 100, offset: 0 },
            });
            runInAction(() => {
              this.allBusinessUnits = data.results;
            })
          } catch {
            // noop
          } finally {
            this.isLoading = false;
          }
        }
      },
    });
  };

  @action
  getBusinessUnitsById = ({ id }: { id: string }) => {
    return this.rootStore.requestTemplate({
      isReturned: true,
      errorMessage: `Ошибка получения данных подразделения ${id}`,
      callback: async () => {
        const { data } = await createAxiosRequest<
          { name: string; search: string; limit?: number; offet?: number },
          ITableBodyBusinessUnit
        >({
          path: `${apiPath.crudBusinessUnitById?.replace('{id}', id)}`,
          method: 'GET',
          useToken: true,
        });

        return data;
      },
    });
  };

  @action
  createBusinessUnit = ({ name, description }: { name: string; description: string }) => {
    return this.rootStore.requestTemplate({
      errorMessage: `Ошибка создания подразделений ${name}`,
      finallyAction: () => {
        this.getBusinessUnits();
        this.getAllBusinessUnits();
      },
      callback: async () => {
        await createAxiosRequest({
          path: `${apiPath.crBusinessUnit}`,
          method: 'POST',
          data: { name, description },
          useToken: true,
        });
      },
    });
  };

  @action
  editBusinessUnit = ({ id, name, description }: { id: string | number; name: string; description: string }) => {
    this.rootStore.requestTemplate({
      errorMessage: `Ошибка редактирования подразделений ${name}`,
      finallyAction: () => {
        this.getBusinessUnits();
        this.getAllBusinessUnits();
      },
      callback: async () => {
        await createAxiosRequest<any, any>({
          path: apiPath.crudBusinessUnitById?.replace('{id}', `${id}`),
          method: 'PATCH',
          data: { name, description },
          useToken: true,
        });
      },
    });
  };

  @action
  deleteBusinessUnit = ({ id }: { id: string | number }) => {
    this.rootStore.requestTemplate({
      errorMessage: `Ошибка удаления подразделений ${id}`,
      finallyAction: () => {
        this.getBusinessUnits();
        this.getAllBusinessUnits();
      },
      callback: async () => {
        await createAxiosRequest({
          path: apiPath.crudBusinessUnitById?.replace('{id}', `${id}`),
          method: 'DELETE',
          useToken: true,
        });
      },
    });
  };
}
