import React from 'react';
import { light } from '@/constants/Theme';

export const CustomContext = React.createContext({
  theme: {
    theme: light,
    changeTheme: () => {},
    isDarkTheme: false,
  },
  drawer: {
    open: true,
    toggleOpen: () => {},
  },
});
