import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';

import { useRootStore } from '@/stores';
import { DrawerLikeModal } from '@/components/common/DrawerLikeModal';
import { WIDTH } from '@/constants/sizes';
import { TemplateInputWithLabel, TemplateWithLabel } from '@/components/atoms/TemplateInputWithLabel';
import type { DaDataParty, DaDataSuggestion } from '@/utils/dadataTypes';
import { AutocompleteInn } from '@/components/atoms/AutocompleteInn';
import { Dropdown } from '@/components/atoms/Dropdown';
import { APP_FACT_TYPE, APP_FACT_TYPE_TO_FACT_TYPE } from '@/constants/backendEnum';
import { generateFactoringDropdownItems } from './ApplicationAddTable';

type Props = {
  openAdd: boolean;
  toggleOpen: () => void;
};

const templateDropdownList = {
  id: 'factoringType',
  label: 'Тип факторинга ⃰',
  placeholder: 'Выбрать',
  dropdownItems: generateFactoringDropdownItems(APP_FACT_TYPE, APP_FACT_TYPE_TO_FACT_TYPE),
};

const templateInputWithLabelList = [
  {
    id: 'limit',
    label: `Лимит ₽ ⃰`,
    required: true,
  },
  {
    id: 'defermentDays',
    label: 'Отсрочка к/д ⃰',
    required: true,
  },
  {
    id: 'preferentialPeriod',
    label: 'Льготный период ⃰',
    required: true,
  },
  {
    id: 'collabMonths',
    label: 'Срок работы с дебитором. мес. ⃰',
    required: true,
  },
];

export const ApplicationAddConditionModal = observer(({ openAdd, toggleOpen }: Props) => {
  const { applicationsPageDetailsStore, isLoading } = useRootStore();
  const { id } = useParams<{ id: string }>();

  const [selectedContragent, setSelectedContragent] = useState<DaDataSuggestion<DaDataParty> | undefined>(undefined);
  const dropdownValueState = useRef<{ [k: string]: string }>({});

  const [formHasErrorRaw, setFormHasErrorRaw] = useState<Record<string, boolean>>({});
  const [isError, setIsError] = useState<boolean>(false);

  const setFormHasError = (val: any) => {
    setFormHasErrorRaw((formHasErrorRaw) => ({ ...(formHasErrorRaw ?? {}), ...val }));
  };

  useEffect(() => {
    setIsError(Object.values(formHasErrorRaw).some((item) => item));
  }, [formHasErrorRaw]);

  const handleChangeDadata = (value: DaDataSuggestion<DaDataParty>) => {
    setSelectedContragent(value);
  };

  const onChangeDropdown = (name: string, value: string) => {
    dropdownValueState.current[name] = value;
  };

  const handleToggleOpen = () => {
    setSelectedContragent(undefined);
    toggleOpen();
  };

  const handleSaveForm = async (event: React.FormEvent<HTMLFormElement>) => {
    const data = new FormData(event.currentTarget);
    const inn = selectedContragent?.data.inn;
    const { factoringType } = dropdownValueState.current;
    const limit = data.get('limit') as string;
    const defermentDays = data.get('defermentDays') as string;
    const preferentialPeriod = data.get('preferentialPeriod') as string;
    const collabMonths = data.get('collabMonths') as string;

    if (id && limit && defermentDays && inn) {
      applicationsPageDetailsStore
        .createConditionsByApplicationId(id, {
          inn,
          limit,
          defermentDays,
          preferentialPeriod,
          collabMonths,
          factoringType,
        })
        .then(() => {
          toggleOpen();
        });
    }
  };

  return (
    <>
      <DrawerLikeModal
        title="Добавить дебитора"
        open={openAdd}
        toggleOpen={handleToggleOpen}
        saveForm={handleSaveForm}
        buttonConfirmText="Создать дебитора"
        isDisabled={isLoading || isError || !selectedContragent}
        topChildren
      >
        <TemplateWithLabel label="Дебитор и ИНН  ⃰">
          <AutocompleteInn sx={{ minWidth: WIDTH.s, margin: 0 }} onChange={handleChangeDadata} />
        </TemplateWithLabel>
        {templateInputWithLabelList?.map(({ id, label, ...other }) => (
          <TemplateInputWithLabel
            handleGlobalError={setFormHasError}
            isError={formHasErrorRaw?.[id as string] ?? false}
            key={id}
            id={id}
            name={id}
            label={label}
            style={{ width: WIDTH.s }}
            autoComplete="off"
            {...other}
          />
        ))}
        <Dropdown
          handleGlobalError={setFormHasError}
          isError={formHasErrorRaw['factoringType'] ?? false}
          onChange={onChangeDropdown}
          required
          key={templateDropdownList.id}
          label={templateDropdownList.label}
          title={templateDropdownList.placeholder}
          id={templateDropdownList.id}
          dropdownItems={templateDropdownList.dropdownItems}
        />
      </DrawerLikeModal>
    </>
  );
});
