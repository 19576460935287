import React, {useState, useRef, useEffect, ChangeEvent} from 'react';
import {observer} from 'mobx-react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {CircularProgress, InputAdornment, SelectChangeEvent} from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

import {Dropdown} from '@/components/atoms/Dropdown';
import {color} from '@/constants/colors';
import {Title} from '@/components/atoms/Title';
import {ButtonStyled} from '@/components/atoms/Button';
import {TemplateWithLabel} from '@/components/atoms/TemplateInputWithLabel';
import {DrawerLikeModal} from '@/components/common/DrawerLikeModal';
import {useRootStore} from '@/stores';
import {OFFSET, WIDTH} from '@/constants/sizes';
import {APP_STATUS__MONIT} from '@/constants/backendEnum';
import {Monitoring} from '@/components/Monitoring/Monitoring';
import {FilterStatus, useFilters} from '@/components/atoms/FilterStatus';
import {MonitoringDatePicker} from '@/components/Monitoring/MonitoringDatePicker/MonitoringDatePicker';
import {TextFieldOutlinedStyled} from '@/components/atoms/TextFieldStyled';
import {formatDateToday, getNextDay} from '@/utils/index';
import {MoreMenuTabData} from '@/components/Administering/AdministrationTabData/MoreMenuTabData';
import {TableBodyContragents} from '@/stores/ContragentsStore';
import {AutocompleteInnMonitoring} from '@/components/atoms/AutocompleteInnMonitoring';
import {runInAction} from "mobx";

const selectItems = {
    IDENTIFIED: 'Выявлен',
    NOT_CONFIRM: 'Не подтверждён',
    CONFIRM: 'Подтверждён',
    REVIEWED_KK: 'Рассмотрен КК',
    ARCHIVE: 'В архиве',
};

const filterLabel = 'Фильтры статуса';
const selectTitle = 'Статус сигнала';

const templateInnList = [
    {
        id: 'inn',
        required: true,
    },
];

const templateDropdownList = [
    {
        id: 'signal',
        placeholder: 'Выбрать...',
        required: true,
        dropdownItems: [
            {
                label: APP_STATUS__MONIT.NameSignal__1.name,
                value: APP_STATUS__MONIT.NameSignal__1.name,
                code: APP_STATUS__MONIT.NameSignal__1.code,
            },
            {
                label: APP_STATUS__MONIT.NameSignal__2.name,
                value: APP_STATUS__MONIT.NameSignal__2.name,
                code: APP_STATUS__MONIT.NameSignal__2.code,
            },
            {
                label: APP_STATUS__MONIT.NameSignal__3.name,
                value: APP_STATUS__MONIT.NameSignal__3.name,
                code: APP_STATUS__MONIT.NameSignal__3.code,
            },
            {
                label: APP_STATUS__MONIT.NameSignal__4.name,
                value: APP_STATUS__MONIT.NameSignal__4.name,
                code: APP_STATUS__MONIT.NameSignal__4.code,
            },
            {
                label: APP_STATUS__MONIT.NameSignal__5.name,
                value: APP_STATUS__MONIT.NameSignal__5.name,
                code: APP_STATUS__MONIT.NameSignal__5.code,
            },
            {
                label: APP_STATUS__MONIT.NameSignal__6.name,
                value: APP_STATUS__MONIT.NameSignal__6.name,
                code: APP_STATUS__MONIT.NameSignal__6.code,
            },
            {
                label: APP_STATUS__MONIT.NameSignal__7.name,
                value: APP_STATUS__MONIT.NameSignal__7.name,
                code: APP_STATUS__MONIT.NameSignal__7.code,
            },
            {
                label: APP_STATUS__MONIT.NameSignal__32.name,
                value: APP_STATUS__MONIT.NameSignal__32.name,
                code: APP_STATUS__MONIT.NameSignal__32.code,
            },
            {
                label: APP_STATUS__MONIT.NameSignal__8.name,
                value: APP_STATUS__MONIT.NameSignal__8.name,
                code: APP_STATUS__MONIT.NameSignal__8.code,
            },
            {
                label: APP_STATUS__MONIT.NameSignal__9.name,
                value: APP_STATUS__MONIT.NameSignal__9.name,
                code: APP_STATUS__MONIT.NameSignal__9.code,
            },
            {
                label: APP_STATUS__MONIT.NameSignal__10.name,
                value: APP_STATUS__MONIT.NameSignal__10.name,
                code: APP_STATUS__MONIT.NameSignal__10.code,
            },
            {
                label: APP_STATUS__MONIT.NameSignal__11.name,
                value: APP_STATUS__MONIT.NameSignal__11.name,
                code: APP_STATUS__MONIT.NameSignal__11.code,
            },
            {
                label: APP_STATUS__MONIT.NameSignal__12.name,
                value: APP_STATUS__MONIT.NameSignal__12.name,
                code: APP_STATUS__MONIT.NameSignal__12.code,
            },
            {
                label: APP_STATUS__MONIT.NameSignal__13.name,
                value: APP_STATUS__MONIT.NameSignal__13.name,
                code: APP_STATUS__MONIT.NameSignal__13.code,
            },
            {
                label: APP_STATUS__MONIT.NameSignal__14.name,
                value: APP_STATUS__MONIT.NameSignal__14.name,
                code: APP_STATUS__MONIT.NameSignal__14.code,
            },
            {
                label: APP_STATUS__MONIT.NameSignal__15.name,
                value: APP_STATUS__MONIT.NameSignal__15.name,
                code: APP_STATUS__MONIT.NameSignal__15.code,
            },
            {
                label: APP_STATUS__MONIT.NameSignal__16.name,
                value: APP_STATUS__MONIT.NameSignal__16.name,
                code: APP_STATUS__MONIT.NameSignal__16.code,
            },
            {
                label: APP_STATUS__MONIT.NameSignal__17.name,
                value: APP_STATUS__MONIT.NameSignal__17.name,
                code: APP_STATUS__MONIT.NameSignal__17.code,
            },
            {
                label: APP_STATUS__MONIT.NameSignal__18.name,
                value: APP_STATUS__MONIT.NameSignal__18.name,
                code: APP_STATUS__MONIT.NameSignal__18.code,
            },
            {
                label: APP_STATUS__MONIT.NameSignal__19.name,
                value: APP_STATUS__MONIT.NameSignal__19.name,
                code: APP_STATUS__MONIT.NameSignal__19.code,
            },
            {
                label: APP_STATUS__MONIT.NameSignal__20.name,
                value: APP_STATUS__MONIT.NameSignal__20.name,
                code: APP_STATUS__MONIT.NameSignal__20.code,
            },
            {
                label: APP_STATUS__MONIT.NameSignal__21.name,
                value: APP_STATUS__MONIT.NameSignal__21.name,
                code: APP_STATUS__MONIT.NameSignal__21.code,
            },
            {
                label: APP_STATUS__MONIT.NameSignal__22.name,
                value: APP_STATUS__MONIT.NameSignal__22.name,
                code: APP_STATUS__MONIT.NameSignal__22.code,
            },
            {
                label: APP_STATUS__MONIT.NameSignal__23.name,
                value: APP_STATUS__MONIT.NameSignal__23.name,
                code: APP_STATUS__MONIT.NameSignal__23.code,
            },
            {
                label: APP_STATUS__MONIT.NameSignal__24.name,
                value: APP_STATUS__MONIT.NameSignal__24.name,
                code: APP_STATUS__MONIT.NameSignal__24.code,
            },
            {
                label: APP_STATUS__MONIT.NameSignal__25.name,
                value: APP_STATUS__MONIT.NameSignal__25.name,
                code: APP_STATUS__MONIT.NameSignal__25.code,
            },
            {
                label: APP_STATUS__MONIT.NameSignal__26.name,
                value: APP_STATUS__MONIT.NameSignal__26.name,
                code: APP_STATUS__MONIT.NameSignal__26.code,
            },
            {
                label: APP_STATUS__MONIT.NameSignal__27.name,
                value: APP_STATUS__MONIT.NameSignal__27.name,
                code: APP_STATUS__MONIT.NameSignal__27.code,
            },
            {
                label: APP_STATUS__MONIT.NameSignal__28.name,
                value: APP_STATUS__MONIT.NameSignal__28.name,
                code: APP_STATUS__MONIT.NameSignal__28.code,
            },
            {
                label: APP_STATUS__MONIT.NameSignal__29.name,
                value: APP_STATUS__MONIT.NameSignal__29.name,
                code: APP_STATUS__MONIT.NameSignal__29.code,
            },
            {
                label: APP_STATUS__MONIT.NameSignal__30.name,
                value: APP_STATUS__MONIT.NameSignal__30.name,
                code: APP_STATUS__MONIT.NameSignal__30.code,
            },
            {
                label: APP_STATUS__MONIT.NameSignal__31.name,
                value: APP_STATUS__MONIT.NameSignal__31.name,
                code: APP_STATUS__MONIT.NameSignal__31.code,
            },
        ],
    },
];

export const ContragentsMonitoringPage = observer(() => {
    const {
        isLoading,
        uiStateStore: {open},
        monitoringStore,
        userStore,
    } = useRootStore();
    const dropdownValueState = useRef<{ [k: string]: string }>({});

    const [selectedContragent, setSelectedContragent] = useState<TableBodyContragents | undefined>(undefined);
    const [openAdd, setOpenAdd] = useState<boolean>(false);
    const [formHasErrorRaw, setFormHasErrorRaw] = useState<Record<string, boolean>>({});
    const [isError, setIsError] = useState<boolean>(false);
    const [isFormFilled, setIsFormFilled] = useState<boolean>(false);
    const [statusName, setStatusName] = useState<string[]>([]);
    const [statusNameActive, setStatusNameActive] = useState<string[]>([]);
    const [codeValue, setCodeValue] = useState<string>('');
    const [nameValue, setNameValue] = useState<string>();
    const {open: openStatus, handleOpen, handleClose} = useFilters();

    const [dateRiskSignal, setDateRiskSignal] = useState<string>(formatDateToday);
    const [dateStartSignal, setDateStartSignal] = useState<string>('');
    const [dateEndSignal, setDateEndSignal] = useState<string>('');
    const [query, setQuery] = useState<string | undefined>(undefined);

    const handleChangeStatus = (event: SelectChangeEvent<typeof statusName>) => {
        const {
            target: {value},
        } = event;
        setStatusName(typeof value === 'string' ? value.split(',') : value);
    };

    const handleResetStatus = (e: React.MouseEvent) => {
        if (e.target instanceof SVGSVGElement) {
            setStatusName([]);
            setStatusNameActive([]);
        }
        setStatusName([]);
        setStatusNameActive([]);
    };

    const handleConfirm = (label: string) => {
        setStatusNameActive(statusName);
        handleClose(label);
    };

    const setFormHasError = (val: any) => {
        setFormHasErrorRaw((formHasErrorRaw) => ({...(formHasErrorRaw ?? {}), ...val}));
    };

    useEffect(() => {
        setIsError(Object.values(formHasErrorRaw).some((item) => item));
    }, [formHasErrorRaw]);

    const handleChange = (value?: TableBodyContragents) => {
        setSelectedContragent(value);
    };

    const onChangeDropdown = (name: string, value: string) => {
        dropdownValueState.current[name] = value;
        setNameValue(value);
        const allSignals = Object.values(APP_STATUS__MONIT);
        const matchedSignal = allSignals.find((signal) => signal.name === value);
        if (matchedSignal) {
            const code = matchedSignal.code;
            setCodeValue(code);
        }
    };

    const toggleOpen = () => {
        if (!isLoading) {
            setSelectedContragent(undefined);
            setOpenAdd(!openAdd);
        }
    };
    const toggleClose = () => {
        if (!isLoading) {
            setOpenAdd(false);
        }
    };

    const handleSaveForm = async (event: React.FormEvent<HTMLFormElement>) => {
        const dataToSave: {
            account: string;
            status_signal: string;
            updated_by: number;
            number_signal: string;
            name_signal: string;
            date_status: string;
            date_identified: string;
        } = {
            account: '',
            status_signal: '',
            updated_by: 0,
            number_signal: '',
            name_signal: '',
            date_status: '',
            date_identified: '',
        };
        const pk = userStore.results?.pk;
        const account = selectedContragent?.inn?.toString() || '';
        const validNameValue: string = nameValue ?? '';

        dataToSave.account = account;
        dataToSave.status_signal = 'IDENTIFIED';
        dataToSave.updated_by = pk;
        dataToSave.number_signal = codeValue;
        dataToSave.name_signal = validNameValue;
        dataToSave.date_status = dateRiskSignal;
        dataToSave.date_identified = dateRiskSignal;

        await monitoringStore.createMonitoring(dataToSave);
        await setOpenAdd(false);
    };

    const checkFormFilled = () => {
        const isAllFieldsFilled =
            Object.keys(formHasErrorRaw).every((key) => !formHasErrorRaw[key]) && selectedContragent !== undefined;
        setIsFormFilled(isAllFieldsFilled);
    };

    const downloadMonitoring = () => {
        monitoringStore.downloadMonitoringAll();
    };

    useEffect(() => {
        checkFormFilled();
    }, [formHasErrorRaw, selectedContragent]);

    const onChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
        setQuery(e.target.value);
    };

    useEffect(() => {
        let handler: NodeJS.Timeout;
        if (query?.length === 0) {
            handler = setTimeout(() => {
                runInAction(() => {
                    monitoringStore.searchString = query;
                })
            }, 1000);
        }

        if (isLoading || (query?.length as number) < 3) {
            return undefined;
        }

        handler = setTimeout(() => {
            runInAction(() => {
                monitoringStore.searchString = query;
            })
        }, 1000);

        return () => {
            if (handler) {
                clearTimeout(handler);
            }
        };
    }, [isLoading, monitoringStore, query]);

    useEffect(() => {
        runInAction(() => {
            monitoringStore.selectedDateIdentifiedGte = dateStartSignal;
        })
    }, [dateStartSignal]);

    useEffect(() => {
        const dateEndSignalNextDay = getNextDay(dateEndSignal);
        runInAction(() => {
            monitoringStore.selectedDateIdentifiedLte = dateEndSignalNextDay;
        })
    }, [dateEndSignal]);

    useEffect(() => {
        runInAction(() => {
            monitoringStore.selectedStatusSignal = statusNameActive.map((name) => name).join(', ');
        })
    }, [statusNameActive]);

    return (
        <Box mb={OFFSET.xxxl}>
            <Box
                sx={{
                    display: 'flex',
                    mt: '10px',
                    mb: '40px',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Title>Мониторинг контрагентов</Title>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '20px',
                    }}
                >
                    <TextFieldOutlinedStyled
                        onChange={onChangeSearch}
                        id="search"
                        name="search"
                        size="small"
                        type="text"
                        autoComplete="no"
                        placeholder="Найти..."
                        sx={{width: WIDTH.s}}
                        value={query}
                        endAdornment={
                            isLoading && (
                                <InputAdornment position="end">
                                    <CircularProgress size={20}/>
                                </InputAdornment>
                            )
                        }
                        startAdornment={
                            <InputAdornment position="start">
                                <SearchOutlinedIcon/>
                            </InputAdornment>
                        }
                    />
                    <ButtonStyled
                        text="Добавить риск-сигнал"
                        customType="success"
                        height="36px"
                        target="_blank"
                        onClick={toggleOpen}
                    />
                </Box>
                <DrawerLikeModal
                    open={openAdd}
                    isLoading={isLoading}
                    toggleOpen={() => {
                        toggleOpen();
                    }}
                    toggleClose={() => {
                        toggleClose();
                    }}
                    saveForm={handleSaveForm}
                    isDisabled={isError || !isFormFilled}
                    sx={{
                        alignItems: 'flex-end',
                        maxWidth: '420px',
                    }}
                >
                    <Typography
                        sx={{color: color.black, fontWeight: '600', textAlign: 'center', margin: '0 auto'}}
                        variant="h4"
                        component="h4"
                    >
                        Риск-сигнал
                    </Typography>
                    <Box
                        sx={{
                            width: WIDTH.auto,
                        }}
                    >
                        <Typography
                            sx={{
                                mt: OFFSET.xl,
                                mb: OFFSET.xxs,
                                fontWeight: '500',
                                fontSize: '18px',
                                lineHeight: '16px',
                                color: color.black,
                            }}
                        >
                            Дата выявления сигнала
                        </Typography>
                        <MonitoringDatePicker placeholder={true} setDateRiskSignal={setDateRiskSignal}/>
                    </Box>
                    <Box
                        sx={{
                            width: WIDTH.auto,
                        }}
                    >
                        <Typography
                            sx={{
                                mt: OFFSET.m,
                                mb: OFFSET.xxs,
                                fontWeight: '500',
                                fontSize: '18px',
                                lineHeight: '16px',
                                color: color.black,
                            }}
                        >
                            Сигнал
                        </Typography>
                        {templateDropdownList.map(({id, placeholder, dropdownItems, ...props}) => (
                            <Dropdown
                                sx={{
                                    width: WIDTH.auto,
                                }}
                                propWidth={true}
                                dropdownHeight={true}
                                handleGlobalError={setFormHasError}
                                onChange={onChangeDropdown}
                                isError={formHasErrorRaw?.[id] ?? false}
                                key={id}
                                title={placeholder}
                                id={id}
                                dropdownItems={dropdownItems}
                                {...props}
                            />
                        ))}
                    </Box>

                    <Box
                        sx={{
                            width: WIDTH.auto,
                        }}
                    >
                        <Typography
                            sx={{
                                mt: OFFSET.m,
                                mb: OFFSET.xxs,
                                fontWeight: '500',
                                fontSize: '18px',
                                lineHeight: '16px',
                                color: color.black,
                            }}
                        >
                            Контрагент
                        </Typography>
                        {templateInnList.map(({id, required}) => (
                            <TemplateWithLabel key={id} id={id} required={required} name={id}>
                                <AutocompleteInnMonitoring sx={{minWidth: '100%', margin: 0}} onChange={handleChange}/>
                            </TemplateWithLabel>
                        ))}
                    </Box>
                </DrawerLikeModal>
            </Box>
            <Box sx={{display: 'flex', mb: OFFSET.sm, alignItems: 'center', gap: '8px'}}>
                <Box sx={{display: 'flex', alignItems: 'center', gap: '8px'}}>
                    <Typography>c</Typography>
                    <MonitoringDatePicker setDate={setDateStartSignal} width="150px"/>
                    <Typography>по</Typography>
                    <MonitoringDatePicker setDate={setDateEndSignal} width="150px"/>
                </Box>
                <FilterStatus
                    filterLabel={filterLabel}
                    selectTitle={selectTitle}
                    selectItems={selectItems}
                    handleOpen={() => handleOpen(filterLabel)}
                    handleClose={() => handleClose(filterLabel)}
                    handleConfirm={() => handleConfirm(filterLabel)}
                    handleResetStatus={handleResetStatus}
                    handleChangeStatus={handleChangeStatus}
                    statusName={statusName}
                    open={openStatus(filterLabel)}
                    width={'140px'}
                />
                <Box sx={{marginLeft: OFFSET.auto}}>
                    <MoreMenuTabData handleDownload={downloadMonitoring} toDownloadAll={true}/>
                </Box>
            </Box>
            <Monitoring/>
        </Box>
    );
});
