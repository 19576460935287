import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Autocomplete, Box } from '@mui/material';

import { DrawerCustom } from '@/components/atoms/DrawerCustom';
import { ButtonStyled } from '@/components/atoms/Button';
import { useRootStore } from '@/stores';
import {
  MapAdministrationDataJoinToTableBody,
  MapAdministrationDataObjectJoinSpec,
} from '@/stores/freskoApi/AdministrationDataObjectJoinStore';
import { DrawerLikeModal } from '@/components/common/DrawerLikeModal';
import { useValidation } from '@/hooks/useValidation';
import { Dropdown } from '@/components/atoms/Dropdown';
import { TextFieldStyled } from '@/components/atoms/TextFieldStyled';
import { InputLabelStyled } from '@/components/atoms/InputLabelStyled';
import { color } from '@/constants/colors';

const templateInputJoinWithLabelList = (editJoin: MapAdministrationDataJoinToTableBody[0] | undefined) => [
  {
    id: 'alias',
    label: 'Название',
    required: true,
    value: editJoin?.displayName,
  },
  {
    id: 'description',
    label: 'Описание',
  },
  {
    id: 'db_layout',
    label: 'Слой',
    value: editJoin?.objectBdName,
  },
  {
    id: 'db_object',
    label: 'Объект',
    value: editJoin?.objectName,
  },
];

export const AdministrationDataDetailTabConnectionsAddConnectModal = observer(
  ({
    editJoin,
    setEditJoin,
    editAttribute,
    setEditAttribute,
    joinId,
  }: {
    editJoin?: MapAdministrationDataJoinToTableBody[0];
    setEditJoin?: (val?: MapAdministrationDataJoinToTableBody[0]) => void;
    editAttribute?: MapAdministrationDataObjectJoinSpec[0];
    setEditAttribute: (val: MapAdministrationDataObjectJoinSpec[0] | undefined) => void;
    joinId?: string | number;
  }) => {
    const {
      uiStateStore: { open },
    } = useRootStore();

    return (
      <>
        <DrawerCustom open={open}>
          <AddOrEditJoinModal editJoin={editJoin} setEditJoin={setEditJoin} />
          {joinId && (
            <AddOrEditAttributeModal
              joinId={joinId}
              editAttribute={editAttribute}
              setEditAttribute={setEditAttribute}
            />
          )}
        </DrawerCustom>
      </>
    );
  },
);

const AddOrEditJoinModal = ({
  editJoin,
  setEditJoin,
}: {
  editJoin?: MapAdministrationDataJoinToTableBody[0];
  setEditJoin?: (val?: MapAdministrationDataJoinToTableBody[0]) => void;
}) => {
  const { value, onChangeDropdown } = useValidation();

  const { id: idData } = useParams<{ id: string }>();
  const { isLoading, administrationDataObjectJoinStore } = useRootStore();

  const [openAdd, setOpenAdd] = useState<boolean>(false);

  const toggleOpen = () => {
    setOpenAdd(!openAdd);
  };

  const handleSaveForm = async (event: React.FormEvent<HTMLFormElement>) => {
    const data = new FormData(event.currentTarget);
    const alias = data.get('alias') as string;
    const db_layout = data.get('db_layout') as string;
    const db_object = data.get('db_object') as string;
    const outer_join = value?.['outer_join'] as string;
    const description = data.get('description') as string;

    if (editJoin) {
      if (idData) {
        administrationDataObjectJoinStore.addAdministrationDataObjectJoinByJoinId({
          data_object_id: `${idData}`,
          joinData: {
            id: editJoin.id,
            alias,
            db_layout,
            db_object,
            outer_join,
            description,
            data_object: idData,
          },
        });

        if (setEditJoin) {
          setEditJoin(undefined);
        }
        toggleOpen();
      }

      return;
    }

    administrationDataObjectJoinStore.addAdministrationDataObjectJoinByJoinId({
      data_object_id: `${idData}`,
      joinData: {
        alias,
        db_layout,
        db_object,
        outer_join,
        description,
        data_object: idData,
      },
    });

    toggleOpen();
  };

  return (
    <>
      <ButtonStyled
        text="добавить соединение"
        startIcon={<AddCircleOutlineIcon />}
        onClick={toggleOpen}
        disabled={false}
      />
      <DrawerLikeModal
        title={editJoin ? 'Изменить соединение' : 'Добавить соединение'}
        open={openAdd}
        toggleOpen={toggleOpen}
        items={templateInputJoinWithLabelList(editJoin)}
        saveForm={handleSaveForm}
        buttonConfirmText={editJoin ? 'Сохранить' : 'Создать соединение'}
        isDisabled={isLoading}
      >
        <Dropdown
          mb={0}
          onChange={onChangeDropdown}
          key={'outer_join'}
          label={'Соединение'}
          title={'Выберете тип соединения'}
          id={'outer_join'}
          value={editJoin?.connectionType ?? 'true'}
          dropdownItems={[
            {
              label: 'Внешнее соединение',
              value: 'true',
            },
            {
              label: 'Внутреннее',
              value: 'false',
            },
          ]}
        />
      </DrawerLikeModal>
    </>
  );
};

const templateInputJoinAttributeWithLabelList = (editAttribute: MapAdministrationDataObjectJoinSpec[0] | undefined) => [
  {
    id: 'destination_obj_column',
    label: 'Колонка назначения',
    required: true,
    value: editAttribute?.destination,
  },
  {
    id: 'description',
    label: 'Описание',
    value: editAttribute?.attributeBdName,
  },
  {
    id: 'dest_obj_column_constr',
    label: 'Константа',
    value: editAttribute?.common,
  },
];

const AddOrEditAttributeModal = ({
  joinId,
  editAttribute,
  setEditAttribute,
}: {
  joinId?: string | number;
  editAttribute?: MapAdministrationDataObjectJoinSpec[0];
  setEditAttribute: (val: MapAdministrationDataObjectJoinSpec[0] | undefined) => void;
}) => {
  const { isLoading, administrationDataObjectJoinStore, administrationDataAttributeStore } = useRootStore();
  const { value, onChangeDropdown } = useValidation();

  const [openAdd, setOpenAdd] = useState<boolean>(false);

  const open = () => {
    setOpenAdd(true);
  };

  const close = useCallback(() => {
    setOpenAdd(false);
    //@ts-ignore
    onChangeDropdown('source_obj_attr', undefined);
    setEditAttribute(undefined);
    value.source_obj_attr = undefined;
  }, [setEditAttribute, value]);

  const toggleOpen = useCallback(() => {
    if (openAdd) {
      return close();
    }

    return open();
  }, [close, openAdd]);

  const handleSaveForm = async (event: React.FormEvent<HTMLFormElement>) => {
    const data = new FormData(event.currentTarget);
    const destination_obj_column = data.get('destination_obj_column') as string;
    const source_obj_attr = value?.source_obj_attr as string;
    const dest_obj_column_constr = data.get('dest_obj_column_constr') as string;
    const description = data.get('description') as string;

    if (editAttribute) {
      if (joinId) {
        administrationDataObjectJoinStore.addAdministrationDataObjectJoinSpecByJoinId({
          joinId: `${joinId}`,
          joinSpecData: {
            id: editAttribute.id,
            destination_obj_column: destination_obj_column ?? editAttribute?.destination,
            source_obj_attr: source_obj_attr ?? editAttribute?.attributeName,
            dest_obj_column_constr: dest_obj_column_constr ?? editAttribute?.common,
            description: description ?? editAttribute?.attributeBdName,
            join: joinId as number,
          },
        });

        setEditAttribute(undefined);
        toggleOpen();
      }

      return;
    }

    administrationDataObjectJoinStore.addAdministrationDataObjectJoinSpecByJoinId({
      joinId: `${joinId}`,
      joinSpecData: {
        destination_obj_column,
        source_obj_attr,
        dest_obj_column_constr,
        description,
        join: joinId as number,
      },
    });

    toggleOpen();
  };

  useEffect(() => {
    if (editAttribute) {
      open();
    }
  }, [editAttribute, toggleOpen]);

  const dropdownItems = useMemo(() => {
    return administrationDataAttributeStore.tableBody.reduce<{ label: string; value: string }[]>((acc, item, index) => {
      if (index === 0) {
        acc.push({
          label: 'Атрибут источника не задан',
          value: '',
        });
      }
      if (!item.expressionName) {
        acc.push({
          label: item.bdName,
          value: item.bdName,
        });
      }

      return acc;
    }, []);
  }, [administrationDataAttributeStore.tableBody]);

  return (
    <>
      <ButtonStyled
        text="добавить атрибут"
        endIcon={<AddCircleOutlineIcon />}
        onClick={toggleOpen}
        disabled={false}
        variant="outlined"
        customType="success"
      />
      <DrawerLikeModal
        title={editAttribute ? 'Изменить атрибут' : 'Добавить атрибут'}
        open={openAdd}
        toggleOpen={toggleOpen}
        items={templateInputJoinAttributeWithLabelList(editAttribute)}
        saveForm={handleSaveForm}
        buttonConfirmText={editAttribute ? 'Сохранить' : 'Создать атрибут'}
        isDisabled={isLoading}
      >
        <Box
          mb={'16px'}
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            minHeight: '40px',
            maxHeight: '40px',
            flex: '1 0 auto',
          }}
        >
          <InputLabelStyled>{'Атрибут источника'}</InputLabelStyled>
          <Box sx={{ width: '285px' }}>
            <AutocompleteData
              onChange={onChangeDropdown}
              dataForDropdown={dropdownItems}
              initialValue={value.source_obj_attr ?? editAttribute?.attributeName}
              isFieldChanged={false}
            />
          </Box>
        </Box>
      </DrawerLikeModal>
    </>
  );
};

const AutocompleteData = React.memo(
  ({
    onChange,
    dataForDropdown,
    initialValue,
    isFieldChanged,
  }: {
    initialValue?: { label: string; value: string } | string;
    onChange?: (name: string, value: string) => void;
    dataForDropdown?: { label: string; value: string }[];
    isFieldChanged: boolean;
  }) => {
    const handleSelectedValue = (event: any, newValue: { label: string; value: string } | null) => {
      if (onChange) {
        onChange('source_obj_attr', newValue?.value ?? '');
      }
    };

    useEffect(() => {
      if (initialValue) {
        if (typeof initialValue === 'string') {
          handleSelectedValue(undefined, { label: initialValue, value: initialValue });
        } else {
          handleSelectedValue(undefined, initialValue);
        }
      }
    }, []);

    return (
      <Autocomplete
        options={dataForDropdown ?? []}
        openOnFocus
        autoComplete
        blurOnSelect
        sx={{ backgroundColor: isFieldChanged ? color.white : 'transparent' }}
        id="source_obj_attr"
        label="Атрибут источника"
        value={initialValue}
        //@ts-ignore
        onChange={handleSelectedValue}
        renderInput={(params) => <TextFieldStyled {...params} label="" sx={{ padding: 0 }} size="small" />}
      />
    );
  },
);
