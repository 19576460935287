import React, { useState } from 'react';
import { observer } from 'mobx-react';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Button } from '@mui/material';
import Typography from '@mui/material/Typography';

import { TableTemplateMonitoring } from '@/components/Templates/TableTemplate';
import { useRootStore } from '@/stores';
import { TableMonitoring } from '@/stores/MonitoringStore';
import { TableRowStyledMonitoring, TableCellStyledMonitoring } from '@/components/Templates/TableComponentStyled';
import {
  ModalSelectingStatus,
  useModalSelectingStatus,
} from '@/components/Monitoring/MonitoringModalStatus/ModalSelectingStatus';
import { ModalMonitoringResults } from '@/components/common/ModalMonitoringResults';
import { useModal } from '@/components/Contagents/ContactTabs/MainTabComponents/ComponentForGridItemByType';
import style from '@/components/Monitoring/Monitoring.module.scss';
import { dateFormatterDefault, formatDateToday } from '@/utils/index';
import { color } from '@/constants/colors';
import { MoreMenuTabData } from '@/components/Administering/AdministrationTabData/MoreMenuTabData';

const tableHeader = [
  'Дата выявления',
  'Номер сигнала',
  'Название сигнала',
  'Контрагент',
  'ИНН',
  'Статус сигнала',
  'Дата статуса',
  'Сотрудники',
  '',
  '',
];

const tableHeaderWidth = ['10%', '8%', '17%', '19%', '8%', '10%', '9%', '10%', '5%', '4%'];

const RenderBodyapplicationsTable = ({ row, filled }: { row: TableMonitoring; filled: boolean }) => {
  const { monitoringStore, userStore } = useRootStore();
  const { open: openMonitoring, handleClose: handleCloseMonitoring, handleOpen: handleOpenReport } = useModal();

  const [addedStatuses, setAddedStatuses] = useState<string[]>([]);
  const { open, handleClose, handleOpen } = useModalSelectingStatus();
  const [activeStatus, setActiveStatus] = useState<string[] | string>(row.status_signal);
  const [activeStatusEn, setActiveStatusEn] = useState<string>('');

  const selectItems = {
    IDENTIFIED: 'Выявлен',
    NOT_CONFIRM: 'Не подтверждён',
    CONFIRM: 'Подтверждён',
    REVIEWED_KK: 'Рассмотрен КК',
    ARCHIVE: 'В архиве',
  };

  const updateStatus = async () => {
    const dataToSave: {
      monitoringId: number;
      status_signal: string;
      updated_by: number;
      date_status: string;
    } = {
      monitoringId: 0,
      status_signal: '',
      updated_by: 0,
      date_status: '',
    };

    dataToSave.monitoringId = row.id;
    dataToSave.status_signal = activeStatusEn;
    dataToSave.updated_by = userStore.results.pk;
    dataToSave.date_status = formatDateToday;

    await monitoringStore.updateMonitoringById(dataToSave);
  };

  const downloadMonitoringById = async () => {
    if (row.account) {
      await monitoringStore.downloadMonitoringById(row.id, row.account.name);
    }
  };

  const identified = row.status_signal === 'выявлен';
  const isArchive = row.status_signal === 'архив';

  return (
    <>
      <TableRowStyledMonitoring identified={identified.toString()} key={row.id}>
        <TableCellStyledMonitoring identified={identified.toString()} isarchive={isArchive.toString()}>
          {dateFormatterDefault(row.date_identified)}
        </TableCellStyledMonitoring>
        <TableCellStyledMonitoring identified={identified.toString()} isarchive={isArchive.toString()}>
          {row.number_signal}
        </TableCellStyledMonitoring>
        <TableCellStyledMonitoring identified={identified.toString()} isarchive={isArchive.toString()}>
          {row.name_signal}
        </TableCellStyledMonitoring>
        <TableCellStyledMonitoring identified={identified.toString()} isarchive={isArchive.toString()}>
          {row.account?.name}
        </TableCellStyledMonitoring>
        <TableCellStyledMonitoring identified={identified.toString()} isarchive={isArchive.toString()}>
          {row.account?.inn}
        </TableCellStyledMonitoring>
        <TableCellStyledMonitoring identified={identified.toString()} sx={{ position: 'relative' }}>
          <ModalSelectingStatus
            identified={identified}
            saveForm={updateStatus}
            statusName={addedStatuses}
            handleOpen={handleOpen}
            open={open}
            handleClose={handleClose}
            selectItems={selectItems}
            setActiveStatus={setActiveStatus}
            setActiveStatusEn={setActiveStatusEn}
            activeStatus={activeStatus}
            activeStatusEn={activeStatusEn}
          />
        </TableCellStyledMonitoring>
        <TableCellStyledMonitoring identified={identified.toString()} isarchive={isArchive.toString()}>
          {dateFormatterDefault(row.date_status)}
        </TableCellStyledMonitoring>
        <TableCellStyledMonitoring identified={identified.toString()} isarchive={isArchive.toString()}>
          {row.updated_by}
        </TableCellStyledMonitoring>
        <TableCellStyledMonitoring
          identified={identified.toString()}
          onClick={handleOpenReport}
          className={style.buttonReport}
          sx={{ display: 'flex' }}
        >
          <Button>
            <Typography
              sx={{
                fontWeight: '400',
                fontSize: '16px',
                lineHeight: '16px',
                color: color.black,
                textDecoration: 'underline',
                mr: '5px',
              }}
            >
              Отчет{' '}
            </Typography>
            <ErrorOutlineIcon />
          </Button>
        </TableCellStyledMonitoring>
        <TableCellStyledMonitoring
          onClick={downloadMonitoringById}
          identified={identified.toString()}
          isarchive={isArchive.toString()}
          sx={{ position: 'relative' }}
        >
          <MoreMenuTabData handleDownload={downloadMonitoringById} download={true} toDownloadById={true} />
        </TableCellStyledMonitoring>
      </TableRowStyledMonitoring>
      <ModalMonitoringResults
        downloadMonitoringById={downloadMonitoringById}
        row={row}
        uniqId="DataMonitoring_modal"
        cancelButtonText="Отменить"
        saveButtonText="Сохранить"
        title={'Отчет о результатах мониторинга\nконтрагента'}
        open={openMonitoring}
        handleClose={handleCloseMonitoring}
      />
    </>
  );
};

export const Monitoring = observer(() => {
  const { monitoringStore, isLoading } = useRootStore();

  return (
    <>
      <TableTemplateMonitoring<TableMonitoring>
        tableHeader={tableHeader}
        tableHeaderWidth={tableHeaderWidth}
        RenderComponent={(props) => <RenderBodyapplicationsTable {...props} />}
        store={monitoringStore}
        isLoading={isLoading}
        isPageApplicationTable={true}
      />
    </>
  );
});
