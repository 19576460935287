import React, { useEffect, useRef, useState } from 'react';
import style from '@/components/Contagents/ContactTabs/ContractReportTable.module.scss';
import { observer } from 'mobx-react';

import { useRootStore } from '@/stores';
import { IDataBalancePeriod } from '@/stores/ContragentReportBalancePeriodStore';
import { formattedDate } from '@/utils/reportTable';
import ContractReportTableInputResults from './ContractReportTableInput/ContractReportTableInputResults';

export type StaticRow = {
  name: string;
  code: number;
};

type TableCellPeriod = {
  year: number | string;
  value: number | string;
};

const StaticRows: StaticRow[] = [
  { name: 'Выручка', code: 2110 },
  { name: 'Себестоимость продаж', code: 2120 },
  { name: 'Валовая прибыль (убыток)', code: 2100 },
  { name: 'Коммерческие расходы', code: 2210 },
  { name: 'Управленческие расходы', code: 2220 },
  { name: 'Прибыль (убыток) от продажи', code: 2200 },
  { name: 'Доходы от участия в других организациях', code: 2310 },
  { name: 'Проценты к получению', code: 2320 },
  { name: 'Проценты к уплате', code: 2330 },
  { name: 'Прочие доходы', code: 2340 },
  { name: 'Прочие расходы', code: 2350 },
  { name: 'Прибыль (убыток) до налогооблажения', code: 2300 },
  { name: 'Налог на прибыль', code: 2410 },
  { name: 'в т.ч. текущий налог на прибыль', code: 2411 },
  { name: 'отложенный налог на прибыль', code: 2412 },
  { name: 'Прочее', code: 2460 },
  { name: 'Чистая прибыль (убыток)', code: 2400 },
];

function numberWithSpaces(number: number | null): string {
  if (number === null || isNaN(number)) {
    return '';
  }

  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}

const specialCodes = [2110, 2200, 2300, 2400];

interface ContractReportTableLTMProps {
  years: string[];
}

const ContractReportTableLTM: React.FC<ContractReportTableLTMProps> = observer(({ years }) => {
  const { contragentReportTableLTM } = useRootStore();
  const [isDataEdited, setIsDataEdited] = useState<boolean>(false);
  const [isRowModified, setIsRowModified] = useState<{ [key: string]: boolean }>({});
  const resultsePeriod = contragentReportTableLTM.results;
  const [results, setResults] = useState<IDataBalancePeriod[]>(resultsePeriod);
  const inputRefs = useRef<Array<HTMLInputElement | null>>([]);

  useEffect(() => {
    setResults(resultsePeriod);
  }, [resultsePeriod]);

  const getTableDataPeriod = (): Record<string, TableCellPeriod> => {
    const tableData: Record<string, TableCellPeriod> = {};

    StaticRows.forEach((staticRow) => {
      const { code } = staticRow;
      years.forEach((year) => {
        const matchingResultPeriod =
          results && results.find((item) => item.parameter_date === year && item.code === code);
        const keyPeriod = `${code}-${year}`;
        if (matchingResultPeriod) {
          const formattedValue = numberWithSpaces(matchingResultPeriod.value);
          tableData[keyPeriod] = {
            year,
            value: formattedValue,
          };
        } else {
          tableData[keyPeriod] = {
            year,
            value: ' ',
          };
        }
      });
    });

    return tableData;
  };

  const [tableDataPeriod, setTableDataPeriod] = useState(getTableDataPeriod());

  useEffect(() => {
    const newTableData = getTableDataPeriod();
    setTableDataPeriod(newTableData);
  }, [results]);

  return (
    <>
      <div className={`${style.wrapper} ${style.wrapper__ofrTable}`}>
        <table className={style.balanceTable}>
          <thead className={style.balanceTable__header}>
            <tr>
              <th className={style.balanceTable__header__name}>Наименование</th>
              <th className={style.balanceTable__header__code}>Код строки</th>
              {years.slice(0, 10).map((year, index) => (
                <React.Fragment key={index}>
                  <th className={style.balanceTable__header__year}>{formattedDate(year)}</th>
                </React.Fragment>
              ))}
            </tr>
          </thead>
          <tbody>
            {StaticRows.map((staticRow, index) => {
              const { name, code } = staticRow;
              return (
                <tr
                  className={`${style.balanceTable__container} ${
                    specialCodes.includes(code) ? style.balanceTable__specialRow : ''
                  }`}
                  key={index}
                >
                  <td className={style.balanceTable__name}>{name}</td>
                  <td className={`${style.balanceTable__code} ${!isNaN(code) ? '' : style.balanceTable__nanRow}`}>
                    {code}
                  </td>
                  <ContractReportTableInputResults
                    years={years}
                    setIsRowModified={setIsRowModified}
                    code={code}
                    disabled={true}
                    inputRefs={inputRefs}
                    setTableDataPeriod={setTableDataPeriod}
                    tableDataPeriod={tableDataPeriod}
                    setIsDataEdited={setIsDataEdited}
                    index={index}
                  />
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
});

export default ContractReportTableLTM;
