import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';

import { useModalDelete } from '@/components/common/hooks/useModalDelete';
import { LabelLikeHintWithText } from '@/components/common/LabelLikeHintWithText';
import { ModalDelete } from '@/components/common/ModalDelete';
import { TableCellSmallPadding, TableRowStyled } from '@/components/Templates/TableComponentStyled';
import { MoreMenuTabData } from '@/components/Administering/AdministrationTabData/MoreMenuTabData';
import { useRootStore } from '@/stores';
import { TableTemplate } from '@/components/Templates/TableTemplate';
import { ITableBodyAdministrationDataAttribute } from '@/stores/freskoApi/AdministrationDataAttributeStore';
import { MAP_DATA_TYPE } from '@/constants/backendEnum';
import { FilterComponent } from '@/components/atoms/FilterComponent';
import { color } from '@/constants/colors'

const tableHeader = ['Отображаемое название', 'Колонка', 'Соединение', 'Выражение', ' '];

const RenderRowTabAttributesTable = ({
  filled,
  row,
  handleOpenDeleteModal,
  setEditAttribute
}: {
  filled: boolean,
  row: ITableBodyAdministrationDataAttribute;
  handleOpenDeleteModal: (row: ITableBodyAdministrationDataAttribute) => void;
  setEditAttribute: (row: ITableBodyAdministrationDataAttribute) => void;
}) => {
  const { bdName, columnName, connectionName, expressionName, dataType } = row;

  const onDelete = () => {
    handleOpenDeleteModal(row);
  }

  const onEdit = () => {
    setEditAttribute(row);
  }

  return (
    <TableRowStyled key={row.id} sx={{ backgroundColor: filled ? color.light : color.transparent }}>
      <TableCellSmallPadding>
        <LabelLikeHintWithText reverse title={MAP_DATA_TYPE[dataType as keyof typeof MAP_DATA_TYPE]} value={bdName} />
      </TableCellSmallPadding>
      <TableCellSmallPadding>
        <LabelLikeHintWithText title={''} value={columnName} />
      </TableCellSmallPadding>
      <TableCellSmallPadding>
        <LabelLikeHintWithText title={''} value={connectionName} />
      </TableCellSmallPadding>
      <TableCellSmallPadding>
        <FilterComponent filterText={expressionName} />
      </TableCellSmallPadding>
      <TableCellSmallPadding>
        <MoreMenuTabData
          onDelete={onDelete}
          onEdit={onEdit}
        />
      </TableCellSmallPadding>
    </ TableRowStyled>
  );
}

const AdministrationDataDetailTabAttributesTableRaw = ({ setEditAttribute }: { setEditAttribute: (data: ITableBodyAdministrationDataAttribute) => void }) => {
  const { id: idData } = useParams<{ id: string; }>();

  const { isLoading, administrationDataAttributeStore } = useRootStore();

  const { open, handleClose, handleOpen, deletedValue: attribute } = useModalDelete<any>();

  useEffect(() => {
    if (idData) {
      administrationDataAttributeStore.getAdministrationDataAttribute({ dataObjectId: idData });
    }
  }, [idData])

  const handleDelete = () => {
    if (attribute && idData) {
      administrationDataAttributeStore.deleteAdministrationDataAttributeById(idData, attribute.id);
      handleClose();
    }
  }

  return (
    <>
      <TableTemplate<ITableBodyAdministrationDataAttribute>
        tableHeader={tableHeader}
        RenderComponent={(props) => <RenderRowTabAttributesTable {...props} handleOpenDeleteModal={handleOpen} setEditAttribute={setEditAttribute} />}
        store={administrationDataAttributeStore}
        isLoading={isLoading}
      />
      <ModalDelete
        uniqId={'Data_table_modal'}
        open={open}
        handleClose={handleClose}
        handleDelete={handleDelete}
        title='Вы действительно хотите удалить атрибут?'
        descriptions={[
          (attribute?.displayName ?? ''),
          (attribute?.bdName ?? ''),
        ]}
      />
    </>
  );
}

AdministrationDataDetailTabAttributesTableRaw.displayName = 'AdministrationDataDetailTabAttributesTable';

export const AdministrationDataDetailTabAttributesTable = observer(AdministrationDataDetailTabAttributesTableRaw);