import { observable, makeObservable, action, computed } from 'mobx';
import type { ChangeEvent } from 'react';

export type TableStoreType<T> = {
  selectedPage: number;
  rowsPerPageChange: number;
  tableBody: T[];
  limit: number;
  offset: number;
  handleChangePage: (event: React.ChangeEvent<unknown>, page: number) => void;
  handleChangeRowsPerPage: (event: ChangeEvent<HTMLInputElement>) => void;
  renderedRows: T[];
  summary: number;
  maxSelectedPage: number;
  searchString?: string;
};

export class TableStore<T> implements TableStoreType<T> {
  @observable
  public selectedPage: number = 0;

  @observable
  public rowsPerPageChange: number = 10;

  @observable
  public tableBody: T[] = [];

  @observable
  public limit: number;

  @observable
  public offset: number;

  @observable
  public summary: number;

  @observable
  public maxSelectedPage: number = 0;

  @observable
  public searchString?: string;

  constructor() {
    makeObservable(this);
  }

  @action
  public handleChangePage = (_: React.ChangeEvent<unknown> | null, page: number) => {
    this.selectedPage = page - 1;
  };

  @action
  public handleChangeRowsPerPage = () => {
    this.rowsPerPageChange = 10;
  };

  @computed.struct
  public get renderedRows() {
    return this.tableBody?.slice();
  }
}
