import React from 'react';

import { Skeleton, TableRow } from '@mui/material';

import { TableCellStyled } from '@/components/Templates/TableTemplate';

export type TableRowSkeletonProps<RowsType, CellsType> = {
  rows: RowsType[];
  cells: CellsType[];
}

export const TableRowSkeleton = <RowsType extends unknown, CellsType extends unknown>
  ({ rows, cells }: TableRowSkeletonProps<RowsType, CellsType>) => {
  return (
    <>
      {
        rows.map((_, index) => (
          <TableRow key={index}>
            {cells.map((_, index) => (
              <TableCellStyled key={index} align={cells?.length - 1 === index ? 'right' : undefined}>
                <Skeleton
                  key={index}
                  variant="rectangular"
                  height={19}
                  sx={{
                    display: 'flex',
                    flex: 1,
                  }}
                />
              </TableCellStyled>
            ))}
          </TableRow>
        ))
      }
    </>
  );
}