// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Monitoring_fileDown__95bzB {
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
  margin: auto;
  cursor: pointer;
}

.Monitoring_buttonReport__XkA1X {
  gap: 5px;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}

.Monitoring_status_signal__eCqw\\+ {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e6e6e6;
  border-radius: 5px;
  text-transform: uppercase;
  padding: 5px;
  font-size: 13px;
}`, "",{"version":3,"sources":["webpack://./src/components/Monitoring/Monitoring.module.scss","webpack://./src/constants/_colors.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;EACA,SAAA;EACA,OAAA;EACA,QAAA;EACA,YAAA;EACA,eAAA;AADF;;AAIA;EACE,QAAA;EACA,mBAAA;EACA,2BAAA;EACA,eAAA;AADF;;AAIA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,yBCnBc;EDoBd,kBAAA;EACA,yBAAA;EACA,YAAA;EACA,eAAA;AADF","sourcesContent":["@import \"@/constants/colors\";\n\n.fileDown {\n  position: absolute;\n  top: 20px;\n  left: 0;\n  right: 0;\n  margin: auto;\n  cursor: pointer;\n}\n\n.buttonReport {\n  gap: 5px;\n  align-items: center;\n  justify-content: flex-start;\n  cursor: pointer;\n}\n\n.status_signal {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background-color: $slightly_grey;\n  border-radius: 5px;\n  text-transform: uppercase;\n  padding: 5px;\n  font-size: 13px;\n}\n","$black: #000000;\n$white: #ffffff;\n$grey: #6e6e6e;\n$slightly_grey: #e6e6e6;\n$light-gray: #def3db;\n$gray_smoke: #bdbdbd;\n$red: #ff0000;\n$heavenly: #61dafb;\n$granite_grey: #282c34;\n$periwinkle_crayola: #f1f9f7;\n$pale_blue: #d7d7d8;\n$dull_grey: #616161;\n$emerald: #5dc689;\n$light_green: #1dc255;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fileDown": `Monitoring_fileDown__95bzB`,
	"buttonReport": `Monitoring_buttonReport__XkA1X`,
	"status_signal": `Monitoring_status_signal__eCqw+`
};
export default ___CSS_LOADER_EXPORT___;
