import React from 'react';

import type { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import MuiAppBar from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import { SvgIcon } from '@mui/material';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';

import { UserMenu } from '@/components/common/AppBar/UserMenu';
import { drawerWidth } from '@/constants/magicalNumber';
import { color } from '@/constants/colors';

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBarStyle = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  background:
    theme.palette.mode === 'dark' ? theme.palette.primary.main : `linear-gradient(90deg, ${color.light_green} 0%, ${color.mountain_meadow} 100%)`,
  width: `calc(100% - (49px))`,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const AppBar = ({
  toggleDrawer,
  open,
  toggleScreen,
}: {
  open: boolean | undefined;
  toggleDrawer: () => void;
  toggleScreen: () => void;
}) => {
  return (
    <AppBarStyle
      position="absolute"
      open={open}
      color="primary"
      style={{ background: `linear-gradient(90deg, ${color.light_green} 0%, ${color.mountain_meadow} 100%)`, maxHeight: '48px' }}
    
      elevation={0}
    >
      <Toolbar
        sx={{
          pr: '24px', 
        }}
        style={{  maxHeight: '48px', minHeight: '48px' }}
      >
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={toggleDrawer}
          sx={{
            marginRight: '36px',
          }}
        >
          {open ? <MenuOpenIcon /> : <MenuIcon />}
        </IconButton>
        <Typography component="h1" variant="subtitle1" color="inherit" noWrap sx={{ flexGrow: 1 }} />
        <SvgIcon sx={{ mr: '24px' }}>
          <SettingsOutlinedIcon />
        </SvgIcon>
        <IconButton color="inherit" sx={{ mr: '24px' }}>
          <Badge badgeContent={0} color="secondary">
            <NotificationsOutlinedIcon onClick={toggleScreen} />
          </Badge>
        </IconButton>
        <UserMenu />
      </Toolbar>
    </AppBarStyle>
  );
};
