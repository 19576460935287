import React, { useState } from 'react';
import { observer } from 'mobx-react';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import { ApplicationAddConditionModal } from '@/components/ApplicationsPage/ApplicationsTabs/ApplicationAddTable/ApplicationAddConditionModal';
import { ApplicationAddTable } from '@/components/ApplicationsPage/ApplicationsTabs/ApplicationAddTable/ApplicationAddTable';
import { Box } from '@mui/material';
import { ButtonStyled } from '@/components/atoms/Button';
import { OFFSET } from '@/constants/sizes';
import { useRootStore } from '@/stores';

export const ApplicationAddCondition = observer(() => {
  const [openAdd, setOpenAdd] = useState<boolean>(false);
  const { applicationsPageDetailsStore } = useRootStore();

  const toggleOpen = () => {
    setOpenAdd(!openAdd);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          marginBottom: OFFSET.s,
          marginTop: `-${OFFSET.xxxxs}`,
        }}
      >
        <ButtonStyled
          text="Добавить дебитора"
          startIcon={<AddCircleOutlineIcon />}
          onClick={toggleOpen}
          disabled={!applicationsPageDetailsStore?.isPossibleEdit}
        />
      </Box>
      <ApplicationAddTable />
      <ApplicationAddConditionModal openAdd={openAdd} toggleOpen={toggleOpen} />
    </>
  );
});
