import React, { useState, useRef, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Box, Checkbox, FormControlLabel, FormHelperText, IconButton, InputAdornment } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { DrawerCustom } from '@/components/atoms/DrawerCustom';
import { ButtonStyled } from '@/components/atoms/Button';
import { useRootStore } from '@/stores';
import { DrawerLikeModal } from '@/components/common/DrawerLikeModal';
import { TemplateInputWithLabel } from '@/components/atoms/TemplateInputWithLabel';
import { OFFSET, WIDTH } from '@/constants/sizes';
import { Dropdown } from '@/components/atoms/Dropdown';
import { TextFieldOutlinedStyled } from '@/components/atoms/TextFieldStyled';
import { InputLabelStyled } from '@/components/atoms/InputLabelStyled';
import { useValidation } from '@/hooks/useValidation';
import { ROLE } from '@/constants';
import { color } from '@/constants/colors';

const templateInputWithLabelListBeforeDropDown = [
  {
    id: 'userName',
    label: 'Имя пользователя ⃰',
    required: true,
    autoComplete: 'nope',
  },
  {
    id: 'firstName',
    placeholder: 'Не обязательно',
    label: 'Имя',
    autoComplete: 'nope',
  },
  {
    id: 'lastName',
    placeholder: 'Не обязательно',
    label: 'Фамилия',
    autoComplete: 'nope',
  },
  {
    id: 'secondName',
    placeholder: 'Не обязательно',
    label: 'Отчество',
  },
];

const templateInputWithLabelListAfterDropDown = [
  {
    id: 'phone',
    label: 'Телефон  ⃰',
    required: true,
  },
  {
    id: 'email',
    placeholder: 'Не обязательно',
    label: 'E-mail',
  },
];

const PasswordField = ({
  id,
  label,
  errorMessage,
  handleChange,
}: {
  id: string;
  label: string;
  errorMessage?: string;
  handleChange?: (val: string) => void;
}) => {
  const [isShowPassword, setIsShowPassword] = useState(true);

  const togglePassword = () => {
    setIsShowPassword(!isShowPassword);
  };

  const onChangePassword = (e: any) => {
    if (handleChange) {
      handleChange(e.target.value);
    }
  };

  return (
    <Box
      mb={OFFSET.xs}
      mt={errorMessage ? OFFSET.xs : undefined}
      sx={{
        alignItems: 'center',
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        maxHeight: '36px',
      }}
    >
      <InputLabelStyled>{label}</InputLabelStyled>
      <Box
        sx={{
          alignItems: 'flex-end',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <TextFieldOutlinedStyled
          onChange={onChangePassword}
          required
          id={id}
          name={id}
          size="small"
          error={Boolean(errorMessage)}
          type={!isShowPassword ? 'text' : 'password'}
          autoComplete="new-password"
          sx={{ width: WIDTH.s }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={togglePassword}
                onMouseDown={togglePassword}
                edge="end"
              >
                {isShowPassword ? (
                  <VisibilityOff htmlColor={color.light_green} />
                ) : (
                  <Visibility htmlColor={color.light_green} />
                )}
              </IconButton>
            </InputAdornment>
          }
        />
        {!!errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
      </Box>
    </Box>
  );
};

const PasswordWithConfirm = ({ handleGlobalError }: { handleGlobalError: (val: any) => void }) => {
  const [password, setPassword] = useState<string | undefined>(undefined);
  const [passwordConfirm, setPasswordConfirm] = useState<string | undefined>(undefined);
  const [error, setError] = useState<string | undefined>(undefined);

  const handleChangePassword = (val: string) => {
    setPassword(val);
  };

  const handleChangePasswordConfirm = (val: string) => {
    setPasswordConfirm(val);
  };

  useEffect(() => {
    if (password !== passwordConfirm) {
      setError('Пароль и подтверждение пароля не совпадают');
      handleGlobalError({ passwords: true });
    } else if (password?.trim() === '' && passwordConfirm?.trim() === '') {
      setError('Пароль не может быть пустым');
      handleGlobalError({ passwords: true });
    } else if (!password && !passwordConfirm) {
      handleGlobalError({ passwords: true });
    } else {
      setError(undefined);
      handleGlobalError({ passwords: false });
    }
  }, [handleGlobalError, password, passwordConfirm]);

  return (
    <>
      <PasswordField id="password" label="Пароль ⃰" handleChange={handleChangePassword} />
      <PasswordField
        id="password_confirm"
        label="Подтверждение пароля ⃰"
        errorMessage={error}
        handleChange={handleChangePasswordConfirm}
      />
    </>
  );
};

export const EmployeesModal = observer(() => {
  const {
    businessUnitStore,
    employeeAsUserStore,
    positionStore,
    employeesStore,
    isLoading,
    userStore,
    uiStateStore: { open },
  } = useRootStore();

  const { setFormHasError, value, onChangeDropdown, formHasErrorRaw, isError } = useValidation();

  const checkboxState = useRef(false);

  const [openAdd, setOpenAdd] = useState<boolean>(false);

  const onChangeCheckbox = () => {
    checkboxState.current = !checkboxState.current;
  };

  const toggleOpen = () => {
    setOpenAdd(!openAdd);
  };

  const dropdownItemsPos = useMemo(() => {
    if (positionStore.allPositions) {
      return positionStore.allPositions.map(({ id, name, bu }) => ({
        label: `${name} - ${businessUnitStore?.allBusinessUnits?.find((some) => some.id === bu)?.name}`,
        value: id,
      }));
    }

    return [];
  }, [businessUnitStore?.allBusinessUnits, positionStore.allPositions]);

  const handleSaveForm = async (event: React.FormEvent<HTMLFormElement>) => {
    const data = new FormData(event.currentTarget);

    const userName = data.get('userName') as string;
    const lastName = data.get('lastName') as string;
    const firstName = data.get('firstName') as string;
    const secondName = data.get('secondName') as string;
    const phone = data.get('phone') as string;
    const email = data.get('email') as string;
    const password = data.get('password') as string;
    const postn = value.postn as number;
    const view_set = value.view_set as string;
    const superVisor = checkboxState.current as boolean;
    const position = positionStore.allPositions?.find((item) => item.id === postn);

    if (userName && phone && password && position) {
      employeesStore
        .createEmployees({
          userName,
          lastName,
          firstName,
          middleName: secondName,
          email,
          role: null,
          phone,
          isStaff: superVisor,
          password,
          view_set,
        })
        .then((newUser) => {
          if (newUser) {
            employeeAsUserStore.createEmployeeAsUser({
              user_id: newUser.pk,
              employee_first_name: firstName,
              employee_last_name: lastName,
              employee_email: email,
              postn_id: position.id,
              bu_id: position.bu,
            });
          }
        });
      toggleOpen();
    }
  };

  return (
    <>
      <DrawerCustom open={open}>
        <ButtonStyled
          text="Добавить сотрудника"
          startIcon={<AddCircleOutlineIcon />}
          onClick={toggleOpen}
          disabled={!userStore?.profileInfo?.is_staff}
        />
      </DrawerCustom>
      <DrawerLikeModal
        title="Добавить сотрудника"
        open={openAdd}
        toggleOpen={toggleOpen}
        saveForm={handleSaveForm}
        buttonConfirmText="Добавить"
        isDisabled={isLoading || isError}
      >
        {templateInputWithLabelListBeforeDropDown?.map(({ id, placeholder, label, ...other }) => (
          <TemplateInputWithLabel
            handleGlobalError={setFormHasError}
            key={id}
            id={id}
            isError={formHasErrorRaw?.[id] ?? false}
            name={id}
            placeholder={placeholder}
            label={label}
            style={{ width: WIDTH.s }}
            {...other}
          />
        ))}
        <Dropdown
          handleGlobalError={setFormHasError}
          onChange={onChangeDropdown}
          key="postn"
          isError={formHasErrorRaw?.postn ?? false}
          label="Роль ⃰"
          title="Роль"
          id="postn"
          dropdownItems={dropdownItemsPos}
          required
        />

        <Dropdown
          handleGlobalError={setFormHasError}
          onChange={onChangeDropdown}
          key="view_set"
          isError={formHasErrorRaw?.view_set ?? false}
          label="Интерфейс ⃰"
          title="Интерфейс"
          id="view_set"
          dropdownItems={[
            {
              label: 'Админ',
              value: ROLE.ADMIN,
            },
            {
              label: 'Стандартный',
              value: ROLE.GENERAL,
            },
            {
              label: 'Смартфакт',
              value: ROLE.SMARTFACT,
            },
            {
              label: 'Балтийский лизинг',
              value: ROLE.BALTICLEASING,
            },
            {
              label: 'ПСБ Лизинг',
              value: ROLE.PSBL,
            },
          ]}
          required
        />

        {templateInputWithLabelListAfterDropDown?.map(({ id, placeholder, label, ...other }) => (
          <TemplateInputWithLabel
            handleGlobalError={setFormHasError}
            key={id}
            id={id}
            name={id}
            isError={formHasErrorRaw?.[id] ?? false}
            placeholder={placeholder}
            label={label}
            style={{ width: WIDTH.s }}
            {...other}
          />
        ))}
        <PasswordWithConfirm handleGlobalError={setFormHasError} />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}
        >
          <Box />
          <Box sx={{ width: WIDTH.s }}>
            <FormControlLabel control={<Checkbox onChange={onChangeCheckbox} />} label="Суперпользователь" />
          </Box>
        </Box>
      </DrawerLikeModal>
    </>
  );
});
