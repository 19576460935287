import { useEffect, useState } from 'react';
import usePrevious from '@/hooks/usePrevious';

export const useValidation = () => {
  const [inputValueState, setInputValueState] = useState<Record<string, any>>({});

  const [dropdownValueState, setDropdownValueState] = useState<Record<string, string>>({});
  const [formHasErrorRaw, setFormHasErrorRaw] = useState<Record<string, boolean>>({});
  const [isError, setIsError] = useState<boolean>(false);

  const setFormHasError = (val: any) => {
    setFormHasErrorRaw((formHasError) => ({ ...(formHasError ?? {}), ...val }));
  };

  const onChangeDropdown = (name: any, value: any) => {
    setDropdownValueState((dropdownValue) => {
      dropdownValue[name] = value;
      return { ...dropdownValue };
    });
  };

  useEffect(() => {
    setIsError(Object.values(formHasErrorRaw).some((item) => item));
  }, [formHasErrorRaw]);

  const handleChangeInput = (val: Record<string, any>) => {
    setInputValueState((inputValue) => {
      inputValue = { ...inputValue, ...val };
      return { ...inputValue };
    });
  };

  const clear = () => setInputValueState({});

  const mutateInputValueState = (value: Record<string, any>) => {
    setInputValueState({ ...inputValueState, ...value });
  };

  const { prevVal, clearPrev } = usePrevious({ ...inputValueState, ...dropdownValueState });

  return {
    value: { ...inputValueState, ...dropdownValueState },
    prevValues: prevVal,
    clearPrevValues: clearPrev,
    isError,
    formHasErrorRaw,
    setFormHasError,
    onChangeDropdown,
    handleChangeInput,
    clear,
    mutateInputValueState,
  };
};
