import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';

import { Box, Checkbox, FormControlLabel } from '@mui/material';

import { useRootStore } from '@/stores';
import { DrawerLikeModal } from '@/components/common/DrawerLikeModal';
import { WIDTH } from '@/constants/sizes';

type Props = {
  openAdd: boolean;
  toggleOpen: () => void;
};

export const templateInputWithLabelList = [
  {
    id: 'lastName',
    label: 'Фамилия ⃰',
    required: true,
    placeholder: 'Введите фамилию',
  },
  {
    id: 'firstName',
    label: 'Имя ⃰',
    required: true,
    placeholder: 'Введите имя',
  },
  {
    id: 'secondName',
    label: 'Отчество',
    placeholder: 'Введите отчество',
  },
  {
    id: 'phone',
    label: 'Телефон',
    placeholder: 'Введите номер телефона',
  },
  {
    id: 'email',
    label: 'E-mail',
    placeholder: 'Введите email',
  },
  {
    id: 'position',
    label: 'Должность',
    placeholder: 'Введите должность',
  },
];

export const ContactPersonModal = observer(({ openAdd, toggleOpen }: Props) => {
  const { contactPersonsStore, isLoading } = useRootStore();
  const checkboxState = useRef(false);
  const { id } = useParams<{ id: string }>();

  const onChangeCheckbox = () => {
    checkboxState.current = !checkboxState.current;
  };

  useEffect(() => {
    if (!openAdd) {
      checkboxState.current = false;
    }
  }, [openAdd]);

  const handleSaveForm = async (event: React.FormEvent<HTMLFormElement>) => {
    const data = new FormData(event.currentTarget);
    const lastName = data.get('lastName') as string;
    const firstName = data.get('firstName') as string;
    const middleName = data.get('secondName') as string;
    const phone = data.get('phone') as string;
    const email = data.get('email') as string;
    const position = data.get('position') as string;
    const main = checkboxState.current;

    if (id && lastName && firstName) {
      contactPersonsStore.createAccountClient(id, {
        lastName,
        firstName,
        middleName,
        phone,
        email,
        position,
        main,
      });
      toggleOpen();
    }
  };

  return (
    <>
      <DrawerLikeModal
        title="Добавить контактное лицо"
        open={openAdd}
        toggleOpen={toggleOpen}
        items={templateInputWithLabelList}
        saveForm={handleSaveForm}
        isDisabled={isLoading}
        buttonConfirmText="Создать контакт"
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}
        >
          <Box />
          <Box sx={{ width: WIDTH.s }}>
            <FormControlLabel control={<Checkbox onChange={onChangeCheckbox} />} label="Основной" />
          </Box>
        </Box>
      </DrawerLikeModal>
    </>
  );
});
