import React, { useState } from 'react';

export const withEditable =
  (Component: React.ComponentType<any>) =>
  ({ ...props }) => {
    const [isEdit, setIsEdit] = useState(false);

    const toggleEdit = (value?: boolean) => {
      if (value !== undefined) {
        setIsEdit(value);
      } else {
        setIsEdit(!isEdit);
      }
    };

    const handleOpenEdit = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation();
      toggleEdit(true);
    };

    const handleCloseEdit = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation();
      toggleEdit(false);
    };

    return (
      <Component
        toggleEdit={toggleEdit}
        isEdit={isEdit}
        handleOpenEdit={handleOpenEdit}
        handleCloseEdit={handleCloseEdit}
        {...props}
      />
    );
  };
