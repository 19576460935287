import React from 'react';

import { Box } from '@mui/material';

import { OFFSET } from '@/constants/sizes';
import { StyledBox } from '@/components/Administering/Assessment/components/common';
import { Badge } from '@/components/atoms/Bage/Badge';

type FilterAssessmentProps = {
  filters?: string[];
  filtersSelected: string[];
  setFiltersSelected: (selected: string[]) => void;
  innerRef: any;
};

type FilterItemProps = {
  title: string;
  isSelected: boolean;
  addFilter: (filter: string) => void;
};

const FilterItem = ({ title, isSelected, addFilter }: FilterItemProps) => {
  const onClick = () => addFilter(title);

  return (
    <StyledBox mr={OFFSET.xxxs} mb={OFFSET.xxxs} width="fit-content" onClick={onClick}>
      <Badge type={isSelected ? 'Active' : 'New'} text={title} />
    </StyledBox>
  );
};

export const FilterAssessment = ({ filters, filtersSelected, setFiltersSelected, innerRef }: FilterAssessmentProps) => {
  if (!filters) {
    return null;
  }

  const addFilter = (newFilter: string) => {
    if (filtersSelected.includes(newFilter)) {
      setFiltersSelected(filtersSelected.filter((item) => item !== newFilter));
      return;
    }

    setFiltersSelected([...filtersSelected, newFilter]);
  };

  return (
    <Box display="flex" maxWidth="520px" flexWrap="wrap" ref={innerRef}>
      {filters.map((filter: string) => (
        <FilterItem key={filter} title={filter} isSelected={filtersSelected.includes(filter)} addFilter={addFilter} />
      ))}
    </Box>
  );
};
