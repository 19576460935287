import React, {useState, useEffect, useMemo} from 'react';
import {observer} from 'mobx-react';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import InputAdornment from '@mui/material/InputAdornment';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CircularProgress from '@mui/material/CircularProgress';

import {useRootStore} from '@/stores';
import type {IButtonGroupItem} from '@/constants/types';
import {OFFSET, WIDTH} from '@/constants/sizes';
import {Title} from '@/components/atoms/Title';
import {ButtonGroupStyled} from '@/components/common/ButtonGroupStyled';
import {TextFieldOutlinedStyled} from '@/components/atoms/TextFieldStyled';
import {TemplateInputWithLabel} from '@/components/atoms/TemplateInputWithLabel';
import {DrawerLikeModal} from '@/components/common/DrawerLikeModal';
import {DrawerCustom} from '@/components/atoms/DrawerCustom';
import {ButtonStyled} from '@/components/atoms/Button';
import {ProductsTable} from '@/components/ProductsPage/ProductsTable';
import {STATUS_TO_STATUS} from '@/constants/backendEnum';
import type {ITableBodyProduct} from '@/stores/ProductsStore';
import {Dropdown} from '@/components/atoms/Dropdown';
import {useValidation} from '@/hooks/useValidation';
import {runInAction} from "mobx";

export const FILTER_TYPE = {
    ALL: 'All',
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE',
} as const;

export const FILTER_TYPE_TO_DISPLAY = {
    ACTIVE: 'Активный',
    INACTIVE: 'Архив',
} as const;

export const FILTER_TYPE_TO_BADGE = {
    ACTIVE: STATUS_TO_STATUS.Active,
    INACTIVE: STATUS_TO_STATUS.Inactive,
} as const;

const buttonItems: IButtonGroupItem<string>[] = [
    {
        name: 'Все',
        type: FILTER_TYPE.ALL,
    },
    {
        name: 'Активные',
        type: FILTER_TYPE.ACTIVE,
    },
    {
        name: 'Архив',
        type: FILTER_TYPE.INACTIVE,
    },
];

export const ProductsPage = observer(() => {
    const {
        isLoading,
        productsStore,
        businessUnitStore,
        userStore,
        employeeAsUserStore,
        uiStateStore: {open},
    } = useRootStore();
    const [openAdd, setOpenAdd] = useState<boolean>(false);
    const [query, setQuery] = useState<string | undefined>(undefined);
    const [editProduct, setEditProduct] = useState<ITableBodyProduct | undefined>(undefined);
    const {value, setFormHasError, isError, onChangeDropdown} = useValidation();
    const [buDisabled, setBuDisabled] = useState<boolean>(false);

    useEffect(() => {
        setBuDisabled(!userStore.profileInfo?.is_staff);
        if (!editProduct) {
            const empl = employeeAsUserStore.allEmployeeAsUsers?.find((some) => some.user === userStore.profileInfo?.pk);
            onChangeDropdown('bu', empl?.bu);
        }
    }, [employeeAsUserStore.allEmployeeAsUsers, userStore.profileInfo?.is_staff, userStore.profileInfo?.pk]);

    const openDrawer = () => {
        setOpenAdd(true);
    };

    const closeDrawer = () => {
        setOpenAdd(false);
        setEditProduct(undefined);
    };

    const toggleOpen = () => {
        if (openAdd) {
            closeDrawer();
            return;
        }

        openDrawer();
    };

    useEffect(() => {
        if (editProduct) {
            openDrawer();
        }
    }, [editProduct]);

    const handleChangeButtonFilter = (selectedName: string) => {
        const selectedItem = buttonItems.find(({name}) => name === selectedName);
        runInAction(() => {
            productsStore.selectedFilter = selectedItem;
        })
    };

    const handleSaveForm = async (event: React.FormEvent<HTMLFormElement>) => {
        const data = new FormData(event.currentTarget);
        const name = data.get('name') as string;
        const description = data.get('description') as string;

        if (editProduct) {
            await productsStore.updateProduct({
                productId: editProduct.id,
                productInfo: {
                    name: name ?? editProduct.name,
                    description: description ?? editProduct?.description,
                    status: value?.status ?? editProduct?.status,
                    bu: value?.bu ?? editProduct?.bu,
                },
            });

            toggleOpen();

            return;
        }

        if (name) {
            await productsStore.createProduct({
                productInfo: {
                    name,
                    description,
                    status: 'ACTIVE',
                    bu: value?.bu,
                },
            });
            toggleOpen();
        }
    };

    const onChangeSearch = (e: any) => {
        setQuery(e.target.value);
    };

    const dropdownItems = useMemo(() => {
        if (businessUnitStore.allBusinessUnits) {
            return businessUnitStore.allBusinessUnits.map(({id, name}) => ({
                label: name,
                value: id,
            }));
        }

        return [];
    }, [businessUnitStore.allBusinessUnits]);

    useEffect(() => {
        let handler: NodeJS.Timeout;
        if (query?.length === 0) {
            handler = setTimeout(() => {
                runInAction(() => {
                    productsStore.searchString = query;
                })
            }, 1000);
        }

        if (isLoading || (query?.length as number) < 2) {
            return undefined;
        }

        handler = setTimeout(() => {
            runInAction(() => {
                productsStore.searchString = query;
            })
        }, 1000);

        return () => {
            if (handler) {
                clearTimeout(handler);
            }
        };
    }, [isLoading, productsStore, query]);

    const selectedName = productsStore.selectedFilter?.name ?? buttonItems[0].name;

    return (
        <Box mb={OFFSET.xxxl}>
            <Title props={{mt: '10px'}}>Продукты</Title>
            <Box
                sx={{
                    mt: OFFSET.sm,
                    mb: OFFSET.sm,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <ButtonGroupStyled items={buttonItems} outOnClick={handleChangeButtonFilter}
                                   selectedName={selectedName}/>
                <TextFieldOutlinedStyled
                    onChange={onChangeSearch}
                    id="search"
                    name="search"
                    size="small"
                    type="text"
                    autoComplete="no"
                    placeholder="Найти..."
                    sx={{width: WIDTH.s}}
                    value={query}
                    endAdornment={
                        isLoading && (
                            <InputAdornment position="end">
                                <CircularProgress size={20}/>
                            </InputAdornment>
                        )
                    }
                    startAdornment={
                        <InputAdornment position="start">
                            <SearchOutlinedIcon/>
                        </InputAdornment>
                    }
                />
            </Box>
            <Box sx={{m: 0, mt: OFFSET.m}}>
                <Grid item xs={12}>
                    <Paper
                        sx={{
                            p: 3,
                            display: 'flex',
                            flexDirection: 'column',
                            padding: '0px',
                            boxShadow: '0',
                            borderRadius: 0,
                            backgroundColor: 'transparent',
                        }}
                    >
                        <ProductsTable setEditProduct={setEditProduct}/>
                    </Paper>
                </Grid>
            </Box>
            <DrawerCustom open={open}>
                <ButtonStyled text="Создать" startIcon={<AddCircleOutlineIcon/>} onClick={toggleOpen}/>
            </DrawerCustom>
            <DrawerLikeModal
                title="Создать продукт"
                open={openAdd}
                toggleOpen={toggleOpen}
                saveForm={handleSaveForm}
                isDisabled={isLoading || isError}
            >
                <TemplateInputWithLabel
                    sx={{minWidth: WIDTH.s}}
                    label="Название"
                    id="name"
                    required
                    value={editProduct?.name}
                    handleGlobalError={setFormHasError}
                />
                <TemplateInputWithLabel
                    sx={{minWidth: WIDTH.s}}
                    label="Описание"
                    id="description"
                    value={editProduct?.description}
                />
                <Dropdown
                    mb="16px"
                    onChange={onChangeDropdown}
                    key="bu"
                    label="Бизнес подразделение"
                    disabledPlaceholder={false}
                    title="Бизнес подразделение"
                    id="bu"
                    value={value?.bu ?? editProduct?.bu}
                    dropdownItems={dropdownItems}
                    required
                    disabled={buDisabled}
                />
                {editProduct ? (
                    <Box sx={{width: '100%'}}>
                        <Dropdown
                            mb="16px"
                            width="100%"
                            onChange={onChangeDropdown}
                            key="status"
                            label="Статус"
                            title="Выберете статус"
                            id="status"
                            value={editProduct.status}
                            dropdownItems={Object.keys(FILTER_TYPE_TO_DISPLAY).map((key) => ({
                                label: FILTER_TYPE_TO_DISPLAY[key as keyof typeof FILTER_TYPE_TO_DISPLAY],
                                value: key,
                            }))}
                        />
                    </Box>
                ) : null}
            </DrawerLikeModal>
        </Box>
    );
});
