import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';

import { ROUTES } from '@/constants';
import { useModalDelete } from '@/components/common/hooks/useModalDelete';
import { LabelLikeHintWithText } from '@/components/common/LabelLikeHintWithText';
import { ModalDelete } from '@/components/common/ModalDelete';
import { TableRowStyled, TableCellSmallPadding } from '@/components/Templates/TableComponentStyled';
import { MoreMenuTabData } from '@/components/Administering/AdministrationTabData/MoreMenuTabData';
import { useRootStore } from '@/stores';
import { TableTemplate } from '@/components/Templates/TableTemplate';
import { ITableBodyAdministrationData } from '@/stores/freskoApi/AdministrationDataStore';
import { dateFormatterWithHoursMinutes } from '@/utils';
import { FilterComponent } from '@/components/atoms/FilterComponent';
import { color } from '@/constants/colors';

const tableHeader = ['Объект', 'Фильтр', 'Изменен', ' '];

const RenderRowAdministrationTabDataTable = ({
  row,
  filled,
  handleOpenDeleteModal,
  setEditData,
  setDuplicateData
}: {
  row: ITableBodyAdministrationData,
  filled: boolean,
  handleOpenDeleteModal: (data: ITableBodyAdministrationData) => void;
  setEditData: (value: ITableBodyAdministrationData | undefined) => void;
  setDuplicateData: (value: React.SetStateAction<ITableBodyAdministrationData | undefined>) => void
}) => {
  const navigate = useNavigate();

  const { bdName, changeName, changeDate, objectName, objectBdName, filter } = row;
  const onDelete = () => {
    handleOpenDeleteModal(row);
  }

  const onEdit = () => {
    setEditData(row)
  }

  const onDuplicate = () => {
    setDuplicateData(row)
  }

  const onRowClick = (e: any) => {
    e.preventDefault();
    navigate && navigate(`${ROUTES.settings}${ROUTES.administration}${ROUTES.data}/${row.id}`);
  }

  return (
    <TableRowStyled key={row.id} sx={{ backgroundColor: filled ? color.light : 'transparent' }} onClick={onRowClick}>
      <TableCellSmallPadding>
        <LabelLikeHintWithText reverse title={`${objectBdName} / ${objectName}`} value={bdName} />
      </TableCellSmallPadding>
      <TableCellSmallPadding>
        <LabelLikeHintWithText reverse title={''}>
          <FilterComponent filterText={filter} />
        </LabelLikeHintWithText>
      </TableCellSmallPadding>
      <TableCellSmallPadding>
        <LabelLikeHintWithText reverse title={dateFormatterWithHoursMinutes(changeDate)} value={changeName} />
      </TableCellSmallPadding>
      <TableCellSmallPadding align='right'>
        <MoreMenuTabData
          onDelete={onDelete}
          onEdit={onEdit}
          onDuplicate={onDuplicate}
        />
      </TableCellSmallPadding>
    </ TableRowStyled>
  )
}

export const AdministrationTabDataTable = observer(({
  setEditData,
}: {
  setEditData: (value: ITableBodyAdministrationData | undefined) => void;
}) => {
  const [duplicateData, setDuplicateData] = useState<ITableBodyAdministrationData | undefined>();
  const { isLoading, administrationDataStore } = useRootStore();
  const { open, handleClose, handleOpen, deletedValue: data } = useModalDelete<any>();

  useEffect(() => {
    administrationDataStore.getAdministrationData();
  }, [])

  const handleDelete = () => {
    if (data) {
      administrationDataStore.deleteAdministrationDataById(data.id);
      handleClose();
    }
  }

  const handleDuplicate = () => {
    if (duplicateData) {
      administrationDataStore.duplicateAdministrationDataById(duplicateData)
      setDuplicateData(undefined);
    }
  }

  useEffect(() => {
    if (duplicateData) {
      handleDuplicate();
    }

  }, [duplicateData])

  return (
    <>
      <TableTemplate<ITableBodyAdministrationData>
        tableHeader={tableHeader}
        RenderComponent={(props) => <RenderRowAdministrationTabDataTable
          {...props}
          handleOpenDeleteModal={handleOpen}
          setEditData={setEditData}
          setDuplicateData={setDuplicateData}
        />}
        store={administrationDataStore}
        isLoading={isLoading || administrationDataStore.isLoading}
      />
      <ModalDelete
        uniqId={'Data_table_modal'}
        open={open}
        handleClose={handleClose}
        handleDelete={handleDelete}
        title="Вы действительно хотите удалить объект?"
        descriptions={[
          (data?.displayName ?? ''),
          (data?.changeName ?? ''),
          (data?.objectName ?? ''),
        ]}
      />
    </>
  );
});
