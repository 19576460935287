import Button from '@mui/material/Button';
import styled from '@emotion/styled';
import { ButtonType } from '@/components/atoms/Button/constants';
import { color } from '@/constants/colors';

type ButtonProps = {
  props: {
    customType: keyof typeof ButtonType;
    height?: string;
    isFilter?: boolean;
  };
};

const ButtonColorsMapContained = {
  [ButtonType.default]: {
    default: `linear-gradient(90deg, ${color.light_green} 0%, ${color.mountain_meadow} 100%)`,
    hover: `linear-gradient(${color.light_green} 0%, ${color.light_green} 100%)`,
    pressed: `linear-gradient(${color.green_jungle} 0%, ${color.green_jungle} 100%)`,
    disabled: color.gray,
    color: color.white,
  },
  [ButtonType.info]: {
    default: `linear-gradient(90deg, ${color.light_green} 0%, ${color.mountain_meadow} 100%)`,
    hover: color.light_green,
    pressed: `rgba(166, 45, 208, 0.1)`,
    disabled: color.gray,
  },
  [ButtonType.success]: {
    default: `linear-gradient(90deg, ${color.light_green} 0%, ${color.mountain_meadow} 100%)`,
    hover: '',
    pressed: '',
    disabled: '',
  },
  [ButtonType.warning]: {
    default: `linear-gradient(90deg, ${color.light_green} 0%, ${color.mountain_meadow} 100%)`,
    hover: '',
    pressed: '',
    disabled: '',
  },
  [ButtonType.error]: {
    default: color.carmine_pink,
    hover: color.light_carmine_pink,
    pressed: color.light_pink_purple,
    disabled: color.pale_blue,
    color: color.white,
  },
};

//@ts-ignore
export const ButtonColorStyledContained = styled(Button, {
  shouldForwardProp: (props) => props,
})<ButtonProps>(({ theme, props }) => {
  return {
    minWidth: 'auto',
    height: props?.height ?? '36px',
    backgroundColor: ButtonColorsMapContained[props.customType].hover,
    background: ButtonColorsMapContained[props.customType].default,
    color: ButtonColorsMapContained[props.customType].color,
    borderRadius: props.isFilter ? '12px' : '4px',
    padding: props.isFilter ? '0px 22px' : '8px 12px',
    textTransform: props.isFilter ? 'none' : 'uppercase',
    fontWeight: props.isFilter ? 'normal' : '',
    letterSpacing: props.isFilter ? '0.2px' : '0',
    '&:hover': {
      background: `${ButtonColorsMapContained[props.customType].hover}`,
      color: ButtonColorsMapContained[props.customType].color,
    },
    '&:active': {
      background: `${ButtonColorsMapContained[props.customType].pressed}`,
      color: ButtonColorsMapContained[props.customType].color,
    },
    '&:disabled': {
      background: props.isFilter ? color.tropical_trail : `${ButtonColorsMapContained[props.customType].disabled}`,
      color: ButtonColorsMapContained[props.customType].color,
    },
  };
});
