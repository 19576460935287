/* eslint-disable camelcase */
import { observable, makeObservable, action, reaction, runInAction } from 'mobx';
import { apiPath } from '@/constants/api';
import type { APP_ACC_ROLE, APP_STAGE_TO_STAGE, APP_STATUS_TO_STATUS } from '@/constants/backendEnum';
import { createAxiosRequest } from '@/utils/request';
import type { IAccount } from '@/stores/ContragentsStore';
import type { TableStoreType } from '@/stores/TableStore';
import { TableStore } from '@/stores/TableStore';
import type { IContactPersonsStore, IRootStore } from '@/stores/type';

export type IComplexRequestParams = {
  // account
  inn?: string;
  contragentId?: string | number;
  // contact
  phone?: string;
  email?: string;
  position?: string;
  firstName?: string;
  lastName?: string | null;
  middleName?: string;
  main?: boolean;
  // Account
  source?: string;
  role?: string;
  application_type?: string;
  manager?: string;
  status: keyof typeof APP_STATUS_TO_STATUS;
  stage?: keyof typeof APP_STAGE_TO_STAGE;
  primaryContact?: number;
  product_name?: string;
};

type IContact = {
  id: number;
  created: string;
  updated: string;
  created_by: number;
  updated_by: number;
  last_name: string;
  first_name: string;
  middle_name?: string;
  phone?: string;
  email?: string;
  position_name: string;
  is_c_level: boolean;
  account: any;
};

type IGetContacts = {
  count: number;
  next?: string;
  previous?: string;
  results: IContact[];
};

export type ITableBodyContactPersons = {
  id: string | number;
  fio: string;
  position: string;
  status: string;
  phone: string;
  email: string;
  isPrimaryContact: boolean;
};

const mapAccountToTableBody = (rawList: IContact[]): ITableBodyContactPersons[] => {
  return rawList?.map((item) => ({
    id: item.id,
    fio: `${item.first_name?.trim()} ${item?.middle_name?.trim() ?? ''} ${item.last_name?.trim()}`?.replace('  ', ' '),
    position: item.position_name,
    status: 'Active',
    phone: item.phone,
    email: item.email,
    isPrimaryContact: item.is_c_level,
  })) as ITableBodyContactPersons[];
};

export type IContactPersonsStoreWithITableStore<T> = IContactPersonsStore & TableStoreType<T>;

export class ContactPersonsStore
  extends TableStore<ITableBodyContactPersons>
  implements IContactPersonsStoreWithITableStore<ITableBodyContactPersons>
{
  @observable
  public rootStore: IRootStore;

  @observable
  public contragenId?: string | number;

  @observable
  public createdAccount?: string | number;

  public isLoading: boolean = false;

  constructor(rootStore: IRootStore) {
    super();
    makeObservable(this);
    this.rootStore = rootStore;

    reaction(
      () => this.contragenId,
      async (value, previousValue) => {
        if (value !== previousValue && this.contragenId) {
          await this.getContacts(this.contragenId);
        }
      },
    );

    reaction(
      () => this.selectedPage,
      (value) => {
        if (this.maxSelectedPage <= value) {
          this.getContacts(this.contragenId);
        }
      },
    );
  }

  contragentId?: string | number | undefined;

  @action
  setContragenId = (id?: string | number) => (this.contragenId = id);

  @action
  getContacts = async (id?: string | number, incomeLimit?: number) => {
    if (!this.isLoading) {
      this.rootStore.requestTemplate({
        errorMessage: `Ошибка получения данных контактов`,
        callback: async () => {
          try {
            const limit = this.rowsPerPageChange;
            const offset = this.rowsPerPageChange * this.selectedPage;
            const { data } = await createAxiosRequest<{ offset: number; limit: number }, IGetContacts>({
              path: apiPath.contactPerson.replace('{account_pk}', `${id}`),
              method: 'GET',
              params: {
                offset: this.rowsPerPageChange * this.selectedPage,
                limit: incomeLimit ?? this.rowsPerPageChange,
              },
              useToken: true,
            });
            runInAction(() => {
              this.limit = limit;
              this.summary = data.count;
              this.offset = offset;
              const tableData = mapAccountToTableBody(data.results);
              this.tableBody = [...tableData];
            })
          } finally {
            this.isLoading = false;
          }
        },
      });
    }
  };

  @action
  createAccountClient = async (
    id: string,
    {
      phone,
      email,
      position,
      firstName,
      lastName,
      middleName,
      main,
    }: {
      phone?: string;
      email?: string;
      position: string;
      firstName: string;
      lastName: string;
      middleName: string;
      main?: boolean;
    },
  ) => {
    this.rootStore.requestTemplate({
      errorMessage: `Не удалось добавить контактное лицо`,
      finallyAction: () => {
        this.tableBody = [];
        this.getContacts(id);
      },
      callback: async () => {
        await createAxiosRequest({
          path: apiPath.accountsCreateContact.replace('{account_pk}', id),
          method: 'POST',
          data: {
            last_name: lastName,
            first_name: firstName,
            middle_name: middleName,
            phone,
            email,
            position_name: position,
            is_c_level: main,
          },
          useToken: true,
        });
      },
    });
  };

  @action
  createAccountApplication = async (
    id: string,
    {
      role,
      application_type,
      product_name,
      manager,
      status,
    }: {
      role: keyof typeof APP_ACC_ROLE;
      application_type?: string;
      product_name: string;
      manager?: string;
      status: keyof typeof APP_STATUS_TO_STATUS;
    },
  ) => {
    this.rootStore.requestTemplate({
      errorMessage: `Не удалось добавить контактное лицо`,
      finallyAction: () => {
        this.tableBody = [];
        this.getContacts(id);
        this.rootStore.applicationsStore.getApplicationsById(id, true);
      },
      callback: async () => {
        await createAxiosRequest({
          path: apiPath.accountsCreateApplication.replace('{account_pk}', id),
          method: 'POST',
          data: {
            role,
            application_type,
            product_name,
            manager,
            status,
          },
          useToken: true,
        });
      },
    });
  };

  @action
  complexCreateApplication = ({
    // account
    inn,
    contragentId,

    // Account
    application_type,
    manager,
    status,
    product_name,
  }: IComplexRequestParams) => {
    return this.rootStore.requestTemplate({
      errorMessage: `Не удалось создать заявку`,
      isReturned: true,
      finallyAction: () => {
        this.rootStore.contragentsStore.searchString = undefined;
        this.rootStore.applicationsPageDetailsStore.getApplications(true);
      },
      callback: async () => {
        let usedContragentId = contragentId;

        // account
        if (!usedContragentId && inn) {
          const { data } = await createAxiosRequest<unknown, IAccount>({
            path: `${apiPath.accounts}`,
            method: 'POST',
            data: {
              accounts: [{ inn, status: 'New', is_phys_legal_addr: true }],
            },
            useToken: true,
          });

          usedContragentId = data.results?.[0]?.id;
        }

        // Account
        if (usedContragentId) {
          const { data } = await createAxiosRequest({
            path: apiPath.accountsCreateApplication.replace('{account_pk}', usedContragentId?.toString()),
            method: 'POST',
            data: {
              product_name,
              type_application: application_type,
              client_manager: manager,
              status,
              source: 'AIP',
            },
            useToken: true,
          });

          return data;
        }
      },
    });
  };

  @action
  updateContragentContactPersonInfo = async ({
    url,
    paramsToPatch,
    contragentId,
  }: {
    url: string;
    paramsToPatch: any;
    contragentId: string | number;
  }) => {
    this.rootStore.requestTemplate({
      errorMessage: `Не удалось добавить контактное лицо`,
      finallyAction: () => {
        this.tableBody = [];
        this.getContacts(contragentId);
        this.rootStore.applicationsStore.getApplicationsById(contragentId, true);
      },
      callback: async () => {
        await createAxiosRequest({
          path: url,
          method: 'PATCH',
          data: paramsToPatch,
          useToken: true,
        });
      },
    });
  };

  @action
  deleteContragentContactPersonInfo = async ({
    contragentId,
    contactId,
  }: {
    contragentId: string | number;
    contactId: string | number;
  }) => {
    this.rootStore.requestTemplate({
      errorMessage: `Не удалось удалить контактное лицо`,
      finallyAction: () => {
        this.tableBody = [];
        this.getContacts(contragentId);
        this.rootStore.applicationsStore.getApplicationsById(contragentId, true);
      },
      callback: async () => {
        await createAxiosRequest({
          path: apiPath.updateContactPersonInfo
            .replace('{account_pk}', `${contragentId}`)
            .replace('{id}', `${contactId}`),
          method: 'DELETE',
          useToken: true,
        });
      },
    });
  };

  @action
  clear = () => {
    this.contragenId = undefined;
    this.tableBody = [];
  };
}
