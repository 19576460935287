import React, { useEffect, useMemo, useRef, useState } from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';

import { WIDTH } from '@/constants/sizes';
import { TextFieldStyled } from '@/components/atoms/TextFieldStyled';
import { createAxiosRequest } from '@/utils/request';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { IGetAccounts, TableBodyContragents, mapAccountsToTableBody } from '@/stores/ContragentsStore';
import { apiPath } from '@/constants/api';

const guardianTypeIsDadata = (value: string | TableBodyContragents): value is TableBodyContragents =>
    (value as TableBodyContragents)?.name !== undefined;

export const AutocompleteInnMonitoring = (props: any) => {
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState<string>(props?.value ?? '');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<readonly TableBodyContragents[]>([]);
  const selectedValue = useRef<TableBodyContragents | null | string>();

  const handleSelectedValue = (
      _event: any,
      value: TableBodyContragents | null | string,
      _reason: any,
      _details?: any,
  ) => {
    if (props.onChange && value) {
      props.onChange(value);
      if (props?.handleChangeInput) {
        props.handleChangeInput({ autocompleteInn: '' });
      }
    }
    selectedValue.current = value;
  };

  const disableSearchDadata = useMemo(
      () => props?.disableClearable && props?.freeSolo && props?.disableSearchDadata,
      [props?.disableClearable, props?.disableSearchDadata, props?.freeSolo],
  );

  const handleChange = (event: any) => {
    setQuery(event.target.value);
    if (props?.handleChangeInput) {
      props.handleChangeInput({ autocompleteInn: event.target.value });
    }
  };

  const handleInputChange = (event: React.ChangeEvent<{}>, value: string, reason: string) => {
    if (reason === 'clear') {
      props.onChange(undefined);
    }
  };

  const requestSuggestion = React.useCallback(async () => {
    if (!disableSearchDadata) {
      setIsLoading(true);
      try {
        const { data } = await createAxiosRequest<
            { name?: string; search?: string; limit?: number; offset?: number; limit_or_charge?: string },
            IGetAccounts
        >({
          path: `${apiPath.accounts}`,
          method: 'GET',
          useToken: true,
          params: { search: query, limit: 10, offset: 0, limit_or_charge: 'Limit' },
        });
        const tableData = mapAccountsToTableBody(data?.results);
        setOptions([...tableData]);
      } catch (e) {
      } finally {
        setIsLoading(false);
      }
    }
  }, [disableSearchDadata, query]);

  useEffect(() => {
    if (!Boolean(query?.trim()) || isLoading) {
      return undefined;
    }

    const handler = setTimeout(() => {
      requestSuggestion();
    }, 500);

    return () => {
      if (handler) {
        clearTimeout(handler);
      }
    };
  }, [query, requestSuggestion]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const onKeyDown = (event: any) => {
    if ((!open || options.length === 0) && props?.onKeyDown) {
      try {
        props.onKeyDown(event);
      } catch (e) {
        console.log('onKeyDown', 'AutocompleteInn', { e });
      }
    }
  };

  return (
      <Autocomplete
          onKeyDown={onKeyDown}
          disableClearable={props?.disableClearable}
          freeSolo={props?.freeSolo}
          id="asynchronous-demo"
          sx={{ width: WIDTH.s, ...props?.sx }}
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          isOptionEqualToValue={(option, value) => option.inn === value.inn}
          getOptionLabel={(option) => {
            if (guardianTypeIsDadata(option)) {
              return `${option?.name} ИНН:${option?.inn}`;
            }

            return option;
          }}
          options={options}
          loading={isLoading}
          popupIcon={<></>}
          renderOption={(props, option, { inputValue }) => {
            const name = option.name;
            const inn = option.inn;
            const matchesInn = match(inn, inputValue);
            const partsInn = parse(inn, matchesInn);

            const matchesName = match(name, inputValue);
            const partsName = parse(name, matchesName);

            const summaryParts = [...partsName, ...partsInn];

            return (
                <li {...props}>
                  <div>
                    {summaryParts.map((part, index) => (
                        <span
                            key={index}
                            style={{
                              fontWeight: part.highlight ? 700 : 400,
                            }}
                        >
                  {part.text}
                </span>
                    ))}
                  </div>
                </li>
            );
          }}
          onChange={handleSelectedValue}
          onInputChange={handleInputChange}
          renderInput={(params) => (
              <TextFieldStyled
                  {...params}
                  label=""
                  placeholder={props.placeholder}
                  onChange={handleChange}
                  sx={{ width: '100%', padding: 0 }}
                  size="small"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                        <React.Fragment>
                          {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                    ),
                  }}
              />
          )}
      />
  );
};

AutocompleteInnMonitoring.displayName = 'AutocompleteInn';
