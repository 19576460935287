import React from 'react';
import Typography from '@mui/material/Typography';
import { color } from '@/constants/colors';

interface TitleProps {
  children?: React.ReactNode;
  props?: any;
}

export const Hint = ({ children, props }: TitleProps) => {
  return (
    <Typography
      {...props}
      component="h3"
      variant="h6"
      color={color.primary}
      gutterBottom
      sx={{
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '14px',
        color: color.gray,
      }}
    >
      {children}
    </Typography>
  );
};
