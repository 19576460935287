import {makeObservable, observable, runInAction} from 'mobx';
import {apiPath} from '@/constants/api';
import {createAxiosRequest} from '@/utils/request';
import type {TableStoreType} from '@/stores/TableStore';
import {TableStore} from '@/stores/TableStore';
import type {IRootStore} from '@/stores/type';

export type IAdministrationData = {
    id: number;
    name: string;
    display_name: string;
    db_layout: string;
    db_object: string;
    db_obj_prim_id_attr_name: string;
    description?: string;
    created: string;
    updated: string;
    created_by: string;
    updated_by: string;
    data_object?: string;
    filter_spec?: string;
};

export type ITableBodyAdministrationData = {
    id: string | number;
    displayName: string;
    primName: string;
    bdName: string;
    changeName?: string;
    changeDate?: string;
    objectName?: string;
    objectBdName?: string;
    description?: string;
    filter?: string;
};

export interface IAdministrationDataStore {
    rootStore: IRootStore;
    getAdministrationData: () => void;
    getAdministrationDataById: (id: string | number) => void;
    deleteAdministrationDataById: (id: string | number) => void;
    createAdministrationData: ({createData}: { createData: Partial<IAdministrationData> }) => void;
    patchAdministrationDataById: ({
                                      id,
                                      patchData,
                                      isRequestByIdAfterEdit,
                                  }: {
        id: string | number;
        patchData: Partial<IAdministrationData>;
        isRequestByIdAfterEdit?: boolean;
    }) => void;
    duplicateAdministrationDataById: (dataObject: ITableBodyAdministrationData) => void;
    tableBody?: ITableBodyAdministrationData[];
    isLoading: boolean;
    selectedAdministrationDataById: ITableBodyAdministrationData | Record<string, string>;
}

export type IAdministrationDataStoreWithITableStore<T> = IAdministrationDataStore & TableStoreType<T>;

export type IGetAdministrationData = IGetWithPagination<IAdministrationData>;

export class AdministrationDataStore
    extends TableStore<ITableBodyAdministrationData>
    implements IAdministrationDataStoreWithITableStore<ITableBodyAdministrationData> {
    @observable
    public rootStore: IRootStore;

    @observable
    public isLoading: boolean = false;

    @observable
    public selectedAdministrationDataById: ITableBodyAdministrationData | Record<string, string> = {};

    constructor(rootStore: IRootStore) {
        super();
        makeObservable(this);
        this.rootStore = rootStore;
    }

    public getAdministrationDataById(id: string | number) {
        if (!this.isLoading) {
            this.rootStore.requestTemplate({
                immediately: true,
                errorMessage: `Ошибка получения данных`,
                callback: async () => {
                    try {
                        this.isLoading = true;

                        const {data} = await createAxiosRequest<{}, IAdministrationData>({
                            path: apiPath.dataObjectById.replace('{id}', `${id}`),
                            method: 'GET',
                            useToken: true,
                        });
                        runInAction(() => {
                            this.selectedAdministrationDataById = mapAdministrationDataToTableBody([data])?.[0];
                        })
                    } finally {
                        this.isLoading = false;
                    }
                },
            });
        }
    }

    public getAdministrationData() {
        if (!this.isLoading) {
            this.rootStore.requestTemplate({
                errorMessage: `Ошибка получения данных`,
                callback: async () => {
                    try {
                        this.isLoading = true;

                        const limit = this.rowsPerPageChange;
                        const offset = this.rowsPerPageChange * this.selectedPage;
                        const {data} = await createAxiosRequest<{
                            offset: number;
                            limit: number
                        }, IGetAdministrationData>({
                            path: apiPath.dataObject,
                            method: 'GET',
                            params: {offset: this.rowsPerPageChange * this.selectedPage, limit: this.rowsPerPageChange},
                            useToken: true,
                        });
                        runInAction(() => {
                            this.limit = limit;
                            this.summary = data.count;
                            this.offset = offset;
                            const tableData = mapAdministrationDataToTableBody(data.results);
                            this.tableBody = [...tableData];
                        })
                    } finally {
                        runInAction(() => {
                            this.isLoading = false;
                        })
                    }
                },
            });
        }
    }

    public duplicateAdministrationDataById(dataObject: ITableBodyAdministrationData) {
        if (!this.isLoading) {
            this.rootStore.requestTemplate({
                finallyAction: () => this.getAdministrationData(),
                errorMessage: `Ошибка дублирования данных`,
                callback: async () => {
                    try {
                        this.isLoading = true;
                        const {id} = dataObject;

                        const dataObjectToRaw = {
                            name: dataObject.bdName,
                            db_layout: dataObject.objectBdName,
                            db_object: dataObject.objectName,
                            db_obj_prim_id_attr_name: dataObject.primName,
                            filter_spec: dataObject.filter,
                            description: dataObject.description,
                        };

                        await createAxiosRequest<{}, IAdministrationData>({
                            path: apiPath.dataObjectDuplicateById.replace('{id}', `${id}`),
                            method: 'POST',
                            data: {...dataObjectToRaw},
                            useToken: true,
                        });
                    } finally {
                        this.isLoading = false;
                    }
                },
            });
        }
    }

    public deleteAdministrationDataById(id: string | number) {
        if (!this.isLoading) {
            this.rootStore.requestTemplate({
                finallyAction: () => this.getAdministrationData(),
                errorMessage: `Ошибка удаления данных`,
                callback: async () => {
                    try {
                        this.isLoading = true;

                        await createAxiosRequest<{}, IAdministrationData>({
                            path: apiPath.dataObjectById.replace('{id}', `${id}`),
                            method: 'DELETE',
                            useToken: true,
                        });
                    } finally {
                        this.isLoading = false;
                    }
                },
            });
        }
    }

    public createAdministrationData({createData}: { createData: Partial<IAdministrationData> }) {
        if (!this.isLoading) {
            this.rootStore.requestTemplate({
                finallyAction: () => this.getAdministrationData(),
                errorMessage: `Ошибка добавления данных`,
                callback: async () => {
                    try {
                        this.isLoading = true;

                        await createAxiosRequest<{}, IAdministrationData>({
                            path: apiPath.dataObject,
                            method: 'POST',
                            data: {...createData},
                            useToken: true,
                        });
                    } finally {
                        this.isLoading = false;
                    }
                },
            });
        }
    }

    public patchAdministrationDataById({
                                           id,
                                           patchData,
                                           isRequestByIdAfterEdit,
                                       }: {
        id: string | number;
        patchData: Partial<IAdministrationData>;
        isRequestByIdAfterEdit?: boolean;
    }) {
        if (!this.isLoading) {
            this.rootStore.requestTemplate({
                finallyAction: () => {
                    if (isRequestByIdAfterEdit) {
                        this.getAdministrationDataById(id);
                        return;
                    }
                    this.getAdministrationData();
                },
                errorMessage: `Ошибка модификации данных`,
                callback: async () => {
                    try {
                        this.isLoading = true;

                        await createAxiosRequest<{}, IAdministrationData>({
                            path: apiPath.dataObjectById.replace('{id}', `${id}`),
                            method: 'PATCH',
                            data: {...patchData},
                            useToken: true,
                        });
                    } finally {
                        this.isLoading = false;
                    }
                },
            });
        }
    }
}

const mapAdministrationDataToTableBody = (data: IAdministrationData[]) =>
    data.map((item) => ({
        id: item.id,
        primName: item.db_obj_prim_id_attr_name,
        displayName: item.display_name,
        bdName: item.name,
        changeName: item?.updated_by ?? item?.created_by,
        changeDate: item?.updated ?? item?.created,
        objectName: item?.db_object,
        objectBdName: item?.db_layout,
        description: item?.description,
        filter: item?.filter_spec,
    }));
