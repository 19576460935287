import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {runInAction} from 'mobx';

import {Box, CircularProgress, InputAdornment, SelectChangeEvent, Typography} from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

import {ContragentApplicationTable} from '@/components/Contagents/ContactTabs/ContragentApplicationTable';
import {OFFSET, WIDTH} from '@/constants/sizes';
import {TextFieldOutlinedStyled} from '@/components/atoms/TextFieldStyled';
import {useRootStore} from '@/stores';
import {FilterItem, FilterType} from '@/constants/types';
import {FilterStatus, useFilters} from '@/components/atoms/FilterStatus';
import {APPLICATION_TYPE, APP_ACC_ROLE, APP_STATUS} from '@/constants/backendEnum';
import {MonitoringDatePicker} from '@/components/Monitoring/MonitoringDatePicker/MonitoringDatePicker';
import {IApplicationsStoreStoreWithITableStore, TableApplications} from '@/stores/ApplicationsStore';
import {getNextDay} from '@/utils';

const filterLabels = {
    PRODUCT: 'Продукт',
    APPLICATION_TYPE: 'Тип заявки',
    ROLE: 'Роль контрагента',
    APPLICATION_STATUS: 'Статус заявки',
};

const filterItems = (applicationsStore: IApplicationsStoreStoreWithITableStore<TableApplications>): FilterItem[] => [
    {
        label: filterLabels.PRODUCT,
        items: applicationsStore?.createAccountSelectValue ?? {},
        width: '88px',
    },
    {
        label: filterLabels.APPLICATION_TYPE,
        items: APPLICATION_TYPE,
        width: '100px',
    },
    {
        label: filterLabels.ROLE,
        items: APP_ACC_ROLE,
        width: '144px',
    },
    {
        label: filterLabels.APPLICATION_STATUS,
        items: APP_STATUS,
        width: '120px',
    },
];

const initialFilterStatus: FilterType = {
    [filterLabels.PRODUCT]: [],
    [filterLabels.APPLICATION_TYPE]: [],
    [filterLabels.ROLE]: [],
    [filterLabels.APPLICATION_STATUS]: [],
};

export const ContragentApplication = observer(() => {
    const [query, setQuery] = useState<string | undefined>(undefined);
    const [filterStatus, setFilterStatus] = useState<FilterType>(initialFilterStatus);
    const [selectedFilters, setSelectedFilters] = useState<FilterType>(initialFilterStatus);
    const [dateStartSignal, setDateStartSignal] = useState<string>('');
    const [dateEndSignal, setDateEndSignal] = useState<string>('');
    const {isLoading, applicationsStore} = useRootStore();

    const {open: openFilter, handleOpen, handleClose} = useFilters();

    const onChangeSearch = (e: any) => {
        setQuery(e.target.value);
    };

    useEffect(() => {
        let handler: NodeJS.Timeout;

        if (query?.length === 0) {
            handler = setTimeout(() => {
                applicationsStore.searchString = query;
            }, 1000);
        }

        if (isLoading || (query?.length as number) < 2) {
            return undefined;
        }

        handler = setTimeout(() => {
            runInAction(() => {
                applicationsStore.searchString = query;
            })
        }, 1000);

        return () => {
            if (handler) {
                clearTimeout(handler);
            }
        };
    }, [isLoading, applicationsStore, query]);

    const handleChangeFilter = (filterKey: string) => (e: SelectChangeEvent) => {
        const {value} = e.target;
        setFilterStatus((prevStatus) => ({
            ...prevStatus,
            [filterKey]: typeof value === 'string' ? value.split(',') : value,
        }));
    };

    const handleResetFilter = (filterKey: string) => (e: React.MouseEvent) => {
        if (e.target instanceof SVGSVGElement) {
            setFilterStatus((prevStatus) => ({
                ...prevStatus,
                [filterKey]: [],
            }));
            setSelectedFilters((prevStatus) => ({
                ...prevStatus,
                [filterKey]: [],
            }));
        }
        setFilterStatus((prevStatus) => ({
            ...prevStatus,
            [filterKey]: [],
        }));
        setSelectedFilters((prevStatus) => ({
            ...prevStatus,
            [filterKey]: [],
        }));
    };

    const handleConfirmFilter = (filterKey: string, label: string) => () => {
        setSelectedFilters((prevSelectedFilters) => ({
            ...prevSelectedFilters,
            [filterKey]: filterStatus[filterKey],
        }));

        handleClose(label);
    };

    useEffect(() => {
        runInAction(() => {
            applicationsStore.selectedProduct = selectedFilters[filterLabels.PRODUCT].join(',');
        })
    }, [selectedFilters[filterLabels.PRODUCT]]);

    useEffect(() => {
        runInAction(() => {
            applicationsStore.selectedApplicationType = selectedFilters[filterLabels.APPLICATION_TYPE].join(',');
        })
    }, [selectedFilters[filterLabels.APPLICATION_TYPE]]);

    useEffect(() => {
        runInAction(() => {
            applicationsStore.selectedApplicationRole = selectedFilters[filterLabels.ROLE].join(',');
        })
    }, [selectedFilters[filterLabels.ROLE]]);

    useEffect(() => {
        runInAction(() => {
            applicationsStore.selectedApplicationStatus = selectedFilters[filterLabels.APPLICATION_STATUS].join(',');
        })
    }, [selectedFilters[filterLabels.APPLICATION_STATUS]]);

    useEffect(() => {
        runInAction(() => {
            applicationsStore.selectedCreatedDateGte = dateStartSignal;
        })
    }, [dateStartSignal]);

    useEffect(() => {
        const dateEndSignalNextDay = getNextDay(dateEndSignal);
        runInAction(() => {
            applicationsStore.selectedCreatedDateLte = dateEndSignalNextDay;
        })
    }, [dateEndSignal]);

    return (
        <Box mb="64px">
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: OFFSET.xs,
                    marginBottom: OFFSET.s,
                    marginTop: `-${OFFSET.xxxxs}`,
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: OFFSET.xs,
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: OFFSET.xs,
                        }}
                    >
                        <Box sx={{display: 'flex', alignItems: 'center', gap: '8px'}}>
                            <Typography sx={{fontSize: '14px', lineHeight: '20px'}}>c</Typography>
                            <MonitoringDatePicker setDate={setDateStartSignal} width="150px"/>
                            <Typography sx={{fontSize: '14px', lineHeight: '20px'}}>по</Typography>
                            <MonitoringDatePicker setDate={setDateEndSignal} width="150px"/>
                        </Box>
                        {filterItems(applicationsStore).map((filterItem) => (
                            <FilterStatus
                                key={filterItem.label}
                                filterLabel={filterItem.label}
                                selectTitle={filterItem.label}
                                selectItems={filterItem.items}
                                handleOpen={() => handleOpen(filterItem.label)}
                                handleClose={() => handleClose(filterItem.label)}
                                handleConfirm={handleConfirmFilter(filterItem.label, filterItem.label)}
                                handleChangeStatus={handleChangeFilter(filterItem.label)}
                                handleResetStatus={handleResetFilter(filterItem.label)}
                                statusName={filterStatus[filterItem.label]}
                                open={openFilter(filterItem.label)}
                                width={filterItem.width}
                            />
                        ))}
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        gap: OFFSET.xs,
                    }}
                >
                    <TextFieldOutlinedStyled
                        onChange={onChangeSearch}
                        id="search"
                        name="search"
                        size="small"
                        type="text"
                        autoComplete="no"
                        placeholder="Найти..."
                        sx={{width: WIDTH.s, borderRadius: '0px'}}
                        value={query ?? ''}
                        endAdornment={
                            isLoading && (
                                <InputAdornment position="end">
                                    <CircularProgress size={20}/>
                                </InputAdornment>
                            )
                        }
                        startAdornment={
                            <InputAdornment position="start">
                                <SearchOutlinedIcon/>
                            </InputAdornment>
                        }
                    />
                </Box>
            </Box>
            <ContragentApplicationTable/>
        </Box>
    );
});
