import React, { useRef } from 'react';

import { Box, Typography } from '@mui/material';

import { OFFSET, WIDTH } from '@/constants/sizes';
import { withEditable } from '@/hoc/withEditable';
import { SimpleInput } from '@/components/ScoringSettingsPageDetails/ScoringSettingsPageDetailsTable';
import { EditButtons } from '@/components/Templates/EditButtons';
import { color } from '@/constants/colors';

const ComponentToEditableRaw = ({
  isEdit,
  handleOpenEdit,
  handleCloseEdit,
  handleSave,
  children,
  disabled,
  initialVal,
  title,
  editComponent,
}: React.PropsWithChildren & {
  isEdit: boolean;
  toggleEdit: () => void;
  handleOpenEdit: () => void;
  handleCloseEdit: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  handleSave: (value: string) => void;
  initialVal: string;
  disabled: boolean;
  title: string;
  editComponent?: JSX.Element;
}) => {
  const inpitRef = useRef(initialVal);

  const handleChange = (val: Record<string, string>) => {
    inpitRef.current = val?.simpleInput;
  };

  const onSave = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    handleSave(inpitRef?.current);
    handleCloseEdit(e);
  };

  return (
    <Box
      mb={OFFSET.xs}
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        marginTop: '-6px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Typography
          component="h2"
          variant="h6"
          color="primary"
          gutterBottom
          mr={OFFSET.xs}
          sx={{
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: '0.875rem',
            lineHeight: '1.5rem',
            color: color.black,
          }}
        >
          {title}
        </Typography>
        <EditButtons
          isEdit={isEdit}
          disabled={disabled}
          handleOpen={handleOpenEdit}
          handleClose={handleCloseEdit}
          handleSave={onSave}
          isHideTrWrapper
        />
      </Box>
      <Box width={isEdit ? '75%' : WIDTH.auto}>
        {isEdit
          ? editComponent ?? <SimpleInput initialVal={initialVal} fieldName={'simpleInput'} onChange={handleChange} />
          : children}
      </Box>
    </Box>
  );
};

export const ComponentToEditable = withEditable(ComponentToEditableRaw);
