export const baseUrl = process.env.REACT_APP_BASE_URL;
export const apiPath = {
  login: '/user/jwt-token-auth/',
  logout: '/user/jwt-token-auth/blacklist/',
  refresh: '/user/jwt-token-auth/refresh/',
  verify: '/user/jwt-token-auth/verify/',
  // Contragent
  accounts: '/accounts/',
  accountsById: '/accounts/{id}/',
  accountsCreateContact: '/accounts/{account_pk}/contacts/',
  accountsCreateApplication: '/accounts/{account_pk}/applications/',
  applicationsById: '/accounts/{account_pk}/applications/',
  applicationsRelationsById: '/accounts/{account_pk}/relations/',
  contactPerson: '/accounts/{account_pk}/contacts/',
  updateContactPersonInfo: '/accounts/{account_pk}/contacts/{id}/',
  reportByPeriod: '/accounts/{account_pk}/inter_report_file/',
  report: '/accounts/{account_pk}/inter_report_params/',
  reportUpload: '/accounts/{account_pk}/reports/generate_report/{res_type}/',
  reportDelete: '/accounts/{account_pk}/inter_report_file/{id}/',
  konturBalance: '/accounts/{account_pk}/kontur_balance_sheet/',
  konturBalancePeriod: '/accounts/{account_pk}/inter_report_params_api/',
  konturFinancialResults: '/accounts/{account_pk}/kontur_financial_results/',
  forQuarter: '/accounts/{account_pk}/inter_report_params_for_quarter/',
  ltm: '/accounts/{account_pk}/inter_report_params_lte/',
  downloadContragentsAll: '/accounts/accounts_export/',
  // Monitoring
  monitoringAccount: '/monitoring_account/',
  monitoringAccountById: '/monitoring_account/{id}/',
  monitoringSignalAccountById: '/monitoring_signal/{id}/monitoring_signal_action/',
  downloadMonitoringAll: '/monitoring_export/',
  downloadMonitoringById: '/single_monitoring_export/{id}',
  // employees
  employees: '/users/',
  employeesById: '/users/{id}/',
  // Application
  applications: '/applications',
  contragentApplicationsById: '/applications/{application_pk}/clients/',
  conditionApplicationsById: '/applications/{application_pk}/conditions/',
  conditionApplicationsByIdApp: '/applications/',

  crMembersById: '/applications/{application_pk}/accounts/',
  crudMembersById: '/applications/{application_pk}/accounts/{id}/',
  conditionAcc: '/applications/{application_pk}/conditions/{id}/',
  conditionAccApp: '/applications/{id}/',
  guarnatorsApplicationsByById: '/applications/{application_pk}/guarnators/',
  editGuarantor: '/applications/{application_pk}/guarnators/{id}/',
  analysisApplicationsByById: '/applications/{application_pk}/k62_files/',
  analysisApplicationsByByIdK51: '/applications/{application_pk}/k51_files/',
  scoringsApplicationsByById: '/applications/{application_pk}/scorings/',
  scoringByApplicationIdByScoringId: '/applications/{application_pk}/scorings/{id}/',
  detailApplicationsById: '/applications/{id}/',
  k62Details: '/applications/{application_pk}/k62_files/{id}/',
  k51Details: '/applications/{application_pk}/k51_files/{id}/',
  // Алгоритм
  // get
  settings: '/algorithms/',
  settingsClientById: '/algorithms/{id}/client-rules/',
  settingsDebtorById: '/algorithms/{id}/debtor-rules/',
  settingsRelationById: '/algorithms/{id}/relation-rules/',
  // patch
  settingsDuplicate: '/algorithms/{id}/duplicate/',
  settingsDebtorByIdPatch: '/algorithms/{algorithm_pk}/debtor-rules/{id}/',
  settingsClientByIdPatch: '/algorithms/{algorithm_pk}/client-rules/{id}/',
  settingsRelationByIdPatch: '/algorithms/{algorithm_pk}/relation-rules/{id}/',
  draftToActive: '/algorithms/{id}/',
  //
  profile: '/users/me/',
  listOfVal: '/list_of_val/',
  changePassword: '/users/{id}/set_password/',
  // Company group
  companyGroups: '/company_groups/',
  companyGroupsById: '/company_groups/{id}/',
  // fresco api
  attr: '/asmnt_attr/',
  attrById: '/asmnt_attr/{id}/',

  templAttr: '/asmnt_tmpl_attr/',
  templAttrInModel: '/assessment_template/{asmnt_tmpl_pk}/assessment_template-attr/',
  templAttrInModelUpdate: '/assessment_template/{asmnt_tmpl_pk}/assessment_template-attr/bulk_update/',
  templAttrById: '/asmnt_tmpl_attr/{id}/',
  templAttrByIdInModel: '/assessment_template/{asmnt_tmpl_pk}/assessment_template-attr/{id}/',

  asmntTmplAttrVal: '/asmnt_tmpl_attr_val/',
  asmntTmplAttrValById: '/asmnt_tmpl_attr_val/{id}/',

  dataContext: '/data_obj_attr/get_by_context/',
  dataContextDropdown: 'data_context',
  dataContextObjectDropdown: '/data_context_object/',

  assessment: '/assessment/',
  assessmentById: '/assessment/{id}/',
  assessmentAttrById: '/assessment/{asmnt_pk}/assessment-attr/',
  patchAssessmentAttrById: '/assessment/{asmnt_pk}/assessment-attr/{id}/',

  assessmentGetAvailableAsmntTmpl: '/applications/{id}/get_available_asmnt_tmpl_v2/',
  assessmentMakeNewAssessmentById: '/applications/{id}/assess/',
  assessmentMakeReAssessmentById: '/assessment/{id}/assess/',

  assessmentTemplate: '/assessment_template/',
  assessmentTemplateById: '/assessment_template/{id}/',
  assessmentTemplateByAsmntTmplPk: '/assessment_template/{asmnt_tmpl_pk}/assessment_template-attr/',
  assessmentTemplateByAsmntTmplPkAttrPk:
    '/assessment_template/{asmnt_tmpl_pk}/assessment_template-attr/{asmnt_tmpl_attr_pk}/asmnt_tmpl_attr-val/',
  assessmentTemplateByAsmntTmplPkAttrPkById:
    '/assessment_template/{asmnt_tmpl_pk}/assessment_template-attr/{asmnt_tmpl_attr_pk}/asmnt_tmpl_attr-val/{id}/',
  duplicateAssessmentTemplateById: '/assessment_template/{id}/duplicate/',
  reviseAssessmentTemplateById: '/assessment_template/{id}/revise/', // надо поправить имеющуюся с выпуском новой версии

  dataObjAttr: '/data_obj_attr/',
  dataObjAttrByDataId: '/data_object/{data_object_pk}/data_object-attr/',
  dataObjAttrById: '/data_obj_attr/{id}/',

  dataObjJoin: '/data_obj_join/',
  dataObjJoinById: '/data_obj_join/{id}/',
  dataObjJoinSpecByJoinPkId: '/data_obj_join/{join_pk}/join-spec/',

  dataObjJoinSpec: '/data_obj_join_spec/',
  dataObjJoinSpecById: '/data_obj_join_spec/{id}/',

  dataObject: '/data_object/',
  dataObjectById: '/data_object/{id}/',
  dataObjectDuplicateById: '/data_object/{id}/duplicate/',

  // product
  productById: '/products/{id}/',
  products: '/products/',

  // 1c
  onecDebtorsLimits: '/accounts/{account_pk}/onec_debtors/',
  onecAsDebtorsLimits: '/accounts/{account_pk}/onec_as_debtor/',

  // BusinessUnit
  crBusinessUnit: '/bu/', // post get
  crudBusinessUnitById: '/bu/{id}/', // crud

  // Position
  crPosition: '/postn/', // post get
  crudPositionById: '/postn/{id}/', // crud

  // CoverageTeam
  crCoverageTeam: '/coverage_team/', // post get
  crudCoverageTeamById: '/coverage_team/{id}/', // crud
  getCoverageTeamByContragentId: '/accounts/{account_pk}/coverage_team/',

  // CoverageTeam
  crEmployeeAsUser: '/employee/', // post get
  crudEmployeeAsUserById: '/employee/{id}/', // crud

  // app_sales_rep
  crAppSalesRep: '/app_sales_rep/', // post get
  crudAppSalesRepById: '/app_sales_rep/{id}/', // crud
  getAppSalesRepTeamByContragentId: '/applications/{application_pk}/app_sales_rep/',
};
