import { STATUS_TO_STATUS } from '@/constants/backendEnum';

export const ROUTES = {
  login: '/login',
  logout: '/logout',
  others: '*',
  home: '/',
  contragents: '/contragents',
  applications: '/applications',
  settings: '/settings',
  profile: '/profile',
  employees: '/employees',
  monitoring: '/monitoring',
  administration: '/administration',
  data: '/data',
  model: '/model',
  products: '/products',
  notFound: '/404',
};

export const ROLE = {
  SMARTFACT: 'Smartfact',
  GENERAL: 'General',
  ADMIN: 'Admin',
  BALTICLEASING: 'Balticleasing',
  PSBL: 'Psbl',
};

export const FILTER_TYPE = {
  ALL: 'All',
  NEW: STATUS_TO_STATUS.New,
  ACTIVE: STATUS_TO_STATUS.Active,
  INACTIVE: STATUS_TO_STATUS.Inactive,
} as const;

export const FILTER_TYPE_REPORT = {
  CF_REPORTING: 'CF_REPORTING',
  BALANCE: 'BALANCE',
  BY_PERIOD: 'BY_PERIOD',
  STATEMENT_OF_FINANCIAL_RESULTS: 'STATEMENT_OF_FINANCIAL_RESULTS',
  PER_QUARTER: 'PER_QUARTER',
  LTM: 'LTM',
};

export const FILTER_TYPE_STATUS = {
  All: 'All',
  Active: 'Active',
  New: 'New',
  Success: 'Success',
  Decline: 'Decline',
  InProgress: 'InProgress',
} as const;

export const FILTER_TYPE_APPLICATION_TO_PRESENTATION = {
  [FILTER_TYPE_STATUS.All]: 'Все заявки',
  [FILTER_TYPE_STATUS.Active]: 'Активен',
  [FILTER_TYPE_STATUS.New]: 'Новые',
  [FILTER_TYPE_STATUS.Success]: 'Принятые',
  [FILTER_TYPE_STATUS.Decline]: 'Отклоненные',
  [FILTER_TYPE_STATUS.InProgress]: 'В обработке у меня',
};

export const FILTER_TYPE_APPLICATION = {
  ALL: FILTER_TYPE_STATUS.All,
  NEW: FILTER_TYPE_STATUS.New,
  SUCCESS: FILTER_TYPE_STATUS.Active,
  DECLINE: FILTER_TYPE_STATUS.Decline,
  IN_PROGRESS: FILTER_TYPE_STATUS.InProgress,
} as const;

export const TYPE_PARAMS = {
  Interval: 'Interval',
  Categorical: 'Categorical',
  Helper: 'Helper',
  Logical: 'Logical',
};

export const SLIDER_TYPE = {
  BOOLEAN: 'BOOLEAN',
  DIAPASON: 'DIAPASON',
} as const;

export const BOOLEAN = {
  TRUE: 'TRUE',
  FALSE: 'FALSE',
} as const;

export const BOOLEAN_MAP_TO_DISPLAYNAME = {
  [BOOLEAN.TRUE]: 'Истина',
  [BOOLEAN.FALSE]: 'Ложь',
} as const;

export const RETURN_ROUTE_APPLICATION = 'Заявки';
