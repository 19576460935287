import React, { useState, useRef, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';

import { Box } from '@mui/material';

import { useRootStore } from '@/stores';
import { DrawerLikeModal } from '@/components/common/DrawerLikeModal';
import { TemplateInputWithLabel, TemplateWithLabel } from '@/components/atoms/TemplateInputWithLabel';
import { OFFSET, WIDTH } from '@/constants/sizes';
import { Dropdown } from '@/components/atoms/Dropdown';
import { APPLICATION_TYPE, APPLICATION_TYPE_KEY, APP_STATUS_TO_STATUS } from '@/constants/backendEnum';
import { AutocompleteInn } from '@/components/atoms/AutocompleteInn';
import type { DaDataParty, DaDataSuggestion } from '@/utils/dadataTypes';
import { LabelLikeHintWithText } from '@/components/common/LabelLikeHintWithText';
import { color } from '@/constants/colors';
import { templateDropdownListStatus } from '../Contagents/ContactTabs/ContragentApplicationModal';
import { useValidation } from '@/hooks/useValidation';
import {runInAction} from "mobx";

type Props = {
  openAdd: boolean;
  toggleOpen: () => void;
};

const templateInnList = [
  {
    id: 'inn',
    required: true,
  },
];

export const ApplicationModalCreate = observer(({ openAdd, toggleOpen }: Props) => {
  const { contactPersonsStore, isLoading, contragentsStore, applicationsStore } = useRootStore();
  const navigate = useNavigate();
  const contragentSearchStringInit = useRef<string | undefined>(contragentsStore.searchString);

  const [selectedContragent, setSelectedContragent] = useState<DaDataSuggestion<DaDataParty> | undefined>(undefined);
  const { setFormHasError, value, onChangeDropdown, formHasErrorRaw, isError } = useValidation();

  const foundContragent = useMemo(() => contragentsStore.tableBody?.[0], [contragentsStore.tableBody]);

  const handleChangeDadata = (value: DaDataSuggestion<DaDataParty>) => {
    setSelectedContragent(value);
  };

  const handleToggleOpen = () => {
    toggleOpen();
  };

  useEffect(() => {
    if (selectedContragent) {
      contragentsStore.searchString = selectedContragent?.data?.inn;
    }
  }, [contragentsStore, selectedContragent]);

  useEffect(() => {
    if (!openAdd) {
      contactPersonsStore.clear();
      runInAction(() => {
        contragentsStore.searchString = contragentSearchStringInit.current;
      })
      contragentsStore.clear();
      setSelectedContragent(undefined);
    }
  }, [contactPersonsStore, contragentsStore, openAdd]);

  useEffect(() => {
    if (selectedContragent && foundContragent && contragentsStore.searchString && !isLoading) {
      contactPersonsStore.setContragenId(foundContragent?.id);
    }
  }, [contactPersonsStore, contragentsStore, foundContragent, isLoading, openAdd, selectedContragent]);

  const handleSaveForm = async (event: React.FormEvent<HTMLFormElement>) => {
    const contragentId = foundContragent?.id;
    const data = new FormData(event.currentTarget);

    const inn = selectedContragent?.data.inn;
    const application_type = value.application_type as string;
    const product_name = value.product_name as string;
    const manager = data.get('manager') as string;
    const status = value.status as keyof typeof APP_STATUS_TO_STATUS;

    if (inn || contragentId) {
      const createdOrderInfo = await contactPersonsStore.complexCreateApplication({
        inn,
        contragentId,
        main: true,
        application_type,
        product_name,
        manager,
        status,
      });

      toggleOpen();
      if (createdOrderInfo) {
        navigate(`${createdOrderInfo.id}`);
      }
    }
  };

  return (
    <>
      <DrawerLikeModal
        title="Заявка клиента"
        open={openAdd}
        toggleOpen={handleToggleOpen}
        saveForm={handleSaveForm}
        buttonConfirmText="Создать заявку"
        isDisabled={isError || !selectedContragent}
        isLoading={isLoading}
      >
        {templateInnList.map(({ id, required }) => (
          <TemplateWithLabel key={id} id={id} required={required} name={id} label="ИНН Клиента ⃰">
            <AutocompleteInn sx={{ minWidth: WIDTH.s, margin: 0 }} onChange={handleChangeDadata} />
          </TemplateWithLabel>
        ))}
        <Dropdown
          handleGlobalError={setFormHasError}
          onChange={onChangeDropdown}
          required
          isError={formHasErrorRaw?.product_name ?? false}
          key="product_name"
          label="Продукт ⃰"
          title="Выберете продукт"
          id="product_name"
          dropdownItems={applicationsStore?.createAccountDropdownValue ?? []}
        />
        <Dropdown
          handleGlobalError={setFormHasError}
          onChange={onChangeDropdown}
          required
          isError={formHasErrorRaw?.application_type ?? false}
          key="application_type"
          label="Тип заявки ⃰"
          title="Выбрать"
          id="application_type"
          dropdownItems={[
            {
              label: APPLICATION_TYPE['Установление лимита'],
              value: APPLICATION_TYPE_KEY['Установление лимита'],
            },
            {
              label: APPLICATION_TYPE['Изменение параметров лимита'],
              value: APPLICATION_TYPE_KEY['Изменение параметров лимита'],
            },
          ]}
        />
        <TemplateInputWithLabel
          key="manager"
          id="Клиентский менеджер"
          name="manager"
          label="Клиентский менеджер"
          style={{ width: WIDTH.s }}
          placeholder={'Введите ФИО'}
        />
        {templateDropdownListStatus.map(({ label, id, placeholder, dropdownItems, ...props }) => (
          <Dropdown
            onChange={onChangeDropdown}
            key={id}
            label={label}
            title={placeholder}
            id={id}
            dropdownItems={dropdownItems}
            {...props}
          />
        ))}

        {/*  Инфо о найденом контрагенте */}
        {selectedContragent && (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }} mb={OFFSET.m}>
            <Box
              sx={{
                height: 'auto',
                mrgingTop: '24px',
                background: color.light,
                border: `1px solid ${color.honeydew}`,
                borderRadius: '4px',
                alignItems: 'flex-start',
                flexDirection: 'column',
                display: 'flex',
                padding: '24px',
                width: `calc(${WIDTH.s} - 48px)`,
              }}
            >
              <LabelLikeHintWithText title="Наименование" value={selectedContragent?.data.name.short_with_opf} />
              <LabelLikeHintWithText
                mt="16px"
                title="Полное наименование"
                value={selectedContragent?.data.name.full_with_opf}
              />
              <LabelLikeHintWithText mt="16px" title="Основной ОКВЭД" value={selectedContragent?.data.okved} />
              <LabelLikeHintWithText mt="16px" title="Вид деятельности" value="-" />
            </Box>
          </Box>
        )}
      </DrawerLikeModal>
    </>
  );
});
