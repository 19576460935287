import React, { useEffect, useLayoutEffect } from 'react';

import { Box, styled } from '@mui/material';

import { useRootStore } from '@/stores';
import { ContractReportModal } from '@/components/Contagents/ContactTabs/ContractReportModal';
import { ContractReportTable } from '@/components/Contagents/ContactTabs/ContractReportTable';

export const ContractReport = () => {
  const { contactPersonsStore, contragentReportTableBalanceStore } = useRootStore();
  useEffect(() => {
    return () => {
      contactPersonsStore.clear();
    };
  }, []);

  return (
    <Box mb="75px" style={{ position: 'relative' }}>
      <ContractReportTable />
      <ContractReportModal />
    </Box>
  );
};
