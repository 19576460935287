import { PaletteOptions } from '@mui/material';
import createTheme from '@mui/material/styles/createTheme';
import { color } from '@/constants/colors';

export const dark = createTheme({
  typography: {
    allVariants: {
      fontFamily: 'Raleway',
      textTransform: 'none',
      fontSize: 16,
    },
  },
  palette: {
    mode: 'dark',
    primary: {
      main: color.light_black,
    },
    secondary: {
      main: color.honeydew,
    },
    error: {
      main: color.light_carmine_pink,
    }
  } as PaletteOptions,
});

export const light = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Raleway';
          font-size: 1rem;
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          line-height: 16px;
          letter-spacing: 0.03rem;
          font-feature-settings: '"lnum"';
          src: local('SuisseIntl'), local('SuisseIntl'), url(./assets/fonts/Raleway-Regular.ttf) format('ttf');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
    },
  },
  typography: {
    fontFamily: 'Raleway',
    allVariants: {
      fontFamily: 'Raleway',
      textTransform: 'none',
      fontFeatureSettings: '"lnum"',
    },
  },
  palette: {
    mode: 'light',
    primary: {
      main: color.grayish_blue,
    },
    error: {
      main: color.light_carmine_pink,
    },
    success: {
      main: color.emerald,
    },
    secondary: {
      main: color.american_pink,
    },
    fontFamily: ['SuisseIntl'].join(','),
  } as PaletteOptions,
});
