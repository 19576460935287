import React from 'react';
import { Box } from '@mui/material';
import { StatusBadge } from '@/components/atoms/Bage/StatusBadge';

const reg = /\[.+?\]/g;

type IRecursiveIterationArgs = {
  result: Array<string | JSX.Element>;
  filterText: string;
  iterator: IterableIterator<RegExpMatchArray>;
  prevVal?: IteratorResult<RegExpMatchArray, any>;
  iterationCounter?: number;
};

interface RecursiveFunction {
  ({ result, filterText, iterator }: IRecursiveIterationArgs): Array<string | JSX.Element>;
}

const recursiveIteration: RecursiveFunction = ({
  result,
  filterText,
  iterator,
  prevVal,
  iterationCounter = 0,
}: IRecursiveIterationArgs) => {
  iterationCounter++;
  const currentVal = iterator?.next();
  // '360 / ([Себестоимость] / [Запасы])';
  if (!currentVal.done) {
    const { value } = currentVal;
    const [currentMatchString] = value;
    const { index: startStringIndex } = value;

    let mutateFilterText = filterText;

    if (prevVal && startStringIndex) {
      const { value: prevValue } = prevVal;
      const [prevMatchString] = prevValue;
      const { index: startPrevStringIndex } = prevValue;
      const offsetSummary = startPrevStringIndex + prevMatchString.length;
      const stringAfterPrevMatch = filterText.slice(0, startStringIndex - offsetSummary);
      mutateFilterText = mutateFilterText.replace(stringAfterPrevMatch, '');
      result.push(stringAfterPrevMatch);
    } else if (startStringIndex !== 0) {
      const stringBeforeFirstMatch = filterText.slice(0, startStringIndex);
      result.push(stringBeforeFirstMatch);
      mutateFilterText = mutateFilterText.replace(stringBeforeFirstMatch, '');
    }

    mutateFilterText = mutateFilterText.replace(currentMatchString, '');

    result.push(
      <StatusBadge
        key={iterationCounter}
        isFirst={iterationCounter === 1}
        isSmall
        type={'unset' as any}
        text={currentMatchString?.replace('[', '')?.replace(']', '')}
      />,
    );

    return recursiveIteration({
      result,
      filterText: mutateFilterText,
      iterator,
      prevVal: currentVal,
      iterationCounter,
    });
  }
  if (iterationCounter === 1 && currentVal.done) {
    result.push(<StatusBadge key={iterationCounter} isSmall isWarning type={'Error' as any} text={filterText} />);
    return result;
  }
  if (prevVal && currentVal.done) {
    result.push(filterText);
    return result;
  }
  return result;
};

const iteratorToArrayComponent = (iterator: IterableIterator<RegExpMatchArray>, filterText: string) => {
  const result: string | JSX.Element[] = [];
  recursiveIteration({ result, filterText, iterator });
  return result;
};

export const FilterComponent = ({ filterText }: { filterText?: string }) => {
  if (!filterText) {
    return <></>;
  }

  const finalArray = iteratorToArrayComponent(filterText.matchAll(reg), filterText);
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', alignSelf: 'start' }}>{finalArray?.map((item) => item)}</Box>
  );
};
