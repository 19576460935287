import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import Box from '@mui/material/Box';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { OFFSET } from '@/constants/sizes';
import { useRootStore } from '@/stores';
import { DrawerLikeModal } from '@/components/common/DrawerLikeModal';
import type { TabBarItem } from '@/components/common/TabBarStyled';
import { TabBarStyled } from '@/components/common/TabBarStyled';
import { ContactPersons } from '@/components/Contagents/ContactTabs/ContactPersons';
import { Main } from '@/components/Contagents/ContactTabs/Main';
import { ContragentApplication } from '@/components/Contagents/ContactTabs/ContragentApplication';
import { Breadrcrumbs } from '@/components/common/Breadrcrumbs';
import { ContractReport } from '@/components/Contagents/ContactTabs/ContractReport';
import { ContractPersonLimits } from '@/components/Contagents/ContactTabs/ContractPersonLimits';
import { RETURN_ROUTE_APPLICATION, ROLE } from '@/constants';
import { color } from '@/constants/colors';
import { useLocation, useNavigate } from 'react-router-dom';
import { LabelLikeHintWithText } from '@/components/common/LabelLikeHintWithText';
import { dateFormatter } from '@/utils';

const templateInputWithLabelList = [
  {
    id: 'lastName',
    label: 'Фамилия',
  },
  {
    id: 'firstName',
    label: 'Имя',
  },
  {
    id: 'secondName',
    placeholder: 'Не обязательно',
    label: 'Отчество',
  },
  {
    id: 'phone',
    placeholder: 'Не обязательно',
    label: 'Телефон',
  },
  {
    id: 'email',
    placeholder: 'Не обязательно',
    label: 'E-mail',
  },
  {
    id: 'position',
    label: 'Должность',
  },
];

const tabBarItems: TabBarItem[] = [
  {
    name: 'Основное',
    component: () => <Main />,
    permissions: [ROLE.SMARTFACT, ROLE.GENERAL, ROLE.BALTICLEASING, ROLE.PSBL],
  },
  {
    name: 'Контактные лица',
    component: () => <ContactPersons />,
    permissions: [ROLE.SMARTFACT, ROLE.GENERAL, ROLE.BALTICLEASING, ROLE.PSBL],
  },
  {
    name: 'Заявки',
    component: () => <ContragentApplication />,
    permissions: [ROLE.SMARTFACT, ROLE.GENERAL, ROLE.BALTICLEASING, ROLE.PSBL],
  },
  {
    name: 'Отчетность',
    component: () => <ContractReport />,
    permissions: [ROLE.SMARTFACT, ROLE.BALTICLEASING, ROLE.PSBL],
  },
  {
    name: 'Лимиты',
    component: () => <ContractPersonLimits />,
    permissions: [ROLE.SMARTFACT, ROLE.BALTICLEASING, ROLE.PSBL],
  },
];

export const ContragentsDetailsPage = observer(() => {
  const [renderComponent, setRenderComponent] = useState(tabBarItems[0].component);
  const { contragentsStore, userStore, applicationsStore, contragentReportStore } = useRootStore();
  const [selectedTab, setSelectedTab] = useState(tabBarItems[0]);
  const [openAdd, setOpenAdd] = useState<boolean>(false);
  const location = useLocation();
  const navigate = useNavigate();

  const locationState = location?.state as { route: string; applicationId?: string; contragenId?: string } | null;

  useEffect(() => {
    if (locationState?.route && locationState?.route === RETURN_ROUTE_APPLICATION) {
      const findItem = tabBarItems.find((item) => item.name === locationState?.route) ?? tabBarItems[0];
      onChangeTab(findItem.name);
      setSelectedTab(findItem);
    }
  }, [locationState]);

  const toggleOpen = () => {
    setOpenAdd(!openAdd);
  };

  const onChangeTab = (currentTab: string) => {
    setRenderComponent(tabBarItems.find(({ name }) => name === currentTab)?.component ?? <></>);
    setSelectedTab(tabBarItems.find(({ name }) => name === currentTab) ?? tabBarItems[0]);
  };

  const handleSaveForm = async (event: React.FormEvent<HTMLFormElement>) => {
    const data = new FormData(event.currentTarget);
  };

  const onArrowClick = () => {
    contragentsStore.clear();
    navigate(`/applications/${locationState?.applicationId}`, { state: locationState?.route });
  };

  useEffect(() => {
    return () => {
      applicationsStore.clear();
      contragentReportStore.clear();
    };
  }, []);

  return (
    <Box
      mb={OFFSET.l}
      sx={{
        position: 'relative',
      }}
    >
      <Box
        sx={{
          mt: '10px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Breadrcrumbs
          breadCrumbItems={[
            {
              name: contragentsStore.selectedContragent?.name ?? '-',
            },
          ]}
        />
        {selectedTab.name === 'Основное' && (
          <LabelLikeHintWithText
            title={'Обновлен из контура'}
            value={dateFormatter(contragentsStore.selectedContragent?.updated, true)}
            row
          />
        )}
      </Box>
      <Box
        sx={{
          mt: OFFSET.sm,
          mb: OFFSET.sm,
          display: 'flex',
          justifyContent: 'space-between',
          borderBottom: `1px solid ${color.gray}`,
        }}
      >
        <TabBarStyled
          items={tabBarItems}
          selected={selectedTab}
          onChangeTab={onChangeTab}
          userRole={userStore.profileInfo?.view_set}
        />
      </Box>
      {locationState && locationState.route !== RETURN_ROUTE_APPLICATION && (
        <ArrowBackIcon
          onClick={onArrowClick}
          sx={{
            color: color.light_green,
            marginBottom: '19px',
            cursor: 'pointer',
          }}
        />
      )}
      {renderComponent}
      <DrawerLikeModal
        title="Добавить контрагента"
        open={openAdd}
        toggleOpen={toggleOpen}
        items={templateInputWithLabelList}
        saveForm={handleSaveForm}
      />
    </Box>
  );
});
