import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { Box, Grid, Link, Modal, Typography, styled, useTheme } from '@mui/material';

import { OFFSET } from '@/constants/sizes';
import { LabelLikeHintWithText, typographyStyleBody } from '@/components/common/LabelLikeHintWithText';
import { modalStyle } from '@/components/common/ModalDelete';
import { Owner } from '@/stores/ContragentsStore';
import { mathRoundTwoDecimals, pluralize } from '@/utils';
import { ComponentToEditable } from './ComponentToEditable';
import { useRootStore } from '@/stores';
import { AutocompleteCompanyGroups } from '@/components/atoms/AutocompleteCompanyGroups';
import { TableBodyCompanyGroup } from '@/stores/CompanyGroupStore';
import { color } from '@/constants/colors';
import { TextareaStyled } from '@/components/atoms/TextareaStyled';

export const ComponentsType = {
  MODAL: 'MODAL',
  MODAL_OWNERS: 'MODAL_OWNERS',
  LINK_WITH_EDIT: 'LINK_WITH_EDIT',
  TEXT_WITH_EDIT: 'TEXT_WITH_EDIT',
  COMPANY_GROUP_WITH_SEARCH: 'COMPANY_GROUP_WITH_SEARCH',
  BUSINESS_DESCRIPTION: 'BUSINESS_DESCRIPTION',
} as const;

type ComponentForGridItemByTypeProps = {
  item: any;
  index: number;
};

const ModalStyled = styled(Modal)(({ theme }) => ({
  '& .MuiBox-root': {
    borderRadius: '4px',
    maxHeight: '85%',
    overflow: 'auto',
  },
}));

const CustomLink = ({
  text,
  handleClick,
  href = '#',
  target,
  rel,
}: {
  text: string;
  handleClick?: () => void;
  href?: string;
  target?: string;
  rel?: string;
}) => (
  <Link
    href={href}
    onClick={handleClick}
    underline="hover"
    variant="caption"
    sx={{ color: color.royal_blue }}
    target={target}
    rel={rel}
  >
    {text}
  </Link>
);

const OwnerComponent = ({ owner }: { owner: Owner }) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      marginBottom: '10px',
    }}
  >
    <Box
      sx={{
        ...typographyStyleBody,
        fontSize: '12px',
        minWidth: 47,
        minHeight: 24,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: `1px solid ${color.aquamarine}`,
        borderRadius: '4px',
        marginRight: OFFSET.xxs,
        textAlign: 'initial',
        fontWeight: 700,
        padding: '5px',
      }}
    >
      {`${mathRoundTwoDecimals(owner?.part)} %`}
    </Box>
    <Box
      sx={{
        ...typographyStyleBody,
        textAlign: 'initial',
      }}
    >
      <Box>{owner?.fio_owner || owner?.fullname}</Box>
      <Box>{owner?.innfl}</Box>
    </Box>
  </Box>
);

export const useModal = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return {
    open,
    handleOpen,
    handleClose,
  };
};

const OkvedModal = ({ item }: { item: any }) => {
  const { open, handleOpen, handleClose } = useModal();

  return (
    <>
      <Grid item xs={2} sm={4} md={4} sx={{ padding: '20px 0 0 24px' }}>
        <LabelLikeHintWithText title={item?.name} value={`${item?.title}`} />
        {item.value?.length > 1 && (
          <CustomLink
            text={`И еще ${pluralize({
              count: item.value?.length - 1,
              words: ['вид', 'вида', 'видов'],
            })} деятельности ...`}
            handleClick={handleOpen}
          />
        )}
      </Grid>
      <ModalStyled
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          top: '100px',
          borderRadius: 4,
          overflow: 'auto',
        }}
      >
        <Box sx={{ ...modalStyle, textAlign: 'left' }}>
          {(item.value as { text: string; code: string }[])?.map((item, index) => (
            <Typography id="modal-modal-description" sx={{ mt: 2 }} key={index}>
              {`${item?.code} ${item?.text}`}
            </Typography>
          ))}
        </Box>
      </ModalStyled>
    </>
  );
};

const OwnerModal = ({ item }: { item: any }) => {
  const { open, handleOpen, handleClose } = useModal();

  const displayedOwners = Array.isArray(item?.value) ? (item.value.slice(0, 3) as Owner[]) : [];

  return (
    <>
      <Grid item xs={2} sm={4} md={4} sx={{ padding: '20px 0 0 24px' }}>
        <LabelLikeHintWithText title={item?.name}>
          {displayedOwners.map((owner, index) => (
            <OwnerComponent owner={owner} key={index} />
          ))}
        </LabelLikeHintWithText>
        {Array.isArray(item?.value) && item?.value?.length > 3 && (
          <CustomLink
            text={`И еще ${pluralize({
              count: item.value?.length - 3,
              words: ['участник', 'участника', 'участников'],
            })}...`}
            handleClick={handleOpen}
          />
        )}
      </Grid>
      <ModalStyled
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          {Array.isArray(item?.value) &&
            (item.value as Owner[])?.map((item, index) => (
              <Typography id="modal-modal-description" sx={{ mt: 2 }} key={index}>
                <OwnerComponent owner={item as Owner} />
              </Typography>
            ))}
        </Box>
      </ModalStyled>
    </>
  );
};

const EditableLinkOrPhones = ({ item }: { item: any }) => {
  const { contragentsStore, isLoading } = useRootStore();
  const { id } = useParams<{ id: string }>();
  const { open, handleOpen, handleClose } = useModal();
  const theme = useTheme();

  const phonesString = item?.value[1] || '';
  const phonesArr = phonesString.split(/,\s*|\s+/).map((phone: string) => phone.trim());

  const handleSaveChanges = (text: string, index: number) => {
    const accountId = item.contragentId ? item.contragentId : id;

    if (text && accountId) {
      const fieldToPatch = item.component?.type?.[index] === ComponentsType.LINK_WITH_EDIT ? 'site' : 'phones';

      contragentsStore.patchAccountById({
        id: accountId,
        applicationId: item.contragentId ? id : undefined,
        patchParameters: { [fieldToPatch]: text },
      });
    }
  };

  return (
    <Grid
      item
      xs={2}
      sm={4}
      md={4}
      sx={{
        gridArea: '3 / 3 / 5 / 4',
        padding: '20px 0 0 24px',
        [theme.breakpoints.down(900)]: {
          gridArea: '5 / 1 / 6 / 2',
        },
      }}
    >
      {(item?.value as any[])?.map((_, index) => (
        <ComponentToEditable
          key={index}
          title={item?.name[index]}
          initialVal={item?.value[index]}
          handleSave={(text: string) => handleSaveChanges(text, index)}
          disabled={isLoading}
        >
          {item.component?.type?.[index] === ComponentsType.LINK_WITH_EDIT ? (
            <CustomLink target="_blank" text={item?.value[index]} href={`https://${item?.value[index]}`} />
          ) : (
            <>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '5px',
                }}
              >
                {phonesArr[0]}
                {phonesArr.length > 1 && (
                  <CustomLink
                    text={`И еще ${pluralize({
                      count: phonesArr?.length - 1,
                      words: ['телефон', 'телефона', 'телефонов'],
                    })}...`}
                    handleClick={handleOpen}
                  />
                )}
              </Box>
              <ModalStyled
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={modalStyle}>
                  {(phonesArr as string[])?.map(
                    (item, index) =>
                      index !== 0 && (
                        <Typography id="modal-modal-description" sx={{ mt: index === 1 ? 0 : 2 }} key={index}>
                          {item}
                        </Typography>
                      ),
                  )}
                </Box>
              </ModalStyled>
            </>
          )}
        </ComponentToEditable>
      ))}
    </Grid>
  );
};

const EditCompanyGroups = ({ item }: { item: any }) => {
  const { companyGroupsStore, contragentsStore } = useRootStore();
  const { id } = useParams<{ id: string }>();

  const companyInfo = useRef<TableBodyCompanyGroup | undefined>(undefined);
  const companyName = useRef<string>('');
  const [companyGroup, setCompanyGroup] = useState<TableBodyCompanyGroup | undefined>(undefined);

  const handleChangeInput = ({ autocompleteCompanyGroups }: { autocompleteCompanyGroups: string }) => {
    companyName.current = autocompleteCompanyGroups;
  };
  const handleChangeSelect = (value?: TableBodyCompanyGroup) => {
    companyInfo.current = value;
  };

  const getCompanyGroupsById = useCallback(async () => {
    const response = await companyGroupsStore.getCompanyGroupsById({ id: item.value });
    setCompanyGroup(companyGroupsStore.results);
  }, [companyGroupsStore, item.value]);

  useEffect(() => {
    if (item.value) {
      getCompanyGroupsById();
    }
  }, [getCompanyGroupsById, item.value]);

  const handleSaveChanges = useCallback(async () => {
    let idCompany;

    if (companyInfo.current?.id) {
      idCompany = companyInfo.current?.id;
    } else if (companyName.current?.trim()) {
      const response = await companyGroupsStore.createCompanyGroup({ name: companyName.current });
      idCompany = response?.id;
    }

    const accountId = item.contragentId ? item.contragentId : id;

    if (idCompany && accountId) {
      contragentsStore.patchAccountById({ id: accountId, patchParameters: { company_group: idCompany } });
    }

    companyInfo.current = undefined;
    companyName.current = '';
  }, [companyGroupsStore, contragentsStore, id]);

  const handleKeyDown = useCallback(
    (event: any) => {
      if (event.key === 'Enter') {
        handleSaveChanges();
      }
    },
    [handleSaveChanges],
  );

  return (
    <Grid item xs={2} sm={4} md={4} sx={{ padding: '20px 0 0 24px' }}>
      <ComponentToEditable
        title={item?.name}
        handleSave={handleSaveChanges}
        editComponent={
          <AutocompleteCompanyGroups
            onChange={handleChangeSelect}
            handleChangeInput={handleChangeInput}
            value={companyGroup?.name}
            onKeyDown={handleKeyDown}
          />
        }
      >
        {companyGroup?.name && <LabelLikeHintWithText value={companyGroup?.name} />}
      </ComponentToEditable>
    </Grid>
  );
};

const BusinessDescription = ({ item }: { item: any }) => {
  const { contragentsStore } = useRootStore();
  const { id } = useParams<{ id: string }>();
  const [text, setText] = useState<string>(item.value || '');
  const [initialText, setInitialText] = useState<string>(item.value || '');
  const [isModified, setIsModified] = useState<boolean>(false);
  const theme = useTheme();

  useEffect(() => {
    setInitialText(item.value?.company_description || '');
    setText(item.value?.company_description || '');
  }, [item.value]);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(e.target.value);
    setIsModified(true);
  };

  const handleConfirm = () => {
    const accountId = item.contragentId ? item.contragentId : id;
    if (text && accountId) {
      contragentsStore.patchAccountById({
        id: accountId,
        applicationId: item.contragentId ? id : undefined,
        patchParameters: {
          company_description: text,
          cntrprt: item?.value?.cntrprt,
          company_group: item?.value?.company_group,
        },
      });
    }
    setIsModified(false);
  };

  const handleClose = () => {
    setText(initialText);
    setIsModified(false);
  };

  return (
    <Grid
      item
      xs={2}
      sm={4}
      md={4}
      position={'relative'}
      sx={{
        gridArea: '5 / 1 / 6 / 4',
        padding: '20px 0 0 24px',
        [theme.breakpoints.down(900)]: {
          gridArea: '7 / 1 / 8 / 3',
        },
      }}
    >
      <LabelLikeHintWithText title={item?.name} />
      <TextareaStyled
        disabled={item?.disabled}
        placeholder="Опишите комментарии"
        value={text}
        onChange={handleChange}
        width={'calc(100% - 40px)'}
      />
      {isModified && (
        <Box
          sx={{
            mt: '12px',
            padding: '0 0 0 40px',
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '24px',
            width: '70%',
          }}
        >
          <Link
            component="button"
            underline="none"
            sx={{ fontSize: '14px', fontWeight: 500, lineHeight: '24px', color: color.black }}
            onClick={handleClose}
          >
            Отменить
          </Link>
          <Link
            component="button"
            sx={{ fontSize: '14px', fontWeight: 500, lineHeight: '24px', color: color.green_v1 }}
            underline="none"
            onClick={handleConfirm}
          >
            Сохранить
          </Link>
        </Box>
      )}
    </Grid>
  );
};

export const ComponentForGridItemByType = ({ item, index }: ComponentForGridItemByTypeProps) => {
  if (item.component?.type) {
    if (item.component.type === ComponentsType.MODAL && item.value) {
      return <OkvedModal item={item} key={index} />;
    }

    if (item.component?.type === ComponentsType.MODAL_OWNERS && item.value) {
      return <OwnerModal item={item} key={index} />;
    }

    if (
      item.component?.type?.includes(ComponentsType.TEXT_WITH_EDIT) &&
      item.component?.type?.includes(ComponentsType.LINK_WITH_EDIT)
    ) {
      return <EditableLinkOrPhones item={item} key={index} />;
    }
    if (item.component?.type === ComponentsType?.COMPANY_GROUP_WITH_SEARCH) {
      return <EditCompanyGroups item={item} key={index} />;
    }
    if (item.component?.type === ComponentsType?.BUSINESS_DESCRIPTION) {
      return <BusinessDescription item={item} key={index} />;
    }
  }

  return (
    <Grid item xs={2} sm={4} md={4} sx={{ padding: '20px 0 0 24px' }} key={index}>
      <LabelLikeHintWithText icon={item?.icon} title={item?.name} value={item?.value} mb={OFFSET.xs} />
    </Grid>
  );
};
