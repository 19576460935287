import React from 'react';
import styled from '@emotion/styled';
import { Checkbox, CheckboxProps } from '@mui/material';
import { color } from '@/constants/colors';

const BpIcon = styled('span')(() => ({
  borderRadius: 4,
  width: 20,
  height: 20,
  border: `0.2px solid ${color.signal_white}`,
  marginLeft: '-5px',
}));

const BpCheckedIcon = styled(BpIcon)({
  width: 22,
  height: 22,
  backgroundColor: color.light_green,
  borderWidth: `0`,
  borderRadius: 4,
  '&::before': {
    content: '""',
    display: 'block',
    width: 4,
    height: 9,
    border: `solid ${color.white}`,
    borderWidth: '0 1.8px 1.8px 0',
    position: 'absolute',
    top: '48%',
    left: '44%',
    transform: 'translate(-50%, -50%) rotate(45deg)',
    backgroundColor: color.transparent,
  },
});

export const CheckboxStyled = (props: CheckboxProps) => {
  return <Checkbox checkedIcon={<BpCheckedIcon />} icon={<BpIcon />} disableRipple {...props} />;
};
