import React, { useEffect } from 'react';

import { styled, Tab, Tabs } from '@mui/material';

import { OFFSET } from '@/constants/sizes';
import { ROLE } from '@/constants';
import { color } from '@/constants/colors';

interface IButtonGroupItemStyled {
  selected?: boolean;
}

// @ts-ignore
const TabStyled = styled(Tab, {
  shouldForwardProp: (selected) => selected,
})<IButtonGroupItemStyled>(() => ({
  height: '36px',
  padding: `${OFFSET.xs} ${OFFSET.sm}`,
  borderColor: color.light_green,
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '24px',
  color: color.slate_gray,
  textTransform: 'none',
  '&.Mui-selected': {
    color: color.light_green,
  },
  '&:hover': {
    color: color.green_jungle,
  },
  '&:active': {
    borderColor: color.green_jungle,
    color: color.green_jungle,
  },
}));

export interface TabBarItem {
  name: string;
  onClick?: () => void;
  selected?: boolean;
  hash?: string;
  permissions: string[];
  component?: any;
}

export const TabBarStyled = ({
  items,
  selected,
  onChangeTab,
  isLoading,
  userRole,
}: {
  items: TabBarItem[];
  selected: TabBarItem;
  onChangeTab: (currentTab: string) => void;
  isLoading?: boolean;
  userRole?: string | null;
}) => {
  const [value, setValue] = React.useState(selected.name);

  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    if (onChangeTab) {
      onChangeTab(newValue);
    }
  };

  useEffect(() => {
    setValue(selected.name);
  }, [selected]);

  return (
    <Tabs
      TabIndicatorProps={{ style: { background: color.light_green } }}
      value={value}
      onChange={handleChange}
      aria-label="secondary tabs example"
    >
      {items.map(({ name, permissions }) => {
        if (!permissions.includes(userRole ?? '') && userRole !== ROLE.ADMIN) {
          return null;
        }
        return <TabStyled key={name} value={name} label={name} disabled={Boolean(isLoading)} />;
      })}
    </Tabs>
  );
};
