import React, { ReactNode, ReactElement, Dispatch, SetStateAction, MutableRefObject } from 'react';
import style from '@/components/Contagents/ContactTabs/ContractReportTable.module.scss';

import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import { Table, TableBody, TableCell, TableRow, TableHead } from '@mui/material';

import { color } from '@/constants/colors';
import { StaticRow, TableCellPeriod } from '@/components/Contagents/ContactTabs/ContractReportTableFinancialResults';
import { RemovingSpaces } from '@/utils/reportTable';

export type RowData = {
  row2110: number[];
  row2120: number[];
  row2100: number[];
  row2210: number[];
  row2220: number[];
  row2200: number[];
  row2310: number[];
  row2320: number[];
  row2330: number[];
  row2340: number[];
  row2350: number[];
  row2300: number[];
  row2410: number[];
  row2411: number[];
  row2412: number[];
  row2460: number[];
  row2400: number[];
};

interface ContractReportTableErrorFinancialResultsProps {
  activeYear?: string[];
  formattedDate: (date: string) => string;
  StaticRowsError: StaticRow[];
  years: string[];
  inputRefs: MutableRefObject<(HTMLInputElement | null)[]>;
  valueWithSpaces: (value: string | null) => string;
  setIsRowModified: Dispatch<SetStateAction<{ [key: string]: boolean }>>;
  setIsDataEdited: Dispatch<SetStateAction<boolean>>;
  tableDataPeriod: Record<string, TableCellPeriod>;
}

const ContractReportTableErrorFinancialResults: React.FC<ContractReportTableErrorFinancialResultsProps> = ({
  activeYear,
  formattedDate,
  StaticRowsError,
  years,
  inputRefs,
  valueWithSpaces,
  setIsRowModified,
  setIsDataEdited,
  tableDataPeriod,
}) => {
  const handleInputChangePeriod = (event: React.ChangeEvent<HTMLInputElement>, keyPeriod: string, index: number) => {
    setIsDataEdited(true);

    setIsRowModified((prevModified) => ({
      ...prevModified,
      [keyPeriod]: true,
    }));
  };

  const RowData: RowData = {
    row2110: years.map((year) => parseInt(RemovingSpaces(tableDataPeriod[`2110-${year}`].value.toString()), 10)),
    row2120: years.map((year) => parseInt(RemovingSpaces(tableDataPeriod[`2120-${year}`].value.toString()), 10)),
    row2100: years.map((year) => parseInt(RemovingSpaces(tableDataPeriod[`2100-${year}`].value.toString()), 10)),
    row2210: years.map((year) => parseInt(RemovingSpaces(tableDataPeriod[`2210-${year}`].value.toString()), 10)),
    row2220: years.map((year) => parseInt(RemovingSpaces(tableDataPeriod[`2220-${year}`].value.toString()), 10)),
    row2200: years.map((year) => parseInt(RemovingSpaces(tableDataPeriod[`2200-${year}`].value.toString()), 10)),
    row2310: years.map((year) => parseInt(RemovingSpaces(tableDataPeriod[`2310-${year}`].value.toString()), 10)),
    row2320: years.map((year) => parseInt(RemovingSpaces(tableDataPeriod[`2320-${year}`].value.toString()), 10)),
    row2330: years.map((year) => parseInt(RemovingSpaces(tableDataPeriod[`2330-${year}`].value.toString()), 10)),
    row2340: years.map((year) => parseInt(RemovingSpaces(tableDataPeriod[`2340-${year}`].value.toString()), 10)),
    row2350: years.map((year) => parseInt(RemovingSpaces(tableDataPeriod[`2350-${year}`].value.toString()), 10)),
    row2300: years.map((year) => parseInt(RemovingSpaces(tableDataPeriod[`2300-${year}`].value.toString()), 10)),
    row2410: years.map((year) => parseInt(RemovingSpaces(tableDataPeriod[`2410-${year}`].value.toString()), 10)),
    row2411: years.map((year) => parseInt(RemovingSpaces(tableDataPeriod[`2411-${year}`].value.toString()), 10)),
    row2412: years.map((year) => parseInt(RemovingSpaces(tableDataPeriod[`2412-${year}`].value.toString()), 10)),
    row2460: years.map((year) => parseInt(RemovingSpaces(tableDataPeriod[`2460-${year}`].value.toString()), 10)),
    row2400: years.map((year) => parseInt(RemovingSpaces(tableDataPeriod[`2400-${year}`].value.toString()), 10)),
  };

  const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, rowIndex: number, indexYear: number) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      let nextRowIndex = rowIndex + 1;

      if (nextRowIndex < StaticRowsError.length) {
        let nextInput = inputRefs.current[nextRowIndex * years.length + indexYear];

        while (nextInput && nextInput.disabled) {
          nextRowIndex++;
          nextInput = inputRefs.current[nextRowIndex * years.length + indexYear];
        }

        if (nextInput) {
          nextInput.focus();
        }
      }
    }
  };
  return (
    <TableContainer
      component={Paper}
      sx={{ minWidth: 600, maxWidth: 900, overflow: 'auto', backgroundColor: `${color.light_carmine_pink}` }}
      className={style.tableErrorContainer}
    >
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell
              sx={{ minWidth: '340px', border: `1px solid ${color.white_light}`, fontWeight: '700', p: '6px' }}
              align="right"
            >
              Формула расчета
            </TableCell>
            {activeYear &&
              activeYear.map((year) => (
                <TableCell
                  key={year}
                  sx={{
                    minWidth: '155px',
                    border: `1px solid ${color.white_light}`,
                    fontWeight: '700',
                    p: '6px',
                    maxWidth: '120px',
                  }}
                  align="center"
                >
                  Расхождение за {formattedDate(year)}
                </TableCell>
              ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {StaticRowsError.map((staticRow, index) => {
            const { code } = staticRow;

            return (
              <TableRow key={index}>
                <TableCell sx={{ border: `1px solid ${color.white_light}`, p: '6px' }} align="right">
                  {staticRow.name}
                </TableCell>
                {years.map((year, indexYear) => {
                  const keyPeriod = `${code}-${year}`;

                  let inputValue = '';

                  if (code === 2110_2120_2100 && activeYear && activeYear.includes(year)) {
                    const value2110 = RowData.row2110[indexYear] || 0;
                    const value2120 = RowData.row2120[indexYear] || 0;
                    const value2100 = RowData.row2100[indexYear] || 0;

                    const difference = value2110 - value2120 - value2100;

                    inputValue = difference !== 0 ? difference.toString() : '';

                    return (
                      <TableCell
                        sx={{ p: '6px 16px', border: `1px solid ${color.white_light}`, maxWidth: '120px' }}
                        key={keyPeriod}
                      >
                        <input
                          ref={(el) => inputRefs.current.push(el)}
                          className={style.calculationInput}
                          type="text"
                          value={valueWithSpaces(inputValue)}
                          onChange={(e) => handleInputChangePeriod(e, keyPeriod, index)}
                          onKeyDown={(e) => handleInputKeyDown(e, index, indexYear)}
                          disabled
                        />
                      </TableCell>
                    );
                  }
                  if (code === 2100_2210_2220_2200 && activeYear && activeYear.includes(year)) {
                    const value2100 = RowData.row2100[indexYear] || 0;
                    const value2210 = RowData.row2210[indexYear] || 0;
                    const value2220 = RowData.row2220[indexYear] || 0;
                    const value2200 = RowData.row2200[indexYear] || 0;

                    const difference = value2100 - value2210 - value2220 - value2200;

                    inputValue = difference !== 0 ? difference.toString() : '';

                    return (
                      <TableCell
                        sx={{ p: '6px 16px', border: `1px solid ${color.white_light}`, maxWidth: '120px' }}
                        key={keyPeriod}
                      >
                        <input
                          ref={(el) => inputRefs.current.push(el)}
                          className={style.calculationInput}
                          type="text"
                          value={valueWithSpaces(inputValue)}
                          onChange={(e) => handleInputChangePeriod(e, keyPeriod, index)}
                          onKeyDown={(e) => handleInputKeyDown(e, index, indexYear)}
                          disabled
                        />
                      </TableCell>
                    );
                  }
                  if (code === 2200_2310_2320_2330_2340_2350_2300 && activeYear && activeYear.includes(year)) {
                    const value2200 = RowData.row2200[indexYear] || 0;
                    const value2310 = RowData.row2310[indexYear] || 0;
                    const value2320 = RowData.row2320[indexYear] || 0;
                    const value2330 = RowData.row2330[indexYear] || 0;
                    const value2340 = RowData.row2340[indexYear] || 0;
                    const value2350 = RowData.row2350[indexYear] || 0;
                    const value2300 = RowData.row2300[indexYear] || 0;

                    const difference =
                      value2200 + value2310 + value2320 - value2330 + value2340 - value2350 - value2300;

                    inputValue = difference !== 0 ? difference.toString() : '';
                    return (
                      <TableCell
                        sx={{ p: '6px 16px', border: `1px solid ${color.white_light}`, maxWidth: '120px' }}
                        key={keyPeriod}
                      >
                        <input
                          ref={(el) => inputRefs.current.push(el)}
                          className={style.calculationInput}
                          type="text"
                          value={valueWithSpaces(inputValue)}
                          onChange={(e) => handleInputChangePeriod(e, keyPeriod, index)}
                          onKeyDown={(e) => handleInputKeyDown(e, index, indexYear)}
                          disabled
                        />
                      </TableCell>
                    );
                  }
                  return null;
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ContractReportTableErrorFinancialResults;
